import { LOSModel, ApiResponse } from '@/models';
import BaseService from '@/utils/base-service';

export class LOSService extends BaseService {
    private endpoint = '/line-of-sight';

    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async getLosData(): Promise<ApiResponse<LOSModel[]>> {
        return (await this.api.get(`${this.endpoint}`)).data;
    }

    public async postLosData(losModel: LOSModel): Promise<ApiResponse<LOSModel>> {
        return (await this.api.post(`${this.endpoint}`, losModel)).data;
    }
}
