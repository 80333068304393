<template>
    <div class="eas-loader">
        <div class="loader"></div>
        <div class="loader-text">{{ $t('loading') }}</div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({
    name: 'eas-loader',
})
export default class EasLoader extends Vue {}
</script>

<style lang="less">
@import '../../variables.less';
.eas-loader{
    z-index: 99;
    .loader-text{
        position: absolute;
        top: 50%;
        left: 45%;
        width: 110px;
        height: 110px;
        line-height: 110px;
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        color: @uniper-blue;
        z-index: inherit;
    }
    .loader {
        position: absolute;
        z-index: inherit;
        top: 50%;
        left: 45%;
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid @uniper-blue;
        width: 80px;
        height: 80px;
        text-align: center;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}

</style>