import { ApiResponse } from '@/models';
import BaseService from '@/utils/base-service';

export class AssetFleetService extends BaseService {
    private endpoint = '/MachineNameMapping/MachinesWithfinalSno';
    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async getFinalSnoList(fleetIds: any): Promise<ApiResponse<any[]>> {
        return (await this.api.post(`${this.endpoint}`, fleetIds)).data;
    }
}
