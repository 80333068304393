<template>
    <div class="report-box">
        <div class="flex-row box-header">
            <div class="flex-col flex-10 justify-center"><slot name="title"></slot></div>
            <div class="flex-col flex-2 justify-center align-end action"><slot name="action"></slot></div>
        </div>
        <div class="flex-row">
            <div class="flex-col flex-1">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'report-box',
})
export default class ReportBox extends Vue {}
</script>

<style lang="less">
@import '../../variables.less';
.report-box{
    .box-header{
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
        color: @dark-blue;
        .action{
            padding-right: 1rem;
            a {
                color: grey;
            }
        }
    }
    .justify-center{
        justify-content: center;
    }
    .align-end{
        align-items: flex-end;
    }
}
</style>