import { Vue, Component } from 'vue-property-decorator';
import Permissions from '@/utils/permissions';

@Component({})
export default class ComponentSecurity extends Vue {
    protected permission = new Permissions();
    protected readonly hasViewAccess = this.permission.hasViewAccess();
    protected readonly hasEditAccess = this.permission.hasEditAccess();
    protected readonly hasLosEditAccess = this.permission.hasLosEditAccess();
    protected readonly hasDataFreezeAccess = this.permission.hasDataFreezeAccess();
}
