export class FileDownload {
    fileName: string;
    data: ArrayBuffer;

    constructor(fileName: string, data: ArrayBuffer) {
        this.fileName = fileName;
        this.data = data;
    }

    static fromResponse(response: any, defaultFileName: string): FileDownload {
        const contentDisposition = response.headers['content-disposition'];
        let fileName = defaultFileName;
        if (contentDisposition) {
            const filenameParts = contentDisposition
                .split(';')
                .map((s: string) => { return s.trim(); })
                .filter((s: string) => { return s.startsWith('filename='); });
            if(filenameParts.length > 0) {
                const fileNameMatch = filenameParts[0].match(/filename=(.+)/);
                console.log(filenameParts[0])
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }

        }
        return new FileDownload(fileName, response.data);
    }
}
