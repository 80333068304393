<template>
    <div class="kpi-c">
        <div v-if="!data || data.length==0" class="loader"></div>
        <div v-else id="type-c-Chart">
            <div class="flex-col flex-1">
                <div class="flex-row flex-1 chart-c">
                    <div class="flex-col flex-1">
                        <div class="flex-row flex-1">
                            <div class="flex-col flex-1">
                                <div class="flex-row flex-1 mb-1 flex-justufy-center chart-title">{{data[0].kpiName}}</div>
                                <div class="flex-row flex-1">
                                    <div class="flex-col flex-1 flex-align-center chart-title" style="padding-left: 4rem">{{ $t('actual') }}</div>
                                    <div class="flex-col flex-1 flex-align-center chart-title" >MTP {{ yearLabel }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-row flex-3">
                            <KpiNChart :chartData="data[0]" :chartDiv="kpi1" />
                        </div>
                    </div>
                    <div class="flex-col flex-1">
                        <div class="flex-row flex-1">
                            <div class="flex-col flex-1">
                                <div class="flex-row flex-1 mb-1 flex-justufy-center chart-title">{{data[1].kpiName}}</div>
                                <div class="flex-row flex-1">
                                    <div class="flex-col flex-1 flex-align-center chart-title" style="padding-left: 4rem">{{ $t('actual') }}</div>
                                    <div class="flex-col flex-1 flex-align-center chart-title">MTP {{ yearLabel }}</div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="flex-row flex-3">
                            <KpiNChart :chartData="data[1]" :chartDiv="kpi2" />
                        </div>
                    </div>
                </div>
                <div class="flex-row flex-1 flex-justufy-center pl-2 pr-2">
                    <div class="flex-col flex-1"><div class="flex-row flex-1 flex-align-center"><div class="target rotate-90-degree mr-1">|</div>Target</div></div>
                    <div class="flex-col flex-1 flex-align-center"><div class="flex-row flex-1 flex-align-center"><div class="peer rotate-90-degree mr-1">|</div>Peer</div></div>
                    <div class="flex-col flex-1 flex-align-end"><div class="flex-row flex-1 flex-align-center"><div class="asset rotate-90-degree mr-1">|</div>{{asset.name}}</div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import KpiNChart from './kpi-n.vue';
import { Asset } from '@/models';

@Component({
    components: {
        KpiNChart,
    },
})
export default class KpiC extends Vue {
    /**
     * set the type C chart data
     */
    @Prop({ required: true })
    private chartData!: any;

    /**
     * set the asset details
     */
    @Prop({ required: true })
    private asset!: Asset;

    /**
     * set the serial number of report
     */
    @Prop({ required: true })
    private serialNumber!: number;

    /**
     * Set chart title year.
     */
    @Prop({ required: true })
    private yearLabel!: number;

    private data: any[] | null = null;
    private kpi1 = 'kpi1' + this.serialNumber;
    private kpi2 = 'kpi2' + this.serialNumber;

    private mounted(): void {
        this.updateData(this.chartData);
    }

    @Watch('chartData')
    private updateComponent(newChartData: any): void {
        this.updateData(newChartData);
    }

    private updateData(data: any): void {
        this.data = [];
        for ( let i = 0; i < data.length; i++ ) {
            const datai = {
                assetName: this.asset.name,
                kpiName: 'KPI' + (i + 1).toString() + ': ' + data[i].kpiName,
                year: data[i].kpiValues.years,
                target: data[i].kpiValues.targetActual,
                peer: data[i].kpiValues.peerAct,
                asset: data[i].kpiValues.actual,
                mtpPeer: data[i].kpiValues.peerMtp,
                mtpTarget: data[i].kpiValues.targetMtp,
            };
            this.data.push(datai);
        }
    }
}
</script>
<style lang="less" scoped>
@import '../../../variables.less';
.flex-justufy-end {
    justify-content: flex-end;
}
.flex-justufy-center {
    justify-content: center;
}
.flex-justufy-between {
    justify-content: space-around;
}
.flex-align-end {
    align-items: flex-end;
}
.flex-align-center {
    align-items: center;
}
.kpi-c {
    .chart-c {
        font-size: 5px;
    }
    .target {
        color: @dark-green;
        font-size: 3rem;
        font-weight: 2rem;
        white-space: pre-wrap;
    }
    .peer {
        color: @uniper-blue-light;
        font-size: 3rem;
        font-weight: 2rem;
        white-space: pre-wrap;
    }
    .asset {
        color: @medium-red;
        font-size: 3rem;
        font-weight: 2rem;
        white-space: pre-wrap;
    }
    .rotate-90-degree {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .loader {
        min-height: 12rem;
    }
}
</style>
