<template>
    <transition :name="fast ? 'fade-fast' : 'fade'" :appear="appear">
        <slot/>
    </transition>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import './fade-transition.less';

@Component({
    name: 'fade-transition',
})
export default class FadeTransition extends Vue {
    /**
     * Set the "appear" property for the inner transition element.
     */
    @Prop({ default: false })
    private appear!: boolean;

    /**
     * Set the transition speed
     */
    @Prop({ default: false })
    private fast!: boolean;
}
</script>
