<template>
    <div class="performance flex-container">
        <div v-if="!isDataLoaded">{{ $t('loading') }}</div>
        <div v-else class="flex-col flex-1 reverse performance-content">
            <div v-if="!isInEditMode" class="flex-row">
                <div class="flex-col flex-1 label mr-3">{{ $t('comment') }}: </div>
                <div class="flex-col flex-8 comment">{{ comment }}</div>
            </div>
            <div
                class="flex-row mb-1"
                :class="{'perf-charts': isNewUi}"
            >
                <div class="flex-col flex-10">
                    <div v-if="isDataLoaded" class="flex-row flex-justify-center mt-2">
                        <KpiA
                            v-if="kpiType==='A'"
                            :kpiAData="chartData"
                            :serialNumber="serialNumber"
                            :yearLabel="yearLabel"
                        />
                        <KpiB
                            v-else-if="kpiType==='B'"
                            :kpiBData="kpiresult.data.typeBC[0]"
                            :asset="asset"
                            :serialNumber="serialNumber"
                            :yearLabel="yearLabel"
                        />
                        <KpiC
                            v-else-if="kpiType==='C'"
                            :chartData="chartData"
                            :asset="asset"
                            :serialNumber="serialNumber"
                            :yearLabel="yearLabel"
                        />
                        <KpiD v-else-if="kpiType==='D' && !isInEditMode" :chartData="chartData"/>
                        <KpiDEdit v-else-if="kpiType==='D' && isInEditMode"
                            :chartData="chartData"
                            @type-d-values="getTypeDValues"
                        />
                        <div v-else class="blank-height"></div>
                    </div>
                    <div v-if="kpiType && (kpiType==='A' || kpiType==='B' || kpiType==='C' || kpiType==='D')">
                        <div v-if="isInEditMode" class="flex-row">
                            <div class="flex-col flex-1 edit-comment">
                                <div class="mt-1 perf-comment">
                                    <label for="comment" class="perf-label">{{ $t('comment') }}</label>
                                    <textarea
                                        id="comment"
                                        @keyup="updateComponentInRealTime()"
                                        class="comment-input"
                                        :placeholder="$t('comment')"
                                        v-model="comment"
                                        maxlength="500"
                                        rows="4"
                                    ></textarea>
                                </div>
                                <div class="mt-3 perf-comment">
                                    <label for="updComment" class="perf-label">{{ $t('reason') }}</label>
                                    <textarea
                                        id="updComment"
                                        @keyup="updateComponentInRealTime()"
                                        class="comment-input"
                                        :placeholder="$t('reason')"
                                        v-model="updComment"
                                        maxlength="250"
                                        rows="4"
                                    ></textarea>
                                </div>
                                <div :class="errorExists ? 'error-text': ''">
                                    <div v-if="errorExists">{{ $t('thisFieldIsMandatory') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Asset, KpiBCData, KpiResult } from '@/models';
import { EventBus } from '@/utils';
import { PerformanceAssessmentService } from '@/services';
import KpiDEdit from './kpi-d/kpi-d-edit.vue';
import KpiA from './kpi-a/kpi-a.vue';
import KpiB from './kpi-b.vue';
import KpiC from './kpi-c/kpi-c.vue';
import KpiD from './kpi-d/kpi-d.vue';

@Component({
    name: 'performance',
    components: { KpiA, KpiB, KpiC, KpiD, KpiDEdit },
})
export default class PerformanceAssessment extends Vue {
    /**
     * Serial Number of the Report
     */
    @Prop({ required: true })
    private serialNumber!: number;
    /**
     * Selected Asset
     */
    @Prop({ required: true })
    private asset!: Asset;

    /**
     * Set popup mode
     */
    @Prop({ default: false })
    private isPopup!: boolean;

    /**
     * UI flag for old and new layout
     */
    @Prop({ default: true })
    private isNewUi!: boolean;

    private kpiType: string | null = null;
    private data: any;
    private chartData: any | null = null;
    private isDataLoaded = false;
    private perfService: PerformanceAssessmentService = new PerformanceAssessmentService();
    private kpiresult: any = {};
    private comment = '';
    private isInEditMode = false;
    private errorExists = false;
    private updComment = '';
    private typeDValue1 = 0;
    private typeDValue2 = 0;
    private componentId = 2;
    private showConfirmModal = false;
    private yearLabel = 0;


    private prepareTypeBData(data: KpiBCData): void {
        this.chartData = {
            assetName: this.asset.name,
            kpiName: 'KPI:' + data.kpiName,
            year: data.kpiValues.years,
            target: data.kpiValues.targetActual,
            peer: data.kpiValues.peerAct,
            asset: data.kpiValues.actual,
            mtpPeer: data.kpiValues.peerMtp,
            mtpTarget: data.kpiValues.targetMtp,
        };
    }

    private mounted(): void {
        this.getKpiData(this.serialNumber);
        this.isDataLoaded = true;
        if (this.isPopup) {
            EventBus.$on(EventBus.REPORT_MODAL.EDIT, (modalId: number) => this.makeEditable(modalId));
            EventBus.$on(EventBus.REPORT_MODAL.SAVE, (modalId: number) => this.save(modalId));
            EventBus.$on(EventBus.REPORT_MODAL.RESET, (modalId: number) => this.reset(modalId));
            EventBus.$on(EventBus.REPORT_MODAL.CLOSE, (modalId: number) => this.cancel(modalId));
        }
    }
    @Watch('serialNumber')
    private updateComponent(newSerialNumber: number): void {
        this.getKpiData(newSerialNumber);
        this.isDataLoaded = true;
    }

    private async getKpiData(serialNumber: number): Promise<void> {
        const kpiResult: KpiResult = (await this.perfService.get(serialNumber)).result;
        if (kpiResult.type === 'B') {
            this.kpiType = kpiResult.type;
        } else if (kpiResult.type === 'C') {
            this.chartData = kpiResult.data.typeBC;
            this.kpiType = kpiResult.type;
        } else if (kpiResult.type === 'A') {
            this.kpiType = 'A';
            this.chartData = kpiResult.data.typeA;
        } else {
            this.kpiType = 'D';
            this.chartData = kpiResult.data.typeD;
        }
        this.kpiresult = kpiResult;
        this.comment = kpiResult.comment;
        this.yearLabel = kpiResult.data.yearLabel;
    }

    private updateComponentInRealTime(): void {
        this.showConfirmModal = true;
        EventBus.$emit(EventBus.REPORT.SHOW_CONFIRMATION, this.showConfirmModal);
    }

    private makeEditable(modalId: number): void {
        this.isInEditMode = modalId === this.componentId;
    }
    private getTypeDValues(values: {
        typeDValue1: number,
        typeDValue2: number,
        }): void {
        this.typeDValue1 = values.typeDValue1;
        this.typeDValue2 = values.typeDValue2;
    }
    private async save(modalId: number): Promise<void> {
        if (modalId !== this.componentId ) {
            return;
        }
        if (this.updComment === '') {
            this.errorExists = true;
        } else {
            this.errorExists = false;
            this.isInEditMode = false;
            this.kpiresult.comment = this.comment;
            this.kpiresult.updComment = this.updComment;
            const typeD = {...this.kpiresult.data.typeD[0]};
            typeD.typeDValue1 = this.typeDValue1;
            typeD.typeDValue2 = this.typeDValue2;
            this.kpiresult.data.typeD[0] = {...typeD};
            const sno = (await this.perfService.savePerData(this.serialNumber, this.kpiresult)).result.sNo;
            EventBus.$emit(EventBus.REPORT.UPDATE, sno);
        }
    }
    private async reset(modalId: number): Promise<void> {
        if (modalId !== this.componentId ) {
            return;
        }
        this.comment = this.kpiresult.comment;
        this.updComment = '';
    }
    private cancel(modalId: number): void {
        if (modalId !== this.componentId ) {
            return;
        }
        this.isInEditMode = false;
        this.comment = this.kpiresult.comment;
        this.updComment = '';
    }
    private closeModal(): void {
        this.errorExists = false;
        this.isInEditMode = false;
    }
    private beforeDestroy(): void {
        if (this.isPopup) {
            EventBus.$off(EventBus.REPORT_MODAL.EDIT);
            EventBus.$off(EventBus.REPORT_MODAL.SAVE);
            EventBus.$off(EventBus.REPORT_MODAL.RESET);
            EventBus.$off(EventBus.REPORT_MODAL.CLOSE);
        }
    }
}

</script>

<style scoped lang="less">
@import './performance-assessment.less';
</style>
