import { render, staticRenderFns } from "./bulk-update-upload.vue?vue&type=template&id=2262dcfa&scoped=true"
import script from "./bulk-update-upload.vue?vue&type=script&lang=ts"
export * from "./bulk-update-upload.vue?vue&type=script&lang=ts"
import style0 from "./bulk-update-upload.vue?vue&type=style&index=0&id=2262dcfa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2262dcfa",
  null
  
)

export default component.exports