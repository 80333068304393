<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import {KpiMappingsService} from '@/services/kpi-mappings-service';
import ContentWrapper from '@/components/content-wrapper/content-wrapper.vue';
import {KpiMapping} from '@/models/kpi-mapping';
import KpiMappingEditor from '@/components/kpi-mapping-editor/kpi-mapping-editor.vue';
import {KpiDto} from '@/models/kpi-dto';
import CustomSelect from '@/components/dropdown/custom-select.vue';
import {toastModuleDisplayError} from '@/store/toastModule';
import EasLoader from '@/components/loader/eas-loader.vue';

@Component({name: 'kpi-mappings',
    components: {EasLoader, CustomSelect, KpiMappingEditor, ContentWrapper}
})
export default class KpiMappings extends Vue
{
    private kpiMappingsService = new KpiMappingsService();
    private mappings: KpiMapping[] = [];
    private availableKpis: KpiDto[] = [];
    private filter = '';
    private availableMappings: KpiMapping[] = [];
    private loading = false;

    private showModal = false;
    private activeMapping: KpiMapping | undefined;

    private async mounted(): Promise<void> {
        await this.loadData();
    }

    private async loadData(): Promise<void> {
        try {
            this.loading = true;
            const result = await this.kpiMappingsService.getAll();
            const kpisResult = await this.kpiMappingsService.getAvailableKpis();

            this.mappings = result.result.sort((a, b) => a.assetName.localeCompare(b.assetName));
            this.availableKpis = kpisResult.result.sort((a, b) => a.name.localeCompare(b.name));
            this.updateFilter();
        } catch (error) {
            await this.$store.dispatch(toastModuleDisplayError, error);
        } finally {
            this.loading = false;
        }

    }

    private updateFilter(): void {
        this.availableMappings = this.mappings.filter((v) => { return `${v.hierarchySid} ${v.assetName}`.toLowerCase().includes(this.filter.toLowerCase()) });
    }

    private clearFilter(): void {
        this.filter = '';
        this.updateFilter();
    }

    private edit(mapping: KpiMapping): void {
        this.showModal = true;
        this.activeMapping = mapping;
    }

    private onModalClose($event: { reason: string }): void {
        this.showModal = false;
        this.activeMapping = undefined;
        if($event.reason == 'save') {
            this.loadData();
        }
    }
}
</script>
<template>
    <div>
        <modal-box v-if="showModal && activeMapping" :open="showModal"
                   :onClose.prop="() => showModal=false"
                   :enableHeader.prop="true"
                   :enableFooter.prop="false"
                   :enableHeaderClose.prop="true"
                   :transparentOverlay.prop="false"
                   :closeOnClickOutside.prop="true">
            <div slot="header" style="line-height: var(--adam-d48)">
                <h3>{{ activeMapping.assetName }}</h3>
            </div>
            <kpi-mapping-editor :mapping="activeMapping" :kpis="availableKpis" @close="onModalClose($event)"></kpi-mapping-editor>
        </modal-box>
        <content-wrapper>
            <template v-slot:filters>
                <div class="pl-2">
                    <div class="search-filter">
                        <input type="text" class="search" :placeholder="$t('search')" v-model="filter" @keyup="updateFilter()"/>
                        <font-awesome-icon class="search-icon fa-2x" icon="search"></font-awesome-icon>
                        <button
                            v-show="filter !== ''"
                            class="clear-search"
                            @click="clearFilter()">
                            <font-awesome-icon :icon="['fas', 'times']" class="clear"/>
                        </button>
                    </div>
                </div>
            </template>
            <template  v-slot:content>
                <div v-if="loading" class="popup">
                    <eas-loader></eas-loader>
                </div>
                <div class="mr-2 ml-2">
                    <div class="alert alert-info">
                        {{ $t('kpiMappingsInfo') }}
                    </div>
                    <div class="kpi-mapping mb-1" v-for="mapping in availableMappings" :key="mapping.hierarchySid">
                        <div class="flex-row pr-1 pl-1 pb-2 pt-2 ">
                            <div class="flex-1">
                                <strong>{{ mapping.hierarchySid }} - {{ mapping.assetName }}</strong>
                                <p>{{ $t('chartTypes.' + mapping.chartType) }}</p>
                            </div>
                            <div class="flex-3">
                                <ol class="mt-0">
                                    <li v-for="kpi in mapping.kpis" :key="kpi.id">{{ kpi.name }}</li>
                                </ol>
                            </div>
                            <div>
                                <button class="btn btn-default" @click="edit(mapping)" :disabled="!['Unset', 'PerformanceBenchmark', 'SingleKpi'].includes(mapping.chartType)">
                                    <font-awesome-icon icon="edit"></font-awesome-icon> {{ $t('edit') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </content-wrapper>
    </div>

</template>

<style scoped lang="less">
@import '../../variables.less';

.kpi-mapping {
    border: 1px #d1d1d1 solid;
    border-radius: --adam-border-radius;
}
.border-top {
    border-top: 1px #b4b4b4 solid;
}

.btn:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: gray;
    border-color: gray;
}

.search-filter input {
    padding-left: 4rem;
    padding-right: 4rem;
    width: 100%;
    max-width: 19rem;
    border: none;
    background: @white;
    border-radius: 0;
    &:focus {
        border-bottom: 0.1rem solid @uniper-blue;
        transition: 0s ease-in;
        outline: none;
    }
}
.search-filter {
    height: 4rem;
    width: 25rem;
    bottom: 3.5rem;
}
.search-icon {
    position: absolute;
    color: @warm-grey;
    top: 0;
    bottom: 0;
    left: 1.7rem;
    padding: 1.7rem 1rem 1rem;
}
.clear-search {
    position: absolute;
    color: @warm-grey;
    top: 0;
    left: 26rem;
    bottom: 0;
    height: 2rem;
    margin: 1.2rem 1.2rem 1.2rem 1rem;
}
.search {
    background-color: @pale-grey;
    border: none;
    margin-bottom: 2rem;
}
</style>
