import { render, staticRenderFns } from "./pr-recommendations-bulk-update-upload.vue?vue&type=template&id=7f762280&scoped=true"
import script from "./pr-recommendations-bulk-update-upload.vue?vue&type=script&lang=ts"
export * from "./pr-recommendations-bulk-update-upload.vue?vue&type=script&lang=ts"
import style0 from "./pr-recommendations-bulk-update-upload.vue?vue&type=style&index=0&id=7f762280&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f762280",
  null
  
)

export default component.exports