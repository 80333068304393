<template>
    <div class='final-ribbon'>
        <bulk-download
            :show-bulk-download="showBulkDownloadModal"
            :checked-assets="checkedAssets"
            @close="showBulkDownloadModal = false"></bulk-download>
        <eas-loader v-if="!isDataLoaded"></eas-loader>
        <div v-else class="flex-container pl-2 pr-2">
            <div class="flex-col flex-2">
                <div class="flex-row">
                    <div class="flex-col">
                        <div class="flex-row filter pt-1 pl-4" v-click-outside="hideFilterFleet">
                            <a
                                href="javascript:void(0)"
                                @click="toggleFleet()"
                                :class="['select-asset', { active: fleetSelect.isActive }]"
                            >
                            {{ $t("selectFleet") }}
                            <img
                                src="@/assets/icons/ic-triangle-dropdown@2x.png"
                                v-if="!fleetSelect.isActive"
                                :class="['triangle ml-1', { up: fleetSelect.isExpanded }]"
                            />
                            <img
                                src="@/assets/icons/ic-triangle-dropdown-dark@2x.png"
                                v-else
                                :class="['triangle ml-1', { up: fleetSelect.isExpanded }]"
                            />
                            </a>
                            <fade-transition :fast="true">
                                <div
                                    class="filter-expand filter-expand--type"
                                    v-if="fleetSelect.isExpanded"
                                >
                                    <div class="checkboxFilter">
                                        <label
                                            class="checkbox-container"
                                        >
                                            <input
                                                type="checkbox"
                                                @click="checkAllFleet()"
                                                v-model="fleetSelect.isCheckAll"
                                            />{{ $t('selectAll') }}
                                        <span class="checkmark"></span>
                                        </label>
                                        <label
                                            class="checkbox-container"
                                            v-for="fleet in fleetList"
                                            :key="fleet.sid"
                                        >
                                            <input
                                                type="checkbox"
                                                v-model="checkedFleets"
                                                @change="updateCheckallFleet()"
                                                :value="fleet.sid"
                                            />{{ fleet.name }}
                                        <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <button
                                        type="button"
                                        class="done-filter-button mt-1"
                                        @click="filterChangeFleet()"
                                        :disabled="disableDoneFleet()"
                                    >
                                    {{ $t("done") }}
                                    </button>
                                </div>
                            </fade-transition>
                        </div>
                    </div>
                    <div class="flex-col">
                        <div class="flex-row filter pt-1 pl-4" v-click-outside="hideFilter">
                            <a
                                href="javascript:void(0)"
                                @click="toggleAsset()"
                                :class="['select-asset', { active: assetSelect.isActive }]"
                            >
                            {{ $t("selectAsset") }}
                            <img
                                src="@/assets/icons/ic-triangle-dropdown@2x.png"
                                v-if="!assetSelect.isActive"
                                :class="['triangle ml-1', { up: assetSelect.isExpanded }]"
                            />
                            <img
                                src="@/assets/icons/ic-triangle-dropdown-dark@2x.png"
                                v-else
                                :class="['triangle ml-1', { up: assetSelect.isExpanded }]"
                            />
                            </a>
                            <fade-transition :fast="true">
                                <div
                                    class="filter-expand filter-expand--type"
                                    v-if="assetSelect.isExpanded"
                                >
                                    <div class="checkboxFilter">
                                        <label
                                            v-if="isSelectAllHidden"
                                            class="checkbox-container"
                                        >
                                            <input
                                                type="checkbox"
                                                @click="checkAllAssets()"
                                                v-model="assetSelect.isCheckAll"
                                            />{{ $t('selectAll') }}
                                        <span class="checkmark"></span>
                                        </label>
                                        <label
                                            v-else
                                            class="no-assets"
                                        >{{ $t('noDataAvailable') }}
                                        </label>
                                        <label
                                            class="checkbox-container"
                                            v-for="asset in assets"
                                            :key="asset.id"
                                        >
                                            <input
                                                type="checkbox"
                                                v-model="checkedAssets"
                                                @change="updateCheckallAssets()"
                                                :value="asset"
                                            />{{ asset.name }}
                                        <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <button
                                        type="button"
                                        class="done-filter-button mt-1"
                                        @click="filterChange()"
                                        :disabled="disableDone()"
                                    >
                                    {{ $t("done") }}
                                    </button>
                                </div>
                            </fade-transition>
                        </div>
                    </div>
                    <div class="flex-col">
                        <div class="flex-row ml-2">
                            <button
                                type="button"
                                class="remove-button reset-filter-button"
                                :class="{reset: isResetActive}"
                                @click="resetFilter()">
                                {{ $t('resetFilters') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-col flex-1" >
                <div class="flex-row flex-1 reverse">
                    <div v-if="hasEditAccess" class="flex-col" @click="toggleReport()">
                        <div :class="['flex-row', 'toggler', isFinalReport ? 'reverse' : '']">
                            <div class="flex-col flex-1 toggle-text">{{ isFinalReport ? 'AD' : 'RO' }}</div>
                            <div class="flex-col flex-1 toggle-switch"></div>
                        </div>
                    </div>
                    <div v-if="hasEditAccess && hasCheckedAsset" class="separator"></div>
                    <div v-if="hasEditAccess && hasCheckedAsset" class="flex-col">
                        <adam-button secondary @click="downloadPdf" class="download-button">{{ $t('downloadPdf') }}</adam-button>
                    </div>
                    <div v-if="hasEditAccess && hasCheckedAsset" class="separator"></div>
                    <div v-if="hasEditAccess && hasCheckedAsset" class="flex-col">
                        <button @click="openNewPage()" class="btn">
                            {{ $t('generateLink') }}
                        </button>
                    </div>
                    <div v-if="hasViewAccess && hasCheckedAsset" class="flex-col">
                        <button class="btn" @click="showExportXlsx">
                            {{ $t('exportXlsx') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Asset } from '@/models';
import EasLoader from '@/components/loader/eas-loader.vue';
import Dropdown from '@/components/dropdown/dropdown.vue';
import CustomSelect from '@/components/dropdown/custom-select.vue';
import FadeTransition from '@/components/fade-transition/fade-transition.vue';
import clickOutside from '@/directives/click-outside';
import { AssetFleetService, DataFreezeService } from '@/services';
import ComponentSecurity from '@/mixins/component-security';
import BulkDownload from '@/components/final-report/bulk-download.vue';


@Component({
    name: 'ribbon',
    components: {
        BulkDownload,
        EasLoader,
        Dropdown,
        CustomSelect,
        FadeTransition,
    },
    directives: {
        clickOutside,
    },
})
export default class FinalRibbon extends Mixins(ComponentSecurity) {
    /**
     * Fleet list for asset
     */
    @Prop({ required: true })
    private fleetList!: any[];

    private assestFleetService: AssetFleetService = new AssetFleetService();
    private dataFreezeService = new DataFreezeService();
    private assets: Asset[] = [];
    private isFinalReport = true;
    private isDataLoaded = true;
    private checkedAssets: Asset[] = [];
    private checkedFleets: number[] = [];
    private fleetSelect = {
        isActive: false,
        isExpanded: false,
        isCheckAll: false,
    };
    private assetSelect = {
        isActive: false,
        isExpanded: false,
        isCheckAll: false,
    };
    private isResetActive = false;
    private isSelectAllHidden = true;

    private showBulkDownloadModal = false;

    private toggleReport(): void {
        this.isFinalReport = !this.isFinalReport;
        this.getAssetList();
    }

    @Watch('fleetList')
    private updateFleetSelection(): void {
        this.isFinalReport = this.hasEditAccess;
        this.checkAllFleet();
        this.filterChangeFleet();
    }

    private async getAssetList(): Promise<void> {
        this.checkedAssets = [];
        if (this.hasEditAccess && this.isFinalReport) {
            this.assets = (await this.assestFleetService.getFinalSnoList({fleetIds: this.checkedFleets})).result;
        } else {
            this.assets = (await this.dataFreezeService.getDataFreezeMachines({fleetIds: this.checkedFleets})).result;
        }
        if (this.assets.length) {
            this.checkedAssets.push(this.assets[0]);
        }
        this.updateCheckallAssets();
        this.filterChange();
    }

    private downloadPdf(): void {
        this.$emit('download');
    }

    private toggleAsset(): void {
        this.assetSelect.isExpanded = !this.assetSelect.isExpanded;
    }

    private filterChange(): void {
        this.assetSelect.isExpanded = false;
        this.checkedAssets.sort(this.compare);
        this.$emit('selection-details', this.checkedAssets);
        if (this.assets.length === 0) {
            this.isSelectAllHidden = false;
            this.assetSelect.isActive = false;
        } else {
            this.isSelectAllHidden = true;
            this.assetSelect.isActive = true;
        }
    }

    private hideFilter(): void {
        if (this.assetSelect.isExpanded) {
            this.assetSelect.isExpanded = false;
        }
    }

    private toggleFleet(): void {
        this.fleetSelect.isExpanded = !this.fleetSelect.isExpanded;
    }

    private async filterChangeFleet(): Promise<void> {
        this.fleetSelect.isExpanded = false;
        this.fleetSelect.isActive = true;
        if (this.checkedFleets.length > 0) {
            await this.getAssetList();
        }
    }

    private hideFilterFleet(): void {
        if (this.fleetSelect.isExpanded) {
            this.fleetSelect.isExpanded = false;
        }
    }

    private compare( a: Asset, b: Asset ): number {
        if ( a.name < b.name ) {
            return -1;
        }
        if ( a.name > b.name ) {
            return 1;
        }
        return 0;
    }

    private checkAllAssets(): any {
        this.assetSelect.isCheckAll = !this.assetSelect.isCheckAll;
        if (this.assetSelect.isCheckAll) {
            this.checkedAssets = [...this.assets];
        } else {
            this.checkedAssets = [];
        }
    }

    private checkAllFleet(): any {
        this.fleetSelect.isCheckAll = !this.fleetSelect.isCheckAll;
        this.checkedFleets = [];
        if (this.fleetSelect.isCheckAll) {
            for (const key in this.fleetList) {
                this.checkedFleets.push(this.fleetList[key].sid);
            }
        }
    }

    private updateCheckallAssets(): void {
        this.assetSelect.isCheckAll = this.checkedAssets.length === this.assets.length;
    }
    private updateCheckallFleet(): void {
        this.fleetSelect.isCheckAll = this.checkedFleets.length === this.fleetList.length;
    }

    private disableDone(): boolean {
        return this.checkedAssets.filter((p) => p).length === 0;
    }
    private disableDoneFleet(): boolean {
        return this.checkedFleets.filter((p) => p).length === 0;
    }

    private resetFilter(): void {
        this.isResetActive = true;
        this.checkedAssets = [];
        this.checkedFleets = [];
        this.assets = [];
        this.assetSelect.isCheckAll = false;
        this.fleetSelect.isCheckAll = false;
        this.filterChangeFleet();
        this.assetSelect.isActive = false;
        this.fleetSelect.isActive = false;
        this.isSelectAllHidden = false;
    }

    private openNewPage(): void {
        const serialNumberList: number[] = [];
        this.checkedAssets.forEach((asset) => {
            serialNumberList.push(asset.finalRunSno);
        });
        const timeStamp = new Date();
        const routeData = this.$router.resolve({
            name: 'static-report',
            query: { payload: encodeURIComponent(
                JSON.stringify({snoList: serialNumberList, timeStamp: timeStamp.toString()}),
            )},
        });
        window.open(routeData.href, '_blank');
    }

    get hasCheckedAsset(): boolean {
        return this.checkedAssets.length > 0;
    }

    private showExportXlsx(): void {
        this.showBulkDownloadModal = true;
    }
}

</script>

<style scoped lang="less">
@import './ribbon.less';
</style>
