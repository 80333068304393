<template>
  <div class="report">
    <content-wrapper>
      <template v-slot:filters>
        <FinalRibbon @selection-details="updateComponents" @download="download" :fleetList="fleetList"/>
      </template>
      <template v-slot:content>
        <div v-if="isDataSelected && !selectedAssets.length" class="no-assets pl-5">{{ $t('noDataAvailable') }}</div>
        <div v-if="isDataSelected && selectedAssets.length" id="reportdiv" class="pl-2 pr-2">
            <ReportGrid
                v-for="asset in selectedAssets" :key="asset.id" :id="idPrefix + asset.id.toString()"
                :serialNumber="asset.finalRunSno" :asset="{...asset}"
                :isNewUi="false"
            />
        </div>
      </template>
    </content-wrapper>
    <div v-if="isDownloading" class="popup">
        <eas-loader></eas-loader>
    </div>
  </div>
</template>

<script lang="ts">
import PDFDocument from 'pdfkit/js/pdfkit.standalone';

import domtoimage from 'dom-to-image';
import { Component, Vue } from 'vue-property-decorator';
import ReportGrid from '../report-grid/report-grid.vue';
import ContentWrapper from '../content-wrapper/content-wrapper.vue';
import FinalRibbon from './final-ribbon.vue';
import EasLoader from '../loader/eas-loader.vue';
import { FleetService } from '@/services';
import { Asset, FleetModel } from '@/models';
import { toastModuleDisplayError } from '@/store/toastModule';

@Component({
    components: {
        ContentWrapper,
        FinalRibbon,
        ReportGrid,
        EasLoader,
    },
})
export default class Report extends Vue {
    private assets: Asset[] = [];
    private selectedAssets: Asset[] = [];
    private fleetService: FleetService = new FleetService();
    private fleetList: FleetModel[] = [];
    private isDataSelected = false;
    private isDownloading = false;
    private idPrefix = 'report-id-';
    private fileNamePrefix = 'EAS_REPORT_';

    private async mounted(): Promise<void> {
        try {
            await this.getFleetList();
        } catch (error) {
            await this.$store.dispatch(toastModuleDisplayError, error);
        }
    }

    private async getFleetList(): Promise<void> {
        this.fleetList = (await this.fleetService.getFleetList()).result;
    }

    private updateComponents(assets: Asset[]): void {
        this.isDataSelected = false;
        this.selectedAssets = assets;
        this.isDataSelected = true;
    }

    private async getImage(asset: Asset): Promise<any> {
        const scale = 2;
        const canvasElement = document.getElementById(this.idPrefix + asset.id.toString()) as HTMLElement;
        const dataurl = await domtoimage.toPng(canvasElement, {
            width: canvasElement.clientWidth * scale,
            height: canvasElement.clientHeight * scale,
            style: {
                transform: 'scale(' + scale + ')',
                transformOrigin: 'top left',
            },
            quality: 0.99,
        });
        const img = new Image();
        img.src = dataurl;
        return img;
    }

    private async download(): Promise<void> {
        if (this.isDownloading) {
            return;
        }
        this.isDownloading = true;

        const dt = new Date();
        const month = (dt.getMonth() + 1).toString().padStart(2, '0');
        const day = dt.getDate().toString().padStart(2, '0');
        const year = dt.getFullYear();
        const fileName = this.fileNamePrefix + year + month + day;

        const doc = new PDFDocument({
            size: 'A4',
            layout: 'landscape',
        });
        doc.save();

        for (let index = 0; index < this.selectedAssets.length; index++) {
            const img = await this.getImage(this.selectedAssets[index]);
            if (index) {
                doc.addPage();
            }
            doc.image(img.src, 10, 10, {
                width: 821.89,
                height: 575.28,
            });
        }

        const buffers: [] = [];
        doc.on('data', buffers.push.bind(buffers));
        doc.on('end', async () => {
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';
            const blob = new Blob(buffers, { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${fileName}.pdf`;
            a.click();
            window.URL.revokeObjectURL(url);
            this.isDownloading = false;
        });

        doc.end();

    }
}
</script>

<style lang="less" scoped>
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: rgba(66, 65, 65, 0.356);
}
.no-assets {
    font-size: 1.5rem;
    justify-content: center;
}
</style>
