<template>
    <div>
        <pr-recommendations-bulk-update-upload
            :show-bulk-update="showPrRecommendationsBulkUpdateModal"
            @close="showPrRecommendationsBulkUpdateModal = false">
        </pr-recommendations-bulk-update-upload>
        <hydro-data-bulk-update-upload
            :show-bulk-update="showHydroDataBulkUpdateModal"
            @close="showHydroDataBulkUpdateModal = false">
        </hydro-data-bulk-update-upload>
        <hydro-data-download-template
            :show-download-template="showHydroDataDownloadTemplateModal"
            @close="showHydroDataDownloadTemplateModal = false">
        </hydro-data-download-template>
        <content-wrapper>
            <template v-slot:filters>
                <div class="flex-container pl-2">
                    <div class="flex-row flex-4">
                        <h3 class="select-title">{{ $t('dataTypes') }}</h3>
                        <select class="form-control" id="dataType" v-model="selectedDataType">
                            <option v-for="(option, i) in dataTypes" :key="i" :value="option" :selected="selectedDataType">
                                {{ option }}
                            </option>
                        </select>
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <div v-if="loading" class="popup">
                    <eas-loader></eas-loader>
                </div>
                <h2 class="mr-2 ml-2">
                    {{ selectedDataType }}
                </h2>
                <div class="mr-2 ml-2">
                    <button class="btn data-management-btn" @click="downloadTemplate">{{ $t('downloadTemplate') }}</button>
                    <button class="btn data-management-btn" @click="showBulkUpdate">{{ $t('bulkUpdate') }}</button>
                </div>
            </template>
        </content-wrapper>
    </div>
</template>

<script lang="ts">
import {Component, Mixins} from 'vue-property-decorator';
import EasLoader from '@/components/loader/eas-loader.vue';
import ContentWrapper from '@/components/content-wrapper/content-wrapper.vue';
import {FileDownload} from '@/models/file-download';
import DownloadFile from '@/mixins/download-file';
import {PrRecommendationsService} from '@/services/pr-recommendations-service';
import PrRecommendationsBulkUpdateUpload
    from '@/components/data-management/bulk-update/pr-recommendations-bulk-update-upload.vue';
import HydroDataBulkUpdateUpload from '@/components/data-management/bulk-update/hydro-data-bulk-update-upload.vue';
import HydroDataDownloadTemplate from '@/components/data-management/hydro-data-download-template.vue';

@Component({
    name: 'data-management',
    components: {
        PrRecommendationsBulkUpdateUpload,
        HydroDataBulkUpdateUpload,
        HydroDataDownloadTemplate,
        EasLoader,
        ContentWrapper
    }
})
export default class DataManagement extends Mixins(DownloadFile)
{
    private loading = false;

    private portfolioReviewRecommendation = 'Portfolio Review Recommendations';
    private hydroData = 'Hydro Data';

    private dataTypes = [this.portfolioReviewRecommendation, this.hydroData];

    private selectedDataType = this.portfolioReviewRecommendation;

    private prRecommendationsService = new PrRecommendationsService();
    private showPrRecommendationsBulkUpdateModal = false;
    private showHydroDataBulkUpdateModal = false;
    private showHydroDataDownloadTemplateModal = false;

    private async downloadTemplate(): Promise<void> {
        if (this.selectedDataType === this.portfolioReviewRecommendation) {
            const file: FileDownload | undefined = await this.prRecommendationsService.downloadTemplate();
            if (file !== undefined) {
                this.downloadFile(file, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            }
        }
        else if (this.selectedDataType === this.hydroData) {
            this.showPrRecommendationsBulkUpdateModal = false;
            this.showHydroDataBulkUpdateModal = false;
            this.showHydroDataDownloadTemplateModal = true;
        }
    }

    private showBulkUpdate(): void {
        if (this.selectedDataType === this.portfolioReviewRecommendation) {
            this.showPrRecommendationsBulkUpdateModal = true;
            this.showHydroDataBulkUpdateModal = false;
            this.showHydroDataDownloadTemplateModal = false;
        }
        else if (this.selectedDataType === this.hydroData) {
            this.showPrRecommendationsBulkUpdateModal = false;
            this.showHydroDataBulkUpdateModal = true;
            this.showHydroDataDownloadTemplateModal = false;
        }
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';
select.form-control {
    width: 20%;
}
textarea.form-control {
    width: 100%;
    box-sizing: border-box;
    resize: none;
}
.select-title {
    padding: 10px 5px 10px 0;
}

.data-management-btn {
    width: auto;
    height: 3rem;
    background-color: #0078dc;
    color: #fff;
    border-radius: .5rem;
    border: 1px solid #0078dc;
    font-size: 1.5rem;
    text-transform: uppercase;
    white-space: nowrap;
    margin: .5rem;
}
</style>
