<template>
    <div class="comment-component">
        <div v-if="!dataLoaded">{{ $t('loading') }}</div>
        <div class="card mr-1 ml-1 pl-1 pt-1 pr-1 pb-1" v-click-outside="() => expand=false">
            <div class="flex-row flex-1 header">
                <div class="flex-col">
                    <div class="comment-icon">
                        <font-awesome-icon :icon="['fas', 'text-width']" />
                    </div>
                </div>
                <div class="flex-col flex-2 flex-justify-center ml-4 mr-5 pr-4 pl-2" id="assetName">
                    {{ comment.assetName }}
                </div>
                <span class="separator ml-6 mr-4"></span>
                <div class="flex-col flex-justify-center flex-3" id="attributeName">
                    {{ comment.attributeName }}
                </div>
                <span class="separator ml-4 mr-4"></span>
                <div class="flex-col flex-justify-center flex-1 pr-2" style="align-items: flex-end">
                    <div class="flex-row expand" @click="expand = !expand">
                        <div class="flex-col mr-1">{{expand ? 'COLLAPSE' : 'EXPAND'}}</div>
                        <font-awesome-icon class="flex-col" :icon="['fas', expand ? 'angle-up' : 'angle-down']" />
                    </div>
                </div>
            </div>
            <span v-show="expand" class="flex-row flex-1 line-separator">
            </span>
            <div v-show="expand" class="flex-row flex-1">
                <div class="flex-col attributeValue" id="attributeValue">
                    <p>{{comment.attributeValue}}</p>
                </div>
            </div>
            <div v-show="expand" class="flex-row reverse pr-2">
                <div class="flex-col flex-align-end">
                    <adam-button secondary icon=ic-latest-edits @click="edit">Edit</adam-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import clickOutside from '@/directives/click-outside';
import { CommentModel, AdamCardConfig } from '@/models';
import { CommentService } from '@/services';

@Component({
    name: 'comment-component',
    directives: {
        clickOutside,
    },
})
export default class CommentComponent extends Vue {
    @Prop()
    private comment!: CommentModel;
    private localComment: any = {};
    private isEditing = false;
    private errorExists = false;
    private adamCardConfig: AdamCardConfig = {
        cardClass: '',
        hasAction: true,
        headerClass: '',
        contentClass: '',
        actionClass: '',
    };
    private commentService: CommentService = new CommentService();
    private dataLoaded = false;
    private modalOpen = false;
    private expand = false;

    @Watch('comment')
    private async updateComponent(newComment: CommentModel, oldComment: CommentModel): Promise<void> {
        if (newComment && newComment !== oldComment) {
            this.localComment = JSON.parse(JSON.stringify(newComment));
            this.localComment.updComment = '';
        }
    }
    private mounted(): void {
        this.dataLoaded = true;
    }

    private edit(): void {
        this.localComment = JSON.parse(JSON.stringify(this.comment));
        this.$emit('on-edit', this.localComment);
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';
.comment-component{
    .card{
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
        box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.22);
        background-color: #fff;
        .header{
            font-size: 1.5rem;
            font-weight: bold;
        }
    }
    .comment-icon{
        margin-left: -3rem;
        padding: 1rem;
        background: @uniper-blue;
        color: @white;
        font-size: 2rem;
        border-radius: 5px;
    }
    .attributeValue {
        white-space: pre-wrap;
    }
    .textArea{
        width:100%;
    }
    .separator {
        width: 2px;
        height: 4rem;
        background: #E0E0E0;
        display: inline-block;
        vertical-align: middle;
    }
    .line-separator{
        width: 100%;
        height: 2px;
        background: #E0E0E0;
        display: inline-block;
    }
    .expand{
        color: @uniper-blue;
        cursor: pointer;
    }
    button:hover{
        transition: 0.3s;
    }
    .edit-btn{
        width: 8rem;
        height: 3.5rem;
        border-radius: 30px;
        border: 2px solid @uniper-blue;
        color: @uniper-blue;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1;
        text-align: center;
    }
}

</style>
