<template>
    <div class="configuration">
        <modal-box v-if="dataloaded && isInEditMode"
            :open="dataloaded && isInEditMode"
            :onClose.prop="closeModal"
            :enableHeader.prop="true"
            :enableFooter.prop="true"
            :enableHeaderClose.prop="true"
            :transparentOverlay.prop="false"
            :closeOnClickOutside.prop="true"
            width='1080px'
        >
            <span slot="header" style="line-height: var(--adam-d48)">
                <div class="flex-row flex-1">
                    <div class="flex-col flex-1">
                        <h3>{{configModel.uiText}}</h3>
                    </div>
                </div>
            </span>
            <span>
                <div class="flex-col edit-config">
                    <div class="mt-3 config">
                        <label for="configOldValue" class="config-label">{{ $t('oldValue') }}</label>
                        <input id="configOldValue"
                            class="config-input"
                            placeholder="Value"
                            :value="configOldValue" :readonly="true">
                    </div>
                    <div class="mt-3 config">
                        <label for="configValue" class="config-label">{{ $t('newValue') }}</label>
                        <input id="configValue"
                            class="config-input"
                            placeholder="Value"
                            v-model="configModel.value">
                    </div>
                    <div class="mt-3 config">
                        <label for="configReason" class="config-label">{{ $t('reason') }}*</label>
                        <textarea id="configReason"
                            class="config-input"
                            placeholder="Reason"
                            v-model="configModel.updComment"
                            rows="4"></textarea>
                    </div>
                    <div :class="errorExists ? 'error-text': ''">
                        <div v-if="errorExists">This field is mandatory</div>
                    </div>
                </div>
                <br/>
            </span>
            <span slot="footer" style="line-height: var(--adam-d48)">
                <div class="flex-row reverse">
                    <div class="flex-col flex-align-end">
                        <adam-button secondary icon="ic-station" @click="save">Save</adam-button>
                    </div>
                </div>
            </span>
        </modal-box>
        <div v-if="!dataloaded" class="popup">
            <eas-loader></eas-loader>
        </div>
        <content-wrapper v-else>
            <template v-slot:filters>
                <div class="flex-row reverse flex-1" style="align-items: center">
                    <div class="flex-col mr-4" style="align-items: flex-end">
                        <select v-model="pageSize" @change="changePageSize">
                            <option v-for="size in pageSizeSelection" :key="size" :value="size">{{size}}</option>
                        </select>
                    </div>
                    <div class="flex-col pagesizelebel pr-2">Page Size: </div>
                </div>
            </template>
            <template v-slot:content>
                <div class="main-body mb-4">
                    <div class="flex-row">
                        <div class="flex-col flex-1"></div>
                        <div class="flex-col flex-12">
                            <paging
                                :pagingData="pagingData"
                                @current-page="modifyContent"
                            >
                                <template v-slot:pageContent>
                                    <ConfigValueEdit v-for="config in configModelList"
                                        :key= "config.sNo"
                                        :configModel="config"
                                        @on-edit="editConfig"
                                    />
                                </template>
                            </paging>
                        </div>
                        <div class="flex-col flex-1"></div>
                    </div>
                </div>
            </template>
        </content-wrapper>
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import { ConfigValueSer } from '@/services';
import ConfigValueEdit from './configurationValue-edit.vue';
import Paging from '@/components/paging/paging.vue';
import ContentWrapper from '@/components/content-wrapper/content-wrapper.vue';
import { ConfigValueModel } from '@/models';
import RowCard from './row-card.vue';
import EasLoader from '../loader/eas-loader.vue';
import { EventBus } from '@/utils';
import { toastModuleDisplayError } from '@/store/toastModule';

@Component({
    components: {
        ContentWrapper,
        ConfigValueEdit,
        RowCard,
        EasLoader,
        Paging,
    },
})
export default class ConfigValue extends Vue {
    private configService: ConfigValueSer = new ConfigValueSer();
    private configModel: any = {};
    private configOldValue = '';
    private dataloaded = false;
    private isInEditMode = false;
    private configModelList: ConfigValueModel[] = [];
    private configResult: any = {};
    private title = '';
    private value = '';
    private comment = '';
    private errorExists = false;
    private pagingData: any = {
        currentPage: 1,
        hasNext: false,
        hasPrevious: false,
        totalPageCount: 37,
        prevArray: [],
        afterArray: [],
    };
    private currentPage = 1;
    private contentPerPage = 5;
    private pageSize = 5;
    private pageSizeSelection: number[] = [5, 10, 15, 25, 30];

    private async mounted(): Promise<void> {
        this.title = this.$i18n.t('title') as string;
        this.value = this.$i18n.t('value') as string;
        this.comment = this.$i18n.t('comment') as string;
        await this.modifyContent(1);
        this.dataloaded = true;
        EventBus.$on(EventBus.CONFIGURATION.UPDATE, () => this.refreshPage());
    }
    private changePageSize(): void {
        this.modifyContent(1);
    }
    private async modifyContent(currentPage: number): Promise<void> {
        await this.getData(currentPage, this.pageSize);
        this.pagingData.totalPageCount = Math.ceil(this.configResult.totalCount / this.pageSize);
        this.pagingData.currentPage = currentPage;
        this.pagingData.hasPrevious = (currentPage > 1) ? true : false;
        this.pagingData.hasNext = (currentPage < this.pagingData.totalPageCount) ? true : false;
        this.pagingData.prevArray = [];
        this.pagingData.afterArray = [];
        for ( let i = 1; i < 3; i++) {
            if (currentPage - i >= 1) { this.pagingData.prevArray.unshift(currentPage - i); }
            if (currentPage + i <= this.pagingData.totalPageCount) { this.pagingData.afterArray.push(currentPage + i); }
        }
    }
    private async refreshPage(): Promise<void> {
        await this.modifyContent(this.pagingData.currentPage);
    }
    private async getData(pagenumber: number, pagesize: number): Promise<void> {
        this.configResult = (await this.configService.get(pagenumber, pagesize)).result;
        this.configModelList = this.configResult.items;
    }

    private editConfig(config: ConfigValueModel): void {
        this.configModel = {...config};
        this.configOldValue = config.value;
        this.configModel.updComment = '';
        this.isInEditMode = true;
    }
    private closeModal(): void {
        this.isInEditMode = false;
        this.errorExists = false;
    }

    private async save(): Promise<void> {
        if (this.configModel.updComment === '' || this.configModel.value === '') {
            this.errorExists = true;
        } else {
            this.errorExists = false;
            this.isInEditMode = false;
            this.configService.postValueComment(this.configModel).then((data) => {
                if (data.statusCode === 409) {
                    this.$store.dispatch(toastModuleDisplayError, data);
                } else {
                    this.modifyContent(1);
                }
            });
            EventBus.$emit(EventBus.CONFIGURATION.UPDATE, 'update');
        }
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.CONFIGURATION.UPDATE);
    }
}


</script>

<style lang="less">
@import '../../variables.less';
.configuration {
    font-size : 1.5rem;
    .error-text{
        color: red;
    }
    .pagesizelebel{
        justify-content: center;
        font-size: 1.2rem;
        font-weight: bold;
    }
    .edit-config{
        height: calc(100% - 11.5rem);
        overflow: hidden;
        overflow-y: auto;
        label {
            margin-top: 1rem;
            min-width: fit-content;
        }
        input, textarea {
            width: 100%;
        }
        .config {
            position: relative;
            label {
                position: absolute;
                background: white;
                left: 1.8rem;
                top: -2.1rem;
                padding: 0 1rem;
                font-size: 1.6rem;
                z-index: 1;
            }
            .config-input {
                display: block;
                margin: 0 auto;
            }
            input,
            select,
            textarea {
                box-sizing: border-box;
                font-size: 1.8rem;
                padding: 1rem 2.5rem;
                border-radius: @border-radius-standard;
                border: 2px solid @dark-grey;
                transition: 0.2s ease-in;
                &:focus {
                    border: 2px solid @uniper-blue;
                    transition: 0.2s ease-in;
                    outline: none;
                }
                &.invalid {
                    border: 2px solid @red-darker;
                    box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
                    transition: 0.1s ease-in;
                }
                &.full-width {
                    width: 100%;
                }
            }
            select {
                padding: 1rem 2.2rem;
                &.invalid {
                    border: 2px solid @red-darker;
                    box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
                    transition: 0.1s ease-in;
                }
            }
        }
    }
}
</style>
