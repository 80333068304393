import { ConfigValueModel, ApiResponse, PagedResult } from '@/models';
import BaseService from '@/utils/base-service';

export class ConfigValueSer extends BaseService {
    private endpoint = '/Configurations';
    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }

    public async get(pageno: number, pagesize: number): Promise<ApiResponse<PagedResult>> {
        return (await this.api.get(`${this.endpoint}?PageNr=${pageno}&PageSize=${pagesize}`)).data;
    }
    public async postValueComment(values: ConfigValueModel): Promise<ApiResponse<ConfigValueModel>> {
        return (await this.api.post(`${this.endpoint}`, values)).data;
    }
}
