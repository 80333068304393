import { DatesGeneralStrategyModel, ApiResponse, DateRibbonModel } from '@/models';
import BaseService from '@/utils/base-service';

export class DatesGeneralStrategyService extends BaseService {
    private endpoint = '/resultsets';

    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async get(sno: number): Promise<ApiResponse<DatesGeneralStrategyModel>> {
        return (await this.api.get(`${this.endpoint}/date_generalstrategy/${sno}`)).data;
    }

    public async saveDateRibbonData(value: DateRibbonModel): Promise<ApiResponse<DateRibbonModel>> {
        return (await this.api.post(`${this.endpoint}/date-ribbon`, value)).data;
    }
}
