<template>
    <div class="line-of-sight">
        <modal-box v-if="dataLoaded && showModal"
            :open="dataLoaded && showModal"
            :onClose.prop="() => showModal=false"
            :enableHeader.prop="true"
            :enableFooter.prop="true"
            :enableHeaderClose.prop="true"
            :transparentOverlay.prop="false"
            :closeOnClickOutside.prop="true"
            width='1080px'
        >
            <span slot="header" class="header">
                <div class="flex-row flex-1">
                    <div class="flex-col flex-1">
                        <h3>{{ losDescription.category }}</h3>
                    </div>
                    <span class="separator ml-6 mr-4 mt-2"></span>
                    <div class="flex-col flex-1">
                        <h3>{{ losDescription.classification }}</h3>
                    </div>
                </div>
            </span>
            <span>
                <div class="flex-col edit-los-description">
                    <div class="mt-3 los-description">
                        <label for="losOldValue" class="los-text-label">{{ $t('oldValue') }}</label>
                        <textarea
                            id="losOldValue"
                            class="los-text-input"
                            :placeholder="$t('value')"
                            :readonly="true"
                            :value="losOldValue"
                        >
                        </textarea>
                    </div>
                    <div class="mt-3 los-description">
                        <label for="losValue" class="los-text-label">{{ $t('newValue') }}</label>
                        <textarea id="losValue"
                            class="los-text-input"
                            :placeholder="$t('value')"
                            v-model="losDescription.value"
                        >
                        </textarea>
                    </div>
                    <div class="mt-3 los-description">
                        <label for="losValue" class="los-text-label">{{ $t('reason') }}</label>
                        <textarea id="losValue"
                            class="los-text-input"
                            :placeholder="$t('reason')"
                            v-model="losDescription.updComment"
                        >
                        </textarea>
                    </div>
                    <div :class="errorExists ? 'error-text': ''">
                        <div v-if="errorExists">{{ $t('thisFieldIsMandatory') }}</div>
                    </div>
                </div>
                <br/>
            </span>
            <span slot="footer" class="footer">
                <div class="flex-row reverse">
                    <div class="flex-col flex-align-end">
                        <adam-button secondary icon="ic-station" @click="save" >Save</adam-button>
                    </div>
                </div>
            </span>
        </modal-box>
        <div v-if="!dataLoaded" class="popup">
            <eas-loader></eas-loader>
        </div>
        <content-wrapper>
            <template v-slot:filters>
                <div class="flex-row flex-1" style="align-items: center">
                    <div class="flex-col flex-2">
                        <div class="flex-row flex-1">
                            <div class="flex-col ml-5" style="width: 30rem">
                                <CustomSelect
                                    :options="ampCategories"
                                    :default="defaultCategory"
                                    displayKey='category'
                                    class='select'
                                    @input="onProcessChange"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <div class="flex-row">
                    <div class="flex-col flex-1"></div>
                    <div class="flex-col flex-12">
                        <AMProcessClassificaton
                            v-for="losData in filteredData"
                            :key="losData.id"
                            class="mb-1"
                            :losData="losData"
                            @on-edit="editComment"
                        />
                    </div>
                    <div class="flex-col flex-1"></div>
                </div>
            </template>
        </content-wrapper>
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import { LOSService } from '@/services';
import ContentWrapper from '@/components/content-wrapper/content-wrapper.vue';
import EasLoader from '../loader/eas-loader.vue';
import CustomSelect from '@/components/dropdown/custom-select.vue';
import AMProcessClassificaton from './am-process-classification.vue';
import { LOSModel } from '@/models';
import { EventBus } from '@/utils';
import { toastModuleDisplayError } from '@/store/toastModule';

@Component({
    name: 'line-of-sight',
    components: {
        ContentWrapper,
        EasLoader,
        CustomSelect,
        AMProcessClassificaton,
    },
})
export default class LineOfSight extends Vue {

    private ampCategories: any[] = [];
    private losModelList: LOSModel[] = [];
    private losService: LOSService = new LOSService();
    private losOldValue = '';
    private showModal = false;
    private errorExists = false;
    private dataLoaded = false;
    private filteredData: any[] = [];
    private processSelection: any = {};
    private losDescription: any = {};
    private defaultCategory: any = { category: 'All', value: ''};
    private categorySeqList: string[] = [];

    private async mounted(): Promise<void> {
        await this.init();
        this.onProcessChange(this.defaultCategory);
        this.categorySeqList = [
            this.$i18n.t('grow') as string,
            this.$i18n.t('maintain') as string,
            this.$i18n.t('keepOption') as string,
            this.$i18n.t('optimizeEol') as string,
        ];
        EventBus.$on(EventBus.LOS.UPDATE, () => this.refresh());
    }

    private async init(): Promise<void> {
        try {
            this.dataLoaded = false;
            this.losModelList = (await this.losService.getLosData()).result;
            this.losModelList.map((item) => {
                if (!this.ampCategories.find((_) => _.category === item.category)) {
                    this.ampCategories.push({ category: item.category, value: item.category });
                }
            });
            this.ampCategories.push(this.defaultCategory);
            this.dataLoaded = true;
            this.processSelection = {...this.defaultCategory};
        } catch (error) {
            console.log(error);
            await this.$store.dispatch(toastModuleDisplayError, error);
        }
    }

    private async getSelectedData(): Promise<void> {
        const selectedData = this.losModelList.filter((x: LOSModel) => {
            if (this.processSelection.value === '' || x.category === this.processSelection.value) {
                return x;
            }
        });
        this.filteredData = [...selectedData];
        this.filteredData.sort(this.compare);
    }

    private compare( a: LOSModel, b: LOSModel ): number {
        return a.category === b.category
            ? this.categorySeqList.indexOf(a.classification) >
                this.categorySeqList.indexOf(b.classification)
                ? 1 : -1
            : -1;
    }

    private onProcessChange(process: any): void {
        this.processSelection = process;
        this.getSelectedData();
    }

    private editComment(losModel: LOSModel): void {
        this.losDescription = {...losModel};
        this.losOldValue = losModel.value;
        this.losDescription.updComment = '';
        this.showModal = true;
    }

    private async save(): Promise<void> {
        if (this.losDescription.updComment === '') {
            this.errorExists = true;
        } else {
            this.showModal = false;
            this.errorExists = false;
            await this.losService.postLosData(this.losDescription).then((data) => {
                if (data.statusCode === 409) {
                    this.$store.dispatch(toastModuleDisplayError, data);
                }
            });
            this.filteredData.sort(this.compare);
            EventBus.$emit(EventBus.LOS.UPDATE, 'update');
        }
    }

    private async refresh(): Promise<void> {
        this.losModelList = (await this.losService.getLosData()).result;
        this.getSelectedData();
        this.$forceUpdate();
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.LOS.UPDATE);
    }
}


</script>

<style lang="less" scoped>
@import '../../variables.less';
.line-of-sight {
    font-size: 1.5rem;
    .separator {
        width: 2px;
        height: 4rem;
        margin-left: 2rem;
        margin-right: 2rem;
        background: #E0E0E0;
        display: inline-block;
        vertical-align: middle;
    }
    .page-size-label {
        justify-content: center;
        font-size: 1.2rem;
        font-weight: bold;
    }
    .error-text {
        color: red;
    }
    .edit-los-description {
        height: calc(100% - 11.5rem);
        overflow: hidden;
        overflow-y: auto;
        label {
            margin-top: 1rem;
            min-width: fit-content;
        }
        input, textarea {
            width: 100%;
        }
        .los-description {
            position: relative;
            label {
                position: absolute;
                background: white;
                left: 1.8rem;
                top: -2.1rem;
                padding: 0 1rem;
                font-size: 1.6rem;
                z-index: 1;
            }
            .los-text-input {
                display: block;
                margin: 0 auto;
            }
            textarea {
                white-space: pre-wrap;
            }
            input,
            select,
            textarea {
                box-sizing: border-box;
                font-size: 1.8rem;
                padding: 1rem 2.5rem;
                border-radius: @border-radius-standard;
                border: 2px solid @dark-grey;
                transition: 0.2s ease-in;
                &:focus {
                    border: 2px solid @uniper-blue;
                    transition: 0.2s ease-in;
                    outline: none;
                }
                &.invalid {
                    border: 2px solid @red-darker;
                    box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
                    transition: 0.1s ease-in;
                }
                &.full-width {
                    width: 100%;
                }
            }
            select {
                padding: 1rem 2.2rem;
                &.invalid {
                    border: 2px solid @red-darker;
                    box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
                    transition: 0.1s ease-in;
                }
            }
        }
    }
    .footer,
    .header {
        line-height: var(--adam-d48);
    }
}
</style>
