import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import store from './store';
import 'adam.ui-core/dist/umd';
import 'adam.ui-core/utils/scaling.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faArrowRight,
    faArrowDown,
    faCheckCircle,
    faArrowUp,
    faArrowAltCircleRight,
    faArrowAltCircleDown,
    faArrowAltCircleUp,
    faEuroSign,
    faEdit,
    faSave,
    faUndo,
    faTimes,
    faCaretDown,
    faCaretUp,
    faCircle,
    faSquare,
    faAngleDown,
    faAngleUp,
    faChartPie,
    faUserCog,
    faTextWidth,
    faTasks,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faCogs,
    faCopy,
    faExpand,
    faDownload,
    faCompressAlt,
    faExpandAlt,
    faSearch,
    faTimesCircle,
    faExclamationTriangle,
    faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/de';
import { sdk } from '@/utils/coode-sdk';

Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add(
    faArrowRight as any,
    faArrowDown as any,
    faArrowUp as any,
    faArrowAltCircleRight as any,
    faArrowAltCircleDown as any,
    faArrowAltCircleUp as any,
    faEuroSign as any,
    faEdit as any,
    faSave as any,
    faUndo as any,
    faTimes as any,
    faCaretDown as any,
    faCaretUp as any,
    faCircle as any,
    faSquare as any,
    faAngleDown as any,
    faAngleUp as any,
    faChartPie as any,
    faUserCog as any,
    faTextWidth as any,
    faTasks as any,
    faAngleDoubleLeft as any,
    faAngleDoubleRight as any,
    faCogs as any,
    faCopy as any,
    faExpand as any,
    faDownload as any,
    faCompressAlt as any,
    faExpandAlt as any,
    faSearch as any,
    faCheckCircle as any,
    faTimesCircle as any,
    faExclamationTriangle as any,
    faQuestionCircle as any,
);
Vue.component('datetime-picker', DatePicker);
Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(VueCookies);
const lang = 'en-GB';
const i18n = new VueI18n({
    locale: lang,
    messages: require(`./locales/${lang}.json`),
});

sdk.auth.init().then(() => {
    new Vue({
        i18n,
        router,
        store,
        render: (h): any => h(App),
    }).$mount('#app');
});
