import { Asset, ApiResponse } from '@/models';
import BaseService from '@/utils/base-service';

export class AssetService extends BaseService {
    private endpoint = '/MachineNameMapping';
    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }

    public async get(): Promise<ApiResponse<Asset[]>> {
        return (await this.api.get(`${this.endpoint}`)).data;
    }
    public async getFinalSno(): Promise<ApiResponse<Asset[]>> {
        return (await this.api.get(`${this.endpoint}/MachinesWithfinalSno`)).data;
    }
}
