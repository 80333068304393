<template>
    <div class="decision-model">
        <div v-if="!dataLoaded">{{ $t('loading') }}</div>
        <div v-else>
            <div class="flex-row pt-2 pl-2"><div class="category">{{ this.decisionData.modelOutput }}</div></div>
            <div class="flex-row header mt-3 mb-2 mr-5 ml-5" v-for="(decision, decisionIndex) in decisionData.data"
                :key="decisionIndex">
                <div v-if="decision" class="flex-col flex-1 flex-justify-center pl-3">{{ decision.output }}</div>
                <div v-if="decision" class="flex-col flex-3 mt-2 mb-2">
                    <div class="flex-row flex-1 mb-2">{{ decision.compoundCriteria }}</div>
                    <div class="flex-row flex-1" v-if="decision.compoundChild">
                        <decision-group :criteria="decision.compoundChild" />
                    </div>
                    <div v-if="decision.topLevelCriteria !== null">
                        <div class="pl-0 mt-2" v-for="(subDecision, subDecisionIndex) in decision.topLevelCriteria"
                            :key="subDecisionIndex">
                            <decision-criterion :criteriaModel="subDecision" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DecisionGroup from './decision-group.vue';
import DecisionCriterion from './decision-criterion.vue';
import { DecisionCriteriaLogService } from '@/services/decision-criteria-log-service';
import { CriteriaGroupModel, DecisionCriteriaLog } from '@/models';
import ContentWrapper from '../content-wrapper/content-wrapper.vue';

@Component({
    name: 'decision-model-component',
    components: {
        DecisionGroup,
        DecisionCriterion,
        ContentWrapper,
    },
})
export default class DecisionModelComponent extends Vue {
    /**
     * serial no of the report
     */
    @Prop({ required: true })
    public serialNumber!: number;

    private decisionModelService = new DecisionCriteriaLogService();
    private decisionData: DecisionCriteriaLog | null = null;
    private dataLoaded = false;
    private criteriaGroupModel!: CriteriaGroupModel;

    @Watch('serialNumber')
    private async updateComponent(): Promise<void> {
        await this.updateDecisionData();
        this.dataLoaded = true;
    }

    private async mounted(): Promise<void> {
        await this.updateDecisionData();
        this.dataLoaded = true;
    }

    private async updateDecisionData(): Promise<void> {
        this.decisionData = (await this.decisionModelService.get(Number(this.serialNumber))).result;
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';
.decision-model {
  font-family: Roboto-Medium, sans-serif;
  font-size: 1.5rem;
  width: 100%;
  height: 45rem;
  overflow-x: hidden;
  overflow-y: auto;
  .category {
    background-color: @contextual-green;
    padding: 1rem;
    border-radius: 0.5rem;
  }
  .checkbox {
    justify-content: center;
    align-items: center;
  }
  .flex-row {
    &.header {
      box-shadow: 0 0 0.8rem 0 @dark-grey-lighter;
      border-radius: 0.5rem;
    }
  }
  .flex-justify-center {
    justify-content: center;
  }
}
</style>
