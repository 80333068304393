<template>
    <div class="amprocess-new pb-1">
        <div v-if="!isDataLoaded">{{ $t('loading') }}</div>
        <div v-else>
            <div v-if="!showAmpTable" class="flex-row">
            </div>
            <div v-else>
                <div class="flex-row flex-6 flex-justify-center">
                    <div class="flex-col flex-1">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th colspan="7" class="mainheader bl br pb-2">{{ $t('maintenanceStrategyAndRisk') }}</th>
                                    <th colspan="2"></th>
                                    <th colspan="9" class="mainheader bl br pb-2">{{ $t('digitalizationComplianceAndSupport') }}</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th class="sub-category" v-for="decisionModel in decisionModelListLocal" :key="decisionModel.id">
                                        <div class="rotate rotate-text">{{ decisionModel.category }}</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="category">{{ grow }}</td>
                                    <td
                                        v-for="(decisionModel, index) in decisionModelListLocal" :key="decisionModel.id"
                                        @click="processSelection(index, grow)"
                                        class="tooltip"
                                        :class="{ darkGreenBox: decisionModel.process==grow, handCursor: isInEditMode }"
                                    >
                                        <div v-if="decisionModel.process === 'Grow Value'">
                                            <div
                                                :class="hoverPosition(index)"
                                            >
                                                <div class="hover-box-title mb-1">
                                                    {{ decisionModel.category }}
                                                </div>
                                                <div>
                                                    {{ getLosDescription(decisionModel.category, 'Grow Value') }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="category">{{ maintain }}</td>
                                    <td
                                        v-for="(decisionModel, index) in decisionModelListLocal" :key="decisionModel.id"
                                        @click="processSelection(index, maintain)"
                                        class="tooltip"
                                        :class="{ lightGreenBox: decisionModel.process==maintain, handCursor: isInEditMode }"
                                    >
                                        <div v-if="decisionModel.process === 'Maintain Value'">
                                            <div
                                                :class="hoverPosition(index)"
                                            >
                                                <div class="hover-box-title mb-1">
                                                    {{ decisionModel.category }}
                                                </div>
                                                <div>
                                                    {{ getLosDescription(decisionModel.category, 'Maintain Value') }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="category">{{ keepOption }}</td>
                                    <td
                                        v-for="(decisionModel, index) in decisionModelListLocal" :key="decisionModel.id"
                                        @click="processSelection(index, keepOption)"
                                        class="tooltip"
                                        :class="{ yellowBox: decisionModel.process==keepOption, handCursor: isInEditMode }"
                                    >
                                        <div v-if="decisionModel.process === 'Keep Option'">
                                            <div
                                                :class="hoverPosition(index)"
                                            >
                                                <div class="hover-box-title mb-1">
                                                    {{ decisionModel.category }}
                                                </div>
                                                <div>
                                                    {{ getLosDescription(decisionModel.category, 'Keep Option') }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="category">{{ optimizeEol }}</td>
                                    <td
                                        v-for="(decisionModel, index) in decisionModelListLocal" :key="decisionModel.id"
                                        @click="processSelection(index, optimizeEol)"
                                        class="tooltip"
                                        :class="{ redBox: decisionModel.process==optimizeEol, handCursor: isInEditMode }"
                                    >
                                        <div v-if="decisionModel.process === 'Optimize EOL'">
                                            <div
                                                :class="hoverPosition(index)"
                                            >
                                                <div class="hover-box-title mb-1">
                                                    {{ decisionModel.category }}
                                                </div>
                                                <div>
                                                    {{ getLosDescription(decisionModel.category, 'Optimize EOL') }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-if="isInEditMode" class="flex-row">
                    <div class="flex-col flex-1 edit-comment">
                        <div class="mt-3 am-comment">
                            <label for="updComment" class="perf-label">{{ $t('reason') }}</label>
                            <textarea
                                @keyup="updateComponentInRealTime()"
                                id="updComment"
                                :class="{ 'comment-input': true, 'invalid': isCommentEmpty }"
                                @focus="() => isCommentEmpty = false"
                                :placeholder="$t('reason')"
                                v-model="updComment"
                                maxlength="250"
                                rows="4"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AMPModel, DecisionModel, LOSModel } from '@/models';
import { EventBus } from '@/utils';
import { AMPService, LOSService } from '@/services';

@Component({
    name: 'amprocess-new',
})
export default class AmProcessNew extends Vue {
    /**
     * Set the serial number of report.
     */
    @Prop({ required: true })
    private serialNumber!: number;

    /**
     * Set AM process data.
     */
    @Prop({ required: true })
    private ampModel!: AMPModel;

    /**
     * Set popup mode.
     */
    @Prop({ default: false })
    private isPopup!: boolean;

    private isInEditMode = false;
    private decisionModelListLocal: DecisionModel[] = [];
    private ampService: AMPService = new AMPService();
    private isDataLoaded = false;
    private grow = '';
    private maintain = '';
    private keepOption = '';
    private optimizeEol = '';
    private componentId = 7;
    private updComment = '';
    private isCommentEmpty = false;
    private showConfirmModal = false;
    private showAmpTable = this.ampModel.showAmpTable;
    private losModelList: LOSModel[] = [];
    private losService: LOSService = new LOSService();

    private async mounted(): Promise<void> {
        this.isCommentEmpty = false;
        this.grow = this.$t('grow') as string;
        this.maintain = this.$t('maintain') as string;
        this.keepOption = this.$t('keepOption') as string;
        this.optimizeEol = this.$t('optimizeEol') as string;
        this.decisionModelListLocal = JSON.parse(JSON.stringify(this.ampModel.ampData));
        this.losModelList = (await this.losService.getLosData()).result;
        this.isDataLoaded = true;
        if (this.isPopup) {
            EventBus.$on(EventBus.REPORT_MODAL.EDIT, (modalId: number) => this.makeEditable(modalId));
            EventBus.$on(EventBus.REPORT_MODAL.SAVE, (modalId: number) => this.save(modalId));
            EventBus.$on(EventBus.REPORT_MODAL.RESET, (modalId: number) => this.reset(modalId));
            EventBus.$on(EventBus.REPORT_MODAL.CLOSE, (modalId: number) => this.cancel(modalId));
        }
    }

    @Watch('decisionModelList')
    private updateComponent(newData: DecisionModel[], oldData: DecisionModel[]): void {
        this.isDataLoaded = false;
        this.decisionModelListLocal = JSON.parse(JSON.stringify(newData));
        if (newData && newData !== oldData) {
            this.isDataLoaded = true;
        }
    }

    private updateComponentInRealTime(): void {
        this.showConfirmModal = true;
        EventBus.$emit(EventBus.REPORT.SHOW_CONFIRMATION, this.showConfirmModal);
    }

    private isValid(): boolean {
        if (this.updComment === '') {
            this.isCommentEmpty = true;
        }
        return !this.isCommentEmpty;
    }

    private makeEditable(modalId: number): void {
        this.isInEditMode = modalId === this.componentId;
    }

    private reset(modalId: number): void {
        if (modalId !== this.componentId ) {
            return;
        }
        this.decisionModelListLocal = JSON.parse(JSON.stringify(this.ampModel.ampData));
        this.updComment = '';
        this.$forceUpdate();
    }

    private cancel(modalId: number): void {
        if (modalId !== this.componentId ) {
            return;
        }
        this.isInEditMode = false;
        this.decisionModelListLocal = JSON.parse(JSON.stringify(this.ampModel.ampData));
        this.updComment = '';
        this.$forceUpdate();
    }

    private async save(modalId: number): Promise<void> {
        if (modalId !== this.componentId ) {
            return;
        }
        if (this.updComment === '') {
            this.isCommentEmpty = true;
            document.getElementById('updComment')?.scrollIntoView({behavior: 'smooth'});
        } else {
            this.isInEditMode = false;
            const ampToSave: AMPModel = {...this.ampModel};
            ampToSave.ampData = this.decisionModelListLocal;
            ampToSave.updComment = this.updComment;
            const sNo = (await this.ampService.saveAMPData(this.serialNumber, ampToSave)).result.sNo;
            this.$emit('new-amp-data', this.decisionModelListLocal);
            EventBus.$emit(EventBus.REPORT.UPDATE, sNo);
        }
    }

    private processSelection(index: number, process: string): void {
        if (this.isInEditMode) {
            this.updateComponentInRealTime();
            const decisionModel: DecisionModel = this.decisionModelListLocal[index];
            if (decisionModel.process === process) {
                decisionModel.process = '';
            } else {
                decisionModel.process = process;
            }
            this.$set(this.decisionModelListLocal, index, decisionModel);
            this.$forceUpdate();
        }
    }

    private beforeDestroy(): void {
        if (this.isPopup) {
            EventBus.$off(EventBus.REPORT_MODAL.EDIT);
            EventBus.$off(EventBus.REPORT_MODAL.SAVE);
            EventBus.$off(EventBus.REPORT_MODAL.RESET);
            EventBus.$off(EventBus.REPORT_MODAL.CLOSE);
        }
    }

    private getLosDescription(category: string, classification: string): string {
        const model = this.losModelList.find(
            (item) => item.category === category && item.classification === classification,
        );
        return model ? model.value : this.$t('noDecriptionAvaliable') as string;
    }

    private hoverPosition(index: number): any {
        return {
            'tooltiptext': true,
            'hover-left': index > 9,
            'hover-right': index <= 9,
        };
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';
.amprocess-new {
    white-space: pre-line;
    min-height: 15rem;
    width: 100%;
    font-size: 1.2rem;
    table {
        border-spacing: 0;
        border-collapse: collapse;
    }
    .scrollable {
        overflow: auto;
    }
    .rotate {
        margin: 0 auto;
        padding: 1rem;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        letter-spacing: 0.15rem;
    }
    .bl {
        border-left: 0.1rem solid @warm-grey;
    }
    .br {
        border-right: 0.1rem solid @warm-grey;
    }
    .sub-category {
        font-size: 1.5rem;
        text-align: center;
        vertical-align: bottom;
    }
    .category {
        font-size: 1.6rem;
        text-align: left;
        padding: 0.5rem;
    }
    td {
        border: 0.1rem solid @warm-grey;
        height: 2rem;
        width: 2rem;
        white-space: nowrap;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
    }
    th:first-child,
    th:nth-child(8),
    th:nth-child(10),
    th:last-child {
        border-right: 0.1rem solid @warm-grey;
    }
    .mainheader {
        font-size: 1.8rem;
        font-stretch: extra-expanded;
    }
    .darkGreenBox::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        margin-top: 0.3rem;
        background-color: @green-darker !important;
    }
    .lightGreenBox::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        margin-top: 0.3rem;
        background-color: @contextual-green !important;
    }
    .yellowBox::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        margin-top: 0.3rem;
        background-color: @amber-light;
    }
    .redBox::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        margin-top: 0.3rem;
        background-color: @red-lighter;
    }
    .flex-justify-center {
        justify-content: center;
    }
    .handCursor {
        cursor: pointer;
    }
    .edit-comment {
        height: calc(100% - 11.5rem);
        overflow: hidden;
        label {
            margin-top: 1rem;
            min-width: fit-content;
        }
        input, textarea {
            width: 100%;
        }
        .am-comment {
            position: relative;
            label {
                position: absolute;
                background: white;
                left: 1.8rem;
                top: -2.1rem;
                padding: 0 1rem;
                font-size: 1.6rem;
                z-index: 1;
            }
            .comment-input {
                display: block;
                margin: 0 auto;
            }
            input, textarea {
                box-sizing: border-box;
                font-size: 1.8rem;
                padding: 1rem 2.5rem;
                border-radius: @border-radius-standard;
                border: 2px solid @dark-grey;
                transition: 0.2s ease-in;
                &:focus {
                    border: 2px solid @uniper-blue;
                    transition: 0.2s ease-in;
                    outline: none;
                }
                &.invalid {
                    border: 2px solid @red-darker;
                    box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
                    transition: 0.1s ease-in;
                }
                &.full-width {
                    width: 100%;
                }
            }
        }
    }

    .tooltip {
        position: relative;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        font-size: 1.5rem;
        width: 30rem;
        height: auto;
        max-height: 20rem;
        background-color: rgba(@uniper-blue, 0.9);
        color: @white;
        white-space: pre-wrap;
        padding: 1.2rem;
        border-radius: @border-radius-standard;

        content: " ";
        position: absolute;
        overflow: scroll;
        overflow-x: hidden;
        z-index: 1;
        border-width: 0.75rem;
        border-style: solid;
        border-color: transparent transparent transparent transparent;
        &.hover-left {
            bottom: 100%;
            right: 0%;
        }
        &.hover-right {
            bottom: 100%;
            left: 0%;
        }
    }
    .hover-box-title {
        left: 0;
        font-weight: bolder;
    }
    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: @uniper-blue-light;
    }
}
</style>
