import { Asset, ApiResponse } from '@/models';
import BaseService from '@/utils/base-service';

export class DataFreezeService extends BaseService {
    private endpoint = '/MachineNameMapping';

    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async getDataFreezeMachines(fleetIds: any): Promise<ApiResponse<Asset[]>> {
        return (await this.api.post(`${this.endpoint}/machines-with-latest-datafreeze`, fleetIds)).data;
    }
}
