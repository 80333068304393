<template>
    <div class="notification-wrapper">
        <transition name="fade" appear>
            <div class="notification" :class="'notification-' + notification.type">
                <a class="close-button" href="#" @click="close"><font-awesome-icon :icon="['fas', 'times']" class="clear"/></a>
                <a class="close-button mr-1" href="#" @click="copy"><font-awesome-icon :icon="['fas', 'copy']" class="clear"/></a>
                <h3>{{ notification.title ? notification.title : '' }}</h3>
                {{ notification.text ? notification.text : 'Default text' }}
                <div>
                    <small>{{ notification.datetime.toUTCString() }}</small>
                </div>
            </div>
        </transition>
    </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ToastNotification } from '@/models/toast-notification';

@Component({
    name: 'toast'
})
export default class Toast extends Vue {
    @Prop() notification!: ToastNotification;

    public close(event: Event): void {
        event?.preventDefault();
        this.$store.dispatch('toastModule/removeNotification', this.notification)
    }

    public copy(event: Event): void {
        event?.preventDefault();
        navigator.clipboard.writeText(`${this.notification.datetime.toISOString()} [${this.notification.type}] - ${this.notification.title}: ${this.notification.text}`);
    }
}
</script>

<style lang="less">
@import '../../variables.less';
h3 {
    margin: 0;
    font-size: 1.6rem;
}
.close-button {
    color: white;
    display: block;
    float: right;
    text-decoration: none;
}
.notification-wrapper {
    position: relative;
    width: 300px;
    margin: 10px;
}
.notification {
    height: 55px;
    padding: 10px 15px;
    border-width: 2px;
    border-style: solid;
    border-radius: @border-radius-standard;
    color: #FFF;
    background-color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.79);
    box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.79);
}

.notification-error {
    background-color: @red-lighter;
    border-color: @red-darker;
}

.notification-success {
    background-color: @green-darker;
    border-color: darken(@green-darker, 5%);
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
