<template>
    <div class="confirm-modal-backdrop">
        <div class="confirm-modal center-align">
            <div class="mb-2">{{$t(`${message}`)}}</div>
            <div>
                <button
                    type="button"
                    class="add-button mr-2"
                    @click="confirm()">
                    {{$t('yes')}}
                </button>
                <button
                    type="button"
                    class="remove-button"
                    @click="cancel()">
                    {{$t('no')}}
                </button>
            </div>
        </div>
        <div class="ourside-confirm-modal" @click="cancel()"></div>
    </div>
</template>

<script lang="ts">

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'confirm-modal',
})
export default class ConfirmModal extends Vue {
    /**
     * Item which is pending for delete.
     */
    @Prop()
    private item!: any;

    /**
     * Message that will be shown for the confirmation dialog.
     */
    @Prop()
    private message!: string;

    private confirm(): void {
        this.$emit('confirm-action', this.item);
    }

    private cancel(): void {
        this.$emit('cancel-action');
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';

.confirm-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    .ourside-confirm-modal {
        z-index: auto;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
    }
    .confirm-modal {
        z-index: 15;
        background: white;
        border-radius: @border-radius-standard;
        box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.2);
        width: 25%;
        position: fixed;
        padding: 2.5rem;
        transition: 0.2s ease-in;
        border: 1px solid @grey-light;
    }
}
</style>
