import { sdk } from '@/utils/coode-sdk';

/**
 * @name AppService
 * @description This class is used to query the identity server for user related data,
 * which is not related to authentication.
 */
export class AppService {
    constructor() {
        sdk?.core.metaData.request
            .api.setBaseUrl(process.env.VUE_APP_META_DATA_FEED_API_BASE_URL + '/api/v1');
    }

    public async getApps(): Promise<any> {
        return (await sdk?.core.metaData.request.api.get(`use-cases`)).data;
    }
}
