<template>
    <div class="kpi-a">
        <div v-if="!dataloaded" class="loader"></div>
        <div v-else id="type-c-Chart">
            <div class="flex-col flex-1">
                <div class="flex-row flex-1">
                    <div class="flex-col flex-10">
                        <div class="flex-row flex-1 flex-justify-center mb-1 chart-title">{{kpiAData.technical.kpiName}}</div>
                        <div class="flex-row flex-10 mr-1">
                            <table>
                                <thead>
                                    <tr>
                                        <td rowspan="2">KPI</td>
                                        <td colspan="3"><p>MTC {{ yearLabel }} ACT<sup>1</sup></p></td>
                                        <td colspan="3">MTC {{ yearLabel }} MTP</td>
                                        <td rowspan="2">MTP {{ yearLabel }} AVG</td>
                                    </tr>
                                    <tr>
                                        <td>Value</td>
                                        <td>Vs. ex.BM</td>
                                        <td>Vs. Fleet</td>
                                        <td>Value</td>
                                        <td>Vs. ex.BM</td>
                                        <td>Vs. Fleet</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(kpi, i) in technicalKpiData" :key="kpi.name">
                                        <td class="label" style="white-space: nowrap;"><p>{{kpi.name}}<sup>{{ i + 2 }}</sup></p></td>
                                        <td>{{kpi.actValue}}%</td>
                                        <td v-html="benchMark[kpi.actBM]" class="qrtr"></td>
                                        <td>
                                            <p v-if="kpi.actFleet === 0">-</p>
                                            <span v-else-if="kpi.actFleet > 0"  class="caret-up">
                                                <font-awesome-icon :icon="['fas', 'caret-up']" />
                                            </span>
                                            <span v-else-if="kpi.actFleet < 0" class="caret-down">
                                                <font-awesome-icon :icon="['fas', 'caret-down']" />
                                            </span>
                                        </td>
                                        <td>{{kpi.mtpValue}}%</td>
                                        <td v-html="benchMark[kpi.mtpBM]"  class="qrtr"></td>
                                        <td>
                                            <p v-if="kpi.mtpFleet === 0">-</p>
                                            <span v-else-if="kpi.mtpFleet > 0"  class="caret-up">
                                                <font-awesome-icon :icon="['fas', 'caret-up']" />
                                            </span>
                                            <span v-else-if="kpi.mtpFleet < 0" class="caret-down">
                                                <font-awesome-icon :icon="['fas', 'caret-down']" />
                                            </span>
                                        </td>
                                        <td>{{kpi.mtpAVG}}%</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td class="qrtr">&#9684;</td>
                                        <td class="qrtr">&#9681;</td>
                                        <td class="qrtr">&#9685;</td>
                                        <td class="qrtr">&#11044;</td>
                                        <td class="caret-up"><font-awesome-icon :icon="['fas', 'caret-up']" /></td>
                                        <td class="caret-down"><font-awesome-icon :icon="['fas', 'caret-down']" /></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>1st Q</td>
                                        <td>2nd Q</td>
                                        <td>3rd Q</td>
                                        <td>4th Q</td>
                                        <td>Higher than AVG</td>
                                        <td>Lower than AVG</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="flex-col flex-10">
                        <div class="flex-row flex-4 reverse">
                            <div class="flex-col">
                                <div class="flex-row flex-1 flex-justify-center mb-1 chart-title chart-title-cost">
                                    {{kpiAData.cost.kpiName}}
                                </div>
                                <div class="flex-row flex-3 reverse">
                                    <div :id="kpi3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-row flex-1">
                            <div class="flex-col flex-1 flex-align-end">
                                <div class="flex-row flex-1 text-no-wrap">
                                    <span class="legend-red"><font-awesome-icon :icon="['fas', 'square']" /></span> Fleet
                                </div>
                            </div>
                            <div class="flex-col flex-1 flex-align-end">
                                <div class="flex-row flex-1 text-no-wrap">
                                    <span class="legend-blue rotate-45-degree"><font-awesome-icon :icon="['fas', 'square']" /></span> {{ yearLabel }}ACT
                                </div>
                            </div>
                            <div class="flex-col flex-1 flex-align-end">
                                <div class="flex-row flex-1 text-no-wrap">
                                    <span class="legend-green"><font-awesome-icon :icon="['fas', 'circle']" /></span> {{ yearLabel }}MTP
                                </div>
                            </div>
                        </div>
                        <div class="flex-row flex-1 flex-justify-end">
                            <div class="flex-col flex-1 flex-align-end">
                                <div class="flex-row flex-1 flex-align-center">
                                    <div class="qrtr1 rotate-90-degree mr-1">|</div>
                                    1st Q
                                </div>
                            </div>
                            <div class="flex-col flex-1 flex-align-end">
                                <div class="flex-row flex-1 flex-align-center"><div class="qrtr2 rotate-90-degree mr-1">|</div>2nd Q</div>
                            </div>
                            <div class="flex-col flex-1 flex-align-end">
                                <div class="flex-row flex-1 flex-align-center"><div class="qrtr3 rotate-90-degree mr-1">|</div>3rd Q</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { GoogleCharts } from 'google-charts';
import {
    KpiAData,
    KpiTechnicalValues,
} from '@/models';

@Component
export default class KpiA extends Vue {
    /**
     * Set the kpi type A data.
     */
    @Prop({ required: true })
    private kpiAData!: KpiAData;

    /**
     * Set the serial number of report.
     */
    @Prop({ required: true })
    private serialNumber!: number;

    /**
     * Set chart title year.
     */
    @Prop({ required: true })
    private yearLabel!: number;

    private technicalKpiData: KpiTechnicalValues[] = [];
    private costKpiData: any = {};
    private benchMark = { 1: '&#9684;', 2: '&#9681;', 3: '&#9685;', 4: '&#11044;' };
    private kpi1n2 = 'kpi1n2';
    private kpi3 = 'kpi3' + this.serialNumber;
    private dataloaded = false;

    @Watch('kpiAData')
    private updateComponent(newKpiAData: KpiAData): void {
        this.technicalKpiData = newKpiAData.technical.kpiValues;
        this.costKpiData = newKpiAData.cost.kpiValues;
        this.dataloaded = true;
        this.drawCostKpi();
    }

    private roundDecimal(num: number, place: number): any {
        return num.toFixed(place);
    }

    private mounted(): void {
        this.technicalKpiData = this.kpiAData.technical.kpiValues;
        this.costKpiData = this.kpiAData.cost.kpiValues;
        this.dataloaded = true;
        GoogleCharts.load(this.drawCostKpi);
    }

    private getCostKpiData(): any {
        const x = this.costKpiData.xAxis;
        const y = this.costKpiData.yAxis;
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('number', 'index');
        data.addColumn('number', this.yearLabel + 'ACT ' + 'Fleet'); // red1
        data.addColumn('number', this.yearLabel + 'MTP ' + 'Fleet'); // red2
        data.addColumn('number', this.yearLabel + 'ACT'); // blue
        data.addColumn('number', this.yearLabel + 'MTP'); // green
        data.addColumn('number', '1st Q');
        data.addColumn('number', '2nd Q');
        data.addColumn('number', '3rd Q');
        data.addRows([
            [0, null, null, null, null, y.q1First, y.q2First, y.q3First],
            [x.actFleet, y.actFleet, null, null, null, null, null, null],
            [x.mtpFleet, null, y.mtpFleet, null, null, null, null, null],
            [x.act, null, null, y.act, null, null, null, null],
            [x.mtp, null, null, null, y.mtp, null, null, null],
            [100, null, null, null, null, y.q1Last, y.q2Last, y.q3Last],
        ]);
        return data;
    }

    private drawCostKpi(): void {
        const data = this.getCostKpiData();

        const options = {
            title: this.kpiAData.cost.kpiName,
            width: 240,
            height: 180,
            chartArea: { width: '75%', height: '60%', right: 10, top: 10 },
            legend: { position: 'none' },
            fontSize: 12,
            hAxis: {
                title: 'Net Capacity Factor (incl. Heat Eq) [%]',
                titleTextStyle: {
                    italic: false,
                    fontSize: 10,
                },
                gridlines: { count: 5 },
            },
            vAxis: {
                title: 'NFOM/Capacity [ €/kW ]',
                titleTextStyle: {
                    italic: false,
                    fontSize: 10,
                },
                minValue: 0,
                gridlines: { count: 3 },
            },
            explorer: {
                actions: ['dragToZoom', 'rightClickToReset'],
                axis: 'horizontal',
                keepInBounds: true,
                maxZoomIn: 10.0,
            },
            colors: ['#D44E41', '#a52714'],
            series: {
                0: { type: 'scatter', visibleInLegend: true, color: '#F21100', pointShape: 'square', pointSize: 12},
                1: { type: 'scatter', visibleInLegend: false, color: '#F21100', pointShape: 'square', pointSize: 12 },
                2: { type: 'scatter', visibleInLegend: true, color: '#0078DC', pointShape: 'diamond', pointSize: 12 },
                3: { type: 'scatter', visibleInLegend: true, color: '#00C853', pointSize: 12 },
                4: { color: '#00944A' },
                5: { color: '#FFB503' },
                6: { color: '#F21100' },
            },
            interpolateNulls: true,
        };

        const chart = new GoogleCharts.api.visualization.LineChart(document.getElementById(this.kpi3));
        chart.draw(data, options);
    }
}

</script>

<style scoped lang="less">
@import '../../../variables.less';
.flex-justify-end{
  justify-content: flex-end;
}
.flex-justify-center{
  justify-content: center;
}
.flex-justify-between{
  justify-content: space-around;
}
.flex-align-end{
  align-items: flex-end;
}
.flex-align-center{
  align-items: center;
}
.kpi-a{
    table{
        thead{
            background-color: @uniper-blue-dark;
            tr{
                td{
                    color: @white;
                    font-size: 1rem;
                    font-weight: 2rem;
                    text-align: center;
                }
            }
        }
        tbody{
            tr{
                height: 2px;
                td{
                    text-align: center;
                }
            }
        }

    }
    .text-no-wrap{
        white-space: pre-wrap;
    }
    .caret-down{
        font-size: 2rem;
        color: @medium-red;
    }
    .caret-up{
        font-size: 2rem;
        color: @contextual-green;
    }
    .legend-red{
        font-size: 1rem;
        color: @contextual-red;
    }
    .legend-green{
        font-size: 1rem;
        color: @contextual-green;
    }
    .legend-blue{
        font-size: 1rem;
        color: @uniper-blue;
    }
    .rotate-45-degree {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .rotate-90-degree {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    .qrtr1{
        color: @green-darker;
        font-size: 3rem;
        font-weight: 2rem;
        white-space: pre-wrap;
    }
    .qrtr2{
        color: @contextual-amber;
        font-size: 3rem;
        font-weight: 2rem;
        white-space: pre-wrap;
    }
    .qrtr3{
        color: @contextual-red;
        font-size: 3rem;
        font-weight: 2rem;
        white-space: pre-wrap;
    }
    .qrtr{
        font-size: 1.5rem;
    }
    .loader{
        min-height: 12rem;
    }
    .chart-title-cost {
        padding-left: 3.5rem;
    }
}
</style>
