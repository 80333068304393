import { CoodeSdk, CoodeSdkOptions, Environments } from '@coode/fe-sdk';

export class EasCoodeSdk extends CoodeSdk {
    constructor() {
        const coodeSdkConfig: CoodeSdkOptions = {
            environment: process.env.VUE_APP_ENVIRONMENT as Environments,
            auth: {
                msal: {
                    auth: {
                        clientId: process.env.VUE_APP_CLIENT_ID as string,
                        authority: process.env.VUE_APP_AUTHORITY_URL as string,
                        redirectUri: window.location.origin,
                        postLogoutRedirectUri: `${window.location.origin}/authorization/logged-out.html`,
                        navigateToLoginRequestUrl: true,
                    },
                }
            },
            urls: {
                userAccessManagement: {
                    development: {
                        url: 'https://app-coode-user-access-management-feed-mi-api-dev.azurewebsites.net/api/v1/',
                        scope: 'api://7c623e28-b433-4f19-84d3-1d27da7122dc/coode',
                    },
                    dev: {
                        url: 'https://app-coode-user-access-management-feed-mi-api-dev.azurewebsites.net/api/v1/',
                        scope: 'api://7c623e28-b433-4f19-84d3-1d27da7122dc/coode',
                    },
                    uat: {
                        url: 'https://app-coode-user-access-management-feed-mi-api-uat.azurewebsites.net/api/v1/',
                        scope: 'api://13bd8d2d-8452-45bc-b441-f3c9a37cfe8e/coode',
                    },
                    prd: {
                        url: 'https://app-coode-user-access-management-feed-mi-api-prd.azurewebsites.net/api/v1/',
                        scope: 'api://1a20c810-03c5-4a24-ae43-ceff755a5d82/coode',
                    },
                },
                metaData: {
                    development: {
                        url: 'https://app-coode-meta-data-feed-mi-api-dev.azurewebsites.net/api/v1/',
                        scope: 'api://af28c31c-9844-4b6d-86d9-b30ed92e97cd/coode',
                    },
                    dev: {
                        url: 'https://app-coode-meta-data-feed-mi-api-dev.azurewebsites.net/api/v1/',
                        scope: 'api://af28c31c-9844-4b6d-86d9-b30ed92e97cd/coode',
                    },
                    uat: {
                        url: 'https://app-coode-meta-data-feed-mi-api-uat.azurewebsites.net/api/v1/',
                        scope: 'api://313adb2f-7c58-417e-a8f4-3f910be78b8e/coode',
                    },
                    prd: {
                        url: 'https://app-coode-meta-data-feed-mi-api-prd.azurewebsites.net/api/v1/',
                        scope: 'api://c6c9ca2f-6328-4070-b3c0-1cef30aa8248/coode',
                    },
                },
                masterData: {
                    development: {
                        url: 'https://app-coode-master-data-feed-mi-api-dev.azurewebsites.net/api/v1.1/',
                        scope: 'api://e37e2af7-016e-464c-926f-555a3741c2e2/coode',
                    },
                    dev: {
                        url: 'https://app-coode-master-data-feed-mi-api-dev.azurewebsites.net/api/v1.1/',
                        scope: 'api://e37e2af7-016e-464c-926f-555a3741c2e2/coode',
                    },
                    uat: {
                        url: 'https://app-coode-master-data-feed-mi-api-uat.azurewebsites.net/api/v1.1/',
                        scope: 'api://48fa1515-fbc4-4818-9bdb-46689669bf66/coode',
                    },
                    prd: {
                        url: 'https://app-coode-master-data-feed-mi-api-prd.azurewebsites.net/api/v1.1/',
                        scope: 'api://2a867901-c7b1-4e61-a6b4-9890c6cb4d6d/coode',
                    },
                },
                translation: {
                    development: {
                        url: 'https://app-coode-translations-mi-api-dev.azurewebsites.net/api/v1/',
                        scope: 'api://09d0782f-5032-4835-9c40-cc4c3f710fcd/coode',
                    },
                    dev: {
                        url: 'https://app-coode-translations-mi-api-dev.azurewebsites.net/api/v1/',
                        scope: 'api://09d0782f-5032-4835-9c40-cc4c3f710fcd/coode',
                    },
                    uat: {
                        url: 'https://app-coode-translations-mi-api-uat.azurewebsites.net/api/v1/',
                        scope: 'api://228c66ef-3244-4107-9786-ea031712349c/coode',
                    },
                    prd: {
                        url: 'https://app-coode-translations-mi-api-prd.azurewebsites.net/api/v1/',
                        scope: 'api://c1cf86d4-065a-40a4-8285-ceacd70c92cd/coode',
                    },
                },
            },
            insights: {
                appInsightsConnectionString: process.env.VUE_APP_APPINSIGHTS_CONNECTION_STRING
            },
        };

        super(coodeSdkConfig);
    }
}

export const sdk = new EasCoodeSdk();
