<template>
  <div class="kpi-d-edit">
    <div
      class="flex-row flex-1"
      v-for="(kpiDData, kpiDIndex) in chartData"
      :key="kpiDIndex"
    >
      <div class="flex-col flex-1 mr-2 ml-1">
        <div class="flex-row flex-1 flex-align-center">
          {{ kpiDData.kpiNameLabel }}
        </div>
        <div class="flex-row flex-1 flex-align-center mt-2 mr-2">
          {{ kpiDData.typeDLabel1 }}
        </div>
        <div class="flex-row flex-1 flex-align-center mt-2 mr-2">
          {{ kpiDData.typeDLabel2 }}
        </div>
      </div>
      <div class="flex-col flex-2">
        <div class="flex-row flex-1 flex-align-center chart-title">
          {{ kpiDData.kpiName }}
        </div>
        <div class="flex-row flex-1 flex-justify-center mt-2">
          <div class="flex-col flex-4 box-side-label pr-1 ml-1">
            {{ $t("struggleWithUnambitiousTargets") }}
          </div>
          <div
            class="flex-col flex-2 ml-1 redBox"
            @click="performanceBlock.isRedMarked = !performanceBlock.isRedMarked"
          >
            <span v-if="performanceBlock.isRedMarked">{{ cross }}</span>
          </div>
          <div
            class="flex-col flex-2 yellowBox"
            @click="performanceBlock.isYellowMarked = !performanceBlock.isYellowMarked"
          >
            <span v-if="performanceBlock.isYellowMarked">{{ cross }}</span>
          </div>
          <div
            class="flex-col flex-2 darkGreenBox"
            @click="performanceBlock.isDarkGreenMarked = !performanceBlock.isDarkGreenMarked"
          >
            <span v-if="performanceBlock.isDarkGreenMarked">{{ cross }}</span>
          </div>
          <div
            class="flex-col flex-2 lightGreenBox"
            @click="performanceBlock.isLightGreenMarked = !performanceBlock.isLightGreenMarked"
          >
            <span v-if="performanceBlock.isLightGreenMarked">{{ cross }}</span>
          </div>
          <div
            class="flex-col flex-2 mr-2 greenBox"
            @click="performanceBlock.isGreenMarked = !performanceBlock.isGreenMarked"
          >
            <span v-if="performanceBlock.isGreenMarked">{{ cross }}</span>
          </div>
          <div
            class="flex-col flex-4 flex-justify-center mr-5 pr-5 box-side-label"
          >
            {{ $t("exceedAmbitiousTarget") }}
          </div>
        </div>
        <div class="flex-row flex-1 mt-2">
          <div
            class="flex-col flex-4 flex-align-center pt-1 pr-1 ml-1 box-side-label"
          >
            {{ $t("underMaintained") }}
          </div>
          <div
            class="flex-col flex-2 ml-1 redBox"
            @click="riskBlock.isRedMarked = !riskBlock.isRedMarked"
          >
            <span v-if="riskBlock.isRedMarked">{{ cross }}</span>
          </div>
          <div
            class="flex-col flex-2 yellowBox"
            @click="riskBlock.isYellowMarked = !riskBlock.isYellowMarked"
          >
            <span v-if="riskBlock.isYellowMarked">{{ cross }}</span>
          </div>
          <div
            class="flex-col flex-2 darkGreenBox"
            @click="riskBlock.isDarkGreenMarked = !riskBlock.isDarkGreenMarked"
          >
            <span v-if="riskBlock.isDarkGreenMarked">{{ cross }}</span>
          </div>
          <div
            class="flex-col flex-2 lightGreenBox"
            @click="riskBlock.isLightGreenMarked = !riskBlock.isLightGreenMarked"
          >
            <span v-if="riskBlock.isLightGreenMarked">{{ cross }}</span>
          </div>
          <div
            class="flex-col flex-2 mr-2 greenBox"
            @click="riskBlock.isGreenMarked = !riskBlock.isGreenMarked"
          >
            <span v-if="riskBlock.isGreenMarked">{{ cross }}</span>
          </div>
          <div
            class="flex-col flex-4 flex-justify-center pt-1 mr-5 pr-5 box-side-label"
          >
            {{ $t("wellMaintained") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class KpiDEdit extends Vue {
    /**
     * kpi-D data from Performance Assesment
     */
    @Prop({ required: true })
    private chartData!: any;

    private cross = 'X';
    private performanceValue = 0;
    private riskBlockValue = 0;
    private performanceBlock = {
        isRedMarked: false,
        isYellowMarked: false,
        isDarkGreenMarked: false,
        isLightGreenMarked: false,
        isGreenMarked: false,
    };
    private riskBlock = {
        isRedMarked: false,
        isYellowMarked: false,
        isDarkGreenMarked: false,
        isLightGreenMarked: false,
        isGreenMarked: false,
    };
    private typeDValues = {
        typeDValue1: 0,
        typeDValue2: 0,
    };

    private mounted(): void {
        this.emitValues();
        this.crossRiskBlock();
        this.crossPerformanceBlock();
    }

    @Watch('performanceBlock.isRedMarked')
    private updatePerformanceRed(): void {
        this.editRed();
    }

    @Watch('performanceBlock.isYellowMarked')
    private updatePerformanceYellow(): void {
        this.editYellow();
    }

    @Watch('performanceBlock.isDarkGreenMarked')
    private updatePerformanceDarkGreen(): void {
        this.editDarkGreen();
    }

    @Watch('performanceBlock.isLightGreenMarked')
    private updatePerformanceLightGreen(): void {
        this.editLightGreen();
    }

    @Watch('performanceBlock.isGreenMarked')
    private updatePerformanceGreen(): void {
        this.editGreen();
    }

    @Watch('riskBlock.isRedMarked')
    private updateRiskRed(): void {
        this.editRed();
    }

    @Watch('riskBlock.isYellowMarked')
    private updateRiskYellow(): void {
        this.editYellow();
    }

    @Watch('riskBlock.isDarkGreenMarked')
    private updateRiskDarkGreen(): void {
        this.editDarkGreen();
    }

    @Watch('riskBlock.isLightGreenMarked')
    private updateRiskLightGreen(): void {
        this.editLightGreen();
    }

    @Watch('riskBlock.isGreenMarked')
    private updateRiskGreen(): void {
        this.editGreen();
    }

    @Watch('chartData')
    private updateComponent(): void {
        this.crossPerformanceBlock();
        this.crossRiskBlock();
    }

    private crossPerformanceBlock(): void {
        for (const crossValue of this.chartData) {
            this.performanceValue = crossValue.typeDValue1;
            if (this.performanceValue === 1) {
                this.performanceBlock.isRedMarked = true;
            }
            if (this.performanceValue === 2) {
                this.performanceBlock.isYellowMarked = true;
            }
            if (this.performanceValue === 3) {
                this.performanceBlock.isDarkGreenMarked = true;
            }
            if (this.performanceValue === 4) {
                this.performanceBlock.isLightGreenMarked = true;
            }
            if (this.performanceValue === 5) {
                this.performanceBlock.isGreenMarked = true;
            }
        }
    }

    private crossRiskBlock(): void {
        for (const crossValue of this.chartData) {
            this.riskBlockValue = crossValue.typeDValue2;
            if (this.riskBlockValue === 1) {
                this.riskBlock.isRedMarked = true;
            }
            if (this.riskBlockValue === 2) {
                this.riskBlock.isYellowMarked = true;
            }
            if (this.riskBlockValue === 3) {
                this.riskBlock.isDarkGreenMarked = true;
            }
            if (this.riskBlockValue === 4) {
                this.riskBlock.isLightGreenMarked = true;
            }
            if (this.riskBlockValue === 5) {
                this.riskBlock.isGreenMarked = true;
            }
        }
    }

    private emitValues(): void {
        this.$emit('type-d-values', this.typeDValues);
    }
    private editRed(): void {
        if (this.performanceBlock.isRedMarked) {
            this.performanceBlock.isYellowMarked = false;
            this.performanceBlock.isDarkGreenMarked = false;
            this.performanceBlock.isLightGreenMarked = false;
            this.performanceBlock.isGreenMarked = false;
            this.typeDValues.typeDValue1 = 1;
            this.emitValues();
        }
        if (this.riskBlock.isRedMarked) {
            this.riskBlock.isYellowMarked = false;
            this.riskBlock.isDarkGreenMarked = false;
            this.riskBlock.isLightGreenMarked = false;
            this.riskBlock.isGreenMarked = false;
            this.typeDValues.typeDValue2 = 1;
            this.emitValues();
        }
    }
    private editYellow(): void {
        if (this.performanceBlock.isYellowMarked) {
            this.performanceBlock.isRedMarked = false;
            this.performanceBlock.isDarkGreenMarked = false;
            this.performanceBlock.isLightGreenMarked = false;
            this.performanceBlock.isGreenMarked = false;
            this.typeDValues.typeDValue1 = 2;
            this.emitValues();
        }
        if (this.riskBlock.isYellowMarked) {
            this.riskBlock.isRedMarked = false;
            this.riskBlock.isDarkGreenMarked = false;
            this.riskBlock.isLightGreenMarked = false;
            this.riskBlock.isGreenMarked = false;
            this.typeDValues.typeDValue2 = 2;
            this.emitValues();
        }
    }
    private editDarkGreen(): void {
        if (this.performanceBlock.isDarkGreenMarked) {
            this.performanceBlock.isYellowMarked = false;
            this.performanceBlock.isRedMarked = false;
            this.performanceBlock.isLightGreenMarked = false;
            this.performanceBlock.isGreenMarked = false;
            this.typeDValues.typeDValue1 = 3;
            this.emitValues();
        }
        if (this.riskBlock.isDarkGreenMarked) {
            this.riskBlock.isYellowMarked = false;
            this.riskBlock.isRedMarked = false;
            this.riskBlock.isLightGreenMarked = false;
            this.riskBlock.isGreenMarked = false;
            this.typeDValues.typeDValue2 = 3;
            this.emitValues();
        }
    }
    private editLightGreen(): void {
        if (this.performanceBlock.isLightGreenMarked) {
            this.performanceBlock.isYellowMarked = false;
            this.performanceBlock.isDarkGreenMarked = false;
            this.performanceBlock.isRedMarked = false;
            this.performanceBlock.isGreenMarked = false;
            this.typeDValues.typeDValue1 = 4;
            this.emitValues();
        }
        if (this.riskBlock.isLightGreenMarked) {
            this.riskBlock.isYellowMarked = false;
            this.riskBlock.isDarkGreenMarked = false;
            this.riskBlock.isRedMarked = false;
            this.riskBlock.isGreenMarked = false;
            this.typeDValues.typeDValue2 = 4;
            this.emitValues();
        }
    }
    private editGreen(): void {
        if (this.performanceBlock.isGreenMarked) {
            this.performanceBlock.isYellowMarked = false;
            this.performanceBlock.isDarkGreenMarked = false;
            this.performanceBlock.isLightGreenMarked = false;
            this.performanceBlock.isRedMarked = false;
            this.typeDValues.typeDValue1 = 5;
            this.emitValues();
        }
        if (this.riskBlock.isGreenMarked) {
            this.riskBlock.isYellowMarked = false;
            this.riskBlock.isDarkGreenMarked = false;
            this.riskBlock.isLightGreenMarked = false;
            this.riskBlock.isRedMarked = false;
            this.typeDValues.typeDValue2 = 5;
            this.emitValues();
        }
    }
}
</script>

<style scoped lang="less">
@import '../../../variables.less';
.kpi-d-edit {
    .flex-justify-center {
        justify-content: center;
    }
    .box-side-label {
        font-size: 1.2rem;
    }
    .redBox {
        background-color: @contextual-red;
        border: 1px solid @font-color-dark-grey;
        font-size: 1rem;
        height: 4rem;
        width: 4rem;
    }
    .yellowBox {
        background-color: @contextual-amber;
        border: 1px solid @font-color-dark-grey;
        border-left: none;
        font-size: 1rem;
        height: 4rem;
        width: 4rem;
    }
    .darkGreenBox {
        background-color: #9acd32;
        border: 1px solid @font-color-dark-grey;
        border-left: none;
        font-size: 1rem;
        height: 4rem;
        width: 4rem;
    }
    .lightGreenBox {
        background-color: #00fa9a;
        border: 1px solid @font-color-dark-grey;
        border-left: none;
        font-size: 1rem;
        width: 4rem;
        height: 4rem;
    }
    .greenBox {
        background-color: #00ff00;
        border: 1px solid @font-color-dark-grey;
        border-left: none;
        font-size: 1rem;
        height: 4rem;
        width: 4rem;
    }
    span {
        font-size: 2rem;
        color: @dark-grey-darker;
        padding: 0.65rem;
        text-align: center;
    }
}
</style>
