import { RunId, ApiResponse, DecisionModelOutput } from '@/models';
import BaseService from '@/utils/base-service';
import {UpdateDataFreezeStatus} from '@/models/update-data-freeze-status';

export class RunIdService extends BaseService {
    private endpoint = '/ResultSets';
    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async get(assetId: number): Promise<ApiResponse<RunId[]>> {
        return (await this.api.get(`${this.endpoint}/runId/${assetId}`)).data;
    }
    public async markAsfinal(requestObj: RunId): Promise<ApiResponse<RunId>> {
        return (await this.api.post(`${this.endpoint}/makefinal`, requestObj)).data;
    }
    public async updateFreezeDataStatus(sno: number, requestObj: UpdateDataFreezeStatus): Promise<ApiResponse<any>> {
        return (await this.api.
            post(`${this.endpoint}/update-data-freeze-status/${sno}`, requestObj)).data;
    }

    public async setDecisionModelOutput(sno: number, requestObj: DecisionModelOutput)
        : Promise<ApiResponse<DecisionModelOutput>> {
        return (await this.api.post(`${this.endpoint}/DecisionModelOutput/${sno}`, requestObj)).data;
    }
}
