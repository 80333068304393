import { Commit, Dispatch, Module } from 'vuex';
import { ToastNotification } from '@/models/toast-notification'

export const toastModuleDisplayError = 'toastModule/displayError';
export const toastModuleDisplayNotification = 'toastModule/displayNotification';

interface ToastState {
    notifications: ToastNotification[]
}

export const toastModule: Module<ToastState, any> = {
    namespaced: true,
    state: {
        notifications: [],
    },
    mutations: {
        displayNotification(state: ToastState, payload: ToastNotification) {
            state.notifications = [...state.notifications, {...payload, id: Symbol(), datetime: new Date(Date.now())}];
        },
        removeNotification(state: ToastState, notification: ToastNotification) {
            const notifications = [...state.notifications];
            const toRemove = notifications.indexOf(notification);
            notifications.splice(toRemove, 1);
            state.notifications = [...notifications]
        },
    },
    actions: {
        displayNotification({dispatch, commit}: {dispatch: Dispatch, commit: Commit}, payload: ToastNotification) {
            commit('displayNotification', payload);
        },
        displayError({dispatch, commit}: {dispatch: Dispatch, commit: Commit}, payload: Error) {
            commit('displayNotification', {
                title: 'Error',
                text: payload.message,
                type: 'error',
                datetime: new Date(Date.now())
            });
        },
        removeNotification({commit}: {commit: Commit}, payload: ToastNotification) {
            commit('removeNotification', payload);
        },
    },
};
