<template>
    <div class='ribbon'>
        <div v-if="!isDataLoaded" class="popup"><eas-loader></eas-loader></div>
        <div v-else class="flex-container pl-2 pr-2">
            <div class="flex-col flex-10">
                <div class="flex-row">
                    <div class="flex-col">
                        <label>{{ $t('selectAsset') }}</label>
                        <div class="flex-row flex-1">
                            <SelectDropdown
                                :options="assets"
                                :default="assetselection"
                                displayKey='name'
                                class='select'
                                @input="onSelectAsset"
                                style="width: 30rem"
                            />
                        </div>
                    </div>
                    <div class="mr-2"></div>
                    <div v-if="hasEditAccess" class="flex-col">
                        <label>{{ $t('selectCalculationDate') }}</label>
                        <div class="flex-row flex-1">
                            <dropdown
                                @selected-runid="onRunIdSelect"
                                :itemList="runIds"
                                :defaultRunId="runIdSelection"
                            />
                        </div>
                    </div>
                    <div v-if="hasEditAccess" class="flex-col pl-2 justify-end" style="padding-bottom: 0.32rem">
                        <button v-show="!runIdSelection.isFinal"
                            class="btn"
                            @click="() => shouldShowConfirm = true">{{ $t('markAsFinal') }}
                        </button>
                    </div>
                    <div v-if="hasDataFreezeAccess && !runIdSelection.isDataFreeze" class="flex-col pl-2 justify-end" style="padding-bottom: 0.32rem">
                        <button
                            v-show="runIdSelection.isFinal"
                            class="btn"
                            @click="() => dataFreezeConfirmModal = true"
                        >{{ $t('freezeData') }}
                        </button>
                    </div>
                    <div v-if="hasDataFreezeAccess && runIdSelection.isDataFreeze" class="flex-col pl-2 justify-end" style="padding-bottom: 0.32rem">
                        <button
                            class="btn"
                            @click="() => dataUnfreezeConfirmModal = true"
                        >{{ $t('unfreezeData') }}
                        </button>
                    </div>
                    <div v-if="hasEditAccess" class="flex-col pl-2 justify-end" style="padding-bottom: 0.32rem">
                        <button class="btn" @click="openModal()">
                            {{ $t('runCalculation') }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex-col flex-3 justify-end">
                <div class="flex-row reverse">
                    <div v-if="isDataLoaded" class="flex-col" @click="onUiToggle()">
                        <div :class="togglerClasses">
                            <div class="flex-col flex-1 toggle-text">{{ isNewUi ? 'I' : 'II' }}</div>
                            <div class="flex-col flex-1 toggle-switch"></div>
                        </div>
                    </div>
                    <div class="separator"></div>
                    <router-link class="flex-col justify-end goto-download" to="/final-report">
                        <font-awesome-icon :icon="['fas', 'download']" />
                        {{ $t('finalReport') }}
                    </router-link>
                </div>
            </div>
        </div>
        <confirm-modal
            v-if="hasEditAccess" v-show="shouldShowConfirm"
            :item="{ sNo: this.runIdSelection.sNo }"
            message='areYouSureToMarkFinal'
            @confirm-action="markAsFinal"
            @cancel-action="() => shouldShowConfirm = false"
        >
        </confirm-modal>
        <confirm-modal
            v-if="hasViewAccess" v-show="dataFreezeConfirmModal"
            :item="{ sno: this.runIdSelection.sNo, isDataFreeze: true }"
            message='areYouSureToFreezeTheData'
            @confirm-action="dataFreeze"
            @cancel-action="() => dataFreezeConfirmModal = false"
        >
        </confirm-modal>
        <confirm-modal
            v-if="hasViewAccess" v-show="dataUnfreezeConfirmModal"
            :item="{ sno: this.runIdSelection.sNo }"
            message='areYouSureToUnfreezeTheData'
            @confirm-action="dataUnfreeze"
            @cancel-action="() => dataUnfreezeConfirmModal = false"
        >
        </confirm-modal>
        <modal-box
            v-if="showDataFreezeModal"
            :open="showDataFreezeModal"
            :onClose.prop="() => showDataFreezeModal=false"
            :enableHeader.prop="false"
            :enableFooter.prop="false"
            :enableHeaderClose.prop="true"
            :transparentOverlay.prop="false"
            :closeOnClickOutside.prop="true"
            width="auto"
        >
        <span>
            <div v-if="!errorExist" class="flex-row flex-1">
                <div class="flex-col pr-1">
                    {{ $t('dataFreezedSuccessfully') }}
                </div>
                <div class="flex-col flex-justify-center">
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="check-circle fa-lg"/>
                </div>
            </div>
            <div v-else class="flex-row flex-1">
                <div class="flex-col pr-1">
                    {{ $t('errorFreezingData') }}
                </div>
                <div class="flex-col">
                    <font-awesome-icon :icon="['fas', 'times-circle']" class="times-circle fa-lg"/>
                </div>
            </div>
            <div class="flex-row flex-1 reverse mt-1">
                <div class="flex-col">
                    <button class="btn mt-1" @click="() => showDataFreezeModal = false">
                        {{ $t('ok') }}
                    </button>
                </div>
            </div>
        </span>
        </modal-box>

        <modal-box
            v-if="showDataUnfreezeModal"
            :open="showDataUnfreezeModal"
            :onClose.prop="() => showDataUnfreezeModal=false"
            :enableHeader.prop="false"
            :enableFooter.prop="false"
            :enableHeaderClose.prop="true"
            :transparentOverlay.prop="false"
            :closeOnClickOutside.prop="true"
            width="auto"
        >
        <span>
            <div v-if="!errorExist" class="flex-row flex-1">
                <div class="flex-col pr-1">
                    {{ $t('dataUnfreezedSuccessfully') }}
                </div>
                <div class="flex-col flex-justify-center">
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="check-circle fa-lg"/>
                </div>
            </div>
            <div v-else class="flex-row flex-1">
                <div class="flex-col pr-1">
                    {{ $t('errorUnfreezingData') }}
                </div>
                <div class="flex-col">
                    <font-awesome-icon :icon="['fas', 'times-circle']" class="times-circle fa-lg"/>
                </div>
            </div>
            <div class="flex-row flex-1 reverse mt-1">
                <div class="flex-col">
                    <button class="btn mt-1" @click="() => showDataUnfreezeModal = false">
                        {{ $t('ok') }}
                    </button>
                </div>
            </div>
        </span>
        </modal-box>
    </div>
</template>

<script lang="ts">

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Asset, RunId } from '@/models';
import EasLoader from '../loader/eas-loader.vue';
import Dropdown from '../dropdown/dropdown.vue';
import SelectDropdown from '@/components/dropdown/select-dropdown.vue';
import ConfirmModal from '@/components/confirm-modal/confirm-modal.vue';
import { EventBus } from '@/utils';
import { RunIdService } from '@/services';
import RunCalculation from '../run-calculation/run-calculation.vue';
import ComponentSecurity from '@/mixins/component-security';
import { toastModuleDisplayError } from '@/store/toastModule';

@Component({
    name: 'ribbon',
    components: {
        EasLoader,
        Dropdown,
        SelectDropdown,
        ConfirmModal,
        RunCalculation,
    },
})
export default class Ribbon extends Mixins(ComponentSecurity) {
    /**
     * Assets for selection
     */
    @Prop({ required: true })
    private assets!: Asset[];
    /**
     * Serial number of the report for status of batch task run
     */
    @Prop({ required: true })
    private serialNumber!: number;

    private isNewUi = true;
    private runIds: RunId[] = [];
    private assetselection: any | undefined = {};
    private runIdSelection: any | undefined = {};
    private runIdService: RunIdService = new RunIdService();
    private isDataLoaded = false;
    private assetSelected = false;
    private runSelected = false;
    private showConfirm = false;
    private serialNumberToSelect = 0;
    private shouldShowConfirm = false;
    private isRunDisabled = false;
    private dataFreezeConfirmModal = false;
    private dataUnfreezeConfirmModal = false
    private showDataFreezeModal = false;
    private showDataUnfreezeModal = false;
    private errorExist = false;
    private errorMessage = 'Internal server error';

    private mounted(): void {
        EventBus.$on(
            EventBus.REPORT.UPDATE,
            (serialNumber: number) => this.reloadRunIdWithSerialNumber(serialNumber),
        );
        if (this.$cookies.get('ui')) {
            this.isNewUi = this.$cookies.get('ui') !== 'new';
            this.onUiToggle();
        } else {
            this.$cookies.set('ui', this.isNewUi ? 'new' : 'old');
        }

    }

    @Watch('assets')
    private selectDefaultAsset(): void {
        if (this.$cookies.get('asset')) {
            const assetInHistory = this.$cookies.get('asset');
            this.assetselection = this.assets.find((asset) => assetInHistory.id === asset.id);
        } else {
            this.assetselection = this.assets.find((asset) => asset.isDefault === true);
        }
        if (this.assetselection === undefined && this.assets.length > 0) {
            this.assetselection = this.assets[0];
        }
        this.onSelectAsset(this.assetselection);
    }

    private selectRunId(runid: RunId | undefined): void {
        if (runid) {
            this.runIdSelection = runid;
            this.$emit('selection-details', { asset: this.assetselection, runId: runid });
        } else {
            if (!this.hasEditAccess) {
                this.runIdSelection = undefined;
                this.$emit('selection-details', { asset: this.assetselection, runId: runid });
            }
        }
    }

    private onRunIdSelect(runid: RunId | undefined): void {
        this.selectRunId(runid);
        this.$cookies.set('sno', this.runIdSelection.sNo);
    }

    private async onSelectAsset(asset: Asset ): Promise<void> {
        this.assetselection = {...asset};
        const assetInHistory = this.$cookies.get('asset');
        if (assetInHistory?.id !== asset.id) {
            this.$cookies.remove('sno');
            this.$cookies.set('asset', this.assetselection);
        }
        await this.loadRunId();
    }

    private async reloadRunIdWithSerialNumber(serialNumber: number): Promise<void> {
        this.serialNumberToSelect = serialNumber;
        await this.loadRunId();
        this.serialNumberToSelect = 0;
    }
    private getRunIdSelection(serialNumber: number): RunId | undefined {
        let result;
        if (!this.hasEditAccess || serialNumber === 0) {
            result = this.runIds.find((run) => run.isFinal);
        } else {
            result = this.runIds.find((run) => run.sNo === serialNumber);
        }

        if(!result && this.runIds.length > 0) {
            result = this.runIds[0];
        }

        return result;
    }

    private async loadRunId(): Promise<void> {
        if (this.assetselection !== undefined) {
            try {
                this.runIds = (await this.runIdService.get(this.assetselection.id)).result;
                this.runIdSelection = this.getRunIdSelection(this.serialNumberToSelect);
                this.onRunIdSelect(this.runIdSelection);
                this.isDataLoaded = true;
            } catch (error) {
                await this.$store.dispatch(toastModuleDisplayError, error);
            }
        }
    }

    private async markAsFinal(item: any): Promise<void> {
        try {
            const serialNumber = (await this.runIdService.markAsfinal(item)).result.sNo;
            await this.reloadRunIdWithSerialNumber(serialNumber);
            this.shouldShowConfirm = false;
        } catch (error) {
            await this.$store.dispatch(toastModuleDisplayError, error);
        }
    }

    private async dataFreeze(item: any): Promise<void> {
        try {
            this.dataFreezeConfirmModal = false;
            await this.runIdService.updateFreezeDataStatus(item.sno, {
                isDataFreeze: true
            });
            await this.reloadRunIdWithSerialNumber(item.sno);
            this.showDataFreezeModal = true;
        } catch (error) {
            this.showDataFreezeModal = true;
            this.errorExist = true;
            await this.$store.dispatch(toastModuleDisplayError, error);
        }
    }

    private async dataUnfreeze(item: any): Promise<void> {
        try {
            this.dataUnfreezeConfirmModal = false;
            await this.runIdService.updateFreezeDataStatus(item.sno, {
                isDataFreeze: false
            });
            await this.reloadRunIdWithSerialNumber(item.sno);
            this.showDataUnfreezeModal = true;
        } catch (error) {
            this.showDataUnfreezeModal = true;
            this.errorExist = true;
            await this.$store.dispatch(toastModuleDisplayError, error);
        }
    }

    private openModal(): void {
        EventBus.$emit(EventBus.REPORT.RUN_CALCULATION, true);
    }


    private onUiToggle(): void {
        this.isNewUi = !this.isNewUi;
        this.$cookies.set('ui', this.isNewUi ? 'new' : 'old');
        this.$emit('on-ui-toggle', this.isNewUi);
    }

    get togglerClasses(): any {
        return {
            'flex-row': true,
            'toggler': true,
            'reverse': !this.isNewUi,
            'isNewUi': this.isNewUi,
            'toggler-blue-bg': this.isNewUi,
            'toggler-grey-bg': !this.isNewUi,
        };
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';
.ribbon {
    .check-circle {
        color: @contextual-green;
    }
    .times-circle {
        color: @contextual-red;
    }
    .header,
    .footer {
        line-height: var(--adam-d48);
        font-weight: bolder;
    }
    .ribbon-lebel {
        justify-content: flex-end;
        font-size: 1.5rem;
        font-weight: bold;
    }
    .final-button {
        justify-content: center;
        align-items: flex-end;
    }
    .btn {
        width: auto;
        height: 3rem;
        background-color: @uniper-blue;
        color: @white;
        border-radius: .5rem;
        border: 1px solid @uniper-blue;
        font-size: 1.2rem;
        text-transform: uppercase;
        white-space: nowrap;
    }
    .separator {
        width: 2px;
        height: 4rem;
        margin-left: 1rem;
        margin-right: 1rem;
        background: #E0E0E0;
        display: inline-block;
        vertical-align: middle;
    }
    .justify-end {
        justify-content: flex-end;
    };

    .goto-download {
        font-size: 1.5rem;
        color: @uniper-blue;
        font-weight: bold;
        text-align: center;
        align-items: center;
        cursor: pointer;
    }

    label {
        font-size: 1.3rem;
    }

    .toggler-blue-bg {
        border: 0.2rem solid @uniper-blue;
        background-color: @uniper-blue;
        color: @white;
    }

    .toggler-grey-bg {
        border: 0.2rem solid @grey-light;
        background-color: @grey-lighter;
        color: @uniper-blue;
    }

    .toggler {
        width: 8rem;
        height: 3rem;
        border-radius: 1.5rem;
        cursor: pointer;
        font-weight: bold;
        .toggle-text {
            justify-content: center;
            align-items: center;
            padding: 1rem;
            font-size: 1.3rem;
            font-weight: bold;
        }
        .toggle-switch {
            width: 3rem;
            height: 2.9rem;
            border-radius: 1.5rem;
            background-color: @white;
        }
    }
    .checkbox-container {
      display: block;
      position: relative;
      padding-left: 3rem;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 1.6rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 2rem;
        width: 2rem;
        background-color: #fafafa;
        border: 1px solid #e6e6e6;
        border-radius: @border-radius-standard;
    }
    .checkbox-container:hover input ~ .checkmark {
        background-color: #f4f4f4;
    }
    .checkbox-container input:checked ~ .checkmark {
        background-color: @uniper-blue;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .checkbox-container input:checked ~ .checkmark:after {
        display: block;
    }
    .checkbox-container .checkmark:after {
        left: 0.6rem;
        top: 0.2rem;
        width: 0.5rem;
        height: 1rem;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .btn:disabled {
        color: @warm-grey;
        border: 1px solid @warm-grey;
        background: @pale-grey;
        cursor: auto;
    }
}
</style>
