import { BatchTask, ApiResponse } from '@/models';
import BaseService from '@/utils/base-service';

export class BatchTaskService extends BaseService {
    private endpoint = '/BatchTask';

    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async get(taskName: string): Promise<ApiResponse<BatchTask>> {
        return (await this.api.get(`${this.endpoint}/Staus/${taskName}`)).data;
    }
    public async post(value: any): Promise<ApiResponse<BatchTask>> {
        return (await this.api.post(`${this.endpoint}/run`, value)).data;
    }
}
