<template>
    <div class="kpi-d">
        <div
            class="flex-row flex-1"
            v-for="(kpiDData, kpiDIndex) in chartData"
            :key="kpiDIndex"
        >
            <div class="flex-col flex-1 mr-2 ml-1">
                <div class="flex-row flex-1 flex-align-center">
                    {{ kpiDData.kpiNameLabel }}
                </div>
                <div class="flex-row flex-1 flex-align-center mb-1">
                    {{ kpiDData.typeDLabel1 }} {{performanceValue}}
                </div>
                <div class="flex-row flex-1 flex-align-center">
                    {{ kpiDData.typeDLabel2 }}
                </div>
            </div>
            <div class="flex-col flex-3">
                <div class="flex-row flex-1 flex-align-center chart-title">
                    {{ kpiDData.kpiName }}
                </div>
                <div class="flex-row flex-1 flex-justify-center perf-block">
                    <div class="flex-col flex-4 box-side-label">
                        {{ $t('struggleWithUnambitiousTargets') }}
                    </div>
                    <div class="flex-col flex-2 ml-1 redBox">
                        <span v-if="performanceBlock.isRedMarked">{{ cross }}</span>
                    </div>
                    <div class="flex-col flex-2 yellowBox">
                        <span v-if="performanceBlock.isYellowMarked">{{ cross }}</span>
                    </div>
                    <div class="flex-col flex-2 darkGreenBox">
                        <span v-if="performanceBlock.isDarkGreenMarked">{{ cross }}</span>
                    </div>
                    <div class="flex-col flex-2 lightGreenBox">
                        <span v-if="performanceBlock.isLightGreenMarked">{{ cross }}</span>
                    </div>
                    <div class="flex-col flex-2 mr-2 greenBox">
                        <span v-if="performanceBlock.isGreenMarked">{{ cross }}</span>
                    </div>
                    <div
                        class="flex-col flex-4 flex-justify-center mr-4 pr-4 box-side-label"
                    >
                        {{ $t('exceedAmbitiousTarget') }}
                    </div>
                </div>
                <div class="flex-row flex-1 risk-block">
                    <div
                        class="flex-col flex-4 flex-align-center pt-1 box-side-label"
                    >
                        {{ $t('underMaintained') }}
                    </div>
                    <div class="flex-col flex-2 ml-1 redBox">
                        <span v-if="riskBlock.isRedMarked">{{ cross }}</span>
                    </div>
                    <div class="flex-col flex-2 yellowBox">
                        <span v-if="riskBlock.isYellowMarked">{{ cross }}</span>
                    </div>
                    <div class="flex-col flex-2 darkGreenBox">
                        <span v-if="riskBlock.isDarkGreenMarked">{{ cross }}</span>
                    </div>
                    <div class="flex-col flex-2 lightGreenBox">
                        <span v-if="riskBlock.isLightGreenMarked">{{ cross }}</span>
                    </div>
                    <div class="flex-col flex-2 mr-2 greenBox">
                        <span v-if="riskBlock.isGreenMarked">{{ cross }}</span>
                    </div>
                    <div
                        class="flex-col flex-4 flex-justify-center pt-1 mr-4 pr-4 box-side-label"
                    >
                        {{ $t('wellMaintained') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class KpiD extends Vue {
    /**
     * kpi-D data from Performance Assesment
     */
    @Prop({ required: true })
    private chartData!: any;

    private cross = 'X';
    private performanceValue = 0;
    private riskBlockValue = 0;
    private performanceBlock = {
        isRedMarked: false,
        isYellowMarked: false,
        isDarkGreenMarked: false,
        isLightGreenMarked: false,
        isGreenMarked: false,
    };
    private riskBlock = {
        isRedMarked: false,
        isYellowMarked: false,
        isDarkGreenMarked: false,
        isLightGreenMarked: false,
        isGreenMarked: false,
    };

    @Watch('chartData')
    private updateComponent(): void {
        this.crossPerformanceBlock();
        this.crossRiskBlock();
    }

    private mounted(): void {
        this.crossRiskBlock();
        this.crossPerformanceBlock();
    }

    private crossPerformanceBlock(): void {
        for (const crossValue of this.chartData) {
            this.performanceValue = crossValue.typeDValue1;
            if (this.performanceValue === 1) {
                this.performanceBlock.isRedMarked = true;
            }
            if (this.performanceValue === 2) {
                this.performanceBlock.isYellowMarked = true;
            }
            if (this.performanceValue === 3) {
                this.performanceBlock.isDarkGreenMarked = true;
            }
            if (this.performanceValue === 4) {
                this.performanceBlock.isLightGreenMarked = true;
            }
            if (this.performanceValue === 5) {
                this.performanceBlock.isGreenMarked = true;
            }
        }
    }

    private crossRiskBlock(): void {
        for (const crossValue of this.chartData) {
            this.riskBlockValue = crossValue.typeDValue2;
            if (this.riskBlockValue === 1) {
                this.riskBlock.isRedMarked = true;
            }
            if (this.riskBlockValue === 2) {
                this.riskBlock.isYellowMarked = true;
            }
            if (this.riskBlockValue === 3) {
                this.riskBlock.isDarkGreenMarked = true;
            }
            if (this.riskBlockValue === 4) {
                this.riskBlock.isLightGreenMarked = true;
            }
            if (this.riskBlockValue === 5) {
                this.riskBlock.isGreenMarked = true;
            }
        }
    }
}
</script>

<style scoped lang="less">
@import '../../../variables.less';
.kpi-d {
    .flex-justify-center {
        justify-content: center;
    }
    .box-side-label {
        font-size: 1.2rem;
    }
    .perf-block {
        margin-top: 1rem;
    }
    .risk-block {
        margin-top: 1rem;
    }
    .redBox {
        background-color: @contextual-red;
        border: 1px solid @font-color-dark-grey;
        font-size: 1rem;
        height: 4rem;
    }
    .yellowBox {
        background-color: @contextual-amber;
        border: 1px solid @font-color-dark-grey;
        border-left: none;
        font-size: 1rem;
        height: 4rem;
    }
    .darkGreenBox {
        background-color: #9acd32;
        border: 1px solid @font-color-dark-grey;
        border-left: none;
        font-size: 1rem;
        height: 4rem;
    }
    .lightGreenBox {
        background-color: #00fa9a;
        border: 1px solid @font-color-dark-grey;
        border-left: none;
        font-size: 1rem;
        height: 4rem;
    }
    .greenBox {
        background-color: #00ff00;
        border: 1px solid @font-color-dark-grey;
        border-left: none;
        font-size: 1rem;
        height: 4rem;
    }
    span {
        font-size: 2rem;
        color: @dark-grey-darker;
        text-align: center;
        padding: 0.5rem;
        padding-top: 0.75rem;
    }
}
</style>