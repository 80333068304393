<template>
    <modal-box
        v-if="showBulkUpdate"
        :open="showBulkUpdate"
        :onClose.prop="hideBulkUpdate"
        :enableHeader.prop="true"
        :enableFooter.prop="true"
        :enableHeaderClose.prop="true"
        :transparentOverlay.prop="false"
        :closeOnClickOutside.prop="true"
    >
        <div v-if="dataLoading" class="popup">
            <eas-loader></eas-loader>
        </div>
        <div slot="header" class="header">
            <div class="flex-row flex-1">
                <div class="flex-col flex-1">
                    <h3>{{ $t('bulkUpdate') }}</h3>
                </div>
            </div>
        </div>
        <div v-if="uploadContainsErrors">
            <div class="flex-col pr-1">
                <div class="flex-row pr-2 mb-1">
                    {{ $t('errorBulkUpdate') }}
                </div>
                <div v-for="validationIssue in bulkUpdateResult.validationIssues"
                     :key="validationIssue.cellAddress"
                     class="flex-row pr-2">

                    <div class="flex-1 mr-2">
                        {{ $t('cellRow') }} {{ validationIssue.cellAddress }}:
                    </div>
                    <div  class="flex-2">
                        {{ validationIssue.message }}
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="showBulkUpdateResults">
            <div class="flex-col pr-1">
                <div class="flex-row pr-2">
                    {{ $t('bulkUpdateSuccessful') }}
                </div>
                <div class="flex-row pr-2">
                    {{ $t('numberOfChangedRecords') }} {{ bulkUpdateResult.numberOfRecords }}
                </div>
            </div>
        </div>
        <div v-else-if="showConfirmChanges">
            <div class="flex-col pr-1">
                <p>{{ $t('recordsToBeUpdated') }}: {{ bulkUpdateResult.numberOfRecords }}</p>
                <div v-for="assetUpdate in bulkUpdateResult.assetUpdateResults"
                     :key="assetUpdate.assetName"
                     class="flex-row">
                        <div class="flex-1 mr-2">
                            {{ assetUpdate.assetName }}
                        </div>
                        <div>
                            {{ assetUpdate.numberOfUpdates }}/{{ assetUpdate.totalAttributes }}
                        </div>
                </div>
                <p>{{ $t('continueConfirmation') }}</p>
            </div>
        </div>
        <div v-else>
            <input type="file" @change="changeBulkUpdateFile($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
        </div>
        <div slot="footer" class="footer">
            <div class="flex-row reverse">
                <div class="flex-col flex-align-end mt-1">
                    <button v-if="uploadContainsErrors || showBulkUpdateResults" class="btn" @click="hideBulkUpdate()">
                        {{ $t('close') }}
                    </button>
                    <div v-else-if="showConfirmChanges">
                        <button class="btn" @click="continueUploadBulkUpdateFile()">
                            {{ $t('continue') }}
                        </button>
                        <button class="btn" @click="hideBulkUpdate()">
                            {{ $t('cancel') }}
                        </button>
                    </div>
                    <button v-else class="btn" :disabled="selectedBulkUpdateFile === null" @click="showConfirmation()">
                        {{ $t('uploadFile') }}
                    </button>
                </div>
            </div>
        </div>
    </modal-box>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommentService } from '@/services';
import { BulkUpdateResult } from '@/models/bulk-update-result';
import { EventBus } from '@/utils';
import CommentComponent from '@/components/comment-edit/comment.vue';
import EasLoader from '@/components/loader/eas-loader.vue';

@Component({
    name: 'bulk-update-upload',
    components: {
        EasLoader,
        CommentComponent,
    },
})
export default class BulkUpdateUpload extends Vue {
    private commentService: CommentService = new CommentService();
    private selectedBulkUpdateFile: File | null = null;
    private showBulkUpdateResults = false;
    private showConfirmChanges = false;
    private bulkUpdateResult: BulkUpdateResult = {
        assetUpdateResults: [],
        hasErrors: false,
        numberOfRecords: 0,
        validationIssues: []
    };

    @Prop()
    private showBulkUpdate = false;

    private dataLoading = false;

    private changeBulkUpdateFile(event: Event): void {
        const element = event.currentTarget as HTMLInputElement;
        const fileList: FileList | null = element.files;
        if (fileList !== null) {
            this.selectedBulkUpdateFile = fileList[0];
        }
    }

    private async uploadBulkUpdateFile(dryRun = false): Promise<void> {
        if (this.selectedBulkUpdateFile !== null) {
            this.dataLoading = true;
            const response = await this.commentService.bulkUpdate(this.selectedBulkUpdateFile, dryRun);
            this.dataLoading = false;
            this.bulkUpdateResult = response.result;
        }
    }

    private async continueUploadBulkUpdateFile(): Promise<void> {
        if (this.selectedBulkUpdateFile !== null) {
            this.dataLoading = true;
            await this.uploadBulkUpdateFile();
            this.dataLoading = false;
            EventBus.$emit(EventBus.UI_TEXT.UPDATE, 'update');
            this.showConfirmChanges = false;
            this.showBulkUpdateResults = true;
            this.selectedBulkUpdateFile = null;

        }
    }

    private async hideBulkUpdate(): Promise<void> {
        this.showBulkUpdateResults = false;
        this.showConfirmChanges = false;
        this.selectedBulkUpdateFile = null;
        this.$emit('close');
    }

    private async showConfirmation(): Promise<void> {
        await this.uploadBulkUpdateFile(true);
        this.showConfirmChanges = true;
    }

    get uploadContainsErrors(): boolean {
        return (this.showBulkUpdateResults || this.showConfirmChanges)
            && this.bulkUpdateResult.hasErrors;
    }
}
</script>

<style lang="less" scoped>
@import '../../../variables.less';
.header {
    line-height: var(--adam-d48);
    font-weight: bolder;
}

.btn {
    width: auto;
    height: 3rem;
    background-color: @uniper-blue;
    color: @white;
    border-radius: .5rem;
    border: 1px solid @uniper-blue;
    font-size: 1.2rem;
    text-transform: uppercase;
    white-space: nowrap;
    margin-left: 1rem;
}

.btn:disabled {
    color: @warm-grey;
    border: 1px solid @warm-grey;
    background: @pale-grey;
    cursor: auto;
}
</style>
