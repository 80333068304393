<template>
    <div class="flex-container strategy-cluster">
        <div class="flex-row flex-1">
            <div class="flex-col flex-1 rotate">
                <div class="flex-row rotate-content textnowrap">
                    {{ $t('uniperStrategyCluster') }}
                </div>
            </div>
            <div class="flex-col flex-1 rotate">
                <div class="flex-row rotate-content blueFont textnowrap">
                    {{ isInTargetText }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class StrategyCluster extends Vue {
    /**
     * set asset in target portfolio
     */
    @Prop({ default: false })
    private isInTarget!: boolean;

    get isInTargetText(): string {
        return this.isInTarget
            ? this.$i18n.t('inTheTargetPortfolio') as string
            : this.$i18n.t('notInTheTargetPortfolio') as string;
    }
}
</script>

<style scoped lang="less">
@import "../../variables.less";
.strategy-cluster {
    height: 100%;
    .rotate {
        vertical-align: center;
        text-align: center;
        writing-mode: vertical-lr;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        letter-spacing: 0.1rem;
        font-weight: bold;
        .rotate-content {
            transform: rotate(180deg);
        }
    }
    .blueFont {
        color: @uniper-blue;
        font-size: 1.2rem;
        font-stretch: extra-expanded;
    }
    .textnowrap {
        font-size: 1.8rem;
        white-space: nowrap;
    }
}
</style>
