<template>
    <modal-box
        v-if="showBulkDownload"
        :open="showBulkDownload"
        :onClose.prop="hideBulkDownload"
        :enableHeader.prop="true"
        :enableFooter.prop="true"
        :enableHeaderClose.prop="true"
        :transparentOverlay.prop="false"
        :closeOnClickOutside.prop="true"
    >
        <div v-if="dataLoading" class="popup">
            <eas-loader></eas-loader>
        </div>
        <div slot="header" class="header">
            <div class="flex-row flex-1">
                <div class="flex-col flex-1">
                    <h3>{{ $t('exportXlsx') }}</h3>
                </div>
            </div>
        </div>
        <div v-if="validYears.length === 0 && !dataLoading" class="errors">
            <div class="bulk-download-section-title">
                {{ $t('noRunsWithFrozenDataForCurrentAssetSelection') }}
            </div>
        </div>
        <div v-else-if="!dataLoading" class="scrollable">
            <div class="flex-col pr-1">
                <div class="flex-row mb-2 bulk-download-section-title"
                     :title="checkedAssetNames">
                    {{ $t('numberOfSelectedAssets') }} {{ checkedAssets.length }}
                </div>
            </div>
            <div class="flex-col pr-1">
                <div class="flex-row mb-2 bulk-download-section-title">
                    {{ $t('reportSections') }}
                </div>
                <label
                    class="checkbox-container"
                >
                    <input
                        type="checkbox"
                        v-model="allComponentsAreChecked"
                    />
                    <span class="select-all-components">{{ $t('selectAll') }}</span>
                    <span class="checkmark"></span>
                </label>
                <label
                    class="checkbox-container"
                    v-for="(componentTitle, index) in componentList"
                    :key="index"
                >
                    <div class="flex-row flex-4">
                        <input
                            type="checkbox"
                            v-model="checkedComponents"
                            :value="componentTitle.id"
                        />
                        {{ componentTitle.component }}
                        <span class="checkmark"></span>
                    </div>
                </label>
            </div>
            <div class="flex-col pr-1" v-if="hasEditAccess">
                <div class="flex-row mb-2 bulk-download-section-title">
                    {{ $t('includeFinalVersion') }}
                    <label
                        class="checkbox-container"
                    >
                        <input
                            type="checkbox"
                            v-model="includeFinalVersion"
                        />
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
            <div class="flex-col pr-1">
                <div class="flex-row mb-2 bulk-download-section-title">
                    {{ $t('reportYearSelection') }}
                </div>
                <div class="warnings" v-if="containsAssetWithNoDataFreezeRuns">
                    <ul>
                        {{ $t('noRunsWithFrozenDataForCurrentExportSelection') }}:
                        <template v-for="(year, i) in checkedYears">
                            <li v-if="containsAssetWithNoDataFreezeRunsForYear(year)"
                                :key="i"
                            >
                                <div style="font-weight: bold">
                                    {{ year }}:
                                </div>
                                <div v-for="(asset, j) in assetsWithNoFrozenData(year)" :key="j">
                                    {{ asset.name }}
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
                <label
                    class="checkbox-container"
                    v-for="(year, index) in validYears"
                    :key="index"
                >
                    <div class="flex-row flex-4">
                        <input
                            type="checkbox"
                            v-model="checkedYears"
                            :value="year"
                        />
                        {{ year }}
                        <span class="icon-container"
                              v-if="containsAssetWithNoDataFreezeRunsForYear(year)">
                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="fa" />
                        </span>
                        <span class="checkmark"></span>
                    </div>
                </label>
            </div>
        </div>
        <div slot="footer" class="footer">
            <div class="flex-row reverse" v-if="validYears.length > 0">
                <div class="flex-col flex-align-end mt-1">
                    <div>
                        <button class="btn" @click="hideBulkDownload()">
                            {{ $t('cancel') }}
                        </button>
                        <button class="btn" @click="download()" :disabled="exportButtonDisabled">
                            {{ $t('export') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </modal-box>
</template>

<script lang="ts">
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {Asset} from '@/models';
import EasLoader from '@/components/loader/eas-loader.vue';
import {BulkDownloadService} from '@/services/bulk-download-service';
import {BulkDownloadRequest, BulkDownloadReportSections} from '@/models/bulk-download-request';
import {AssetIdentifier} from '@/models/asset-identifier';
import ComponentSecurity from '@/mixins/component-security';
import clickOutside from '@/directives/click-outside';
import {FileDownload} from '@/models/file-download';
import DownloadFile from '@/mixins/download-file';

@Component({
    name: 'bulk-download',
    components: {
        EasLoader
    },
    directives: {
        clickOutside
    },
})
export default class BulkDownload extends Mixins(ComponentSecurity, DownloadFile) {
    @Prop({ default: false })
    private showBulkDownload!: boolean;

    @Prop()
    private checkedAssets!: Asset[];

    private componentList = [
        {id: BulkDownloadReportSections.CommercialAndSustainabilityAssessment, component: 'Commercial and Sustainability Assessment'},
        {id: BulkDownloadReportSections.Dates, component: 'Dates'},
        {id: BulkDownloadReportSections.GeneralStrategy, component: 'General Strategy'},
        {id: BulkDownloadReportSections.MaintenanceAndRiskProcesses, component: 'Maintenance And Risk Processes'},
        {id: BulkDownloadReportSections.SupportingAssetProcesses, component: 'Supporting Asset Processes'},
        {id: BulkDownloadReportSections.AmProcessOverview, component: 'AM Process Overview'},
    ];
    private bulkDownloadService: BulkDownloadService = new BulkDownloadService();
    private dataLoading = false;

    private checkedComponents: BulkDownloadReportSections[] = [];
    private validYears: number[] = [];
    private checkedYears: number[] = [];
    private readonly yearToAssetIdentifiersMap = new Map<number, AssetIdentifier[]>();
    private readonly firstValidYear = 2021;
    private includeFinalVersion = false;

    private async mounted(): Promise<void> {
        this.validYears = [];
        const currentYear = new Date().getUTCFullYear();
        for (let year = currentYear; year >= this.firstValidYear; year--) {
            this.validYears.push(year);
            this.yearToAssetIdentifiersMap.set(year, []);
        }
        this.checkedYears.push(currentYear);

        this.checkedComponents = this.componentList.map(c => c.id);

        this.dataLoading = true;

        for (const year of this.validYears) {
            let assetIdentifiers = (await this.bulkDownloadService.frozenReportAvailability(year)).result;
            this.yearToAssetIdentifiersMap.set(year, assetIdentifiers);
        }
        this.dataLoading = false;
    }

    get exportButtonDisabled(): boolean {
        return this.checkedYears.length === 0 || this.checkedComponents.length === 0;
    }

    get checkedAssetNames(): string {
        return this.checkedAssets.map(a => a.name).join('\n');
    }

    get containsAssetWithNoDataFreezeRuns(): boolean {
        return this.checkedYears.map(year => this.containsAssetWithNoDataFreezeRunsForYear(year)).includes(true);
    }

    get allComponentsAreChecked(): boolean {
        return this.checkedComponents.length === this.componentList.length;
    }

    set allComponentsAreChecked(value: boolean) {
        if (value) {
            this.checkedComponents = this.componentList.map(c => c.id);
        } else {
            this.checkedComponents = [];
        }
    }

    @Watch('checkedYears', { deep: true })
    private updateCheckedYears(newList: number[], oldList: number[]): void {
        if (newList !== oldList) {
            this.checkedYears = this.checkedYears.sort().reverse();
        }
    }

    private async download(): Promise<void> {
        let request: BulkDownloadRequest = {
            hierarchySids: this.checkedAssets.map(asset => asset.id),
            reportYears: this.checkedYears,
            sections: this.checkedComponents,
            includeFinals: this.includeFinalVersion
        };

        const file: FileDownload = await this.bulkDownloadService.download(request);

        this.downloadFile(file, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        await this.hideBulkDownload();
    }

    private async hideBulkDownload(): Promise<void> {
        this.$emit('close');
    }

    private containsAssetWithNoDataFreezeRunsForYear(year: number): boolean {
        return this.assetsWithNoFrozenData(year).length > 0;
    }

    private assetsWithNoFrozenData(year: number): Asset[] {
        return this.checkedAssets.filter(
            checkedAsset => !this.yearToAssetIdentifiersMap.get(year)!
                .map(assetIdentifier => assetIdentifier.hierarchySid)
                .includes(checkedAsset.id)
        );
    }
}
</script>

<style lang="less" scoped>
@import '../../variables.less';
.btn {
    width: auto;
    height: 3rem;
    background-color: @uniper-blue;
    color: @white;
    border-radius: .5rem;
    border: 1px solid @uniper-blue;
    font-size: 1.2rem;
    text-transform: uppercase;
    white-space: nowrap;
    margin-left: 1rem;
}

.btn:disabled {
    color: @warm-grey;
    border: 1px solid @warm-grey;
    background: @pale-grey;
    cursor: auto;
}
.warnings {
    color: #482901;
    background-color: #f8e9d7;
    border: 1px solid #f5c6cb;
    border-radius: 1px;
    margin-bottom: 1rem;
}
.errors {
    color: #480109;
    background-color: #f8d7db;
    border: 1px solid #f5c6cb;
    border-radius: 1px;
    margin-bottom: 2rem;
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 3rem;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.6rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: #fafafa;
    border: 1px solid #e6e6e6;
    border-radius: @border-radius-standard;
}
.checkbox-container:hover input ~ .checkmark {
    background-color: #f4f4f4;
}
.checkbox-container input:checked ~ .checkmark {
    background-color: @uniper-blue;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}
.checkbox-container .checkmark:after {
    left: 0.6rem;
    top: 0.2rem;
    width: 0.5rem;
    height: 1rem;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


.header {
    line-height: var(--adam-d48);
    font-weight: bolder;
}

.scrollable {
    overflow-y: scroll;
    max-height: 60vh;
}

.icon-container {
    padding-left: 1rem;
}

.select-all-components {
    font-style: italic;
}

.bulk-download-section-title {
    font-weight: bolder;
}
</style>
