<template>
    <div class="mnr">
        <div v-if="!dataloaded">{{ $t('loading') }}</div>
        <div v-if="dataloaded && !isNewUi" class="flex-row mb-1">
            <div class="flex-col flex-1">
                <div class="flex-row flex-2 mb-1">
                    <div class="flex-col flex-2 label">{{ $t('rationale') }}:</div>
                    <div class="flex-col flex-6" id="rationaleComment">
                        {{ mrp.rationaleComment }}
                    </div>
                </div>
                <div class="flex-row flex-2 mb-1">
                    <div class="flex-col flex-2 label">{{ $t('maintenanceStrategy') }}:</div>
                    <div class="flex-col flex-6" id="maintenanceStrategyComment">
                        {{ mrp.maintenanceStrategyComment }}
                    </div>
                </div>
                <div class="flex-row flex-2 mb-1">
                    <div class="flex-col flex-2 label">{{ $t('riskAssessment') }}:</div>
                    <div class="flex-col flex-6" id="riskAssessmentComment">
                        {{ mrp.riskAssessmentComment }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="dataloaded && isNewUi">
            <dl>
                <dt><b>{{ $t('rationale') }}:</b></dt>
                <dd>
                    <br/> 
                    {{ mrp.rationaleComment }}
                </dd>
                <br/>
                <dt><b>{{ $t('maintenanceStrategy') }}:</b></dt>
                <dd>
                    <br/> 
                    {{ mrp.maintenanceStrategyComment }} 
                </dd>
                <br/>
                <dt><b>{{ $t('riskAssessment') }}:</b></dt>
                <dd>
                    <br/>
                    {{ mrp.riskAssessmentComment }}
                </dd>
            </dl>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MRP } from '@/models';

@Component
export default class MentenanceAndRisk extends Vue {
    /**
     * Set to enable new UI view.
     */
    @Prop({default: false})
    private isNewUi!: boolean;

    /**
     * Set maintanance and risk data.
     */
    @Prop({ required: true })
    private mrp!: MRP;

    private dataloaded = false;

    private mounted(): void {
        this.dataloaded = true;
    }

    @Watch('mrp')
    private updateComponent(newMRP: MRP, oldMRP: MRP): void {
        if (newMRP && newMRP !== oldMRP) {
            this.dataloaded = true;
        } else {
            this.dataloaded = false;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.mnr{
    white-space: pre-line;
    .component-header{
        font-weight: bold;
        font-size: 1rem;
    }
    .flex-justify-center{
        justify-content: center;
    }
}
</style>
