<template>
<div class="csaedit">
    <div class="flex-container dark-text">
        <div class="flex-col flex-1">

            <div class="flex-row flex-1 container mb-10">
                <div class="flex-col flex-1"></div>


                <div class="flex-col flex-10 boxborder">
                    <div class="flex-row flex-1 asset-name" style="justify-content: center; align-items: center">
                        <div class="flex-col flex-1"></div>
                        <div class="flex-col flex-10" style="align-items: center">{{assetName}}</div>
                        <div class="flex-col flex-1"></div>
                    </div>

                    <div class="flex-row flex-1 mt-2">

                        <div class="flex-col flex-5">
                            <div class="flex-row flex-1 component-header">Commercial and Sustainability Assessment</div>

                            <div class="flex-row flex-2 light-padding">
                                <div class="flex-col flex-1 flex-justify-center">{{csaLabels.commercialOutlookLabel}}: </div>
                                <div class="flex-col flex-1">
                                    <div class="flex-row flex-1">
                                        <div class="flex-col flex-1 flex-justify-center">{{csaLabels.commercialOutlookSubLabel1}}: </div>
                                        <div class="flex-col flex-1 flex-justify-center"><input v-model="csaData.commercialOutlookSubValue1" /></div>
                                    </div>
                                    <div class="flex-row flex-1">
                                        <div class="flex-col flex-1 flex-justify-center">{{csaLabels.commercialOutlookSubLabel2}}: </div>
                                        <div class="flex-col flex-1"><input v-model="csaData.commercialOutlookSubValue2" /></div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-row flex-1 light-padding">
                                <div class="flex-col flex-1 flex-justify-center">{{csaLabels.currentValueDriverLabel}}: </div>
                                <div class="flex-col flex-1 pre-formatted"><textarea v-model="csaData.currentValueDriver"></textarea></div>
                            </div>

                            <div class="flex-row flex-1 light-padding">
                                <div class="flex-col flex-1 flex-justify-center">{{csaLabels.ltContractValueLabel}}: </div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.ltContractValue" /></div>
                            </div>

                            <div class="flex-row flex-1 light-padding">
                                <div class="flex-col flex-2 flex-justify-center">{{csaLabels.runningRegimeTrendLabel}}: </div>
                                <div class="flex-col flex-1"><input v-model="csaData.runningRegime" /></div>
                                <div class="flex-col flex-1 center-align" style="font-size: 2rem">
                                    <select name="rtrend" v-model="csaData.runningTrend">
                                        <option value="Up">Up</option>
                                        <option value="Unchanged">Unchanged</option>
                                        <option value="Down">Down</option>
                                        <option value="No running forecasted">{{ $t('noRunningForecasted') }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="flex-row flex-2 light-padding">
                                <div class="flex-col flex-1 flex-justify-center"><p v-html="csaLabels.co2AbatementCostsLabel.replace('CO2', 'CO<sub>2</sub>')+':' "></p></div>
                                <div class="flex-col flex-1">
                                    <div class="flex-row flex-1"><input class="smallInputBox" v-model="csaData.co2AbatementCostsText1" /></div>
                                    <div class="flex-row flex-1"><input class="smallInputBox" v-model="csaData.co2AbatementCostsText2" /></div>
                                </div>
                                <div class="flex-col flex-1">
                                    <div class="flex-row flex-1"><input class="smallInputBox" v-model="csaData.co2AbatementCostsValue1" /></div>
                                    <div class="flex-row flex-1"><input class="smallInputBox" v-model="csaData.co2AbatementCostsValue2" /></div>
                                </div>
                                <div class="flex-col flex-1">
                                    <div class="flex-row flex-1">{{csaLabels.specificEmissionsLabel}}: </div>
                                    <div class="flex-row flex-1">{{csaData.specificEmissionsText}}</div>
                                </div>
                                <div class="flex-col flex-1">
                                    <div class="flex-row flex-1 flex-center"><input class="smallInputBox" v-model="csaData.specificEmissionsValue" /></div>
                                    <div class="flex-row flex-1 flex-center">{{csaLabels.specificEmissionsMeasureUnitLabel}}</div>
                                </div>
                            </div>
                            <div class="flex-row flex-1 light-padding">
                                <div class="flex-col flex-1 flex-justify-center">{{csaLabels.commentSustainabilityLabel}}: </div>
                                <div class="flex-col flex-2 flex-wrap"><textarea v-model="csaData.commentSustainabilityValue"></textarea></div>
                            </div>
                            <div class="flex-row flex-1 light-padding">
                                <div class="flex-col flex-1 flex-justify-center">{{csaLabels.externalRisksLabel}}: </div>
                                <div class="flex-col flex-2"><textarea v-model="csaData.externalRisksValue"></textarea></div>
                            </div>
                            <div class="flex-row flex-1 light-padding">
                                <div class="flex-col flex-1 flex-justify-center">{{csaLabels.pmaViewLabel}}: </div>
                                <div class="flex-col flex-2"><textarea v-model="csaData.pmaViewValue"></textarea></div>
                            </div>
                        </div>


                        <div class="flex-col flex-5">
                            <div class="flex-row flex-1">
                                <div id="fcf-chart" class="fcf-chart"></div>
                            </div>
                            <div class="flex-row flex-1 light-padding">
                                <div class="flex-col flex-1 flex-justify-center" style="align-items: center">FCF</div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.fcfValues[0]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.fcfValues[1]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.fcfValues[2]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.fcfValues[3]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.fcfValues[4]"/></div>
                            </div>
                            <div class="flex-row flex-1 light-padding">
                                <div class="flex-col flex-1 flex-justify-center" style="align-items: center">P5</div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.p5Values[0]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.p5Values[1]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.p5Values[2]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" value="N/A" readonly /></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" value="N/A" readonly /></div>
                            </div>
                            <div class="flex-row flex-1 light-padding">
                                <div class="flex-col flex-1 flex-justify-center" style="align-items: center">P95</div>
                                <div class="flex-col flex-1 flex-justify-center"><input class="smallInputBox" v-model="csaData.p95Values[0]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.p95Values[1]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.p95Values[2]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" value="N/A" readonly /></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" value="N/A" readonly /></div>
                            </div>
                            <div class="flex-row flex-1">
                                <div id="co2-chart" class="co2-chart"></div>
                            </div>
                            <div class="flex-row flex-1 light-padding">
                                <div class="flex-col flex-1 flex-justify-center" style="align-items: center">CO2</div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.absoluteCo2Values[0]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.absoluteCo2Values[1]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.absoluteCo2Values[2]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.absoluteCo2Values[3]"/></div>
                                <div class="flex-col flex-1"><input class="smallInputBox" v-model="csaData.absoluteCo2Values[4]"/></div>
                            </div>

                        </div>

                    </div>
                    <div class="flex-row flex-1 light-padding mb-1">
                        <div class="flex-col flex-1 flex-justify-center">*Comment: </div>
                        <div class="flex-col flex-5"><textarea v-model="updComment"></textarea></div>
                    </div>
                    <div class="flex-row flex-1 flex-justify-center mt-5 flex-justify-between">
                        <div class="flex-col flex-1 flex-justify-end" style="align-items: center"><button class="add-button" @click="home">Home</button></div>
                        <div class="flex-col flex-1 flex-justify-end" style="align-items: center"><button class="add-button" @click="reset">Reset</button></div>
                        <div class="flex-col flex-1 flex-justify-end" style="align-items: center"><button class="add-button" @click="saveCSAData">Save</button></div>
                    </div>
                </div>


                <div class="flex-col flex-1"></div>
            </div>


        </div>
    </div>



</div>
</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommercialSustainability, CsaLabel } from '@/models';
import { CsaDataService } from '@/services';
import { GoogleCharts } from 'google-charts';

@Component({
    name: 'csa-edit',
})
export default class CSAEdit extends Vue {
    @Prop()
    private csaData!: CommercialSustainability;

    @Prop()
    private csaLabels!: CsaLabel;

    @Prop()
    private assetName!: string;

    @Prop()
    private sno!: number;

    private csaDataService: CsaDataService = new CsaDataService();
    private dataloaded = true;
    private isEditable = true;
    private hideTrendDropdown = true;
    private updComment = '';

    private mounted(): void {
        GoogleCharts.load(this.drawFcfChart);
        GoogleCharts.load(this.drawCO2Chart);
        this.dataloaded = true;
        this.drawFcfChart();
        this.drawCO2Chart();
    }

    private home(): void {
        if (confirm('Discard changes?')) {
            this.$router.push('/');
        }
    }

    private async saveCSAData(): Promise<void> {
        if (this.updComment === '') {
            alert('Please put reason for modification in comment.');
            return;
        }
        this.csaData.updComment = this.updComment;
        await this.csaDataService.saveCSAData(this.sno, this.csaData);
        this.$router.push('/');
    }

    private async reset(): Promise<void> {
        if (confirm('Discard changes?')) {
            this.csaData = (await this.csaDataService.get(this.sno)).result;
            this.assetName = this.csaData.assetName;
            this.updComment = '';
        }
    }

    private displayTrendDropdown(): void {
        if (this.isEditable) {
            this.hideTrendDropdown = !this.hideTrendDropdown;
        }
    }

    private updateTrend(val: string): void {
        this.csaData.runningTrend = val;
        this.hideTrendDropdown = true;
    }

    get trendDropdownClass(): any {
        return {
            trendDropdown: true,
            showTerndDropdown: !this.hideTrendDropdown,
            hideTerndDropdown: this.hideTrendDropdown,
        };
    }

    private drawFcfChart(): void {
        const options = {
            vAxis: { title: 'm\u20ac', gridlines: { count: 4 } },
            title: 'Free Cash Flow',
            seriesType: 'candlesticks',
            candlestick: {
                risingColor: { stroke: 'red', fill: 'red' },
                fallingColor: { stroke: 'red', fill: 'red' },
            },
            series: {
                0: { type: 'line' },
                1: { type: 'line', color: 'red', tooltip: false },
                2: { type: 'line', color: 'red', tooltip: false },
            },
            bar: { groupWidth: '10%' },
            chartArea: { right: 0 },
            pointSize: 3,
        };
        const dataTable = this.getFcfChartDataTable(this.csaData);
        this.$nextTick(() => {
            const el = document.getElementById('fcf-chart');
            if (el) {
                setTimeout(() => {
                    const chart = new GoogleCharts.api.visualization.ComboChart(el);
                    chart.draw(dataTable, options);
                }, 50);
            }
        });
    }

    private drawCO2Chart(): void {
        const options = {
            vAxis: { title: 'KTCO2', gridlines: { count: 4 } },
            title: 'Absolute CO2 Emissions',
            chartArea: { right: 0 },
            pointSize: 3,
            series: { 0: { color: '#9E9E9E'} },
        };
        const dataTable = this.getCO2ChartDataTable(this.csaData);
        this.$nextTick(() => {
            const el = document.getElementById('co2-chart');
            if (el) {
                setTimeout(() => {
                    const chart = new GoogleCharts.api.visualization.LineChart(el);
                    chart.draw(dataTable, options);
                }, 50);
            }
        });
    }

    private getFcfChartDataTable(data: CommercialSustainability): any {
        const dataTable = new GoogleCharts.api.visualization.DataTable();
        const year = data.yearLabels;
        const meuro = data.fcfValues;
        const p5 = data.p5Values.concat([NaN, NaN]);
        const p95 = data.p95Values.concat([NaN, NaN]);
        for (let i = 0; i < data.p5Values.length; i++) {
            if (Math.abs(data.p5Values[i] - data.p95Values[i] ) > 0 ) {
                p5[i] = data.p5Values[i];
                p95[i] = data.p95Values[i];
            } else {
                p5[i] = NaN;
                p95[i] = NaN;
            }
        }
        const p5anno = [p5[0], NaN, NaN, NaN, NaN];
        const p95anno = [p95[0], NaN, NaN, NaN, NaN];
        dataTable.addColumn('string', 'Year');
        dataTable.addColumn('number', 'Free CF');
        dataTable.addColumn('number', 'annotation_p5');
        dataTable.addColumn({ type: 'string', role: 'annotation' });
        dataTable.addColumn({ type: 'string', role: 'annotationText' });
        dataTable.addColumn('number', 'annotation_p95');
        dataTable.addColumn({ type: 'string', role: 'annotation' });
        dataTable.addColumn({ type: 'string', role: 'annotationText' });
        dataTable.addColumn('number', 'p5');
        dataTable.addColumn('number', 'p5i');
        dataTable.addColumn('number', 'p95i');
        dataTable.addColumn('number', 'p95');

        for (let i = 0; i < year.length; i++) {
            const row = [ year[i].toString(),
                meuro[i],
                p5anno[i],
                'P5',
                'P5',
                p95anno[i],
                'P95',
                'P95',
                p5[i],
                p5[i],
                p95[i],
                p95[i],
            ];
            dataTable.addRow(row);
        }
        return dataTable;
    }

    private getCO2ChartDataTable(data: CommercialSustainability): any {
        const dataTable = new GoogleCharts.api.visualization.DataTable();
        const year = data.yearLabels;
        const emission = data.absoluteCo2Values;
        dataTable.addColumn('string', 'Year');
        dataTable.addColumn('number', 'CO2 Emissions');

        for (let i = 0; i < year.length; i++) {
            const row = [year[i].toString(), emission[i]];
            dataTable.addRow(row);
        }
        return dataTable;
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';
@import './csa-edit.less';

</style>
