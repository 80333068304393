import { Vue, Component } from 'vue-property-decorator';
import {FileDownload} from '@/models/file-download';

@Component({})
export default class DownloadFile extends Vue {
    downloadFile(file: FileDownload, fileType: string): void {
        const blob = new Blob([file.data], {
            type: fileType,
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = file.fileName;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}
