<template>
    <div class="kpi-n">
        <div :id="chartDiv"></div>
    </div>
</template>

<script lang="ts">

import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { GoogleCharts } from 'google-charts';

@Component
export default class KpiNChart extends Vue {
    @Prop()
    private chartData!: any;

    @Prop()
    private chartDiv!: string;

    private dataloaded = false;

    @Watch('chartData')
    private updateComponent(): void {
        this.dataloaded = true;
        this.drawKPIChart();
    }

    private mounted(): void {
        this.dataloaded = true;
        GoogleCharts.load(this.drawKPIChart);
    }

    private drawKPIChart(): void {
        const options = {
            legend: { position: 'none' },
            vAxis: { gridlines: { count: 4 }, format: '#\'%\'' },
            pointSize: 3,
            series: {
                0: { color: '#43A047' }, // target
                1: { color: '#229AFE' }, // pear
                2: { color: '#E53935' }, // asset
                3: { color: '#43A047', visibleInLegend: false }, // mtp t
                4: { color: '#229AFE', visibleInLegend: false }, // mtp p
            },
            height: 100,
            width: 250,
            chartArea: {width: '100%', top: 10, right: '0%', left: '15%'},
        };
        const dataTable = this.getKPIChartDataTable(this.chartData);
        this.$nextTick(() => {
            const el = document.getElementById(this.chartDiv);
            if (el) {
                setTimeout(() => {
                    const chart = new GoogleCharts.api.visualization.LineChart(el);
                    chart.draw(dataTable, options);
                }, 50);
            }
        });
    }

    private getKPIChartDataTable(data: any): any {
        const dataTable = new GoogleCharts.api.visualization.DataTable();
        const year = data.year;
        const target = data.target; // .concat([NaN, NaN, NaN]);
        const peer = data.peer; // .concat([NaN, NaN, NaN]);
        const asset = data.asset; // .concat([NaN, NaN, NaN]);
        const mtpTarget = data.mtpTarget; // [NaN, NaN, NaN].concat(data.mtpTarget);
        const mtpPeer = data.mtpPeer; // [NaN, NaN, NaN].concat(data.mtpPeer);
        dataTable.addColumn('string', 'Year');
        dataTable.addColumn('number', 'target');
        dataTable.addColumn('number', 'peer');
        dataTable.addColumn('number', this.chartData.assetName);
        dataTable.addColumn('number', 'mtp target');
        dataTable.addColumn('number', 'mtp peer');
        for (let i = 0; i < year.length; i++) {
            const row = [
                year[i],
                target[i],
                peer[i],
                asset[i],
                mtpTarget[i],
                mtpPeer[i],
            ];
            dataTable.addRow(row);
        }
        return dataTable;
    }
}

</script>

<style scoped lang="less">
</style>
