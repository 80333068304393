<template>
    <div class='static-report-ribbon'>
        <div v-if="!isDataLoaded">{{ $t('loading') }}</div>
        <div v-else class="flex-container pl-2 pr-2">
            <div class="flex-col flex-10">
                <div class="flex-row">
                    <div class="flex-col">
                        <label>{{ $t('selectAsset') }}</label>
                        <div class="flex-row flex-1">
                            <static-report-dropdown
                                :options="assetAndCalculationDate"
                                :default="assetselection"
                                class="select"
                                @input="onSelectAsset"
                                style="width: auto"
                            ></static-report-dropdown>
                        </div>
                    </div>
                    <div class="mr-2"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Asset, RunId, StaticLinkEditModel } from '@/models';
import Dropdown from '../../dropdown/dropdown.vue';
import SelectDropdown from '@/components/dropdown/select-dropdown.vue';
import { RunIdService, StaticLinkEditService } from '@/services';
import StaticReportDropdown from '@/components/dropdown/static-report-dropdown.vue';
import ComponentSecurity from '@/mixins/component-security';
import { toastModuleDisplayError } from '@/store/toastModule';

@Component({
    name: 'static-report-ribbon',
    components: {
        Dropdown,
        SelectDropdown,
        staticReportDropdown: StaticReportDropdown,
    },
})
export default class StaticReportRibbon extends Mixins(ComponentSecurity) {
    /**
     * Serial number list to get asset and calculation date details.
     */
    @Prop({ required: true })
    private serialNumberList!: number[];

    private isNewUi = true;
    private assetList: Asset[] = [];
    private runIds: RunId[] = [];
    private assetAndCalculationDate: StaticLinkEditModel[] = [];
    private assetselection!: StaticLinkEditModel;
    private runIdService: RunIdService = new RunIdService();
    private staticLinkEditService: StaticLinkEditService = new StaticLinkEditService();
    private isDataLoaded = false;

    @Watch('serialNumberList')
    private async selectDefaultAsset(): Promise<void> {
        await this.getEditRespone();
        this.isDataLoaded = false;
        if (this.assetselection === undefined && this.serialNumberList.length > 0) {
            this.assetselection = this.assetAndCalculationDate[0];
        } else {
            const selection = this.assetAndCalculationDate.find(
                (item) => item.asset.id === this.assetselection.asset.id,
            );
            this.assetselection = selection || this.assetAndCalculationDate[0];
        }
        this.onSelectAsset(this.assetselection);
    }

    private async onSelectAsset(data: StaticLinkEditModel): Promise<void> {
        this.assetselection = {...data};
        await this.loadRunId();
    }

    private async loadRunId(): Promise<void> {
        if (this.assetselection.asset !== undefined) {
            try {
                this.runIds = (await this.runIdService.get(this.assetselection.asset.id)).result;
                this.$emit('selection-details',
                    {
                        asset: this.assetselection.asset,
                        sno: this.assetselection.sno,
                    });
                this.isDataLoaded = true;
            } catch (error) {
                await this.$store.dispatch(toastModuleDisplayError, error);
            }
        }
    }

    private async getEditRespone(): Promise<void> {
        this.assetAndCalculationDate = (await this.staticLinkEditService.postSerialNumberList({
            sNo: this.serialNumberList,
        })).result;
    }

}

</script>

<style scoped lang="less">
@import '../../../variables.less';
.static-report-ribbon {
    .header,
    .footer {
        line-height: var(--adam-d48);
        font-weight: bolder;
    }
    .ribbon-lebel {
        justify-content: flex-end;
        font-size: 1.5rem;
        font-weight: bold;
    }
    .final-button {
        justify-content: center;
        align-items: flex-end;
    }
    .separator {
        width: 2px;
        height: 4rem;
        margin-left: 1rem;
        margin-right: 1rem;
        background: #E0E0E0;
        display: inline-block;
        vertical-align: middle;
    }
    .justify-end {
        justify-content: flex-end;
    };

    label {
        font-size: 1.3rem;
    }
}
</style>
