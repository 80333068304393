import { GoogleCharts } from 'google-charts';
import { CommercialSustainability } from '@/models/commercial-sustainability';
export default class CsaUtil {
    public getFcfChartDataTable(data: CommercialSustainability): any {
        const dataTable = new GoogleCharts.api.visualization.DataTable();
        const year = data.yearLabels;
        const meuro = data.fcfValues;
        const p5 = data.p5Values.concat([NaN, NaN]);
        const p95 = data.p95Values.concat([NaN, NaN]);
        for (let i = 0; i < data.p5Values.length; i++) {
            if (data.p5Values[i] === data.p95Values[i] && data.p95Values[i] ===  meuro[i]) {
                p5[i] = NaN;
                p95[i] = NaN;
            } else {
                p5[i] = data.p5Values[i];
                p95[i] = data.p95Values[i];
            }
        }
        dataTable.addColumn('string', 'Year');
        dataTable.addColumn('number', 'Free CF');
        dataTable.addColumn('number', 'P5');
        dataTable.addColumn('number', 'P95');

        for (let i = 0; i < year.length; i++) {
            const row = [ year[i].toString(),
                meuro[i],
                p5[i],
                p95[i],
            ];
            dataTable.addRow(row);
        }
        return dataTable;
    }

    public getCO2ChartDataTable(data: CommercialSustainability): any {
        const dataTable = new GoogleCharts.api.visualization.DataTable();
        const year = data.yearLabels;
        const emission = data.absoluteCo2Values;
        dataTable.addColumn('string', 'Year');
        dataTable.addColumn('number', 'CO2 Emissions');

        for (let i = 0; i < year.length; i++) {
            const row = [year[i].toString(), emission[i]];
            dataTable.addRow(row);
        }
        return dataTable;
    }

}
