<template>
    <div class="comments">
        <modal-box v-if="dataLoaded && showModal"
            :open="dataLoaded && showModal"
            :onClose.prop="() => showModal=false"
            :enableHeader.prop="true"
            :enableFooter.prop="true"
            :enableHeaderClose.prop="true"
            :transparentOverlay.prop="false"
            :closeOnClickOutside.prop="true"
            width='1080px'
        >
            <span slot="header" style="line-height: var(--adam-d48)">
                <div class="flex-row flex-1">
                    <div class="flex-col flex-1">
                        <h3>{{ commentToEdit.assetName }}</h3>
                    </div>
                    <span class="separator ml-6 mr-4 mt-2"></span>
                    <div class="flex-col flex-2">
                        <h3>{{ commentToEdit.attributeName }}</h3>
                    </div>
                </div>
            </span>
            <span>
                <div class="flex-col edit-comment">
                    <div class="mt-3 ui-text">
                        <label for="uiTextOldValue" class="ui-text-label">{{ $t('oldValue') }}</label>
                        <textarea id="uiTextOldValue"
                            class="ui-text-input"
                            placeholder="Value"
                            v-model="commentOldValue" :readonly="true"
                        >&#10;
                        </textarea>
                    </div>
                    <div class="mt-3 ui-text">
                        <label for="uiTextValue" class="ui-text-label">{{ $t('newValue') }}</label>
                        <textarea
                            id="uiTextValue"
                            class="ui-text-input"
                            placeholder="Value"
                            v-model="commentToEdit.attributeValue"
                        >&#10;
                        </textarea>
                    </div>
                    <div class="mt-3 ui-text">
                        <label for="uiTextReason" class="ui-text-label">{{ $t('reason') }}</label>
                        <textarea id="uiTextReason"
                            class="ui-text-input"
                            placeholder="Value"
                            v-model="commentToEdit.updComment"
                        >&#10;
                        </textarea>
                    </div>
                    <div :class="errorExists ? 'error-text': ''">
                        <div v-if="errorExists">This field is mandatory</div>
                    </div>
                </div>
                <br/>
            </span>
            <span slot="footer" style="line-height: var(--adam-d48)">
                <div class="flex-row reverse">
                    <div class="flex-col flex-align-end">
                        <adam-button secondary icon="ic-station" @click="save" >Save</adam-button>
                    </div>
                </div>
            </span>
        </modal-box>
        <bulk-update-upload :show-bulk-update="showBulkUpdateModal" @close="showBulkUpdateModal = false"></bulk-update-upload>
        <div v-if="!dataLoaded" class="popup">
            <eas-loader></eas-loader>
        </div>
        <content-wrapper>
            <template v-slot:filters>
                <div class="flex-row flex-1" style="align-items: center">
                    <div class="flex-col">
                        <div class="flex-row flex-1">
                            <div class="flex-col ml-5" style="width: 30rem">
                                <CustomSelect
                                    :options="assets"
                                    :default="defaultAsset"
                                    displayKey='name'
                                    class='select'
                                    @input="onAssetChange"
                                />
                            </div>
                            <span class="separator ml-6 mr-4"></span>
                            <div class="flex-col ml-2"  style="width: 30rem">
                                <CustomSelect
                                    :options="commentAttributes"
                                    :default="defaultAttrib"
                                    displayKey='attributeTitle'
                                    class='select'
                                    @input="onTitleChange"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="flex-col flex-1 mr-4">
                        <div class="flex-row" style="justify-content: flex-end">
                            <div class="flex-col mr-1" style="justify-content: center">
                                <button class="btn" @click="downloadTemplate">
                                    {{ $t('downloadTemplate') }}
                                </button>
                            </div>
                            <div class="flex-col" style="justify-content: center">
                                <button class="btn" @click="showBulkUpdate">
                                    {{ $t('bulkUpdate') }}
                                </button>
                            </div>
                            <span class="separator ml-4 mr-4"></span>
                            <div class="flex-col page-size-label pr-2">Page Size: </div>
                            <div class="flex-col" style="align-items: flex-end">
                                <select v-model="pageSize" @change="changePageSize">
                                    <option v-for="size in pageSizeSelection" :key="size" :value="size">{{size}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <div class="flex-row">
                    <div class="flex-col flex-1"></div>
                    <div class="flex-col flex-12">
                        <div v-if="!pagingData.totalPageCount"> No Data Available </div>
                        <paging v-else
                            :pagingData="pagingData"
                            @current-page="modifyContent"
                        >
                            <template v-slot:pageContent>
                                <CommentComponent
                                    class="mb-1"
                                    v-for="cmnt in comments"
                                    :key= "cmnt.sno" :comment="cmnt"
                                    @on-edit="editComment"
                                />
                            </template>
                        </paging>
                    </div>
                    <div class="flex-col flex-1"></div>
                </div>
            </template>
        </content-wrapper>
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import {CommentService, AssetService} from '@/services';
import ContentWrapper from '@/components/content-wrapper/content-wrapper.vue';
import CommentComponent from './comment.vue';
import Paging from '@/components/paging/paging.vue';
import EasLoader from '../loader/eas-loader.vue';
import CustomSelect from '@/components/dropdown/custom-select.vue';
import { Asset, CommentModel } from '@/models';
import { EventBus } from '@/utils';
import BulkUpdateUpload from '@/components/comment-edit/bulk-update/bulk-update-upload.vue';
import { FileDownload } from '@/models/file-download';
import { toastModuleDisplayError } from '@/store/toastModule';

@Component({
    name: 'comments',
    components: {
        BulkUpdateUpload,
        ContentWrapper,
        CommentComponent,
        EasLoader,
        Paging,
        CustomSelect,
    },
})
export default class Comments extends Vue {
    private assets: Asset[] = [];
    private showModal = false;
    private errorExists = false;
    private commentOldValue = '';
    private commentService: CommentService = new CommentService();
    private assetService: AssetService = new AssetService();
    private attribSelection: any = {};
    private defaultAttrib: any = {sNo: null, attributeTitle: 'All Attribute'};
    private dataLoaded = false;
    private assetselection: any = {};
    private defaultAsset: any = {id: null, name: 'All Assets', isDefault: false};
    private commentResult: any = {};
    private comments: CommentModel[] = [];
    private commentAttributes: any[] = [];
    private commentToEdit: any = {};
    private showBulkUpdateModal = false;
    private pagingData: any = {
        currentPage: 1,
        hasNext: false,
        hasPrevious: false,
        totalPageCount: 37,
        prevArray: [],
        afterArray: [],
    };
    private currentPage = 1;
    private contentPerPage = 5;
    private pageSize = 5;
    private pageSizeSelection: number[] = [5, 10, 15, 25, 30];
    private genericText = 'Generic UI Text (No Asset)';

    private async mounted(): Promise<void> {
        await this.init();
        EventBus.$on(EventBus.UI_TEXT.UPDATE, () => this.refreshPage());
    }

    private async init(): Promise<void> {
        try {
            this.dataLoaded = false;
            this.assets = (await this.assetService.get()).result;
            this.assets.push({
                id: 0,
                name: this.genericText,
                isDefault: false,
                finalRunSno: 0,
            });
            this.assets.unshift(this.defaultAsset);
            this.assetselection = {...this.defaultAsset};
            this.commentAttributes = (await this.commentService.getAttributes()).result;
            this.commentAttributes.unshift(this.defaultAttrib);
            this.attribSelection = {...this.defaultAttrib};
            this.modifyContent(1);
        } catch (error) {
            await this.$store.dispatch(toastModuleDisplayError, error);
        }
        finally {
            this.dataLoaded = true;
        }
    }

    private changePageSize(): void {
        this.modifyContent(1);
    }

    private async modifyContent(currentPage: number): Promise<void> {
        this.commentResult = (await this.commentService
            .get(currentPage, this.pageSize, {
                assetId: this.assetselection.id,
                attributeId: this.attribSelection.sNo,
            })).result;
        this.comments = this.commentResult.items;
        this.pagingData.totalPageCount = Math.ceil(this.commentResult.totalCount / this.pageSize);
        this.pagingData.currentPage = currentPage;
        this.pagingData.hasPrevious = (currentPage > 1) ? true : false;
        this.pagingData.hasNext = (currentPage < this.pagingData.totalPageCount) ? true : false;
        this.pagingData.prevArray = [];
        this.pagingData.afterArray = [];
        for ( let i = 1; i < 3; i++) {
            if (currentPage - i >= 1) { this.pagingData.prevArray.unshift(currentPage - i); }
            if (currentPage + i <= this.pagingData.totalPageCount) { this.pagingData.afterArray.push(currentPage + i); }
        }
        this.$forceUpdate();
    }

    private async refreshPage(): Promise<void> {
        await this.modifyContent(this.pagingData.currentPage);
    }

    private onChange(): void {
        this.modifyContent(1);
    }

    private onAssetChange(asset: any): void {
        this.assetselection = asset;
        this.modifyContent(1);
    }

    private onTitleChange(title: any): void {
        this.attribSelection = title;
        this.modifyContent(1);
    }

    private editComment(comment: CommentModel): void {
        this.commentToEdit = {...comment};
        this.commentOldValue = comment.attributeValue;
        this.commentToEdit.updComment = '';
        this.showModal = true;
    }

    private async save(): Promise<void> {
        if (this.commentToEdit.updComment === '') {
            this.errorExists = true;
        } else {
            this.errorExists = false;
            await this.commentService.save(this.commentToEdit).then((data) => {
                if (data.statusCode === 409) {
                    this.$store.dispatch(toastModuleDisplayError, data);
                }
            });
            this.showModal = false;
            this.dataLoaded = false;
            this.modifyContent(1);
            this.comments = this.commentResult.items;
            this.dataLoaded = true;
            this.$forceUpdate();
            this.onChange();
            EventBus.$emit(EventBus.UI_TEXT.UPDATE, 'update');
        }
    }

    private showBulkUpdate(): void {
        this.showBulkUpdateModal = true;
    }

    private async downloadTemplate(): Promise<void> {
        const file: FileDownload = await this.commentService.downloadTemplate();

        const blob = new Blob([file.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = file.fileName;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.UI_TEXT.UPDATE);
    }
}


</script>

<style lang="less" scoped>
@import '../../variables.less';
.btn {
    width: auto;
    height: 3rem;
    background-color: @uniper-blue;
    color: @white;
    border-radius: .5rem;
    border: 1px solid @uniper-blue;
    font-size: 1.2rem;
    text-transform: uppercase;
    white-space: nowrap;
}

.btn:disabled {
    color: @warm-grey;
    border: 1px solid @warm-grey;
    background: @pale-grey;
    cursor: auto;
}

.comments {
    font-size: 1.5rem;
    .separator {
        width: 2px;
        height: 4rem;
        margin-left: 2rem;
        margin-right: 2rem;
        background: #E0E0E0;
        display: inline-block;
        vertical-align: middle;
    }
    .page-size-label {
        justify-content: center;
        font-size: 1.2rem;
        font-weight: bold;
    }
    .error-text {
        color: red;
    }
    .edit-comment {
        height: calc(100% - 11.5rem);
        overflow: hidden;
        overflow-y: auto;
        label {
            margin-top: 1rem;
            min-width: fit-content;
        }
        input {
            width: 100%;
        }
        .ui-text {
            position: relative;
            label {
                position: absolute;
                background: white;
                left: 1.8rem;
                top: -2.1rem;
                padding: 0 1rem;
                font-size: 1.6rem;
                z-index: 1;
            }
            .ui-text-input {
                display: block;
                margin: 0 auto;
            }
            textarea {
                width: 100%;
                white-space: pre-wrap;
                max-width: 100%;
                max-height: 10rem;
                min-width: 100%;
                min-height: 5rem;
            }
            input,
            select,
            textarea {
                box-sizing: border-box;
                font-size: 1.8rem;
                padding: 1rem 2.5rem;
                border-radius: @border-radius-standard;
                border: 2px solid @dark-grey;
                transition: 0.2s ease-in;
                &:focus {
                    border: 2px solid @uniper-blue;
                    transition: 0.2s ease-in;
                    outline: none;
                }
                &.invalid {
                    border: 2px solid @red-darker;
                    box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
                    transition: 0.1s ease-in;
                }
                &.full-width {
                    width: 100%;
                }
            }
            select {
                padding: 1rem 2.2rem;
                &.invalid {
                    border: 2px solid @red-darker;
                    box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
                    transition: 0.1s ease-in;
                }
            }
        }
    }
}
</style>
