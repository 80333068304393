<template>
    <div class="decision-group  pl-4">
        <div v-for="(criterion, criterionIndex) in criteria" :key="criterionIndex">
            <div v-if="criterion !== null">
                <decision-criterion :criteriaModel="criterion" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import DecisionCriterion from './decision-criterion.vue';
import { CriteriaModel } from '@/models';

@Component({
    name: 'decision-group',
    components: {
        DecisionCriterion,
    },
})
export default class DecisionGroup extends Vue {
    /**
     * This is to take the criteria object recursive
     */
    @Prop({required: true})
    private criteria!: CriteriaModel[];
}
</script>