<template>
  <div class="paging">
        <div class="flex-row justify-center">
            <button v-if="pagingData.hasPrevious" class="flex-col pagebtn" @click="prevPage()"> &lt; </button>
            <button v-if="showFirstPage" class="flex-col pagebtn"  @click="setCurrentPage(1)"> 1 </button>
            <span v-if="showStartEllipses" class="flex-col pagebtn"> ... </span>
            <button 
                v-for="(num, i) in pagingData.prevArray"
                :key="prev(i)"
                class="flex-col pagebtn"
                @click="setCurrentPage(pagingData.prevArray[i])"
            >
                {{pagingData.prevArray[i]}}
            </button>
            <div class="flex-col pagebtn"> {{pagingData.currentPage}} </div>
            <button
                v-for="(num, j) in pagingData.afterArray"
                :key="nxt(j)"
                class="flex-col pagebtn"
                @click="setCurrentPage(pagingData.afterArray[j])"
            >
                {{pagingData.afterArray[j]}}
            </button>
            <span v-if="showEndEllipses" class="flex-col pagebtn"> ... </span>
            <button v-if="showLastPage" class="flex-col pagebtn" @click="setCurrentPage(pagingData.totalPageCount)"> {{pagingData.totalPageCount}} </button>
            <button v-if="pagingData.hasNext" class="flex-col pagebtn" @click="nextPage()"> &gt; </button>
        </div>
        <div class="flex-row flex-1 mt-2">
            <div class="flex-col flex-1">
                <slot name="pageContent"></slot>
            </div>
        </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'paging',
})
export default class Paging extends Vue {
    @Prop({ default: () => {
        return {
            currentPage: 1,
            hasNext: false,
            hasPrevious: false,
            totalPageCount: 1,
            contentPerPage: [],
            prevArray: [],
            afterArray: [],
        };
    }})
    private pagingData!: any;

    private prev(num: number): string {
        return 'prev' + num.toString();
    }
    private nxt(num: number): string {
        return 'nxt' + num.toString();
    }
    private prevPage(): void {
        this.setCurrentPage(this.pagingData.currentPage - 1);
    }
    private nextPage(): void {
        this.setCurrentPage(this.pagingData.currentPage + 1);
    }
    private setCurrentPage(num: number): void {
        this.$emit('current-page', num);
    }
    get showFirstPage(): boolean {
        return this.pagingData.prevArray.length > 0 && this.pagingData.prevArray[0] !== 1;
    }
    get showLastPage(): boolean {
        return this.pagingData.afterArray.length > 0
        && this.pagingData.afterArray.slice(-1).pop() !== this.pagingData.totalPageCount;
    }
    get showStartEllipses(): boolean {
        return this.pagingData.prevArray.length > 0 && this.pagingData.prevArray[0] - 1 > 1;
    }
    get showEndEllipses(): boolean {
        return this.pagingData.afterArray.length > 0
        && this.pagingData.totalPageCount - this.pagingData.afterArray.slice(-1).pop() > 1;
    }
}
</script>

<style lang="less">
@import '../../variables.less';
.paging {
    .justify-center{
        justify-content: center;
    }
    .pagebtn{
        font-size: 1.5rem;
        font-weight: bold;
        // padding: .5rem;
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        box-sizing: border-box;
        text-align: center;
        border: 1px solid @grey-lighter;
    }
    button.pagebtn{
        background-color: @white;
        color: @uniper-blue;
        text-align: center;
        align-items: center;
        justify-content: center;
        &:hover{
            background-color: @uniper-blue;
            color: @white;
        }
    }
    div.pagebtn{
        background-color: @uniper-blue;
        color: @white;
    }
}
</style>