import { FleetModel, ApiResponse } from '@/models';
import BaseService from '@/utils/base-service';

export class FleetService extends BaseService {

    private endpoint = '/MachineNameMapping/fleet';

    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async getFleetList(): Promise<ApiResponse<FleetModel[]>> {
        return (await this.api.get(`${this.endpoint}`)).data;
    }

}
