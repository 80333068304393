<template>
    <div class="report-box-new">
        <div :class="['flex-row', 'box-header', isNewUi ? 'blue-bg' : '']">
            <div class="flex-col flex-10 justify-center">
                <slot name="title"></slot>
            </div>
            <div v-if="isNewUi" class="flex-col flex-2 justify-center align-end action">
                <font-awesome-icon :icon="['fas', isExpanded ? 'compress-alt' : 'expand-alt']" title="expand" @click="expandClicked()"/>
            </div>
            <div v-if="!isNewUi" class="flex-col flex-2 justify-center align-end action">
                <slot name="action"></slot>
            </div>
        </div>
        <div class="flex-row report-box-content">
            <div class="flex-col flex-1">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'report-box-new',
})
export default class ReportBoxNew extends Vue {
    /**
     * Set to eanble new UI view.
     */
    @Prop({ default: false })
    private isNewUi!: boolean;

    /**
     * Set to open the pop-up of components.
     */
    @Prop({ default: false })
    private isExpanded!: boolean;

    private expandClicked(): void {
        this.$emit('size-change');
    }
}
</script>

<style lang="less">
@import '../../variables.less';
.report-box-new{
    height: 100%;
    .report-box-content {
        height: 100%;
    }
    .box-header{
        font-size: 1.8rem;
        font-weight: bold;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        .action{
            padding-right: 1rem;
            font-size: 2rem;
            a {
                color: grey;
            }
        }
    }
    .blue-bg {
        font-size: 1.5rem;
        color: white;
        background-color: @uniper-blue;
    }
    .justify-center{
        justify-content: center;
    }
    .align-end{
        align-items: flex-end;
    }
    .flex-row{
        padding: 0.5rem;
    }
}
</style>
