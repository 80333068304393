var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex-container decision-edit"},[_c('div',{staticClass:"flex-col flex-1"},[_c('div',{staticClass:"flex-row flex-1 heading"},[_vm._v(_vm._s(_vm.decision))]),(_vm.isNothingSelected)?_c('div',{staticClass:"flex-row flex-1 redtext"},[_vm._v(_vm._s(_vm.$t('selectCategoryWarning')))]):_vm._e(),_c('div',{staticClass:"flex-row"},[_c('div',{class:[
                    'flex-col flex-1 decisionbox ml-1 mt-2 mr-1 mb-2 darkGreenBox',
                    { highlight: _vm.selectedDecision === 'grow' },
                ],on:{"click":function($event){return _vm.selectAction('grow')}}},[_c('div',{staticClass:"text-bg"},[_vm._v(_vm._s(_vm.$t('grow')))])]),_c('div',{class:[
                    'flex-col flex-1 decisionbox ml-1 mt-2 mr-1 mb-2 lightGreenBox',
                    { highlight: _vm.selectedDecision === 'maintain' },
                ],on:{"click":function($event){return _vm.selectAction('maintain')}}},[_c('div',{staticClass:"text-bg"},[_vm._v(_vm._s(_vm.$t('maintain')))])])]),_c('div',{staticClass:"flex-row"},[_c('div',{class:[
                    'flex-col flex-1 decisionbox ml-1 mt-2 mr-1 mb-2 yellowBox',
                    { highlight: _vm.selectedDecision === 'keepOption' },
                ],on:{"click":function($event){return _vm.selectAction('keepOption')}}},[_c('div',{staticClass:"text-bg"},[_vm._v(_vm._s(_vm.$t('keepOption')))])]),_c('div',{class:[
                    'flex-col flex-1 decisionbox ml-1 mt-2 mr-1 mb-2 redBox',
                    { highlight: _vm.selectedDecision === 'optimizeEol' },
                ],on:{"click":function($event){return _vm.selectAction('optimizeEol')}}},[_c('div',{staticClass:"text-bg"},[_vm._v(_vm._s(_vm.$t('optimizeEol')))])])]),_c('div',{staticClass:"flex-row flex-1 light-padding mb-1"},[_c('div',{staticClass:"flex-col flex-1 flex-justify-center"},[_vm._v("*"+_vm._s(_vm.$t('comment'))+": ")]),_c('div',{staticClass:"flex-col flex-5"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.updComment),expression:"updComment"}],class:{ invalid: _vm.isCommentEmpty },domProps:{"value":(_vm.updComment)},on:{"focus":() => _vm.isCommentEmpty = false,"input":function($event){if($event.target.composing)return;_vm.updComment=$event.target.value}}})])]),_c('div',{staticClass:"flex-row reverse"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.save()}}},[_vm._v("save")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }