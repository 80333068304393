<template>
    <div class="dates">
        <!-- <div class="flex-row flex-1 component-header ml-1 mb-1 mt-1">Dates</div> -->
        <div class="flex-row flex-3">
            <div class="flex-col flex-2">
                <div class="flex-row flex-1">
                    <div class="flex-col flex-1 label flex-justify-center pb-1 pl-1 pt-1 pr-1">{{ $t('nextMajorOutage') }}:</div>
                    <div class="flex-col flex-1 flex-justify-center pb-1 pl-1 pt-1 pr-1" id="nextMajorOutagesValue1">
                        {{datesGeneralStrategyobj.nextMajorOutagesValue1}}
                    </div>
                </div>
                <div class="flex-row flex-1">
                    <div class="flex-col flex-1 label flex-justify-center pb-1 pl-1 pt-1 pr-1">{{$t('eolTechnical') }}:</div>
                    <div class="flex-col flex-1 pb-1 flex-justify-center pl-1 pt-1 pr-1" id="eolTechnicalValue">
                        {{datesGeneralStrategyobj.eolTechnicalValue}}
                    </div>
                </div>
                <div class="flex-row flex-1">
                    <div class="flex-col flex-1 label flex-justify-center pb-1 pl-1 pt-1 pr-1 mb-1">{{ $t('expiryContracts') }}:</div>
                    <div class="flex-col flex-1 flex-justify-center pb-1 pl-1 pt-1 pr-1 mb-1" id="expiryContractsValue">
                        {{datesGeneralStrategyobj.expiryContractsValue}}
                    </div>
                </div>
            </div>
            <div class="flex-col flex-3">
                <div class="flex-row flex-1 pb-1 pl-1 pt-1 pr-1" id="nextMajorOutagesValue2">
                    {{datesGeneralStrategyobj.nextMajorOutagesValue2}}
                </div>
                <div class="flex-row flex-2 pb-1 pl-1 pt-1 pr-1 mb-1" id="dateCommentValue">
                    {{datesGeneralStrategyobj.dateCommentValue}}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator';
import { DatesGeneralStrategyModel } from '@/models/dates-generalstrategy-model';

@Component
export default class Dates extends Vue {
    /**
     * Set the Dates component data.
     */
    @Prop({ required: true })
    private datesGeneralStrategyobj!: DatesGeneralStrategyModel;
}

</script>

<style scoped lang="less">
.dates{
    white-space: pre-line;
    .component-header{
      font-weight: bold;
      font-size: 1rem;
    }
    
}
.flex-justify-center{
  justify-content: center;
}
</style>
