import { SAP, ApiResponse } from '@/models';
import BaseService from '@/utils/base-service';

export class SAPService extends BaseService {
    private endpoint = '/resultsets/sap';
    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async get(sno: number): Promise<ApiResponse<SAP>> {
        return (await this.api.get(`${this.endpoint}/${sno}`)).data;
    }
}
