/**
 * Collection of helper methods for dates.
 */
import moment from 'moment-timezone';

export class DateHelper {
    /**
     * Static method to format dates as ISO strings without time.
     * @name formatDate
     * @param {string} dateString
     * @returns {string} Formatted date string.
     */
    public static formatDate(dateString: string): string {
        const date = new Date(dateString);
        const monthPart = date.getMonth() + 1;
        const datePart = date.getDate();
        const monthString = monthPart < 10 ? `0${monthPart}` : monthPart;
        const dateDayString = datePart < 10 ? `0${datePart}` : datePart;
        return `${date.getFullYear()}-${monthString}-${dateDayString}`;
    }

    /**
     * Static method to format dates as ISO strings with time.
     * @name formatDateTime
     * @param {string} dateString
     * @returns {string} Formatted date string.
     */
    public static formatDateTime(dateString: string): string {
        const date = new Date(dateString);
        const monthPart = date.getMonth() + 1;
        const datePart = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const monthString = monthPart < 10 ?  `0${monthPart}` : monthPart;
        const dateDayString = datePart < 10 ? `0${datePart}` : datePart;
        const hoursString = hours < 10 ? `0${hours}` : hours;
        const minutesString = minutes < 10 ? `0${minutes}` : minutes;
        return `${date.getFullYear()}-${monthString}-${dateDayString} ${hoursString}:${minutesString}`;
    }

    /**
     * Static method to format time hh:mm from a date string;
     * @param dateString date string yyyy-MM-dd format
     * @returns {string} formatted time.
     */
    public static formatTime(dateString: string): string {
        const date = new Date(dateString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const hoursString = hours < 10 ? `0${hours}` : hours;
        const minutesString = minutes < 10 ? `0${minutes}` : minutes;
        return `${hoursString}:${minutesString}`;
    }

    /**
     * Static method to get the UNIX timestamp from a date string.
     * @name getTimestamp
     * @param {string} dateString
     * @returns {number} Timestamp from date.
     */
    public static getTimestamp(dateString: string): number {
        return new Date(dateString).getTime();
    }

    /**
     * Static method to determine if a date is greater than the other.
     * @param {Date} a
     * @param {Date} b
     * @returns {boolean} True or false.
     */
    public static dateGreaterThan(a: Date, b: Date): boolean {
        return a.getTime() > b.getTime();
    }

    /**
     * Get the number of months between two dates.
     * @param date1 date1
     * @param date2 date2
     * @returns {number} number of months, zero if negative.
     */
    public static getMonthsDiff(date1: Date, date2: Date): number {
        let months;
        months = (date2.getFullYear() - date1.getFullYear()) * 12;
        months -= date1.getMonth() + 1;
        months += date2.getMonth() + 1;
        return months <= 0 ? 0 : months;
    }

    /**
     * Static method to convert date to the selected country's timezone
     * @name convertToTimezone
     * @param {Date} date
     * @param {string} timezone
     * @returns {Date} Converted date.
     */
    // public static convertToTimezone(date: Date, timezone: string): Date {
    //     return new Date(moment.tz(moment(date), timezone).format('YYYY-MM-DD HH:mm'));
    // }

    /**
     * Static method to convert date back to local timezone
     * @name convertToLocalTimezone
     * @param {Date} date date object in local format but having the selected country's date and time
     * * @param {string} timezone
     * @returns {Date} Converted date.
     */
    public static convertToLocalTimezone(date: Date, timezone: string): Date {
        return new Date(moment(date).tz(timezone, true).format());
    }

    /**
     * Static method to format date to the selected country's timezone
     * @name formatDateToTimezone
     * @param {Date} date
     * @param {string} timezone
     * @returns {string} Converted date with time.
     */
    public static formatDateToTimezone(date: Date, timezone: string): string {
        return moment.tz(moment(date), timezone).format('YYYY-MM-DD HH:mm');
    }

    /**
     * Static method to format date to the selected country's timezone
     * @name formatWithoutTimeToTimezone
     * @param {Date} date
     * @param {string} timezone
     * @returns {string} Converted date without time
     */
    public static formatWithoutTimeToTimezone(date: Date, timezone: string): string {
        return moment.tz(moment(date), timezone).format('YYYY-MM-DD');
    }

    /**
     * Static method to format time to the selected country's timezone from a date
     * @name formatTimeToTimezone
     * @param {Date} date
     * @param {string} timezone
     * @returns {string} Converted time
     */
    public static formatTimeToTimezone(date: Date, timezone: string): string {
        const timezoneDate = new Date(moment.tz(moment(date), timezone).format('YYYY-MM-DD HH:mm'));
        const hours = timezoneDate.getHours();
        const minutes = timezoneDate.getMinutes();
        const hoursString = hours < 10 ? `0${hours}` : hours;
        const minutesString = minutes < 10 ? `0${minutes}` : minutes;
        return `${hoursString}:${minutesString}`;
    }

    /**
     * Static method to format time hh:mm from a date string with timezone abbreviation;
     * @param dateString date string yyyy-MM-dd format
     * @param {string} timezone
     * @returns {string} formatted time with timezone abbreviation.
     */
    public static formatTimeWithTimezoneAbbreviation(dateString: string, timezone: string): string {
        const date = new Date(dateString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const hoursString = hours < 10 ? `0${hours}` : hours;
        const minutesString = minutes < 10 ? `0${minutes}` : minutes;
        const timezoneAbbreviation = moment.tz(timezone).zoneAbbr();
        return `${hoursString}:${minutesString} (${timezoneAbbreviation})`;
    }

    /**
     * Formats a date to a UTC string taking into account the offset between UTC and the timezone.
     * @param {Date} date Date to format.
     * @param {string} timezone Timezone for which the offset is taken.
     * @returns {string}
     */
    public static formatToUTCStringFromMarketAreaTimezone(date: Date, timezone: string): string {
        const prependZeroToLessThanTen = (num: number): string => {
            return num < 10 ? `0${num}` : num.toString();
        };

        const now = moment.utc();
        const timezoneOffset = (moment.tz.zone(timezone) as any).utcOffset(now);
        const newDate = new Date(date);
        newDate.setHours(newDate.getHours() + (timezoneOffset / 60));
        const monthPart = newDate.getMonth() + 1;
        const monthPartString = prependZeroToLessThanTen(monthPart);
        const datePart = prependZeroToLessThanTen(newDate.getDate());
        const hourPart = prependZeroToLessThanTen(newDate.getHours());
        const minutePart = prependZeroToLessThanTen(newDate.getMinutes());
        const secondsPart = prependZeroToLessThanTen(newDate.getSeconds());
        return `${newDate.getFullYear()}-${monthPartString}-${datePart}` +
            `T${hourPart}:${minutePart}:${secondsPart}.000Z`;
    }
}
