<template>
  <div class="static-report">
    <Navbar />
    <div v-if="isDataLoaded" class="static-content">
        <div class="flex-row ribbon">
            <div class="flex-col flex-1">
                <div v-if="shoulShowReport">
                    Updated On: <br> <b>{{ timeStamp }}</b>
                </div>
                <div v-else class="mr-4">
                    <select class="flex-col flex-1"
                        @change="onProcessChange"
                        v-model="processSelection"
                    >
                        <option value="">All</option>
                        <option
                            v-for="category in distinctCategories"
                            :key="category"
                            :value="category"
                        >
                            {{ category }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="flex-col flex-1 flex-align-center">
                <div class="flex-row tab-toggler">
                    <div
                        :class="['flex-col','tab', 'tab-report', shoulShowReport ? 'active' : '']"
                        @click="() => shoulShowReport=true"
                    >Report</div>
                    <div
                        :class="['flex-col','tab', 'tab-report', !shoulShowReport ? 'active' : '']"
                        @click="() => shoulShowReport=false"
                    >Line of sight</div>
                </div>
            </div>
            <div class="flex-col flex-1 flex-align-center">
                <button
                    v-if="shoulShowReport && hasEditAccess"
                    class="btn"
                    @click="openEdit"
                >
                    {{ $t('openInApplication') }}
                </button>
            </div>
        </div>
        <div class="flex-row page-content">
            <div v-show="shoulShowReport">
                <div v-for="data in snoListResponse" :key="data.asset.id" >
                    <ReportGrid
                        v-if="hasAssetAccess(data.asset.id)"
                        :id="idPrefix + data.asset.id.toString()"
                        :serialNumber="data.sno" :asset="{...data.asset}"
                        :isNewUi="false"
                    />
                </div>
            </div>
            <div v-show="!shoulShowReport">
                <div class="flex-row">
                    <adam-table
                        :columns.prop="columns"
                        :data.prop="filteredList"
                    >
                    </adam-table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import ContentWrapper from '@/components/content-wrapper/content-wrapper.vue';
import Navbar from '@/components/header/navbar.vue';
import EasLoader from '@/components/loader/eas-loader.vue';
import LineOfSight from '@/components/line-of-sight/line-of-sight.vue';
import ReportGrid from '../report-grid/report-grid.vue';
import { LOSModel, StaticLinkEditModel } from '@/models';
import { LOSService, StaticLinkEditService } from '@/services';
import ComponentSecurity from '@/mixins/component-security';
import Permissions from '@/utils/permissions';

@Component({
    components: {
        ContentWrapper,
        EasLoader,
        Navbar,
        ReportGrid,
        LineOfSight,
    },
})
export default class StaticReport extends Mixins(ComponentSecurity) {
    private idPrefix = 'report-id-';
    private distinctCategories: string[] = [];
    private processSelection = '';
    private timeStamp!: string;
    private shoulShowReport = true;
    private isDataLoaded = false;
    private losModelList: LOSModel[] = [];
    private filteredList: LOSModel[] = [];
    private snoListResponse: StaticLinkEditModel[] = [];
    private serialNumberList: number[] = [];
    private losService: LOSService = new LOSService();
    private staticLinkEditService: StaticLinkEditService = new StaticLinkEditService();
    private columns: any[] = [
        { key: 'category', label: 'Category', hidden: false, width: '' },
        { key: 'classification', label: 'Classification', hidden: false, width: '' },
        { key: 'value', label: 'Description', hidden: false, width: '' },
    ];

    private async mounted(): Promise<void> {
        const payload = this.$route.query.payload ? this.$route.query.payload as string : '';
        try {
            const data = JSON.parse(decodeURIComponent(payload));
            this.timeStamp = data.timeStamp;
            this.serialNumberList = data.snoList;
            this.snoListResponse = (await this.staticLinkEditService.postSerialNumberList({
                sno: data.snoList,
            })).result;
            this.snoListResponse.sort((a, b) => a.asset.name > b.asset.name ? 1 : -1);
            this.losModelList = (await this.losService.getLosData()).result;
            this.filteredList = [...this.losModelList];
            this.distinctCategories = [...new Set(this.losModelList.map((_: LOSModel) => _.category))];
            this.isDataLoaded = true;
        } catch (error) {
            window.location.href = `${window.location.origin}/authorization/page-not-found.html`;
        }
    }

    private onProcessChange(): void {
        const selectedData = this.losModelList.filter((x: LOSModel) => {
            if (this.processSelection === '' || x.category === this.processSelection) {
                return x;
            }
        });
        this.filteredList = [...selectedData];
    }

    private openEdit(): void {
        const routeData = this.$router.resolve({
            name: 'static-link-edit',
            query: { payload: encodeURIComponent(
                JSON.stringify({snoList: this.serialNumberList}),
            )},
        });
        window.open(routeData.href, '_blank');
    }

    private hasAssetAccess(assetId: number): boolean {
        return (new Permissions()).hasAssetAccess(assetId);
    }
}
</script>

<style lang="less" scoped>
@import '../../variables.less';
.static-content {
    margin-top: 2rem;
    padding: 2rem;
    .ribbon {
        font-size: 1.5rem;
        align-items: center;
        margin-bottom: 2rem;
        .tab-toggler {
            box-sizing: border-box;
            width: 100%;
            height: 4rem;
            border: 0.1rem solid @uniper-blue;
            border-radius: 2rem;
            cursor: pointer;
            .tab {
                flex: 1;
                color: @uniper-blue;
                justify-content: center;
                align-items: center;
                font-size: 2rem;
                transition: all 0.5s;
                &.active {
                    background-color: @uniper-blue;
                    color: @white;
                    border-radius: 2rem;
                }
            }
        }
    }
    .btn {
        width: 20rem;
        height: 3rem;
        background-color: @uniper-blue;
        color: @white;
        border-radius: 0.5rem;
        border: 1px solid @uniper-blue;
        font-size: 1.5rem;
        text-transform: uppercase;
        white-space: nowrap;
        margin: 0.5rem;
    }
}
.flex-align-end {
    align-items: flex-end;
}
.flex-align-center {
    align-items: center;
}
.page-content {
    height: calc(100vh - 17rem);
    overflow-x: scroll;
}
/deep/ .adam-table {
    .table__header-cell, .table__body-cell, .table__body-cell-action {
        &:nth-child(1) {
            flex: 0.2 0 15%;
        }
        &:nth-child(2) {
            flex: 0.2 0 15%;
        }
        &:last-child {
            flex: 1 1 100%;
        }
    }
}

</style>
