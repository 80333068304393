<template>
  <div>
    <select
      class="select-dropdown"
      :tabindex="tabindex"
      @change="onChange()"
      v-model="selected"
    >
        <option v-for="(option, i) of options" :value="option" :key="i">
          <div class="flex-row flex-1">
            <div class="flex-col flex-1">
              {{ option.asset.name }}
            </div>
            <div class="flex-col flex-1">|</div>
            <div class="flex-col flex-2">
              {{ formatDateValue(option.calcualtionDate) }}
            </div>
          </div>
        </option>
    </select>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component({
    name: 'static-report-dropdown',
})
export default class StaticReportDropdown extends Vue {
  /**
   * Set the list of option/item to be displayed.
   */
  @Prop({ required: true })
  private options!: any[];

  /**
   * Set the default option/item to select.
   */
  @Prop({ required: false, default: null })
  private default!: any | null;

  /**
   * Set the key of the item object to be displayed against each option.
   */
  @Prop({ required: false, default: '' })
  private displayKey!: string;

  /**
   * Set the tab index to focus.
   */
  @Prop({ required: false, default: 0 })
  private tabindex!: number;

    private selected = this.default
        ? this.default
        : this.options.length > 0
            ? this.options[0]
            : null;
    private open = false;
    private mounted(): void {
        this.$emit('input', this.selected);
    }

    private formatDateValue(date: Date): string {
        return moment(date).format('DD MMM YYYY HH:mm');
    }

    private onChange(): void {
        this.$emit('input', this.selected);
    }
}
</script>

<style scoped lang="less">
@import "../../variables.less";
.select-dropdown {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    line-height: 3.5rem;
    z-index: 10;
    font-size: 1.2rem;
    font-weight: bolder;
}
.select-dropdown .selected {
    background-color: @white;
    color: @dark-grey;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.5rem;
}
.select-dropdown .selected:after {
    position: absolute;
    content: "";
    top: 50%;
    right: 1em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: @uniper-blue-light transparent transparent transparent;
}
.select-dropdown .items {
    color: @dark-grey;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    background-color: white;
    position: absolute;
    background-color: @white;
    left: 0;
    right: 0;
    height: calc(100vh - 15rem);
    overflow-y: scroll;
}
.select-dropdown .items div {
    color: @dark-grey;
    cursor: pointer;
}
.select-dropdown .items div:hover {
    background-color: @uniper-blue-light;
}
.selectHide {
    display: none;
}

</style>
