<template>
    <div class="sa">
        <div v-if="!dataloaded">{{ $t('loading') }}</div>
        <div v-if="dataloaded && !isNewUi" class="flex-row flex-3 mb-1">
            <div class="flex-col flex-1">
                <div class="flex-row flex-2 mt-1 mb-1">
                    <div class="flex-col flex-2 label flex-justify-center">
                        <div v-if="isGasStorageLabel">{{ $t('maintainenceRiskScheduling') }}:</div>
                        <div v-else>{{ $t('nomination') }}:</div>
                    </div>
                    <div class="flex-col flex-6 flex-justify-center pb-1" id="nominationComment">
                        {{sap.nominationComment}}
                    </div>
                </div>
                <div class="flex-row flex-2 mb-1">
                    <div class="flex-col flex-2 label flex-justify-center">{{ $t('opportunities') }}:</div>
                    <div class="flex-col flex-6 flex-justify-center pb-1" id="opportunitiesComment">
                        {{sap.opportunitiesComment}}
                    </div>
                </div>
                <div class="flex-row flex-2 mb-1">
                    <div class="flex-col flex-2 label flex-justify-center">{{ $t('linksToOtherUniperPnLs') }}:</div>
                    <div class="flex-col flex-6 flex-justify-center pb-1" id="linksToOtherUniperPnlsComment">
                        {{sap.linksToOtherUniperPnlsComment}}
                    </div>
                </div>
                <div class="flex-row flex-2 mb-1">
                    <div class="flex-col flex-2 label flex-justify-center pb-1">{{ $t('other') }}:</div>
                    <div class="flex-col flex-6" id="otherComment">
                        {{sap.otherComment}}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="dataloaded && isNewUi">
            <dl>
                <dt><b>
                       <div v-if="isGasStorageLabel">{{ $t('maintainenceRiskScheduling') }}:</div>
                       <div v-else>{{ $t('nomination') }}:</div>
                    </b></dt>
                <dd>
                    <br/> 
                    {{ sap.nominationComment }}
                </dd>
                <br/>
                <dt><b>{{ $t('opportunities') }}:</b></dt>
                <dd>
                    <br/> 
                    {{ sap.opportunitiesComment }} 
                </dd>
                <br/>
                <dt><b>{{ $t('linksToOtherUniperPnLs') }}:</b></dt>
                <dd>
                    <br/>
                    {{ sap.linksToOtherUniperPnlsComment }}
                </dd>
                <dt class="sub-title"><b>{{ $t('other') }}:</b></dt>
                <dd>
                    <br/>
                    {{ sap.otherComment }}
                </dd>
            </dl>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SAP } from '@/models';

@Component
export default class SupportingAsset extends Vue {
    /**
     * Set to enable new UI view.
     */
    @Prop({default: false})
    private isNewUi!: boolean;

    /**
     * Set the supporting asset data.
     */
    @Prop({ required: true })
    private sap!: SAP;

    private dataloaded = false;
    private fleetName = '';
    private isGasStorageLabel = false;

    private mounted(): void {
        this.changeLabel();
        this.dataloaded = true;
    }

    private changeLabel(): void {
        this.fleetName = this.sap.fleetName;
        if (this.fleetName === 'Gas Storage') {
            this.isGasStorageLabel = true;
        }
    }

    @Watch('sap')
    private updateComponent(newSAP: SAP, oldSAP: SAP): void {
        if (newSAP && newSAP !== oldSAP) {
            this.dataloaded = true;
            this.changeLabel();
        } else {
            this.dataloaded = false;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../../variables.less';
.sa{
    white-space: pre-line;
    .component-header{
      font-weight: bold;
      font-size: 1rem;
    }
    .flex-justify-center{
        justify-content: center;
    }
    .bb{
        border-bottom: 1px solid @dark-grey-darker;
    }
    .bt{
        border-top: 1px solid @dark-grey-darker;
    }
    .sub-title {
        padding-top: 1.5rem;
    }
}
</style>
