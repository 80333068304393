<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import ContentWrapper from '@/components/content-wrapper/content-wrapper.vue';
import {KpiMapping} from '@/models/kpi-mapping';
import {KpiDto} from '@/models/kpi-dto';
import {KpiMappingsService, KpiMappingUpdateData} from '@/services/kpi-mappings-service';
import BulkUpdateUpload from '@/components/comment-edit/bulk-update/bulk-update-upload.vue';
import {toastModuleDisplayError} from '@/store/toastModule';

@Component({name: 'kpi-mappings',
    components: {BulkUpdateUpload, ContentWrapper}
})
export default class KpiMappings extends Vue
{
    @Prop()
    private mapping: KpiMapping | undefined;

    @Prop()
    private kpis: KpiDto[] | undefined;

    private kpiMappingService = new KpiMappingsService();
    private chartType: { type: string, allowKpiSelection: boolean, numKpis: number } = { type: 'SingleKpi', allowKpiSelection: true, numKpis: 1 };

    private selectedKpi1 = 0;
    private selectedKpi2 = 0;
    private comment = '';

    private chartTypeConfigs = [
        // { type: 'Unset', allowKpiSelection: false, numKpis: 0 }, // Currently not possible to be changed by user
        { type: 'SingleKpi', allowKpiSelection: true, numKpis: 1 },
        // { type: 'DoubleKpi', allowKpiSelection: true, numKpis: 2 }, // Currently not possible to be changed by user
        // { type: 'PerformanceHeatmap', allowKpiSelection: true, numKpis: 1 }, // Currently not possible to be changed by user
        { type: 'PerformanceBenchmark', allowKpiSelection: false, numKpis: 0 }
    ]
    private validationErrors: string[] = [];

    private mounted(): void {
        if(this.mapping) {
            const selectedType = this.chartTypeConfigs.find((v) => { return  v.type == this.mapping?.chartType; } );
            if (selectedType) {
                this.chartType = selectedType;
            }
            if(this.mapping.kpis[1]) {
                this.selectedKpi1 = this.mapping.kpis[1].id;
            }
            if(this.mapping.kpis[2]) {
                this.selectedKpi2 = this.mapping.kpis[2].id;
            }
        }
    }

    private async saveChanges(): Promise<void> {
        if(!this.validate()) {
            return;
        }
        if(this.mapping) {
            const newMapping: KpiMappingUpdateData = {
                chartType: this.chartType.type,
                comment: this.comment,
                kpis: []
            };

            if(this.chartType.numKpis > 0) {
                newMapping.kpis.push({id: this.selectedKpi1})
            }
            if(this.chartType.numKpis > 1) {
                newMapping.kpis.push({id: this.selectedKpi2})
            }
            try {
                await this.kpiMappingService.updateKpiMapping(this.mapping.hierarchySid, newMapping);
                this.$emit('close',{ reason: 'save' });
            } catch (e) {
                await this.$store.dispatch(toastModuleDisplayError, e);
            }
        }
    }

    private chartTypeChanged(): void {
        this.validationErrors = [];
        this.selectedKpi1 = 0;
        this.selectedKpi2 = 0;
    }

    private validate(): boolean {
        this.validationErrors = [];
        if(this.chartType.numKpis > 0 && this.selectedKpi1 === 0) {
            this.validationErrors.push(`${this.$t('kpi1')} ${this.$t('required')}`);
        }
        if(this.chartType.numKpis > 1 && this.selectedKpi2 === 0) {
            this.validationErrors.push(`${this.$t('kpi1')}  ${this.$t('required')}`);
        }
        if(this.chartType.numKpis > 1 && this.selectedKpi1 === this.selectedKpi2) {
            this.validationErrors.push(this.$t('kpisMustNotBeEqual').toString());
        }
        if(this.comment.length > 250) {
            this.validationErrors.push(this.$t('commentTooLong').toString());
        }
        if(this.comment.length === 0) {
            this.validationErrors.push(`${this.$t('comment')} ${this.$t('required')}`);
        }
        return this.validationErrors.length === 0;
    }
}
</script>

<template>
    <div class="">
        <div class="form-controls pr-1 pl-1 pb-2">
            <div class="alert alert-error" v-if="validationErrors.length > 0">
                <ul>
                    <li v-for="(error, i) in validationErrors" :key="i">{{ error }}</li>
                </ul>
            </div>
            <div class="form-group">
                <label class="form-label" for="chartType">{{ $t('chartType') }}</label>
                <select class="form-control" id="chartType" v-model="chartType" @change="chartTypeChanged()">
                    <option v-for="option in chartTypeConfigs" :key="option.type" :value="option">
                        {{ $t('chartTypes.' + option.type) }}
                    </option>
                </select>
            </div>
            <div v-if="chartType.numKpis == 0">
                <label for="kpi1">{{ $t('kpi1') }}</label>
                <div class="alert alert-info">
                    {{ $t('kpiSelectionNotAvailableForThisChartType') }}
                </div>
            </div>
            <div v-if="chartType.numKpis > 0" class="form-group">
                <label for="kpi1">{{ $t('kpi1') }}</label>
                <select class="form-control" id="kpi1" v-model="selectedKpi1">
                    <option disabled value="0">{{ $t('emptyOption') }}</option>
                    <option v-for="option in kpis" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
            <div v-if="chartType.numKpis > 1" class="form-group">
                <label for="kpi2">{{ $t('kpi2') }}</label>
                <select class="form-control" id="kpi2" v-model="selectedKpi2">
                    <option disabled value="0">{{ $t('emptyOption') }}</option>
                    <option v-for="option in kpis" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label for="comment">{{ $t('comment') }}</label>
                <textarea class="form-control" id="comment" v-model="comment"></textarea>
            </div>
        </div>
        <div class="flex-row footer-container">
            <button class="btn btn-default mr-2" @click="$emit('close', { reason: 'cancel' })">
                Cancel
            </button>
            <button class="btn btn-default" @click="saveChanges()">
                Save
            </button>
        </div>
    </div>
</template>

<style scoped lang="less">
select.form-control {
    width: 100%;
}
textarea.form-control {
    width: 100%;
    box-sizing: border-box;
    resize: none;
}
.footer-container {
    background-color: var(--adam-bg-modal);
    box-shadow: var(--adam-elev);
    min-height: var(--adam-d48);
    padding-left: var(--adam-d32);
    padding-right: var(--adam-d32);
    margin-left: -30px;
    margin-bottom: -24px;
    margin-right: -30px;
    align-items: center;
    justify-content: end;
}
.form-group {
    margin-bottom: 10px;
}
</style>
