import { CommercialSustainability, ApiResponse } from '@/models';
import BaseService from '@/utils/base-service';

export class CsaDataService extends BaseService {
    private endpoint = '/resultsets/csa';
    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async get(sno: number): Promise<ApiResponse<CommercialSustainability>> {
        return (await this.api.get(`${this.endpoint}/${sno}`)).data;
    }

    public async saveCSAData(sno: number, values: CommercialSustainability):
    Promise<ApiResponse<CommercialSustainability>> {
        return (await this.api.post(`${this.endpoint}/${sno}`, values)).data;
    }
}
