<template>
<div>
    <div  v-for="(notification, index) of notifications"
          class="toast-overflow"
          :style="{ bottom: getNotificationPosition(index) }"
          :key="notification.id">
        <toast :notification="notification"></toast>
    </div>
</div>
</template>
<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import Vue from 'vue';
import Toast from '@/components/notifications/toast.vue';

@Component({
    name: 'notifications',
    components: {
        Toast
    },
    computed: {
        ...mapState('toastModule', ['notifications'])
    }
})
export default class Notifications extends Vue {
    firstNotificationBottomPosition = 0;
    notificationVerticalSpace = 80;

    getNotificationPosition(notificationIndex: number): string {
        return `${notificationIndex * this.notificationVerticalSpace + this.firstNotificationBottomPosition}px`;
    }
}
</script>
<style scoped>
.toast-overflow {
    position: fixed;
    right: 30px;
    z-index: 9999;
    transition: all 1s ease-in-out;
}
</style>
