import { KpiResult, ApiResponse } from '@/models';
import BaseService from '@/utils/base-service';

export class PerformanceAssessmentService extends BaseService {
    private endpoint = '/resultsets/performance';

    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async get(sno: number): Promise<ApiResponse<KpiResult>> {
        return (await this.api.get(`${this.endpoint}/${sno}`)).data;
    }

    public async savePerData(sno: number, values: KpiResult):
    Promise<ApiResponse<KpiResult>> {
        return (await this.api.post(`${this.endpoint}/${sno}`, values)).data;
    }
}
