<template>
    <!-- 1st aproach (start) ------------------>
    <div class="home">
        <div class="top-fixed">
            <Navbar />
            <LinkBar />
        </div>
        <div class="flex-row content">
            <div class="flex-col flex-1 mb-3">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Navbar from '../header/navbar.vue';
import LinkBar from '../link-bar/link-bar.vue';

@Component({
    name: 'home',
    components: {
        Navbar,
        LinkBar,
    },
})
export default class Home extends Vue {
}
</script>

<style lang="less">
.home{
    .top-fixed{
        top: 0;
        right: 0;
        width: 100%;
        position: fixed;
        z-index: 99;
    }
    > .content{
        top: 9rem;
        position: relative;
    }
}
</style>
