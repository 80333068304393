<template>
    <div class="flex-container signoff">
        <modal-box v-if="showModal"
            :open="showModal"
            :onClose.prop="closeModal"
            :enableHeader.prop="true"
            :enableFooter.prop="true"
            :enableHeaderClose.prop="true"
            :transparentOverlay.prop="false"
            :closeOnClickOutside.prop="true"
            width='1080px'
        >
            <span slot="header" class="header">
                <div class="flex-row flex-1">
                    <div class="flex-col">
                        <h3>{{ asset.name }}</h3>
                    </div>
                    <span class="separator ml-6 mr-4 mt-1"></span>
                    <div class="flex-col">
                        <h3>{{ $t('updateDate') }}</h3>
                    </div>
                </div>
            </span>
            <span>
                <div class="flex-col edit-ribbon">
                    <div class="mt-3 ribbon">
                        <label for="ribbonValue" class="ribbon-label">{{ $t('value') }}*</label>
                        <input id="ribbonValue"
                            class="ribbon-input"
                            :placeholder="$t('value')"
                            v-model="dateRibbonText"
                        >
                    </div>
                    <div class="mt-3 ribbon">
                        <label for="ribbonVersion" class="ribbon-label">{{ $t('version') }}*</label>
                        <input id="ribbonVersion"
                            class="ribbon-input"
                            :placeholder="$t('version')"
                            v-model="dateRibbonValue"
                        >
                    </div>
                    <div :class="errorExists ? 'error-text': ''">
                        <div v-if="errorExists && valueIsEmpty">{{ $t('thisFieldIsMandatory') }}</div>
                    </div>
                    <div class="mt-3 ribbon">
                        <label for="ribbonReason" class="ribbon-label">{{ $t('reason') }}*</label>
                        <input id="ribbonReason"
                            class="ribbon-input"
                            :placeholder="$t('reason')"
                            v-model="updComment"
                        >
                    </div>
                    <div :class="errorExists ? 'error-text': ''">
                        <div v-if="errorExists && commentIsEmpty">{{ $t('thisFieldIsMandatory') }}</div>
                    </div>
                </div>
                <br/>
            </span>
            <span slot="footer" class="footer">
                <div class="flex-row reverse">
                    <div class="flex-col flex-align-end">
                        <button class="btn mt-1" @click="save()">
                            {{ $t('save') }}
                        </button>
                    </div>
                </div>
            </span>
        </modal-box>
        <div class="flex-row flex-1">
            <div class="flex-col flex-1 rotate">
                <div class="flex-row rotate-content redFont textnowrap">{{ datesGeneralStrategyobj.dateRibbonText }}</div>
            </div>
            <div class="flex-col flex-1 rotate">
                <div class="flex-row rotate-content redFont textnowrap">
                    {{ datesGeneralStrategyobj.dateRibbonValue }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import ComponentSecurity from '@/mixins/component-security';
import { Asset, DateRibbonModel } from '@/models';
import { DatesGeneralStrategyService } from '@/services';
import { EventBus } from '@/utils';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';

@Component
export default class SignOff extends Mixins(ComponentSecurity) {
    /**
     * Open modal box for editing.
     */
    @Prop({ default: false })
    private openModal!: boolean;

    /**
     * Asset details for pop-up.
     */
    @Prop({ required: true })
    private asset!: Asset;

    /**
     * Serial number of the report.
     */
    @Prop({ required: true })
    private serialNumber!: number;

    /**
     * New UI flag.
     */
    @Prop({ default: true })
    private isNewUi!: boolean;

    private datesGeneralStrategyobj: any = {};
    private showModal = false;
    private errorExists = false;
    private commentIsEmpty = false;
    private valueIsEmpty = false;
    private dateRibbonValue = '';
    private dateRibbonText = '';
    private dateCompnentService: DatesGeneralStrategyService = new DatesGeneralStrategyService();
    private dateRibbonModel: DateRibbonModel = {
        id: 0,
        dateRibbonText: '',
        dateRibbonValue: '',
        updateComment: '',
    };
    private updComment = '';

    @Watch('openModal')
    private editDateRibbon(): void {
        if (this.openModal && this.hasEditAccess && this.isNewUi) {
            this.dateRibbonText = this.datesGeneralStrategyobj.dateRibbonText;
            this.dateRibbonValue = this.datesGeneralStrategyobj.dateRibbonValue;
            this.showModal = true;
            this.updComment = '';
        } else {
            this.closeModal();
        }
    }

    @Watch('serialNumber')
    private updateComponent(
        newSelection: number,
        oldSelection: number): void {
        if (newSelection && newSelection !== oldSelection) {
            this.getDateGeneralStrategy(newSelection);
        }
    }

    private mounted(): void {
        this.getDateGeneralStrategy(this.serialNumber);
    }

    private async getDateGeneralStrategy(selection: number): Promise<void> {
        this.datesGeneralStrategyobj = (await this.dateCompnentService.get(selection)).result;
    }

    private clearErrors(): void {
        this.errorExists = false;
        this.commentIsEmpty = false;
        this.valueIsEmpty = false;
    }

    private closeModal(): void {
        this.showModal = false;
        this.clearErrors();
        this.$emit('close-modal', false);
    }

    private async save(): Promise<void> {
        this.clearErrors();
        if (!this.updComment.trim().length) {
            this.errorExists = true;
            this.commentIsEmpty = true;
        }
        if (!this.dateRibbonValue.trim().length) {
            this.errorExists = true;
            this.valueIsEmpty = true;
        }
        if (!this.errorExists) {
            this.clearErrors();
            this.dateRibbonModel.id = this.serialNumber;
            this.dateRibbonModel.dateRibbonText = this.dateRibbonText;
            this.dateRibbonModel.dateRibbonValue = this.dateRibbonValue;
            this.dateRibbonModel.updateComment = this.updComment;
            const dateRibbon = await this.dateCompnentService.saveDateRibbonData(this.dateRibbonModel);
            EventBus.$emit(EventBus.REPORT.UPDATE, dateRibbon.result.id);
            this.closeModal();
        }
    }

}

</script>

<style scoped lang="less">
@import '../../variables.less';
.signoff {
    height: 100%;
    .separator {
        width: 2px;
        height: 4rem;
        margin-left: 2rem;
        margin-right: 2rem;
        background: #E0E0E0;
        display: inline-block;
        vertical-align: middle;
    }
    .rotate {
        vertical-align: center;
        text-align: center;
        writing-mode: vertical-lr;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
        .rotate-content {
            transform: rotate(180deg);
        }
    }
    .redFont {
        color: @medium-red;
        font-stretch: extra-expanded;
    }
    .textnowrap {
        font-size: 1.8rem;
        white-space: nowrap;
    }
    .edit-ribbon {
        height: calc(100% - 11.5rem);
        overflow: hidden;
        overflow-y: auto;
        label {
            margin-top: 1rem;
            min-width: fit-content;
        }
        input {
            width: 100%;
        }
        .ribbon {
            position: relative;
            label {
                position: absolute;
                background: white;
                left: 1.8rem;
                top: -2.1rem;
                padding: 0 1rem;
                font-size: 1.6rem;
                z-index: 1;
            }
            .ribbon-input {
                display: block;
                margin: 0 auto;
            }
            input,
            select,
            textarea {
                box-sizing: border-box;
                font-size: 1.8rem;
                padding: 1rem 2.5rem;
                border-radius: @border-radius-standard;
                border: 2px solid @dark-grey;
                transition: 0.2s ease-in;
                &:focus {
                    border: 2px solid @uniper-blue;
                    transition: 0.2s ease-in;
                    outline: none;
                }
                &.invalid {
                    border: 2px solid @red-darker;
                    box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
                    transition: 0.1s ease-in;
                }
                &.full-width {
                    width: 100%;
                }
            }
            select {
                padding: 1rem 2.2rem;
                &.invalid {
                    border: 2px solid @red-darker;
                    box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
                    transition: 0.1s ease-in;
                }
            }
        }
        .footer,
        .header {
            line-height: var(--adam-d48)
        }
        .error-text{
            color: @contextual-red;
        }
    }
    .btn {
        width: auto;
        height: 3rem;
        background-color: @uniper-blue;
        color: @white;
        border-radius: 0.5rem;
        border: 1px solid @uniper-blue;
        font-size: 1.5rem;
        text-transform: uppercase;
        white-space: nowrap;
        margin: 0.5rem;
    }
}
</style>
