<template>
    <div class="row-card">
        <div class="flex-row header flex-1 mr-1 ml-1 pl-1 pt-1 pr-1 pb-1">
            <div v-show="!isHeader" class="flex-col">
                <div class="settings-icon">
                    <font-awesome-icon :icon="['fas', 'cogs']" />
                </div>
            </div>
            <div class="flex-col flex-4 flex-justify-center ml-4 mr-5 pr-4 pl-2">
                <slot name="title"></slot>
            </div>
            <span class="separator ml-6 mr-4"></span>
            <div class="flex-col flex-justify-center flex-3">
                <slot name="value"></slot>
            </div>
            <!-- <span class="separator ml-4 mr-4"></span>
            <div class="flex-col flex-justify-center flex-3">
                <slot name="comment"></slot>
            </div> -->
            <span class="separator ml-4 mr-4"></span>
            <div class="flex-col flex-justify-center flex-1">
                <slot name="buttons"></slot>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'row-card',
})
export default class RowCard extends Vue {
    @Prop({default: false})
    private isHeader!: boolean;
}
</script>

<style lang='less'>
@import '../../variables.less';
.row-card {
    font-family: Roboto-Medium, sans-serif;
    font-size: 2rem;
    width: 100%;
    // height: calc(100% - 5rem);
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 1rem;
    .settings-icon{
        // width: 20px;
        z-index: 5;
        position: absolute;
        margin-left: -3rem;
        padding: 1rem;
        background: @uniper-blue;
        color: @white;
        font-size: 2rem;
        border-radius: 5px;
    }
    .flex-justify-center{
        justify-content: center;
    }
    .flex-align-center{
        align-items: center;
    }
    .flex-row.header{
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
        box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.22);
        background-color: #fff;
    }
    .separator {
        width: 2px;
        height: 4rem;
        background: #E0E0E0;
        display: inline-block;
        vertical-align: middle;
    }
}
</style>
