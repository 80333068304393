import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Reporting from '../components/Reporting.vue';
import CSAEdit from '../components/csa/csa-edit.vue';
import Comments from '../components/comment-edit/Comments.vue';
import Home from '../components/home/home.vue';
import ConfigValue from '../components/configuration/configurationValue.vue';
import DecisionModel from '../components/decision-model/decision-model.vue';
import ChangeLog from '../components/change-log/change-log.vue';
import Report from '../components/final-report/report.vue';
import StaticReport from '../components/final-report/static-report.vue';
import LineOfSight from '../components/line-of-sight/line-of-sight.vue';
import StaticReportEdit from '../components/final-report/static-report-edit/static-report-edit.vue';
import KpiMappings from '@/components/kpi-mapping/kpi-mapping.vue';
import DataManagement from '@/components/data-management/data-management.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: '/report',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuthentication: true,
            permission: 'CanViewReport',
        },
        children: [
            {
                path: '/csa/edit',
                name: 'csaedit',
                component: CSAEdit,
                props: true,
                meta: {
                    requiresAuthentication: true,
                    permission: 'CanViewReport',
                },
            },
            {
                path: '/comment',
                name: 'comment',
                component: Comments,
                meta: {
                    requiresAuthentication: true,
                    permission: 'CanEditReport',
                },
            },
            {
                path: '/report',
                name: 'Report',
                component: Reporting,
                meta: {
                    requiresAuthentication: true,
                    permission: 'CanViewReport',
                },
            },
            {
                path: '/final-report',
                name: 'final-report',
                component: Report,
                meta: {
                    requiresAuthentication: true,
                    permission: 'CanViewReport',
                },
            },
            {
                path: '/configuration',
                name: 'configuration',
                component: ConfigValue,
                meta: {
                    requiresAuthentication: true,
                    permission: 'CanEditReport',
                },
            },
            {
                path: '/kpi-mappings',
                name: 'kpi-mappings',
                component: KpiMappings,
                meta: {
                    requiresAuthentication: true,
                    permission: 'CanEditReport',
                },
            },
            {
                path: '/data-management',
                name: 'data-management',
                component: DataManagement,
                meta: {
                    requiresAuthentication: true,
                    permission: 'CanEditReport',
                },
            },
            {
                path: '/decision',
                name: 'decision',
                component: DecisionModel,
                props: true,
                meta: {
                    requiresAuthentication: true,
                    permission: 'CanEditReport',
                },
            },
            {
                path: '/changelog',
                name: 'changelog',
                component: ChangeLog,
                meta: {
                    requiresAuthentication: true,
                    permission: 'CanEditReport',
                },
            },
            {
                path: '/line-of-sight',
                name: 'lineOfSight',
                component: LineOfSight,
                meta: {
                    requiresAuthentication: true,
                },
            },
            {
                path: '/static-link-edit',
                name: 'static-link-edit',
                component: StaticReportEdit,
                meta: {
                    requiresAuthentication: true,
                },
            },
        ],
    },
    {
        path: '/static-report',
        name: 'static-report',
        component: StaticReport,
        meta: {
            requiresAuthentication: true,
            permission: 'CanViewReport',
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;

