<template>
  <div class="content-wrapper">
    <div v-if="filterRequired" class="first-row">
      <slot name="filters"></slot>
    </div>
    <div class="second-row">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'content-wrapper',
})
export default class ContentWrapper extends Vue {
  /**
   * Set the value to display filter slot
   */
  @Prop({ default: true })
  private filterRequired!: boolean;
}
</script>

<style lang="less">
@import "../../variables.less";
.first-row {
  top: 8.9rem;
  position: fixed;
  background-color: @white;
  border-bottom: 0.1rem solid @grey-light;
  color: @dark-grey;
  width: 100%;
  height: auto;
  padding: 5px;
  margin-bottom: 30px;
  left: 0;
  right: 0;
  z-index: 11;
  font-size: 0.8rem;
  font-family: Roboto-Regular, sans-serif;
}
.second-row {
  top: 8rem;
  position: relative;
  // height: 300px;
  left: 0;
  right: 0;
  // overflow: scroll;
  // border: 1px solid red;
}
</style>