import { DirectiveOptions } from 'vue';
const clickOutside: DirectiveOptions = {
    bind: (el: any, binding) => {
        el.event = (event: any): void => {
            if (!el.contains(event.target)) {
                binding.value(event);
            }
        };
        document.body.addEventListener('click', el.event);
    },
    unbind: (el: any) => {
        document.body.removeEventListener('click', el.event);
    },
};

export default clickOutside;
