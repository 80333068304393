<template>
    <div id="app">
        <router-view></router-view>
        <notifications></notifications>
    </div>
</template>
<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import './main.less';
import ComponentSecurity from '@/mixins/component-security';
import { sdk } from '@/utils/coode-sdk';
import notificationHub from './notification-hub';
import { BatchTaskInfo, BatchTaskState } from './models/batch-task-model';
import Notifications from '@/components/notifications/notifications.vue';
import { toastModuleDisplayError } from '@/store/toastModule';

@Component({
    name: 'app',
    components: {
        Notifications
    },
})
export default class App extends Mixins(ComponentSecurity) {
    private displayNotification = 'toastModule/displayNotification'

    private async beforeCreate(): Promise<void>
    {
        const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
        await sdk?.core.userAccessManagement.setPermissions(useCaseId);

        if(this.hasEditAccess) {
            this.registerTaskNotification();
            notificationHub.start();
        }
    }

    private errorCaptured(err: Error): boolean {
        this.$store.dispatch(toastModuleDisplayError, err);
        return false;
    }

    private registerTaskNotification() : void {
        sdk?.console.log('Registering task notifications');
        notificationHub.on('TaskStarted', (task: BatchTaskInfo) => {
            this.$store.dispatch(this.displayNotification, {
                title: this.$t('calculationStarted'),
                text: task.id,
                type: 'success'
            });
        });

        notificationHub.on('TaskCompleted', (task: BatchTaskInfo) => {
            if (task.state == BatchTaskState.Failed) {
                this.$store.dispatch(this.displayNotification, {
                    title: this.$t('calculationFailed'),
                    text: `${task.id}: ${task.errorMessage} Details: ${task.errorDetails}`,
                    type: 'error'
                });
            } else {
                this.$store.dispatch(this.displayNotification, {
                    title: this.$t('calculationCompleted'),
                    text: task.id,
                    type: 'success'
                });
            }
        });
    }
}
</script>

<style lang="less">
@import './variables.less';
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    font-size: 1.5rem;
}

small {
    font-size: 1rem;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: @white;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: @warm-grey;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: @medium-grey;
    width: 20px;
}
</style>
