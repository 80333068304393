import { CsaLabel, ApiResponse } from '@/models';
import BaseService from '@/utils/base-service';

export class CsaLabelService extends BaseService {
    private endpoint = '/uilabels/csa';
    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }

    public async get(): Promise<ApiResponse<CsaLabel>> {
        return (await this.api.get(`${this.endpoint}`)).data;
    }
}
