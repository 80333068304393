<template>
    <div class="navbar">
        <div class="flex-container">
            <adam-header-bar
                :userName.prop="user.name"
                :emailAddress.prop="user.email"
                :showNotifications.prop="false"
                @apps-click="clickDrawer"
                notifications=[]
            >
                <div slot="headerContent">
                    <div class="flex-col nav-links top-row-content">
                        <router-link to="/report" class="title pr-1">
                            <img
                                class="logo"
                                src="~adam.ui-core/assets/brand/logo-coode-white.svg"
                            >
                        </router-link>
                    </div>
                    <div class="separator"></div>
                    <div class="flex-col nav-links top-row-content">
                        {{ $t('easTitle') }}
                    </div>
                </div>
                <div slot="profileDetails">
                    <div class="flex-col profile-container">
                        <div class="profile-details">
                            <div class="pb-1">
                                <button type="button" @click="copyToken()" class="copy-token-button">
                                    {{ $t('copyToken') }}
                                    <font-awesome-icon icon="copy" />
                                </button>
                            </div>
                            <div class="pb-1">
                                <a :href="swaggerURL" target="_blank" class="swagger-link">
                                    {{ $t('openSwagger') }}
                                </a>
                            </div>
                            <div class="flex-col flex-3 pb-1">
                                <div class="version">{{ $t('version') }}&nbsp;{{ version }}</div>
                                <div class="version ">{{ $t('apiUrl') }}&nbsp;
                                    <a :href="apiUrl" class="swagger-link">
                                        {{ apiUrl }}
                                    </a>
                                </div>
                            </div>
                            <div>
                                <button type="button" @click="logOut()" class="logout-button">
                                    {{ $t('signOut') }}
                                </button>
                            </div>
                        </div>
                        <input type="text" class="copy-text" id="copyText">
                    </div>
                </div>
            </adam-header-bar>
             <app-drawer
                ref="appDrawer"
                :data.prop="applications"
                :labels.prop="getAppDrawerLabels"
                :on-click.prop="handleAppClick"
            >
                <span
                    slot="appLogo"
                    class="app-logo"
                >
                    <img src="~adam.ui-core/assets/brand/logo-coode-rgb.svg">
                    <img src="~adam.ui-core/assets/brand/logo-enerlytics-rgb.svg">
                </span>
            </app-drawer>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { Application } from '@/models';
import { AppService } from '@/services';
import { sdk } from '@/utils/coode-sdk';
import {toastModuleDisplayError} from '@/store/toastModule';

@Component({
    name: 'navbar',
    components: {
    },
})
export default class Navbar extends Vue {
    @Prop() private msg!: string;

    private isSignedIn = false;
    private appService: AppService = new AppService();
    private version: string | undefined = process.env.VUE_APP_VERSION;
    private apiUrl: string | undefined = process.env.VUE_APP_UI_BASE_URL;
    private swaggerURL: string | undefined = process.env.VUE_APP_API_SWAGGER_URL;
    private applications: MenuEntry[] = [];
    private scope = process.env.VUE_APP_EAS_SCOPE!;
    private user = {
        name: '',
        email: '',
    };

    private async mounted(): Promise<void> {
        this.loadApps();
        this.isSignedIn = sdk?.auth.isAuthenticated() as boolean;
        const profile: any = await sdk?.auth.getAccount();
        if (profile) {
            this.user.name = profile.name;
            this.user.email = profile.username;
        }
    }

    private async loadApps(): Promise<void> {
        this.$store.commit('loading');
        try {
            const apps = (await this.appService.getApps()).result.items;
            this.applications = this.processApps(apps);
        } catch (err) {
            this.$store.dispatch(toastModuleDisplayError, new Error(this.$t('errorLoadingApplications').toString()));
        } finally {
            this.$store.commit('loading');
        }
    }

    private processApps(apps: Application[]): MenuEntry[] {
        return apps.map((app: Application): MenuEntry => ({
            id: app.id.toString(),
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        })).sort((a: MenuEntry, b: MenuEntry) => {
            const nameA = a?.label?.toLowerCase();
            const nameB = b?.label?.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    private clickDrawer(): void {
        (this.$refs.appDrawer as any).open = true;
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url);
        }
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    get getAppDrawerLabels(): any {
        return { applications: this.$i18n.t('appsWithAccess'), miscellaneous: this.$i18n.t('appsWithoutAccess') };
    }

    private logOut(): void {
        sdk?.auth.logout();
    }
    private async copyToken(): Promise<void> {
        const textElement: any = document.getElementById('copyText');
        if (textElement) {
            const token = await sdk?.auth.getToken(this.scope);
            textElement.value = '';
            textElement.value = `Bearer ${token}`;
            textElement.select();
            document.execCommand('copy');
        } else {
            throw new Error('Copy text element is undefined');
        }
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';
.navbar{
    .header{
        color: @white;
    }
    .nav-link:hover{
        border-bottom: 2px solid rgb(255, 255, 255);
    }
    .profile-details {
        color: @dark-blue-grey;
        z-index: 10;
        font-size: 1.6rem;
        right: 0;
        .logout-button {
            height: 3.5rem;
            width: 100%;
            color: @dark-grey;
            background: @light-grey;
            transition: 0.2s ease-in;
            border-radius: 0;
            &:hover {
                transition: 0.2s ease-in;
                background: #E0E0E0;
            }
        }
        .copy-token-button {
            width: 100%;
            color: @dark-blue-grey;
            transition: 0.2s ease-in;
            box-shadow: none;
            padding: 0;
            :hover {
                transition: 0.2s ease-in;
            }
        }
        .swagger-link {
            color: @uniper-blue;
        }
    }

    .version {
        font-size: 1.3rem;
        margin: 0.3rem;
    }

    .logo {
        height: 3.8rem;
        margin: 0.5rem 0 0 0.5rem;
    }

    .separator {
        width: 0.2rem;
        height: 3rem;
        background: @dark-blue;
        display: inline-block;
        vertical-align: middle;
        margin: 0 1rem;
    }
}
</style>
