<template>
    <div class='run-calculation'>
        <div class="flex-container pl-2 pr-2">
            <modal-box
                v-if="shouldShowModalComponent"
                :open="shouldShowModalComponent"
                :onClose.prop="closeModal"
                :enableHeader.prop="true"
                :enableFooter.prop="true"
                :enableHeaderClose.prop="true"
                :transparentOverlay.prop="false"
                :closeOnClickOutside.prop="true"
                width="auto"
            >
                <span slot="header" class="header">
                    <div class="flex-row flex-1">
                        <div class="flex-col flex-1">
                            <h3>{{ $t('runCalculationForAssetsAndComponents') }}</h3>
                        </div>
                    </div>
                </span>
                <span>
                    <div v-if="shouldShowModalStatus">{{ $t('status') }} : {{ status }}</div>
                    <div v-else class="flex-row flex-1">
                        <div class="flex-col flex-1">
                            <label class="checkbox-container">
                                <div class="flex-row checkboxFilter mb-2">
                                    <input
                                        type="checkbox"
                                        v-model="isCheckAllAssets"
                                        @click="checkAllAssests()"
                                    />
                                    {{ $t("forAllAssestAndComponents") }}
                                    <span class="checkmark"></span>
                                </div>
                            </label>
                            <div class="flex-row checkboxFilter mb-2" style="font-weight: bolder">
                                {{ $t('runCalculationFor') }} {{ asset.name }}
                            </div>
                            <label class="checkbox-container">
                                    <div class="flex-row checkboxFilter">
                                    <input
                                        type="checkbox"
                                        v-model="isCheckAll"
                                        @click="checkAll()"
                                    />
                                    {{ $t("selectAll") }}
                                    <span class="checkmark"></span>
                                </div>
                            </label>
                            <label
                                class="checkbox-container"
                                v-for="(componentTitle, index) in componentList"
                                :key="index"
                            >
                                <div class="flex-row flex-4 checkboxFilter">
                                    <input
                                        type="checkbox"
                                        v-model="checkedComponents"
                                        @change="updateCheckall()"
                                        :value="componentTitle.id"
                                    />
                                    {{ componentTitle.component }}
                                    <span class="checkmark"></span>
                                </div>
                            </label>
                        </div>
                    </div>
                </span>
                <span slot="footer" class="footer">
                    <div class="flex-row reverse">
                        <div v-if="!shouldShowModalStatus" class="flex-col flex-align-end mt-1">
                            <button class="btn run-calculation" @click="run()" :disabled="disableRun()">
                                {{ $t("run") }}
                            </button>
                        </div>
                    </div>
                </span>
            </modal-box>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Asset } from '@/models';
import EasLoader from '../loader/eas-loader.vue';
import Dropdown from '../dropdown/dropdown.vue';
import SelectDropdown from '@/components/dropdown/select-dropdown.vue';
import ConfirmModal from '@/components/confirm-modal/confirm-modal.vue';
import { EventBus } from '@/utils';
import { BatchTaskService } from '@/services';

@Component({
    name: 'RunCalculation',
    components: {
        EasLoader,
        Dropdown,
        SelectDropdown,
        ConfirmModal,
    },
})
export default class RunCalculation extends Vue {
    /**
     * Assets for selection
     */
    @Prop({ required: true })
    private asset!: Asset;
    /**
     * Serial number of the report for status of batch task run
     */
    @Prop({ required: true })
    private serialNumber!: number;

    private isNewUi = true;
    private batchTaskService: BatchTaskService = new BatchTaskService();
    private batchTaskData: any;
    private shouldShowModalComponent = false;
    private shouldShowModalStatus = false;
    private status: string | null = '';
    private checkedComponents: string[] = [];
    private isCheckAllAssets = false;
    private isCheckAll = false;
    private componentList = [
        {id: 'csa', component: 'Commercial and Sustainability Assessment'},
        {id: 'performance', component: 'Performance Assessment (Key Indicator)'},
        {id: 'dates', component: 'Dates'},
        {id: 'general_strategy', component: 'General Strategy'},
        {id: 'mrp', component: 'Maintenance And RiskProcesses'},
        {id: 'sap', component: 'Supporting Asset Processes'},
        {id: 'amp', component: 'AM Process Overview'},
    ];

    private mounted(): void {
        EventBus.$on(EventBus.REPORT.RUN_CALCULATION,
            (shouldShowModalComponent: boolean) => this.openModal(shouldShowModalComponent));
    }

    @Watch('componentList.name')
    private updateComponent(): void {
        this.checkAll();
    }

    private async runCalculation(): Promise<void> {
        try {
            if (this.isCheckAllAssets && this.checkedComponents.length === 7) {
                const batchData = (await this.batchTaskService.post({})).result;
                this.status = batchData.status;
            }
            if ((this.checkedComponents.length === 0 || this.checkedComponents.length === 7)
                && !this.isCheckAllAssets) {
                const batchData = (await this.batchTaskService.post({
                    sNo: this.serialNumber,
                    hierarchySid: this.asset.id,
                    components: [],
                })).result;
                this.status = batchData.status;
            }
            if (this.checkedComponents.length >= 1 && this.checkedComponents.length <= 6) {
                const batchData = (await this.batchTaskService.post({
                    sNo: this.serialNumber,
                    hierarchySid: this.asset.id,
                    components: this.checkedComponents,
                })).result;
                this.status = batchData.status;
            }
        } catch (error) {
            alert(this.$i18n.t('somethingWentWrong'));
            const temp = await this.batchTaskService.post({
                sNo: 0,
                hierarchySid: 0,
                components: [],
            });
            this.status = temp.message;
        }
    }

    private openModal(shouldShowModalComponent: boolean): void {
        this.shouldShowModalComponent = shouldShowModalComponent;
    }

    private closeModal(): void {
        this.shouldShowModalComponent = false;
        this.shouldShowModalStatus = false;
        const emptyArray: string[] = [];
        this.checkedComponents = emptyArray;
        this.isCheckAll = false;
        this.isCheckAllAssets = false;
    }

    private async run(): Promise<void> {
        await this.runCalculation();
        this.shouldShowModalStatus = true;
    }

    private checkAll(): void {
        this.isCheckAll = !this.isCheckAll;
        this.checkedComponents = [];
        if (this.isCheckAll) {
            for (const key in this.componentList) {
                this.checkedComponents.push(this.componentList[key].id);
            }
        }
    }

    private updateCheckall(): void {
        this.isCheckAll = this.checkedComponents.length === this.componentList.length;
    }

    private checkAllAssests(): void {
        this.isCheckAll = false;
        if (this.isCheckAllAssets) {
            this.isCheckAll = true;
            this.updateCheckall();
        }
        this.checkAll();
    }

    private disableRun(): boolean {
        if (this.isCheckAllAssets) {
            return false;
        }
        return this.checkedComponents.filter((p) => p).length === 0;
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.REPORT.RUN_CALCULATION);
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';
.run-calculation {
    .header,
    .footer {
        line-height: var(--adam-d48);
        font-weight: bolder;
    }
    .ribbon-lebel {
        justify-content: flex-end;
        font-size: 1.5rem;
        font-weight: bold;
    }
    .final-button {
        justify-content: center;
        align-items: flex-end;
    }
    .btn {
        width: auto;
        height: 3rem;
        background-color: @uniper-blue;
        color: @white;
        border-radius: .5rem;
        border: 1px solid @uniper-blue;
        font-size: 1.2rem;
        text-transform: uppercase;
        white-space: nowrap;
    }
    .separator {
        width: 2px;
        height: 4rem;
        margin-left: 1rem;
        margin-right: 1rem;
        background: #E0E0E0;
        display: inline-block;
        vertical-align: middle;
    }
    .justify-end {
        justify-content: flex-end;
    };

    .goto-download {
        font-size: 1.5rem;
        color: @uniper-blue;
        font-weight: bold;
        text-align: center;
        align-items: center;
        cursor: pointer;
    }

    label {
        font-size: 1.3rem;
    }

    .toggler {
        width: 8rem;
        height: 3rem;
        border-radius: 1.5rem;
        border: 0.2rem solid @grey-light;
        background-color: @grey-lighter;
        cursor: pointer;
        color: @uniper-blue;
        font-weight: bold;
        .toggle-text {
            justify-content: center;
            align-items: center;
            padding: 1rem;
            font-size: 1.3rem;
            font-weight: bold;
        }
        .toggle-switch {
            width: 3rem;
            height: 2.9rem;
            border-radius: 1.5rem;
            background-color: @white;
        }
    }
    .checkbox-container {
      display: block;
      position: relative;
      padding-left: 3rem;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 1.6rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 2rem;
        width: 2rem;
        background-color: #fafafa;
        border: 1px solid #e6e6e6;
        border-radius: @border-radius-standard;
    }
    .checkbox-container:hover input ~ .checkmark {
        background-color: #f4f4f4;
    }
    .checkbox-container input:checked ~ .checkmark {
        background-color: @uniper-blue;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .checkbox-container input:checked ~ .checkmark:after {
        display: block;
    }
    .checkbox-container .checkmark:after {
        left: 0.6rem;
        top: 0.2rem;
        width: 0.5rem;
        height: 1rem;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .btn:disabled {
        color: @warm-grey;
        border: 1px solid @warm-grey;
        background: @pale-grey;
        cursor: auto;
    }
}
</style>
