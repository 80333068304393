<template>
    <div class="flex-container vribbon">
        <div :class="{
            'flex-row': true,
            'flex-1' : true,
            'gray-bg' : isNewUi,
            'handCursor' : hasAction,
        }" @click="clickAction">
            <div class="flex-col flex-1 rotate ribonvalue">
                <div class="flex-row rotate-content" id="ribbonValue">{{ ribbonValue }}</div>
            </div>
            <div class="flex-col flex-1 rotate blueBox whiteFont textnowrap">
                <div class="flex-row rotate-content" id="ribbonText">{{ ribbonText }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class VerticalRibbon extends Vue {
    /**
     * Text to show in vertical ribbon
     */
    @Prop({ required: true })
    private ribbonText!: string;

    /**
     * Value to show in vertical ribbon
     */
    @Prop({ required: true })
    private ribbonValue!: string;

    /**
     * Set to enable click action on vertical ribbon
     */
    @Prop({ default: false })
    private hasAction!: boolean;
    @Prop({default: ''})
    private actionString!: string;
    @Prop({default: false})
    private isNewUi!: boolean;

    private clickAction(): void {
        if (this.hasAction) {
            this.$emit('show-modal', true);
        }
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';
.vribbon{
    height: 100%;
    .rotate{
        vertical-align: center;
        text-align: center;
        writing-mode: vertical-lr;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.1rem;
    }
    .handCursor{
        cursor: pointer;
    }
    .gray-bg {
        .ribonvalue { 
            background-color: @grey-lighter;
            border-top-left-radius: @border-radius-standard;
            border-bottom-left-radius: @border-radius-standard;
        }
    }
    .tooltip {
        position: relative;
        display: inline-block;
        border: none;
    }

    .tooltip .tooltiptext {
        visibility: visible;
        width: 12rem;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 0.5rem 0;

        /* Position the tooltip */
        position: absolute;
        z-index: 5;
    }
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
    .blueBox{
        background-color: @uniper-blue-dark;
        border-bottom-right-radius: @border-radius-standard;
        border-top-right-radius: @border-radius-standard;
    }
    .whiteFont{
        color: @white;
        font-weight: bold;
        font-size: 1.8rem;
    }
    .ribonvalue{
        font-weight: bold;
        font-size: 1.8rem;
        font-stretch: ultra-expanded;
    }
    .textnowrap{
        white-space: nowrap;
    }
    .border{
        border: 0.1rem solid black;
    }
    .flex-justify-center{
        justify-content: center;
    }
}


</style>
