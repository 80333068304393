import { AMPModel, ApiResponse } from '@/models';
import BaseService from '@/utils/base-service';

export class AMPService extends BaseService {
    private endpoint = '/resultsets/amp';

    constructor() {
        super();
    }

    public async get(sNo: number): Promise<ApiResponse<AMPModel>> {
        return (await this.api.get(`${this.endpoint}/${sNo}`)).data;
    }
    public async saveAMPData(sNo: number, values: AMPModel): Promise<ApiResponse<AMPModel>> {
        return (await this.api.post(`${this.endpoint}/${sNo}`, values)).data;
    }
}
