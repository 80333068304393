import Vue from 'vue';

export const EventBus = new Vue({
    data(): any {
        return {
            GLOBAL: {
                SHOW_SNACKBAR: 'global.show-snackbar',
            },
            REPORT_MODAL: {
                EDIT: 'report_modal.edit',
                SAVE: 'report_modal.save',
                RESET: 'rport_modal.reset',
                CLOSE: 'report_modal.close',
            },
            REPORT: {
                UPDATE: 'report.update',
                SHOW_CONFIRMATION: 'report.confirmation',
                RUN_CALCULATION: 'report.run-calculation',
            },
            UI_TEXT: {
                UPDATE: 'uitext.update',
            },
            CONFIGURATION: {
                UPDATE: 'configuration.update',
            },
            LOS: {
                UPDATE: 'los.update',
            },
        };
    },
});
