<template>
    <div class='csa-new flex-container'>
        <eas-loader v-if="!dataloaded"></eas-loader>
        <div v-else class="csabody flex-col flex-1">
            <div class="flex-row flex-5 mb-1">
                <div :class="['flex-col', isPopup ? 'flex-3' : 'flex-4']">
                    <dl>
                        <dt>
                            <b>{{ csaLabels.commercialOutlookLabel }}:</b>
                        </dt>
                        <dd>
                            <br/>
                            <div class="flex-row flex-1">
                                <div class="flex-col flex-1 mr-1">
                                    <b>{{ csaLabels.commercialOutlookSubLabel1 }}: </b>
                                </div>
                                <div class="flex-col flex-4">
                                    <div v-if="isInEditMode"><input @keyup="updateComponentInRealTime()" maxlength="250" class="medium-input" v-model="csaData.commercialOutlookSubValue1" /></div>
                                    <div v-else>{{ csaData.commercialOutlookSubValue1 }}</div>
                                </div>
                            </div>
                            <div class="flex-row flex-1">
                                <div class="flex-col flex-1 mr-1">
                                    <b>{{ csaLabels.commercialOutlookSubLabel2 }}: </b>
                                </div>
                                <div class="flex-col flex-4">
                                    <div v-if="isInEditMode"><input @keyup="updateComponentInRealTime()" maxlength="250" class="medium-input" v-model="csaData.commercialOutlookSubValue2" /></div>
                                    <div v-else>{{ csaData.commercialOutlookSubValue2 }}</div>
                                </div>
                            </div>
                        </dd>

                        <dt> <b>{{ csaLabels.currentValueDriverLabel }}:</b> </dt>
                        <dd>
                            <br/>
                            <div v-if="isInEditMode" class="pre-formatted"><textarea @keyup="updateComponentInRealTime()" maxlength="500" v-model="csaData.currentValueDriver"></textarea></div>
                            <div v-else class="pre-formatted">{{ csaData.currentValueDriver }}</div>
                        </dd>

                        <dt> <b>{{ csaLabels.ltContractValueLabel }}:</b> </dt>
                        <dd>
                            <br/>
                            <div v-if="isInEditMode"><input @keyup="updateComponentInRealTime()" maxlength="250" v-model="csaData.ltContractValue" /></div>
                            <div v-else>{{ csaData.ltContractValue }}</div>
                        </dd>

                        <dt>
                            <b>{{ csaLabels.runningRegimeTrendLabel }}:</b>
                        </dt>
                        <dd>
                            <br/>
                            <div v-if="isInEditMode">
                                <textarea
                                        @keyup="updateComponentInRealTime()"
                                        maxlength="250"
                                        v-model="csaData.runningRegime"
                                        class="mb-2"
                                >
                                    </textarea>
                                <select @click="updateComponentInRealTime()" name="rtrend" v-model="csaData.runningTrend">
                                    <option value="Up">{{ $t('up') }}</option>
                                    <option value="Unchanged">{{ $t('unchanged') }}</option>
                                    <option value="Down">{{ $t('down') }}</option>
                                    <option value="No running forecasted">{{ $t('noRunningForecasted') }}</option>
                                </select>
                            </div>
                            <div v-else>
                                <div class="mb-1">{{ csaData.runningRegime }}</div>
                                <div v-if="csaData.runningTrend == 'Unchanged'"><font-awesome-icon :icon="['fas', 'arrow-right']" /></div>
                                <div v-else-if="csaData.runningTrend == 'Up'" class="uparrow"><font-awesome-icon :icon="['fas', 'arrow-up']" /></div>
                                <div v-else-if="csaData.runningTrend == 'Down'" class="downarrow"><font-awesome-icon :icon="['fas', 'arrow-down']" /></div>
                                <div v-else-if="csaData.runningTrend == 'No running forecasted'">{{ $t('noRunningForecasted') }}</div>
                            </div>
                        </dd>

                        <dt>
                            <b><p v-html="csaLabels.co2AbatementCostsLabel.replace('CO2', 'CO<sub>2</sub>')+':' "></p></b>
                        </dt>
                        <dd>
                            <div v-if="isInEditMode">
                                <input  @keyup="updateComponentInRealTime()" maxlength="250" class="small-input" v-model="csaData.co2AbatementCostsText1" /> :  <input @keyup="updateComponentInRealTime()" maxlength="200" class="small-input" v-model="csaData.co2AbatementCostsValue1" />
                                <br/>
                                <input @keyup="updateComponentInRealTime()" maxlength="250" class="small-input" v-model="csaData.co2AbatementCostsText2" /> :  <input @keyup="updateComponentInRealTime()" maxlength="200" class="small-input" v-model="csaData.co2AbatementCostsValue2" />
                            </div>
                            <div v-else>
                                <b>{{ csaData.co2AbatementCostsText1 }} :  {{ csaData.co2AbatementCostsValue1 }}</b>
                                <br/>
                                <b>{{ csaData.co2AbatementCostsText2 }} : {{ csaData.co2AbatementCostsValue2 }}</b>
                            </div>
                            <br/>
                            <b>{{ csaLabels.specificEmissionsLabel }}:</b>
                            <br/>
                            {{ csaData.specificEmissionsText }}
                            <br/>
                            <div v-if="isInEditMode">
                                <input @keyup="updateComponentInRealTime()" maxlength="50" type="number" class="small-input" v-model="csaData.specificEmissionsValue" />&nbsp; {{ csaLabels.specificEmissionsMeasureUnitLabel }}
                            </div>
                            <div v-else-if="csaData.specificEmissionsValue>0">
                                {{ csaData.specificEmissionsValue }} {{ csaLabels.specificEmissionsMeasureUnitLabel }}
                            </div>
                        </dd>
                        <div v-if="!isPopup">
                            <dt>
                                <b>{{ csaLabels.commentSustainabilityLabel }}:</b>
                            </dt>
                            <dd>
                                <br/>
                                {{ co2subscript(csaData.commentSustainabilityValue) }}
                            </dd>

                            <dt>
                                <b>{{ csaLabels.externalRisksLabel }}:</b>
                            </dt>
                            <dd>
                                <br/>
                                {{ csaData.externalRisksValue }}
                            </dd>

                            <dt>
                                <b>{{ csaLabels.pmaViewLabel }}:</b>
                            </dt>
                            <dd>
                                <br/>
                                {{ csaData.pmaViewValue }}
                            </dd>
                        </div>
                    </dl>
                </div>
                <div v-if="isPopup" class="flex-col flex-3">
                    <dl>
                        <dt>
                            <b>{{ csaLabels.commentSustainabilityLabel }}:</b>
                        </dt>
                        <dd>
                            <br/>
                            <div v-if="isInEditMode">
                                <textarea
                                    @keyup="updateComponentInRealTime()"
                                    maxlength="1000"
                                    v-model="csaData.commentSustainabilityValue"
                                >
                                </textarea>
                            </div>
                            <div v-else>{{ co2subscript(csaData.commentSustainabilityValue) }}</div>
                        </dd>

                        <dt>
                            <b>{{ csaLabels.externalRisksLabel }}:</b>
                        </dt>
                        <dd>
                            <br/>
                            <div v-if="isInEditMode">
                                    <textarea
                                        @keyup="updateComponentInRealTime()"
                                        maxlength="500"
                                        v-model="csaData.externalRisksValue"
                                    >
                                    </textarea>
                                </div>
                            <div v-else>{{ csaData.externalRisksValue }}</div>
                        </dd>

                        <dt>
                            <b>{{ csaLabels.pmaViewLabel }}:</b>
                        </dt>
                        <dd>
                            <br/>
                            <div v-if="isInEditMode">
                                <textarea
                                    @keyup="updateComponentInRealTime()"
                                    maxlength="1000"
                                    v-model="csaData.pmaViewValue"
                                >
                                </textarea>
                            </div>
                            <div v-else>{{ csaData.pmaViewValue }}</div>
                        </dd>
                        <div v-if="isInEditMode">
                            <dt>
                                <b>{{ $t('comment') }}:</b>
                            </dt>
                            <dd>
                                <br/>
                                <textarea
                                    @keyup="updateComponentInRealTime()"
                                    maxlength="250"
                                    :class="{ 'invalid': isCommentEmpty }"
                                    @focus="() => isCommentEmpty = false"
                                    v-model="updComment">
                                </textarea>
                            </dd>
                        </div>
                    </dl>
                </div>
                <div class="flex-col flex-4">
                    <div class="flex-row">
                        <div :class="{ 'flex-col': true, 'flex-1': true, 'border': isInEditMode }">
                            <div class="flex-row chart-title chart-title-fcf">{{ $t('freeCashFlow') }}</div>
                            <div class="flex-row">
                                <div :id="fcfChartId" class="fcf-chart"></div>
                            </div>
                            <div class="flex-row flex-1 flex-justify-center mb-2">
                                <div class="flex-col flex-align-end text-no-wrap pl-5">
                                    <span class="legend-p5-p95"><font-awesome-icon :icon="['fas', 'square']" /></span>
                                </div>
                                <div class="flex-col">P5 - P95</div>
                            </div>
                            <div v-if="isInEditMode" class="flex-row grey-bg">
                                <div class="flex-col flex-1 flex-justify-center"></div>
                                <div class="flex-col flex-1 mr-1">{{ csaData.yearLabels[0] }}</div>
                                <div class="flex-col flex-1 mr-1">{{ csaData.yearLabels[1] }}</div>
                                <div class="flex-col flex-1 mr-1">{{ csaData.yearLabels[2] }}</div>
                                <div class="flex-col flex-1 mr-1">{{ csaData.yearLabels[3] }}</div>
                                <div class="flex-col flex-1 mr-1">{{ csaData.yearLabels[4] }}</div>
                            </div>
                            <div v-if="isInEditMode" class="flex-row grey-bg">
                                <div class="flex-col flex-1 flex-justify-center">FCF</div>
                                <div class="flex-col flex-1 mr-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.fcfValues[0]"/></div>
                                <div class="flex-col flex-1 mr-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.fcfValues[1]"/></div>
                                <div class="flex-col flex-1 mr-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.fcfValues[2]"/></div>
                                <div class="flex-col flex-1 mr-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.fcfValues[3]"/></div>
                                <div class="flex-col flex-1 mr-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.fcfValues[4]"/></div>
                            </div>
                            <div v-if="isInEditMode" class="flex-row grey-bg">
                                <div class="flex-col flex-1 flex-justify-center">P5</div>
                                <div class="flex-col flex-1 mr-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.p5Values[0]"/></div>
                                <div class="flex-col flex-1 mr-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.p5Values[1]"/></div>
                                <div class="flex-col flex-1 mr-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.p5Values[2]"/></div>
                                <div class="flex-col flex-1 mr-1"><input class="extra-small-input" :value="$t('na')" readonly /></div>
                                <div class="flex-col flex-1 mr-1"><input class="extra-small-input" :value="$t('na')" readonly /></div>
                            </div>
                            <div v-if="isInEditMode" class="flex-row grey-bg">
                                <div class="flex-col flex-1 flex-justify-center">P95</div>
                                <div class="flex-col flex-1 mr-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.p95Values[0]"/></div>
                                <div class="flex-col flex-1 mr-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.p95Values[1]"/></div>
                                <div class="flex-col flex-1 mr-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.p95Values[2]"/></div>
                                <div class="flex-col flex-1 mr-1"><input class="extra-small-input" :value="$t('na')" readonly /></div>
                                <div class="flex-col flex-1 mr-1"><input class="extra-small-input" :value="$t('na')" readonly /></div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-row">
                        <div :class="{ 'flex-col': true, 'flex-1': true, 'border': isInEditMode }">
                            <div v-if="csaData.isCo2GraphRequired" class="flex-row chart-title chart-title-co2">
                                {{ $t('absoluteCo2Emissions') }}
                            </div>
                            <div v-if="csaData.isCo2GraphRequired" class="flex-row">
                                <div :id="co2ChartId" class="co2-chart"></div>
                            </div>
                            <div v-if="isInEditMode && csaData.isCo2GraphRequired" class="flex-row grey-bg">
                                <div class="flex-col flex-1 flex-justify-center"></div>
                                <div class="flex-col flex-1 mr-1">{{ csaData.yearLabels[0] }}</div>
                                <div class="flex-col flex-1 mr-1">{{ csaData.yearLabels[1] }}</div>
                                <div class="flex-col flex-1 mr-1">{{ csaData.yearLabels[2] }}</div>
                                <div class="flex-col flex-1 mr-1">{{ csaData.yearLabels[3] }}</div>
                                <div class="flex-col flex-1 mr-1">{{ csaData.yearLabels[4] }}</div>
                            </div>
                            <div v-if="isInEditMode && csaData.isCo2GraphRequired" class="flex-row grey-bg">
                                <div class="flex-col flex-1 flex-justify-center">CO2</div>
                                <div class="flex-col flex-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.absoluteCo2Values[0]"/></div>
                                <div class="flex-col flex-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.absoluteCo2Values[1]"/></div>
                                <div class="flex-col flex-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.absoluteCo2Values[2]"/></div>
                                <div class="flex-col flex-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.absoluteCo2Values[3]"/></div>
                                <div class="flex-col flex-1"><input maxlength="11" type="number" class="extra-small-input" @keyup="updateCharttInRealTime()" v-model.number="csaData.absoluteCo2Values[4]"/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CommercialSustainability } from '@/models/commercial-sustainability';
import EasLoader from '../loader/eas-loader.vue';
import { CsaLabel } from '@/models/csa-label';
import { GoogleCharts } from 'google-charts';
import { EventBus } from '@/utils';
import { CsaDataService } from '@/services';
import CsaUtil from './csa-util';

@Component({
    name: 'csa-new',
    components: {
        EasLoader,
    },
})
export default class CommercialAndSustainabilityNew extends Vue {
    /**
     * Set the Commercial and Sustainability data.
     */
    @Prop({ required: true })
    private csaDataProp!: CommercialSustainability;

    /**
     * Set the serial number of report.
     */
    @Prop({ required: true })
    private serialNumber!: number;

    /**
     * Set the value to determine view selection between old and new.
     */
    @Prop({default: false})
    private isNewUi!: boolean;

    /**
     * Set the value to perform few operation only on exapanded popup view.
     */
    @Prop({default: false})
    private isPopup!: boolean;

    /**
     * Set all the label of Commercial and Sustainability layout
     */
    @Prop({ required: true })
    private csaLabels!: CsaLabel;

    private conflictCode = 409;
    private successCode = 200;
    private csaUtil = new CsaUtil();
    private csaDataService: CsaDataService = new CsaDataService();
    private dataloaded = false;
    private trendArrowMap = { Up: 'uparrow', Unchanged: 'rightarrow', Down: 'downarrow' };
    private isInEditMode = false;
    private componentId = 1;
    private updComment = '';
    private isCommentEmpty = false;
    private csaData: CommercialSustainability = {...this.csaDataProp};

    @Watch('csaDataProp', {deep: true})
    private updateComponent(
        newCsaData: CommercialSustainability
    ): void {
        if (newCsaData) {
            this.updateChart(newCsaData);
        }
    }

    private updateComponentInRealTime(): void {
        EventBus.$emit(EventBus.REPORT.SHOW_CONFIRMATION, true);
    }

    private updateCharttInRealTime(): void {
        this.updateComponentInRealTime();
        this.updateChart(this.csaData);
    }

    private updateChart(newCsaData: CommercialSustainability): void {
        this.csaData = JSON.parse(JSON.stringify(newCsaData));
        this.dataloaded = true;
        this.drawFcfChart();
        this.drawCO2Chart();
    }

    private mounted(): void {
        GoogleCharts.load(this.drawFcfChart);
        GoogleCharts.load(this.drawCO2Chart);
        this.dataloaded = true;
        if (this.isPopup) {
            EventBus.$on(EventBus.REPORT_MODAL.EDIT, (modalId: number) => this.makeEditable(modalId));
            EventBus.$on(EventBus.REPORT_MODAL.SAVE, (modalId: number) => this.save(modalId));
            EventBus.$on(EventBus.REPORT_MODAL.RESET, (modalId: number) => this.reset(modalId));
            EventBus.$on(EventBus.REPORT_MODAL.CLOSE, (modalId: number) => this.cancel(modalId));
        }
    }

    private co2subscript(val: string): string {
        return val != null ? val.replace(/CO2/g, 'CO₂') : val;
    }

    private makeEditable(modalId: number): void {
        this.isInEditMode = modalId === this.componentId;
    }

    private async reset(modalId: number): Promise<void> {
        if (modalId !== this.componentId ) {
            return;
        }
        this.csaData = (await this.csaDataService.get(this.serialNumber)).result;
        this.csaData = JSON.parse(JSON.stringify(this.csaData));
        this.updComment = '';
    }

    private async cancel(modalId: number): Promise<void> {
        if (modalId !== this.componentId ) {
            return;
        }
        this.isInEditMode = false;
        this.csaData = JSON.parse(JSON.stringify(this.csaData));
        this.csaData = (await this.csaDataService.get(this.serialNumber)).result;
        this.updComment = '';
    }

    private async save(modalId: number): Promise<void> {
        try {
            if (modalId !== this.componentId ) {
                return;
            }
            if (this.updComment === '') {
                this.isCommentEmpty = true;
                return;
            }
            this.isInEditMode = false;
            this.csaData.updComment = this.updComment;
            const temp = await this.csaDataService.saveCSAData(this.serialNumber, this.csaData);
            if (temp.statusCode === this.conflictCode) {
                alert(temp.message);
                EventBus.$emit(EventBus.REPORT.UPDATE);
            } else if (temp.statusCode === this.successCode) {
                const sNo = (temp).result.sNo;
                EventBus.$emit(EventBus.REPORT.UPDATE, sNo);
            }
        } catch (error) {
            this.isInEditMode = true;
        }
    }

    private drawFcfChart(): void {
        const options = {
            fontSize: 13,
            vAxis: {
                title: 'm\u20ac',
                titleTextStyle: {
                    italic: false,
                    fontSize: 15,
                },
                gridlines: { count: 4 },
            },
            seriesType: 'scatter',
            colors: ['#FFB503'],
            pointShape: 'diamond',
            series: {
                0: { type: 'line', color: '#0078DC', pointShape: 'circle', pointSize: 3 },
            },
            chartArea: { width: '75%', right: 0, top: 10 },
            pointSize: 5,
        };
        const dataTable = this.csaUtil.getFcfChartDataTable(this.csaData);
        this.$nextTick(() => {
            const el = document.getElementById(this.getFcfChartId());
            if (el) {
                setTimeout(() => {
                    const chart = new GoogleCharts.api.visualization.ComboChart(el);
                    chart.draw(dataTable, options);
                }, 50);
            }
        });
    }

    private drawCO2Chart(): void {
        const options = {
            fontSize: 12,
            vAxis: {
                title: 'ktCO₂',
                titleTextStyle: {
                    italic: false,
                    fontSize: 15,
                },
                gridlines: { count: 4 },
            },
            title: 'Absolute CO2 Emissions',
            titlePosition: 'none',
            chartArea: { width: '75%', right: 0, top: 10 },
            pointSize: 3,
            series: { 0: { color: '#9E9E9E'} },
        };
        const dataTable = this.csaUtil.getCO2ChartDataTable(this.csaData);
        this.$nextTick(() => {
            const el = document.getElementById(this.getCo2ChartId());
            if (el) {
                setTimeout(() => {
                    const chart = new GoogleCharts.api.visualization.LineChart(el);
                    if (this.csaData.isCo2GraphRequired) {
                        chart.draw(dataTable, options);
                    }
                }, 50);
            }
        });
    }

    private getFcfChartId(): string {
        return 'fcf-chart-' + this.csaData.sNo.toString();
    }

    private getCo2ChartId(): string {
        return 'co2-chart-' + this.csaData.sNo.toString();
    }

    get fcfChartId(): string {
        return this.getFcfChartId();
    }

    get co2ChartId(): string {
        return this.getCo2ChartId();
    }

    private beforeDestroy(): void {
        if (this.isPopup) {
            EventBus.$off(EventBus.REPORT_MODAL.EDIT);
            EventBus.$off(EventBus.REPORT_MODAL.SAVE);
            EventBus.$off(EventBus.REPORT_MODAL.RESET);
            EventBus.$off(EventBus.REPORT_MODAL.CLOSE);
        }
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';
.csa-new{
    white-space: pre-line;
    .fcf-chart{
        height: 12rem;
        width: 100%;
    }
    .co2graphspace{
        min-height: 12em;
    }
    .co2-chart{
        height: 12rem;
        width: 100%;
    }
    .pre-formatted{
        word-wrap: break-word;
    }
    .component-header{
        font-weight: bold;
        font-size: 1rem;
    }
    .flex-justify-center{
        justify-content: center;
    }
    .flex-justify-end{
        justify-content: flex-end;
    }
    .uparrow {
        width: 0.2rem;
        transform: rotate(45deg);
    }
    .downarrow {
        width: 0.2rem;
        transform: rotate(-45deg);
    }
    dt {
        margin-top:10px;
    }
    dd {
        word-break: break-word;
    }
    .grey-bg {
        background-color: @grey-lighter !important;
    }
    .border {
        border: 0.2rem solid @grey-lighter;
    }
    select {
        width: 22rem;
        border: none;
        font-size: 1.2rem;
        padding: 0.4rem;
        border-radius: 0;
        border-bottom: 0.2rem solid @grey-lighter;
        transition: 0.2s ease-in;
        &:focus {
            border-bottom: 0.2rem solid @uniper-blue;
            transition: 0.2s ease-in;
            outline: none;
        }
    }
    input {
        width: 21rem;
        height: 1rem !important;
        font-size: 1.2rem;
        padding: 0.4rem;
        border: none;
        border-radius: 0;
        border-bottom: 0.2rem solid @grey-lighter;
        transition: 0.2s ease-in;
        &:focus {
            border-bottom: 0.2rem solid @uniper-blue;
            transition: 0.2s ease-in;
            outline: none;
        }
    }
    textarea {
        min-width: 21rem;
        max-width: 25rem;
        max-height: 35rem;
        min-height: 6rem;
        font-size: 1.2rem;
        padding: 0.4rem;
        border-radius: 0;
        border: 0.2rem solid @grey-lighter;
        transition: 0.2s ease-in;
        &:focus {
            border: 0.2rem solid @uniper-blue;
            transition: 0.2s ease-in;
            outline: none;
        }
        &.invalid {
            border: 0.2rem solid @red-darker;
            box-shadow: 0 0.1rem 0.4rem 0 rgba(255, 0, 0, 0.22);
        }
    }
    .medium-input {
        width: 13.5rem !important;
        font-size: 1.2rem;
        padding: 0.2rem;
    }
    .small-input {
        width: 5rem !important;
        font-size: 1.2rem;
        padding: 0.2rem;
    }
    .extra-small-input {
        width: 4rem !important;
        padding: 0.2rem !important;
        font-size: 1rem;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
    .legend-p5-p95 {
        font-size: 1rem;
        color: @contextual-amber;
        margin-right: 0.5rem;
        transform: rotate(45deg);
    }
    .flex-align-end {
        align-items: flex-end;
    }
    .text-no-wrap {
        white-space: nowrap;
    }
    .rotate-45-degree {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
</style>


