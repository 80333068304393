<style lang="less">
@import '../../variables.less';
.link-bar{
    z-index: 5;
    overflow: hidden;
    overflow-y: auto;
    font-size: 1.5rem;
    font-family: Roboto, sans-serif;
    background-color: @white;
    border-bottom: 1px solid @warm-grey;
    a {
        text-decoration: none;
    }
    .link-box{
        box-sizing: border-box;
        width: 21rem;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-decoration: none;
        text-transform: uppercase;
        color: @dark-grey-darker;
        transition: 0.1s ease-in;
        box-sizing: border-box;
    }
    .help-wiki{
        width: 10rem;
    }
    .bl{
        border-left: 1px solid @warm-grey;
    }
    .br{
        border-right: 1px solid @warm-grey;
    }
    // .link-box:hover, .router-link-active{
    .router-link-active{
        // box-shadow: 0px -5px 0px @uniper-blue-darker inset;
        color: @uniper-blue;
        transition: 0.1s ease-in;
        border-bottom: 5px solid @uniper-blue;
        font-family: Roboto-Medium, sans-serif;
    }
}
</style>
<template>
    <div class="link-bar">
        <div class="flex-container">
            <div class="flex-col flex-12">
                <div class="flex-row flex-1 link-bar-row">
                    <router-link to="/report" class="flex-col link-box pl-1 pr-1 bl">Report</router-link>
                    <router-link v-if="hasEditAccess" to="configuration" class="flex-col link-box pl-1 pr-1 bl">Configuration</router-link>
                    <router-link v-if="hasEditAccess" to="/kpi-mappings" class="flex-col link-box pl-1 pr-1 bl">KPI Mappings</router-link>
                    <router-link v-if="hasEditAccess" to="/data-management" class="flex-col link-box pl-1 pr-1 bl">Data Management</router-link>
                    <router-link v-if="hasEditAccess" to="/comment" class="flex-col link-box pl-1 pr-1 bl">UI Text</router-link>
                    <router-link to="/line-of-sight" class="flex-col link-box pl-1 pr-1 bl">Line of Sight</router-link>
                    <router-link v-if="hasEditAccess" to="/changelog" class="flex-col link-box pl-1 pr-1 bl">Change Log</router-link>
                    <a v-if="hasEditAccess" href="https://wiki.intranet.uniper.energy/sales/display/COODE/Navigation+in+EAS"
                       target="_blank" class="link-box help-wiki pl-2 pr-1 bl"><font-awesome-icon :icon="['fas', 'question-circle']" class="mr-1"/>Wiki</a>
                    <div class="br"></div>
                </div>
            </div>
            <div class="flex-col flex-1"></div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';

@Component({
    name: 'link-bar',
})
export default class LinkBar extends Mixins(ComponentSecurity) {
}
</script>
