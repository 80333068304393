import BaseService from '@/utils/base-service';
import {ApiResponse} from '@/models';
import {FileDownload} from '@/models/file-download';
import {HydroDataBulkUpdateResult} from '@/models/hydro-data-bulk-update-result';

export class HydroDataService extends BaseService {
    private endpoint = '/DataManagement';

    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async downloadTemplate(reviewScenarioCode: string): Promise<FileDownload> {
        const response = (await this.api.get(`${this.endpoint}/HydroDataXlsxTemplate?reviewScenarioCode=${encodeURIComponent(reviewScenarioCode)}`,
            {
                responseType: 'arraybuffer',
            }));

        return FileDownload.fromResponse(response, 'bulk-update-template.xlsx');
    }

    public async bulkUpdate(file: File, dryRun = false): Promise<ApiResponse<HydroDataBulkUpdateResult>> {
        const formData = new FormData();
        formData.append('file', file);
        return (await this.api.post(`${this.endpoint}/HydroDataExcelUpdate?dryRun=${dryRun}`,
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })).data;
    }
}
