import { sdk } from '@/utils/coode-sdk';
import {AssetPermission, Permission, UserPermissions} from '@coode/fe-sdk/dist/types/uam';

export default class Permissions {
    private  PERMISSIONS_NAMES: any = {
        EAS_READ: 'CanViewReport',
        EAS_EDIT: 'CanEditReport',
        LOS_EDIT: 'CanEditLOS',
        DATA_FREEZE: 'CanFreezeData',
    };

    public getPermissions(): UserPermissions {
        return sdk?.localStorage.getPermissionsLocalStorage();
    }

    public hasViewAccess(): boolean {
        const permissions = this.getPermissions();
        return !!permissions.permissions?.find((permission: Permission) => permission.name === this.PERMISSIONS_NAMES.EAS_READ);
    }

    public hasEditAccess(): boolean {
        const permissions = this.getPermissions();
        return !!permissions.permissions?.find((permission: Permission) => permission.name === this.PERMISSIONS_NAMES.EAS_EDIT);
    }

    public hasLosEditAccess(): boolean {
        const permissions = this.getPermissions();
        return !!permissions.permissions?.find((permission: Permission) => permission.name === this.PERMISSIONS_NAMES.LOS_EDIT);
    }

    public hasDataFreezeAccess(): boolean {
        const permissions = this.getPermissions();
        return !!permissions.permissions?.find((permission: Permission) => permission.name === this.PERMISSIONS_NAMES.DATA_FREEZE);
    }

    public hasAssetAccess(assetId: number): boolean {
        const permissions = this.getPermissions();
        return !!permissions.assetPermissions?.find((asset: AssetPermission) => asset.assetSid === assetId);
    }
}
