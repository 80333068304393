<template>
    <div class="reporting">
        <content-wrapper>
            <template v-slot:filters>
                <Ribbon @selection-details="updateComponents" @on-ui-toggle="updateUi" :assets="assets" :serialNumber="serialNumber"/>
            </template>
            <template v-slot:content>
                <div class="pl-2 pr-2">
                    <div v-if="!isFinalExists" class="noreport border">
                        {{ $t('noFinalAvailable') }}
                    </div>
                    <ReportGrid
                        v-else-if="isDataSelected"
                        class="border"
                        :isNewUi="isNewUi"
                        :serialNumber="serialNumber"
                        :asset="selectedAsset"
                        @data-loaded="openModal"
                    />
                </div>
            </template>
        </content-wrapper>
    </div>
</template>

<script lang="ts">

import { Component, Mixins } from 'vue-property-decorator';
import Ribbon from './selector-ribbon/ribbon.vue';
import ReportGrid from './report-grid/report-grid.vue';
import ContentWrapper from './content-wrapper/content-wrapper.vue';
import { AssetService, CommentService } from '@/services';
import { Asset, RunId } from '@/models';
import ComponentSecurity from '@/mixins/component-security';
import { toastModuleDisplayError } from '@/store/toastModule';

@Component({
    components: {
        ContentWrapper,
        Ribbon,
        ReportGrid,
    },
})
export default class Reporting extends Mixins(ComponentSecurity) {
    private assets: Asset[] = [];
    private selectedAsset = {};
    private serialNumber = 0;
    private assetService: AssetService = new AssetService();
    private commetService: CommentService = new CommentService();
    private message = '';
    private shouldShowModal = false;
    private isDataSelected = false;
    private isNewUi = true;
    private isFinalExists = true;

    private beforeCreate(): void {
        const requestedUrl = window.localStorage?.getItem('requestedUrl');
        if (requestedUrl) {
            window.localStorage.removeItem('requestedUrl');
            window.open(requestedUrl, '_self');
        }
    }
    private async mounted(): Promise<void> {
        try {
            if (this.hasEditAccess) {
                this.assets = (await this.assetService.get()).result;
            } else {
                this.$router.push('/final-report');
            }
        } catch (error) {
            console.log(error);
            await this.$store.dispatch(toastModuleDisplayError, error);
        }
    }

    private updateComponents(data: { asset: Asset, runId: RunId }): void {
        this.isDataSelected = false;
        if (data.runId) {
            this.isFinalExists = true;
            this.serialNumber = data.runId.sNo;
        } else {
            this.isFinalExists = false;
        }
        this.selectedAsset = data.asset;
        this.isDataSelected = true;
    }

    private updateUi(uiFlag: boolean): void {
        this.isNewUi = uiFlag;
    }

    private async openModal(isReportDataLoaded: boolean): Promise<void> {
        this.message = (await this.commetService.getPopUpMessage()).result.message;
        if (isReportDataLoaded && this.message !== '' && !this.$cookies.get('shouldShowModal')) {
            this.shouldShowModal = true;
        }
    }

    private closeModal(): void {
        this.shouldShowModal = false;
        this.$cookies.set('shouldShowModal', this.shouldShowModal);
    }
}
</script>

<style lang="less" scoped>
@import '../variables.less';
.border {
    border: 0.1rem solid @grey-lighter;
}
.noreport {
    width: 100%;
    margin: 0px auto;
    padding-top: 10rem;
    z-index: 5;
    font-size: 2rem;
    color: @uniper-blue;
    font-family: Roboto-Regular, sans-serif;
    font-weight: bolder;
    font-stretch: extra-expanded;
    background: @white;
    overflow-x: auto;
    min-height: calc(100vh - 30rem);
    text-align: center;
}

.btn {
    width: 5rem;
    height: 3rem;
    background-color: @uniper-blue;
    color: @white;
    border-radius: .5rem;
    border: 1px solid @uniper-blue;
    font-size: 1.2rem;
    text-transform: uppercase;
    white-space: nowrap;
}
.pop-up-message {
    white-space: pre-line;
}
</style>
