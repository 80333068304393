<template>
    <div class="kpi-b">
        <div class="flex-row flex-1">
            <div class="flex-col flex-1">
                <div class="flex-row flex-1 mb-1 flex-justufy-center chart-title">
                    {{ kpiBData.kpiName }} 
                </div>
                <div class="flex-row flex-justufy-center flex-1">
                    <div class="flex-col flex-1 flex-end chart-title sub-header">
                        {{ $t('actual') }}
                    </div>
                    <div class="flex-col flex-1 chart-title ml-1">
                       MTP {{ yearLabel }}
                    </div>
                </div>
                <div class="flex-row flex-3 flex-justufy-center type-b-chart">
                    <div :id="kpiBChartId" class=""></div>
                </div>
            </div>
        </div>
        <div class="flex-row flex-justufy-center pr-2">
            <div class="flex-col mr-3">
                <div class="flex-row flex-1 flex-align-center">
                    <div class="target rotate-90-degree mr-2">|</div>
                        {{ $t('target') }}
                </div>
            </div>
            <div class="flex-col flex-align-center mr-3">
                <div class="flex-row flex-1 flex-align-center">
                    <div class="peer rotate-90-degree mr-2">|</div>
                    {{ $t('peer') }}
                </div>
            </div>
            <div class="flex-col flex-align-end mr-3">
                <div class="flex-row flex-1 flex-align-center">
                    <div class="asset rotate-90-degree mr-2">|</div>
                    {{ asset.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { GoogleCharts } from 'google-charts';
import { Asset, KpiBCData } from '@/models';

@Component
export default class KpiB extends Vue {
    /**
     * Set kpi type B data.
     */
    @Prop({ required: true })
    private kpiBData!: KpiBCData;

    /**
     * Set the asset details.
     */
    @Prop({ required: true })
    private asset!: Asset;

    /**
     * Set the serial number of report.
     */
    @Prop({ required: true })
    private serialNumber!: number;

    /**
     * Set chart title year.
     */
    @Prop({ required: true })
    private yearLabel!: number;

    private kpiBChartId = 'kpi-b-chart-' + this.serialNumber;
    private dataloaded = false;

    @Watch('kpiBData')
    private updateComponent(): void {
        this.dataloaded = true;
        this.drawKPIChart();
    }

    private mounted(): void {
        GoogleCharts.load(this.drawKPIChart);
    }

    private drawKPIChart(): void {
        const dataTable = this.getKPIChartDataTable(this.kpiBData);
        const options = {
            legend: { position: 'none' },
            vAxis: { gridlines: { count: 4 }, format: '#\'%\'' },
            chartArea: { width: '80%', top: 10, bottom: 20 },
            pointSize: 3,
            series: {
                0: { color: '#43A047' },
                1: { color: '#229AFE' },
                2: { color: '#E53935' },
                3: { color: '#43A047', visibleInLegend: false },
                4: { color: '#229AFE', visibleInLegend: false },
            },

            height: 150,
        };
        this.$nextTick(() => {
            const el = document.getElementById(this.kpiBChartId);
            if (el) {
                setTimeout(() => {
                    const chart = new GoogleCharts.api.visualization.LineChart(el);
                    chart.draw(dataTable, options);
                }, 50);
            }
        });
    }

    private getKPIChartDataTable(data: KpiBCData): any {
        const dataTable = new GoogleCharts.api.visualization.DataTable();
        const year = data.kpiValues.years;
        const target = data.kpiValues.targetActual;
        const peer = data.kpiValues.peerAct;
        const asset = data.kpiValues.actual;
        const mtpTarget = data.kpiValues.targetMtp;
        const mtpPeer = data.kpiValues.peerMtp;
        dataTable.addColumn('string', 'Year');
        dataTable.addColumn('number', 'target');
        dataTable.addColumn('number', 'peer');
        dataTable.addColumn('number', this.asset.name);
        dataTable.addColumn('number', 'mtp target');
        dataTable.addColumn('number', 'mtp peer');
        for (let i = 0; i < year.length; i++) {
            const row = [
                year[i],
                target[i],
                peer[i],
                asset[i],
                mtpTarget[i],
                mtpPeer[i],
            ];
            dataTable.addRow(row);
        }
        return dataTable;
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';
.flex-justufy-end {
    justify-content: flex-end;
}
.flex-justufy-center {
    justify-content: center;
}
.flex-justufy-between {
    justify-content: space-around;
}
.flex-align-end {
    align-items: flex-end;
}
.flex-align-center {
    align-items: center;
}
.kpi-b {
    width: 100%;
    .sub-header {
        padding-right: 10rem;
    }
    .target {
        color: @dark-green;
        font-size: 3rem;
        font-weight: 2rem;
        white-space: pre-wrap;
    }
    .peer {
        color: @uniper-blue-light;
        font-size: 3rem;
        font-weight: 2rem;
        white-space: pre-wrap;
    }
    .asset {
        color: @medium-red;
        font-size: 3rem;
        font-weight: 2rem;
        white-space: nowrap;
    }
    .rotate-90-degree {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}
</style>
