<template>
    <div class="flex-row flex-1">
        <div class="flex-col flex-1">
            <div class="flex-row flex-1 pt-1 pb-1" v-if="criteriaModel">
                <div class="flex-col checkbox mr-2">
                    <div class="satisfaction-box" :class="{ green: criteriaModel.isSatisfied }"></div>
                </div>
                <div class="flex-col flex-1">{{ criteriaModel.uiText }}
                    <div class="flex-row flex-1" v-for="(decision, decisionIndex) in criteriaModel.params"
                        :key="decisionIndex"
                    >
                        <div class="flex-row flex-1 pl-4 pt-1 pb-1">{{ decision.uiText }} : {{ decision.value }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { CriteriaModel } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'decision-criterion',
})
export default class DecisionCriterion extends Vue {
    /**
     * set the criteria satisfaction on which decision has taken
     */
    @Prop({required: true})
    private criteriaModel!: CriteriaModel;
}
</script>

<style lang="less">
@import '../../variables.less';
.satisfaction-box {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.2rem;
    border: 0.1rem solid @grey-light;
}
.green {
    background-color: @contextual-green;
}
</style>