export interface BulkDownloadRequest {
    hierarchySids: number[];
    reportYears: number[];
    sections: BulkDownloadReportSections[];
    includeFinals: boolean;
}

export enum BulkDownloadReportSections {
    CommercialAndSustainabilityAssessment,
    GeneralStrategy,
    MaintenanceAndRiskProcesses,
    SupportingAssetProcesses,
    Dates,
    AmProcessOverview,
}
