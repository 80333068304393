<template>
    <div class="general-strategy">
        <div v-if="!dataloaded">{{ $t('loading') }}</div>
        <div v-else>
            <div class="flex-row">
                <div class="flex-col flex-3 pr-1" id="generalStrategyCommentValue">
                    {{ genStat.generalStrategyCommentValue }}
                </div>
                <div class="flex-col flex-1 flex-align-center">
                    <div class="flex-row flex-5 boxborder">
                        <div class="flex-col">
                            <div class="flex-row flex-1">{{ $t('nextDecision') }}:</div>
                            <div class="flex-row flex-4" id="nextDecisionValue">
                                {{ genStat.nextDecisionValue }}
                            </div>
                        </div>
                    </div>
                    <div class="flex-row component-header">{{ $t('prRecomendations') }}</div>
                    <div class="flex-row pr-recommendation-value" id="prRecommendationValue">
                        <span v-for="(recommendation, index) in recommendations" :key="recommendation.name" style="whitespace: nowrap">
                            <!-- <span v-if="index">, </span>    -->
                            <span class="tooltip">
                                <span class="tooltiptext">
                                    <dt>{{ recommendation.name }}</dt>
                                    <br>
                                    <dt>Description: </dt>
                                    <dd>{{ recommendation.description || $t('recommendationDescriptionNotAvailable') }}</dd>
                                    <br>
                                    <dt>Rationale: </dt>
                                    <dd>{{ recommendation.rationale || $t('recommendationRationaleNotAvailable') }}</dd>
                                </span>
                                <div class="tooltip__text">
                                   {{ index ? ' ' + recommendation.name : recommendation.name }}
                                </div>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DatesGeneralStrategyModel, Recommendation } from '@/models';


@Component({
    name: 'general-strategy',
})
export default class GeneralStrategy extends Vue {
    /**
     * Set the General Startegy component data.
     */
    @Prop({ required: true })
    private genStat!: DatesGeneralStrategyModel;

    private dataloaded = false;
    private recommendations: Recommendation[] = [];

    private mounted(): void {
        this.setRecomendation(this.genStat);
        this.dataloaded = true;
    }

    @Watch('genStat', { deep: true })
    private async generalStrategy(
        newVal: DatesGeneralStrategyModel, oldVal: DatesGeneralStrategyModel,
    ): Promise<void> {
        if (newVal) {
            this.setRecomendation(newVal);
            this.dataloaded = true;
        }
    }

    private setRecomendation(genStat: DatesGeneralStrategyModel): void {
        this.recommendations = [...genStat.prRecommendationValue];
        this.recommendations.sort((a: Recommendation, b: Recommendation) => a.name > b.name ? 1 : - 1);
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';
.general-strategy {
    white-space: pre-line;
    .component-header {
        white-space: nowrap;
        font-weight: bold;
        font-size: 1.4rem;
    }
    .boxborder {
        border: 1px dashed black;
    }
    #generalStrategyCommentValue {
        text-align: justify;
    }
    .pr-recommendation-value {
        font-size: 1.5rem;
        justify-content: center;
        flex-wrap: wrap;
    }
}
.flex-align-center {
    align-items: center;
}
.tooltip {
    position: relative;
    border: none;
    white-space: pre-wrap;
    &__text {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
}
.tooltiptext {
    display: none;
    position: absolute;
    right: 150%;
    top: 150%;
    z-index: 99;
    width: 30rem;
    padding: 1.5rem;
    color: white;
    background-color: rgba(@uniper-blue, 0.9);
    border-radius: @border-radius-standard;
}
.tooltip:hover > .tooltiptext {
    display: block;
}
</style>
