export interface BatchTask {
    jobName: string;
    taskName: string;
    status: string;
}

export interface BatchTaskPost {
    sNo: number;
    hierarchySid: number | null;
    components: string [];
}

export enum BatchTaskState {
    Unknown,
    Pending,
    Preparing,
    Running,
    Completed,
    Failed,
}

export interface BatchTaskInfo {
    state: BatchTaskState;
    id: string;
    errorMessage: string;
    errorDetails: string;
}
