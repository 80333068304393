<template>
    <div class='report'>
        <div v-if="!isDataLoaded" class="popup">
            <eas-loader></eas-loader>
        </div>
        <div v-else-if="hasError" class="error-container mt-2 ml-2 mb-3">
            <div class="error-icon mr-5">
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="fa-3x" />
            </div>
            <div class="error-content">
                <h2> {{ $t('generalError') }}</h2>
            </div>
        </div>
        <div v-else class="flex-container dark-text mb-1 pl-1">
            <div class="flex-col flex-1">
                <div class="flex-row flex-1">
                    <div class="flex-col flex-16">
                        <div class="flex-row flex-1 asset-name">
                            <div class="flex-col flex-1 rotate left-vertical-bar boxborder mr-1 mb-1"
                                :class="{'grey-bg': isNewUi}">
                                    <div v-if="isDataLoaded" class="flex-row rotate-asset">{{ assetName }}</div>
                            </div>
                            <div class="flex-col flex-11 boxborder mr-1 mb-1 pl-1 pt-1 pr-1 pb-1"
                                :class="{'no-pad': isNewUi }">
                                <report-box-new :isNewUi="isNewUi" @size-change="expandClicked(1)">
                                    <template v-slot:title>{{ $t('commercialAndSustainabilityAsessment') }}</template>
                                    <template v-slot:content>
                                        <div :class="{ 'new-ui-csa-container': isNewUi }">
                                            <CommercialAndSustainabilityNew v-if="isDataLoaded && isNewUi && currentModalId != 1" :serialNumber="serialNumber" :csaDataProp="csaData" :csaLabels="csaLabels" />
                                            <CommercialAndSustainability v-if="isDataLoaded && !isNewUi" :csaData="csaData" :csaLabels="csaLabels" />
                                        </div>
                                    </template>
                                </report-box-new>
                            </div>
                        </div>
                        <div class="flex-row flex-1 strategy-cluster">
                            <div class="flex-col flex-1 left-vertical-bar boxborder mr-1 mb-1"
                                :class="{'grey-bg': isNewUi}">
                                <StrategyCluster v-if="isDataLoaded" :isInTarget="isInTarget"/>
                            </div>
                            <div class="flex-col flex-11 boxborder mr-1 mb-1 pl-1 pt-1 pr-1 pb-1"
                                :class="{'no-pad': isNewUi }">
                                <report-box-new class="report-content" :isNewUi="isNewUi" @size-change="expandClicked(2)">
                                    <template v-slot:title>{{ $t('performamceAssessment') }}</template>
                                    <template v-slot:content>
                                        <PerformanceAssessment
                                            v-if="isDataLoaded && currentModalId != 2"
                                            :serialNumber="serialNumber"
                                            :asset="asset"
                                            :isNewUi="isNewUi"
                                        />
                                    </template>
                                </report-box-new>
                            </div>
                        </div>
                        <div class="flex-row flex-1 sign-off">
                            <div class="flex-col flex-1 left-vertical-bar boxborder mr-1 mb-1"
                                :class="{'grey-bg': isNewUi}"
                                @click="editDateRibbon()"
                            >
                                <SignOff
                                    :isNewUi="isNewUi"
                                    :asset="asset"
                                    :openModal="isDateRibbonEdtitable"
                                    :serialNumber="serialNumber"
                                    @close-modal="closeDateRibbon"
                                />
                            </div>
                            <div class="flex-col flex-11 boxborder mr-1 mb-1 pl-1 pt-1 pr-1 pb-1"
                                :class="{'no-pad': isNewUi }">
                                <report-box-new :isNewUi="isNewUi" @size-change="expandClicked(3)">
                                    <template v-slot:title>{{ $t('dates') }}</template>
                                    <template v-slot:content>
                                        <Dates v-if="isDataLoaded" :datesGeneralStrategyobj="datesGeneralStrategyobj" />
                                    </template>
                                </report-box-new>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col flex-16">
                        <div class="flex-row flex-1 strategic-eol">
                            <div class="flex-col flex-11 boxborder mr-1 mb-1 pl-1 pt-1 pr-1 pb-1"
                                :class="{'no-pad': isNewUi }">
                                <report-box-new :isNewUi="isNewUi" @size-change="expandClicked(4)">
                                    <template v-slot:title>
                                        <div v-if="isDataLoaded">{{ $t('generalStrategy') }}</div>
                                    </template>
                                    <template v-slot:content>
                                        <GeneralStrategy v-if="isDataLoaded" :genStat="datesGeneralStrategyobj" />
                                    </template>
                                </report-box-new>
                            </div>
                            <div class="flex-col flex-1 boxborder mr-1 mb-1" :class="{'no-shadow' : isNewUi }">
                                    <VerticalRibbon v-if="isDataLoaded"
                                        :isNewUi="isNewUi"
                                        ribbonText='Strategic EOL'
                                        :ribbonValue="datesGeneralStrategyobj.strategyEolValue"
                                    />
                            </div>
                        </div>
                        <div class="flex-row flex-1 eas-asset-cluster">
                            <div class="flex-col flex-11 boxborder mr-1 mb-1 pl-1 pt-1 pr-1 pb-1"
                                :class="{'no-pad': isNewUi }">
                                <report-box-new :isNewUi="isNewUi" @size-change="expandClicked(5)">
                                    <template v-slot:title>{{ $t('maintenanceAndRiskProcesses') }}</template>
                                    <template v-slot:content>
                                        <MentenanceAndRisk :isNewUi="isNewUi" v-if="isDataLoaded" :mrp="mrp" />
                                    </template>
                                </report-box-new>
                            </div>
                            <div class="flex-col flex-1 boxborder mr-1 mb-1" :class="{'no-shadow' : isNewUi }">
                                <VerticalRibbon v-if="isDataLoaded"
                                    :ribbonText="isNuclearAsset ? $t('maintainenceCluster') : $t('easAssetCluster')"
                                    :isNewUi="isNewUi"
                                    :ribbonValue="decisionModelOutput"
                                    :hasAction="isNewUi && mrp.showDecisionOutput"
                                    actionString='asset cluster'
                                    @show-modal="showStratFlagModal()"
                                />
                            </div>
                        </div>
                        <div class="flex-row flex-1 aero-risk-life">
                            <div class="flex-col flex-11 boxborder mr-1 mb-1 pl-1 pt-1 pr-1 pb-1"
                                :class="{'no-pad': isNewUi }">
                                <report-box-new :isNewUi="isNewUi" @size-change="expandClicked(6)">
                                    <template v-slot:title>{{ $t('supportingAssetProcesses') }}</template>
                                    <template v-slot:content>
                                        <SupportingAsset v-if="isDataLoaded" :sap="sap" :isNewUi="isNewUi" />
                                    </template>
                                </report-box-new>
                            </div>
                            <div class="flex-col flex-1 boxborder mr-1 mb-1" :class="{'no-shadow' : isNewUi }">
                                <VerticalRibbon v-if="isDataLoaded"
                                    :isNewUi="isNewUi"
                                    :ribbonText="isNuclearAsset ? $t('opportunitiesCluster') : $t('aeroRiskLife')"
                                    :ribbonValue="isNuclearAsset ? sap.opportunitiesCluster : sap.aeroRiskLife"
                                />
                            </div>
                        </div>
                        <div v-if="!isNewUi" class="flex-row flex-1 am-process">
                            <div class="flex-col flex-11 boxborder mr-1 mb-1 pl-1 pt-1 pr-1 pb-1">
                                <report-box-new :isNewUi="isNewUi" @size-change="expandClicked(7)">
                                    <template v-slot:title>{{ $t('amProcessOverview') }}</template>
                                    <template v-slot:content>
                                        <AmProcess
                                            v-if="isDataLoaded"
                                            @newAMPData="onAMPSave"
                                            :decisionModelList="ampData"
                                            :ampModel="ampModel"
                                        />
                                    </template>
                                </report-box-new>
                            </div>
                            <div
                                class="flex-col flex-1 rotate left-vertical-bar boxborder mr-1 mb-1"
                                :class="{'grey-bg': isNewUi}"
                            >
                                <div class="flex-row">
                                    <span class="tooltip">
                                        <span class="tooltiptext" @click.stop.prevent>
                                            <dt>{{ $t('cashFlow') }}</dt>
                                            <dd>{{ this.strategicFlagComments.cashFlowsComment || $t('cashflowCommentNotAvailable') }}</dd>
                                            <br/>
                                            <dt>{{  $t('comment') }}:</dt>
                                            <dd>{{ this.strategicFlagComments.strategicFlagValueComment || $t('strategicFlagCommentNotAvailable') }}</dd>
                                        </span>
                                        {{ ampModel.blockReleaseStrategicFlagValue }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isNewUi" class="flex-row flex-1 am-process">
                    <div class="flex-col flex-24 boxborder mr-1 mb-1 pl-1 pt-1 pr-1 pb-1 no-pad">
                        <report-box-new :isNewUi="isNewUi" @size-change="expandClicked(7)">
                            <template v-slot:title>{{ $t('amProcessOverview') }}</template>
                            <template v-slot:content>
                                <AmProcessNew
                                    v-if="isDataLoaded && currentModalId != 7"
                                    @newAMPData="onAMPSave"
                                    :serialNumber="serialNumber"
                                    :ampModel="ampModel"
                                />
                            </template>
                        </report-box-new>
                    </div>
                    <div
                        class="flex-col flex-1 rotate boxborder mr-1 mb-1"
                        :class="{'grey-bg': isNewUi}"
                        @click="editStrategicFlag()"
                    >
                        <div class="flex-row">
                            <span class="tooltip">
                                <span class="tooltiptext" @click.stop.prevent>
                                    <dt>{{ $t('cashFlow') }}:</dt>
                                    <dd>{{ this.strategicFlagComments.cashFlowsComment || $t('cashflowCommentNotAvailable') }}</dd>
                                    <br/>
                                    <dt>{{  $t('comment') }}:</dt>
                                    <dd>{{ this.strategicFlagComments.strategicFlagValueComment || $t('strategicFlagCommentNotAvailable') }}</dd>
                                </span>
                                {{ ampModel.blockReleaseStrategicFlagValue }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex-row" v-for="footer in footerData" :key="footer.attributeTitle">
                    <div class="flex-col report-footer pr-2 pl-1">
                        <div class="uiText"><b>{{ footer.attributeTitle }}</b>&nbsp;{{ footer.attributeText }}</div>
                    </div>
                </div>
            </div>
        </div>
        <modal-box v-if="isDataLoaded && shouldShowModal"
            :open="isDataLoaded && shouldShowModal"
            :onClose.prop="closeModal"
            :enableHeader.prop="true"
            :enableFooter.prop="false"
            :enableHeaderClose.prop="true"
            :transparentOverlay.prop="false"
            :closeOnClickOutside.prop="true"
            width='1080px'
        >
            <span slot="header" class="modalHeader">
                <div v-if="!showStrategicFlagModal" class="flex-row flex-1 modal-header">
                    <div class="flex-col flex-1">
                        <h3>{{ asset.name }} - {{ $t('decisionModelDetails') }}</h3>
                    </div>
                </div>
                <div v-else class="flex-row flex-1 modal-header">
                    <div class="flex-col">
                        <h3>{{ asset.name }}</h3>
                    </div>
                    <span class="separator ml-6 mr-4 mt-1"></span>
                    <div class="flex-col flex-1">
                        <h3>{{ $t('strategicFlag') }}</h3>
                    </div>
                </div>
            </span>
            <span>
                <div v-if="!showStrategicFlagModal">
                    <div class="flex-row flex-1 link-bar">
                        <div class="flex-col flex-1">
                            <div class="flex-row flex-1 link-bar-row">
                                <div
                                    class="flex-col link-box pl-1 pr-1 br"
                                    :class="{ active: activeTab === 'cal' }"
                                    @click="() => activeTab = 'cal'"
                                >{{ $t('calculatedDecision') }}</div>
                                <div v-if="hasEditAccess"
                                    class="flex-col link-box pl-1 pr-1"
                                    :class="{ active: activeTab === 'mod' }"
                                    @click="() => activeTab = 'mod'"
                                >{{ $t('modifiedDecision') }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-row flex-1">
                        <div class="flex-col flex-1">
                            <decision-model-component v-if="activeTab === 'cal'" :serialNumber="serialNumber"></decision-model-component>
                            <decision-model-edit
                            v-if="activeTab === 'mod'"
                            class="decision-output-edit"
                            :decision="decisionModelOutput"
                            :serialNumber="serialNumber"
                            @on-decision-output-change="onDecisionOutputSave"></decision-model-edit>
                        </div>
                    </div>
                </div>
                <div v-else class="modal-content">
                    <div class="flex-col edit-comment">
                        <div class="mt-3 ui-text">
                            <label for="uiTextValue" class="ui-text-label">{{ $t('value') }}</label>
                            <textarea
                                id="uiTextValue"
                                class="ui-text-input"
                                :placeholder="$t('value')"
                                v-model="ampModel.blockReleaseStrategicFlagValue"
                                rows="1"
                            >&#10;
                            </textarea>
                        </div>
                        <div class="mt-3 ui-text">
                            <label for="uiTextValue" class="ui-text-label">{{ $t('reason') }}</label>
                            <input id="uiTextValue"
                                class="ui-text-input"
                                :placeholder="$t('reason')"
                                v-model="strategicFlagUpdComment"
                            >
                        </div>
                        <div :class="errorExists ? 'error-text': ''">
                            <div v-if="errorExists">{{ $t('thisFieldIsMandatory') }}</div>
                        </div>
                    </div>
                    <br/>
                    <div class="flex-row reverse save-startegic">
                        <div class="flex-col flex-align-end">
                            <button class="btn mt-1" @click="saveStrategicFlagValue()">
                                {{ $t('save') }}
                            </button>
                        </div>
                    </div>
                </div>
            </span>
        </modal-box>

        <report-modal v-if="currentModalId > -1">
            <template v-slot:content>
                <div class="flex-row flex-1">
                    <div v-show="!isPopUpInEditMode"
                        class="flex-col flex-1 direction-arrow justify-center align-center mr-2"
                        @click="showPrevReportModal()">&lt;</div>
                    <div class="flex-col flex-14">
                        <div class="flex-row flex-1 asset-name">
                            <div class="flex-col flex-1 rotate left-vertical-bar boxborder mr-1 mb-1"
                                        :class="{'grey-bg': isNewUi}">
                                <div v-if="isDataLoaded" class="flex-row rotate-asset">{{ assetName }}</div>
                            </div>
                            <div class="flex-col flex-11 boxborder mr-1 mb-1 pl-1 pt-1 pr-1 pb-1 no-pad">
                                <report-box-new
                                    class="report-content"
                                    :isNewUi="true"
                                    :isExpanded="true"
                                    @size-change="shrinkClicked()"
                                >
                                    <template v-slot:title>{{ modalTitle }}</template>
                                    <template v-slot:content>
                                        <div style="max-height:45rem; overflow:auto">
                                            <component :is="selectedComponent" v-bind="componentProperties"></component>
                                        </div>
                                    </template>
                                </report-box-new>
                                <div class="modal-footer">
                                    <button v-if="isPopUpInEditMode" class="btn btn-primary reset"
                                        @click="resetEditedContent()">{{ $t('reset') }}</button>
                                    <button
                                        v-if="hasModalEdit && hasEditAccess && !isPopUpInEditMode"
                                        class="btn btn-primary ëdit"
                                        @click="setEditMode()"
                                    >{{ $t('edit') }}</button>
                                    <button v-if="hasModalEdit && hasEditAccess && isPopUpInEditMode" class="btn btn-primary ëdit"
                                        @click="saveEditedContent()">{{ $t('save') }}</button>
                                    <button
                                        class="btn btn-danger close"
                                        @click="isPopUpInEditMode ? cancelEdit() : shrinkClicked()">
                                        {{ closeButtonText }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="!isPopUpInEditMode"
                        class="flex-col flex-1 direction-arrow justify-center align-center ml-2"
                        @click="showNextReportModal()">&gt;</div>
                </div>
            </template>
        </report-modal>
        <confirm-modal
            v-show="showConfirm"
            :item="{ sNo: this.serialNumber }"
            message='areYouSureToDiscardChanges'
            @confirm-action="callbackFunc"
            @cancel-action="() => showConfirm = false">
        </confirm-modal>
        <RunCalculation
            :asset="asset"
            :serialNumber="serialNumber"
        />
    </div>
</template>

<script lang="ts">

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import CommercialAndSustainability from '@/components/csa/csa.vue';
import CommercialAndSustainabilityNew from '@/components/csa/csa-new.vue';
import StrategyCluster from '@/components/performance-assessment/strategy-cluster.vue';
import PerformanceAssessment from '@/components/performance-assessment/performance-assessment.vue';
import GeneralStrategy from '@/components/general-startegy/general-strategy.vue';
import Dates from '@/components/dates/dates.vue';
import SignOff from '@/components/dates/signoff.vue';
import VerticalRibbon from '@/components/vertical-ribbon/vribbon.vue';
import MentenanceAndRisk from '@/components/maintenance-and-risk/maintenance-and-risk.vue';
import SupportingAsset from '@/components/supporting-asset/supporting-asset.vue';
import AmProcess from '@/components/am-process/am-process.vue';
import AmProcessNew from '@/components/am-process/am-process-new.vue';
import DecisionModelComponent from '@/components/decision-model/decision-model.vue';
import DecisionModelEdit from '@/components/decision-model/decision-model-edit.vue';
import ReportBox from '@/components/report-box/report-box.vue';
import ReportBoxNew from '@/components/report-box-new/report-box-new.vue';
import ReportModal from '@/components/report-modal/report-modal.vue';
import RunCalculation from '../run-calculation/run-calculation.vue';
import ConfirmModal from '@/components/confirm-modal/confirm-modal.vue';
import EasLoader from '../loader/eas-loader.vue';
import { EventBus } from '@/utils';
import {
    Asset, DecisionModel,
    AMPModel,
    CommercialSustainability,
    CsaLabel,
    DatesGeneralStrategyModel,
    MRP,
    SAP,
    FooterData,
} from '@/models';
import {
    CsaDataService,
    CsaLabelService,
    DatesGeneralStrategyService,
    MRPService, SAPService,
    AMPService,
    CommentService,
    PerformanceAssessmentService,
} from '@/services';
import { StrategicFlagCommentsModel } from '@/models/strategic-flag-comments';
import ComponentSecurity from '@/mixins/component-security';
import { toastModuleDisplayError } from '@/store/toastModule';

@Component({
    name: 'report-grid',
    components: {
        DecisionModelComponent,
        DecisionModelEdit,
        EasLoader,
        CommercialAndSustainability,
        CommercialAndSustainabilityNew,
        StrategyCluster,
        PerformanceAssessment,
        VerticalRibbon,
        GeneralStrategy,
        Dates,
        SignOff,
        MentenanceAndRisk,
        SupportingAsset,
        AmProcess,
        AmProcessNew,
        ReportBox,
        ReportBoxNew,
        ReportModal,
        ConfirmModal,
        RunCalculation,
    },
})
export default class ReportGrid extends Mixins(ComponentSecurity) {
    /**
     * Set the serial number to fetch details of different runs of assets.
     */
    @Prop({ required: true })
    private serialNumber!: number;

    /**
     * Set the asset details to show relevant data.
     */
    @Prop({ required: true })
    private asset!: Asset;

    /**
     * Set the new attribute to perform some distinct operations.
     */
    @Prop({ default: true })
    private isNewUi!: boolean;

    private shouldShowModal = false;
    private csaData: CommercialSustainability | null = null;
    private csaLabels: CsaLabel | null = null;
    private datesGeneralStrategyobj: DatesGeneralStrategyModel | null = null;
    private mrp: MRP | null = null;
    private sap: SAP | null = null;
    private footerData: FooterData[] = [];
    private ampData: DecisionModel[] = [];
    private strategicFlagComments: StrategicFlagCommentsModel = {cashFlowsComment: '', strategicFlagValueComment: ''};
    private ampModel: AMPModel = {
        sNo: this.serialNumber,
        ampData: this.ampData,
        blockReleaseStrategicFlagValue: '',
        comments: this.strategicFlagComments,
        updComment: '',
        showAmpTable: false,
    };
    private hasError = false;
    private modalTitle = '';
    private selectedComponent = '';
    private componentProperties = {};
    private currentModalId = -1;
    private maxModalId = 7;
    private hasModalReset = false;
    private hasModalEdit = false;

    private isInTarget = false;
    private decisionModelOutput = '';
    private assetName = '';
    private csaDataService: CsaDataService = new CsaDataService();
    private csaLabelService: CsaLabelService = new CsaLabelService();
    private perfService: PerformanceAssessmentService = new PerformanceAssessmentService();
    private datesGeneralStrategySer: DatesGeneralStrategyService = new DatesGeneralStrategyService();
    private uiTextService: CommentService = new CommentService();
    private mrpService: MRPService = new MRPService();
    private sapService: SAPService = new SAPService();
    private ampService: AMPService = new AMPService();
    private commentService: CommentService = new CommentService();
    private performanceComment = '';
    private fleetName = '';
    private strategicFlagUpdComment = '';
    private isNuclearAsset = false;
    private isDataLoaded = false;
    private isPopUpInEditMode = false;
    private activeTab = 'cal';
    private showConfirm = false;
    private showConfirmModal = false;
    private isDateRibbonEdtitable = false;
    private showStrategicFlagModal = false;
    private errorExists = false;
    private callbackFunc = (): void => { return; };

    private async mounted(): Promise<void> {
        await this.getCSALabels();
        await this.loadNewData(this.serialNumber);
        EventBus.$on(EventBus.REPORT.UPDATE, () => this.exitFromEditMode());
        EventBus.$on(
            EventBus.REPORT.SHOW_CONFIRMATION,
            (showConfirmModal: boolean) => this.showModal(showConfirmModal),
        );
    }

    @Watch('serialNumber')
    private updateComponent(
        newSelection: number,
        oldSelection: number): void {
        if (newSelection && newSelection !== oldSelection) {
            this.loadNewData(newSelection);
        }
    }

    private setEditMode(): void {
        this.isPopUpInEditMode = true;
        EventBus.$emit(EventBus.REPORT_MODAL.EDIT, this.currentModalId);
    }

    private showModal(showConfirmModal: boolean): void {
        this.showConfirmModal = showConfirmModal;
    }

    private cancelEdit(): void {
        if (this.showConfirmModal) {
            this.callbackFunc = (): void => {
                EventBus.$emit(EventBus.REPORT_MODAL.CLOSE, this.currentModalId);
                this.isPopUpInEditMode = false;
                this.showConfirm = false;
                this.showConfirmModal = false;
            };
            this.showConfirm = true;
        } else {
            EventBus.$emit(EventBus.REPORT_MODAL.CLOSE, this.currentModalId);
            this.isPopUpInEditMode = false;
            this.showConfirm = false;
        }
    }

    private saveEditedContent(): void {
        EventBus.$emit(EventBus.REPORT_MODAL.SAVE, this.currentModalId);
    }

    private resetEditedContent(): void {
        this.callbackFunc = this.reset;
        this.showConfirm = true;
    }

    private reset(): void {
        this.showConfirm = false;
        EventBus.$emit(EventBus.REPORT_MODAL.RESET, this.currentModalId);
    }

    private exitFromEditMode(): void {
        this.isPopUpInEditMode = false;
        this.showConfirmModal = false;
    }

    private collapse(): void {
        this.exitFromEditMode();
        this.currentModalId = -1;
        this.selectedComponent = '';
        this.componentProperties = {};
        this.modalTitle = '';
        this.showConfirm = false;
        this.showConfirmModal = false;
    }

    private shrinkClicked(): void {
        if (this.showConfirmModal) {
            this.callbackFunc = this.collapse;
            this.showConfirm = true;
        } else {
            this.collapse();
        }
    }

    private showNextReportModal(): void {
        this.currentModalId = this.currentModalId === this.maxModalId ? 1 : this.currentModalId + 1;
        this.expandClicked(this.currentModalId);
    }

    private showPrevReportModal(): void {
        this.currentModalId = this.currentModalId === 1 ? 7 : this.currentModalId - 1;
        this.expandClicked(this.currentModalId);
    }

    private showStratFlagModal(): void {
        this.showStrategicFlagModal = false;
        this.shouldShowModal = true;
    }

    private expandClicked(id: number): void {
        switch (id) {
        case 1:
            this.modalTitle = this.$i18n.t('commercialAndSustainabilityAsessment') as string;
            this.selectedComponent = 'CommercialAndSustainabilityNew';
            this.componentProperties = {
                serialNumber: this.serialNumber,
                csaDataProp: this.csaData,
                csaLabels: this.csaLabels,
                isPopup: true,
            };
            this.hasModalEdit = true;
            break;
        case 2:
            this.modalTitle = this.$i18n.t('performamceAssessment') as string;
            this.selectedComponent = 'PerformanceAssessment';
            this.componentProperties = {
                serialNumber: this.serialNumber,
                asset: this.asset,
                isPopup: true,
            };
            this.hasModalEdit = true;
            break;
        case 3:
            this.modalTitle = this.$i18n.t('dates') as string;
            this.selectedComponent = 'Dates';
            this.componentProperties = {
                datesGeneralStrategyobj: this.datesGeneralStrategyobj,
                isPopup: true,
            };
            this.hasModalEdit = false;
            break;
        case 4:
            if (this.isNuclearAsset) {
                this.modalTitle = this.$i18n.t('generalAndOrganisatinalStrartegy') as string;
            } else {
                this.modalTitle = this.$i18n.t('generalStrategy') as string;
            }
            this.selectedComponent = 'GeneralStrategy';
            this.componentProperties = {
                genStat: this.datesGeneralStrategyobj,
                isPopup: true,
            };
            this.hasModalEdit = false;
            break;
        case 5:
            this.modalTitle = this.$i18n.t('maintenanceAndRiskProcesses') as string;
            this.selectedComponent = 'MentenanceAndRisk';
            this.componentProperties = {
                mrp: this.mrp,
                isPopup: true,
            };
            this.hasModalEdit = false;
            break;
        case 6:
            this.modalTitle = this.$i18n.t('supportingAssetProcesses') as string;
            this.selectedComponent = 'SupportingAsset';
            this.componentProperties = {
                sap: this.sap,
                isPopup: true,
            };
            this.hasModalEdit = false;
            break;
        case 7:
            this.modalTitle = this.$i18n.t('amProcessOverview') as string;
            this.selectedComponent = 'AmProcessNew';
            this.componentProperties = {
                serialNumber: this.serialNumber,
                ampModel: this.ampModel,
                isPopup: true,
            };
            this.hasModalEdit = this.ampModel.showAmpTable;
            break;
        default:
            this.currentModalId = -1;
            this.hasModalEdit = false;
            return;
        }
        this.currentModalId = id;
    }

    private async getFooter(): Promise<void> {
        this.footerData = (await this.uiTextService.getFooter(this.asset.id)).result;
    }

    private async onAMPSave(ampData: DecisionModel[]): Promise<void> {
        this.ampModel.ampData = ampData;
        await this.setAMPData(this.ampModel);
        alert('Data successfully Saved!');
        await this.getMRPData(this.serialNumber);
        await this.getSAPData(this.serialNumber);
        await this.getAMPData(this.serialNumber);
        this.$forceUpdate();
    }

    private async loadNewData(selection: number): Promise<void> {
        this.isDataLoaded = false;
        try {
            await this.getCSAData(selection);
            await this.getDateGeneralStrategy(selection);
            await this.getMRPData(selection);
            await this.getSAPData(selection);
            await this.getAMPData(selection);
            await this.getFooter();
            if (this.fleetName === 'Nuclear') {
                this.isNuclearAsset = true;
            } else {
                this.isNuclearAsset = false;
            }
            this.assetName = this.asset.name;
            this.$emit('data-loaded', this.isDataLoaded);
            this.hasError = false;
        } catch (error) {
            await this.$store.dispatch(toastModuleDisplayError, error);
            this.hasError = true;
        }
        finally {
            this.isDataLoaded = true;
        }
    }

    private async getCSAData(selection: number): Promise<void> {
        this.csaData = (await this.csaDataService.get(selection)).result;
        this.assetName = this.csaData.assetName;
    }

    private async getCSALabels(): Promise<void> {
        this.csaLabels = (await this.csaLabelService.get()).result;
    }

    private async getDateGeneralStrategy(selection: number): Promise<void> {
        this.datesGeneralStrategyobj = (await this.datesGeneralStrategySer.get(selection)).result;
    }

    private async getMRPData(selection: number): Promise<void> {
        this.mrp = (await this.mrpService.get(selection)).result;
        this.decisionModelOutput = this.mrp.easAssetCluster;
        this.fleetName = this.mrp.fleetName;
        this.isInTarget = this.mrp.isInTarget;
    }

    private closeModal(): void {
        this.shouldShowModal = false;
        this.errorExists = false;
        this.activeTab = 'cal';
    }

    private async getSAPData(selection: number): Promise<void> {
        this.sap = (await this.sapService.get(selection)).result;
    }

    private async getAMPData(selection: number): Promise<void> {
        this.ampModel = (await this.ampService.get(selection)).result;
        this.ampData = this.ampModel.ampData;
        this.strategicFlagComments = this.ampModel.comments;
    }

    private async setAMPData(ampModel: AMPModel): Promise<AMPModel> {
        return (await this.ampService.saveAMPData(this.serialNumber, ampModel)).result;
    }

    private async onDecisionOutputSave(sNo: number): Promise<void> {
        EventBus.$emit(EventBus.REPORT.UPDATE, sNo);
        this.closeModal();
    }

    private editDateRibbon(): void {
        this.isDateRibbonEdtitable = true;
    }

    private closeDateRibbon(closeModal: boolean): void {
        this.isDateRibbonEdtitable = closeModal;
    }

    private editStrategicFlag(): void {
        if (this.hasEditAccess) {
            this.shouldShowModal = true;
            this.showStrategicFlagModal = true;
            this.strategicFlagUpdComment = '';
        } else {
            this.shouldShowModal = false;
            this.showStrategicFlagModal = false;
        }
    }

    private async saveStrategicFlagValue(): Promise<void> {
        if (this.strategicFlagUpdComment === '') {
            this.errorExists = true;
        } else {
            this.errorExists = false;
            this.shouldShowModal = false;
            this.ampModel.updComment = this.strategicFlagUpdComment;
            const amp = await this.setAMPData(this.ampModel);
            this.loadNewData(amp.sNo);
            EventBus.$emit(EventBus.REPORT.UPDATE, amp.sNo);
            this.closeModal();
        }
    }

    get closeButtonText(): string {
        return this.isPopUpInEditMode ?
            this.$i18n.t('cancel') as string :
            this.$i18n.t('close') as string;
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.REPORT.UPDATE);
        EventBus.$off(EventBus.REPORT.SHOW_CONFIRMATION);
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';

.report {
    width: 100%;
    margin: 0rem auto;
    padding-top: 1rem;
    left: 0;
    right: 0;
    z-index: 5;
    font-size: 1.5rem;
    color: black;
    font-family: Roboto-Regular, sans-serif;
    font-stretch: extra-expanded;
    background: @white;
    overflow-x: auto;
    .separator {
        width: 2px;
        height: 4rem;
        margin-left: 2rem;
        margin-right: 2rem;
        background: #E0E0E0;
        display: inline-block;
        vertical-align: middle;
    }
    .error-container {
        display: flex;
        align-items: center;
        color: @red-darker;
    }
    .grey-bg {
        background-color: @grey-lighter !important;
        box-shadow: none !important;
    }
    .boxborder {
        border-radius: @border-radius-standard;
        box-shadow: 0.2rem 0.3rem 1.3rem 0 @dark-grey-lighter;
        background-color: @white;
    }
    .boxborder.no-pad {
        padding:0rem;
        box-shadow: none !important;
        border: 0.1rem Solid @grey-lighter;
    }
    .dark-text {
        color: @dark-grey;
    }
    .left-vertical-bar {
        width: 4rem; // same throughout all display size
        padding: 2rem 0;
        font-weight: bold;
        box-sizing: border-box;
    }
    .left-vertical-bar-thin {
        width: 0.5rem; // same throughout all display size
        font-weight: bold;
        box-sizing: border-box;
    }
    .rotate {
        vertical-align: center;
        text-align: center;
        writing-mode: vertical-lr;
        align-items: center;
        justify-content: center;
        color: @uniper-blue-dark;
        font-size: 2rem;
        letter-spacing: 0.2rem;
    }
    .modal-footer {
        padding:1.2rem;
        text-align:right;
        background: @pale-grey;
        .btn{
            width: 12rem;
            margin-left:0.8rem;
            height: 3rem;
            background-color: @uniper-blue;
            color: @white;
            border-radius: .5rem;
            border: 0.1rem solid @uniper-blue;
            font-size: 1.2rem;
            text-transform: uppercase;
        }
        .btn:disabled {
            color: @warm-grey;
            border: 0.1rem solid @warm-grey;
            background: @pale-grey;
            cursor: auto;
        }
        .btn-danger{
            background:@contextual-red;
            border: 0.1rem solid @contextual-red;
        }
    }
    .rotate-asset {
        transform: rotate(180deg);
    }
    .report-footer {
        white-space: pre-line;
        font-size: 1.2rem;
        text-align: justify;
    }
    .modal-content {
        .link-bar {
            z-index: 5;
            overflow: hidden;
            overflow-y: auto;
            font-size: 1.5rem;
            font-family: Roboto, sans-serif;
            background-color: @white;
            border-bottom: 0.1rem solid @warm-grey;
            a {
                text-decoration: none;
            }
            .link-box {
                box-sizing: border-box;
                width: 25rem;
                align-items: center;
                padding-top: 1rem;
                padding-bottom: 1rem;
                text-decoration: none;
                text-transform: uppercase;
                color: @dark-grey-darker;
                transition: 0.1s ease-in;
                box-sizing: border-box;
            }
            .bl {
                border-left: 0.1rem solid @warm-grey;
            }
            .br {
                border-right: 0.1rem solid @warm-grey;
            }
            .active {
                color: @uniper-blue;
                transition: 0.1s ease-in;
                border-bottom: 0.5rem solid @uniper-blue;
                font-family: Roboto-Medium, sans-serif;
            }
            .modalHeader {
                line-height: var(--adam-d38);
            }
        }
    }
    .decision-output-edit {
        height: 45rem;
    }
    .no-shadow {
        box-shadow: none !important;
    }
    .direction-arrow {
        color: @white;
        font-size: 4rem;
        font-weight: bold;
        &:hover{
            background-color: rgba(41, 40, 40, 0.712);
        }
    }
    .justify-center {
        justify-content: center;
    }
    .align-center {
        align-items: center;
    }
    .new-ui-csa-container {
        max-height: 30rem;
        overflow: auto;
    }
    .report-content {
        height: calc(100% - 2rem);
    }
    .edit-comment {
        height: calc(100% - 11.5rem);
        overflow: hidden;
        overflow-y: auto;
        label {
            margin-top: 1rem;
            min-width: fit-content;
        }
        input {
            width: 100%;
        }
        .ui-text {
            position: relative;
            label {
                position: absolute;
                background: white;
                left: 1.8rem;
                top: -2.1rem;
                padding: 0 1rem;
                font-size: 1.6rem;
                z-index: 1;
            }
            .ui-text-input {
                display: block;
                margin: 0 auto;
            }
            textarea {
                width: 100%;
                white-space: pre-wrap;
                max-width: 100%;
                max-height: 10rem;
                min-width: 100%;
                min-height: 5rem;
            }
            input,
            select,
            textarea {
                box-sizing: border-box;
                font-size: 1.8rem;
                padding: 1rem 2.5rem;
                border-radius: @border-radius-standard;
                border: 0.2rem solid @dark-grey;
                transition: 0.2s ease-in;
                &:focus {
                    border: 0.2rem solid @uniper-blue;
                    transition: 0.2s ease-in;
                    outline: none;
                }
                &.invalid {
                    border: 0.2rem solid @red-darker;
                    box-shadow: 0 0.1rem 0.4rem 0 rgba(255, 0, 0, 0.22);
                    transition: 0.1s ease-in;
                }
                &.full-width {
                    width: 100%;
                }
            }
            select {
                padding: 1rem 2.2rem;
                &.invalid {
                    border: 0.2rem solid @red-darker;
                    box-shadow: 0 0.1rem 0.4rem 0 rgba(255, 0, 0, 0.22);
                    transition: 0.1s ease-in;
                }
            }
        }
        .error-text{
            color: @contextual-red;
            font-weight: bolder;
        }
    }
    .btn {
        width: auto;
        height: 3rem;
        background-color: @uniper-blue;
        color: @white;
        border-radius: .5rem;
        border: 0.1rem solid @uniper-blue;
        font-size: 1.5rem;
        text-transform: uppercase;
        white-space: nowrap;
    }
    .btn:disabled {
        color: @warm-grey;
        border: 0.1rem solid @warm-grey;
        background: @pale-grey;
        cursor: auto;
    }
}
.tooltip {
    position: relative;
    border: none;
}
.tooltiptext {
    display: none;
    position: absolute;
    writing-mode: horizontal-tb;
    right: 150%;
    top: 0%;
    z-index: 99;
    text-align: left;
    width: 30rem;
    padding: 1.5rem;
    color: white;
    font-weight: lighter;
    font-size: 1.4rem;
    background-color: rgba(@uniper-blue, 0.9);
    border-radius: @border-radius-standard;
}
.boxborder:hover .tooltip .tooltiptext {
    display: block;
}
</style>
