<template>
    <div class="custom-select" :tabindex="tabindex" v-click-outside="() => (open = false)">
        <div class="selected" :class="{ open: open }" @click="open = !open">
            <div class="selected-text" :title="selectedText">
                {{ selectedText }}
            </div>
        </div>
        <div class="items" :class="{ selectHide: !open }">
            <label v-if="!filteredResults.length" class="empty-list">
                {{ $t('noItemsMatched') }}
            </label>
            <div
            v-for="(option, i) of filteredResults"
            :key="i"
            @click="
                selected = option;
                open = false;
                $emit('input', option);
            "
            >
                {{ displayKey!=='' ? option[displayKey] : option }}
            </div>
        </div>
        <div v-if="open" class="search-filter">
            <input
                type="text"
                v-model="search"
                :placeholder="$t('search')"
                max-length="100"
                class="search"
            >
            <font-awesome-icon :icon="['fas', 'search']" class="search-icon fa-lg"/>
            <button
                v-show="search !== ''"
                class="clear-search"
                @click="() => search = ''"
            >
                <font-awesome-icon :icon="['fas', 'times']" class="clear"/>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import clickOutside from '@/directives/click-outside';

@Component({
    name: 'custom-select',
    directives: {
        clickOutside,
    },
})
export default class CustomSelect extends Vue {
    /**
     * Set list options.
     */
    @Prop({required: true})
    private options!: any[];

    /**
     * Show default option.
     */
    @Prop({ required: false, default: null })
    private default!: any | null;

    /**
     * Set relevant param for the options object.
     */
    @Prop({required: false, default: ''})
    private displayKey!: string;

    /**
     * Set the tab order.
     */
    @Prop({ required: false, default: 0 })
    private tabindex!: number;

    private search = '';

    private selected = this.default ? this.default : this.options.length > 0 ? this.options[0] : null;
    private open = false;

    private mounted(): void {
        this.$emit('input', this.selected);
    }

    get filteredResults(): any[] {
        return this.options.filter((x: any) => {
            let p: string;
            p = x[this.displayKey].toLowerCase();
            const search = this.search.toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            return p.match(search);
        });
    }

    private get selectedText(): string {
        this.search = '';
        return this.displayKey !== '' ? this.selected[this.displayKey] : this.selected;
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';
.custom-select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 3.5rem;
    line-height: 3.5rem;
    z-index: 10;
    font-size: 1.2rem;
}
.custom-select .selected {
    background-color: @white;
    color: @dark-grey;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.5rem;
    font-weight: bold;
}
.custom-select .selected:after {
    position: absolute;
    content: "";
    top: 50%;
    right: 1em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: @uniper-blue-light transparent transparent transparent;
}
.custom-select .items {
    color: @dark-grey;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    background-color: white;
    position: absolute;
    background-color: @white;
    left: 0;
    right: 0;
    height: calc(100vh - 15rem);
    overflow-y: scroll;
    margin-top: 2rem;
}
.custom-select .items div {
    color: @dark-grey;
    padding-left: 1em;
    cursor: pointer;
}
.custom-select .items div:hover {
    background-color: @uniper-blue-light;
}
.selectHide {
    display: none;
}
.selected .selected-text {
    width: calc(100% - 3rem);
    overflow: hidden;
}
.search-filter input {
    padding-left: 4rem;
    padding-right: 4rem;
    width: 100%;
    max-width: 19rem;
    border: none;
    background: @white;
    border-radius: 0;
    &:focus {
        border-bottom: 0.1rem solid @uniper-blue;
        transition: 0s ease-in;
        outline: none;
    }
}
.search-filter {
    position: relative;
    height: 4rem;
    width: 25rem;
    bottom: 3.5rem;
}
.search-icon {
    position: absolute;
    color: @warm-grey;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    padding-top: 1.3rem;
}
.clear-search {
    position: absolute;
    top: 0;
    left: 22rem;
    bottom: 0;
    margin: 0.8rem;
    height: 2rem;
    margin-left: 1rem;
}
.search {
    background-color: @pale-grey;
    border: none;
    margin-bottom: 2rem;
}
.empty-list {
    padding: 1rem;
    font-size: 1.5rem;
}
</style>
