import { ChangeLog, ApiResponse, PagedResult } from '@/models';
import BaseService from '@/utils/base-service';

export class ChangeLogService extends BaseService {
    private endpoint = '/Changelog';

    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }

    public async getUsers(): Promise<ApiResponse<ChangeLog[]>> {
        return (await this.api.get(`${this.endpoint}/Users`)).data;
    }

    public async getChangedItems(): Promise<ApiResponse<ChangeLog[]>> {
        return (await this.api.get(`${this.endpoint}/ChangedItems`)).data;
    }

    public async getPages(pageno: number, pagesize: number, filters: any): Promise<ApiResponse<PagedResult>> {
        return (await this.api.
            post(`${this.endpoint}/paged?PageNr=${pageno}&PageSize=${pagesize}`, filters)).data;
    }
}
