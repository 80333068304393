<template>
    <div class="flex-container decision-edit">
        <div class="flex-col flex-1">
            <div class="flex-row flex-1 heading">{{ decision }}</div>
            <div v-if="isNothingSelected" class="flex-row flex-1 redtext">{{ $t('selectCategoryWarning') }}</div>
            <div class="flex-row">
                <div
                    :class="[
                        'flex-col flex-1 decisionbox ml-1 mt-2 mr-1 mb-2 darkGreenBox',
                        { highlight: selectedDecision === 'grow' },
                    ]"
                    @click="selectAction('grow')"
                >
                    <div class="text-bg">{{ $t('grow') }}</div>
                </div>
                <div
                    :class="[
                        'flex-col flex-1 decisionbox ml-1 mt-2 mr-1 mb-2 lightGreenBox',
                        { highlight: selectedDecision === 'maintain' },
                    ]"
                    @click="selectAction('maintain')"
                >
                    <div class="text-bg">{{ $t('maintain') }}</div>
                </div>
            </div>
            <div class="flex-row">
                <div
                    :class="[
                        'flex-col flex-1 decisionbox ml-1 mt-2 mr-1 mb-2 yellowBox',
                        { highlight: selectedDecision === 'keepOption' },
                    ]"
                    @click="selectAction('keepOption')"
                >
                    <div class="text-bg">{{ $t('keepOption') }}</div>
                </div>
                <div
                    :class="[
                        'flex-col flex-1 decisionbox ml-1 mt-2 mr-1 mb-2 redBox',
                        { highlight: selectedDecision === 'optimizeEol' },
                    ]"
                    @click="selectAction('optimizeEol')"
                >
                    <div class="text-bg">{{ $t('optimizeEol') }}</div>
                </div>
            </div>
            <div class="flex-row flex-1 light-padding mb-1">
                <div class="flex-col flex-1 flex-justify-center">*{{ $t('comment') }}: </div>
                <div class="flex-col flex-5">
                    <textarea
                        :class="{ invalid: isCommentEmpty }"
                        @focus="() => isCommentEmpty = false"
                        v-model="updComment"></textarea>
                    </div>
            </div>
            <div class="flex-row reverse">
                <button class="btn" @click="save()">save</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RunIdService } from '@/services';
import { DecisionCategory } from '@/models';

@Component({
    name: 'decision-model-edit',
})
export default class DecisionModelEdit extends Vue {
    /**
     * already set decision to show
     */
    @Prop({ required: true })
    private decision!: string;

    /**
     * serial no to which modification required
     */
    @Prop({ required: true })
    private serialNumber!: number;

    private runIdService: RunIdService = new RunIdService();
    private selectedDecision = '';
    private updComment = '';
    private categories: DecisionCategory = {
        grow: 'Grow Value',
        maintain: 'Maintain Value',
        keepOption: 'Keep Option',
        optimizeEol: 'Optimize EOL',
    };
    private isNothingSelected = false;
    private isCommentEmpty = false;

    private mounted(): void {
        this.isNothingSelected = false;
        this.isCommentEmpty = false;
    }

    private selectAction(decision: string): void {
        this.selectedDecision = '';
        if (this.decision === this.categories[decision]) {
            return;
        }
        this.selectedDecision = decision;
        this.isNothingSelected = false;
    }

    private isValid(): boolean {
        if (this.selectedDecision === '') {
            this.isNothingSelected = true;
        }
        if (this.updComment === '') {
            this.isCommentEmpty = true;
        }
        return !this.isNothingSelected && !this.isCommentEmpty;
    }

    private async save(): Promise<void> {
        if (this.isValid()) {
            const sNo = (await this.runIdService.setDecisionModelOutput(this.serialNumber, {
                sNo: this.serialNumber,
                modifiedOutput: this.categories[this.selectedDecision],
                updComment: this.updComment,
            })).result.sNo;
            this.$emit('on-decision-output-change', sNo);
        }
    }
}
</script>

<style lang="less">
@import '../../variables.less';
.decision-edit {
  .decisionbox {
    height: 10rem;
    width: 10rem;
    border-radius: 2rem;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: bold;
    color: @white;
    box-sizing: border-box;
    -webkit-text-stroke: 0.1rem black;
  }
  .heading {
    justify-content: center;
    align-items: center;
    margin: 1rem;
    padding: 1rem auto;
    background: @uniper-blue;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(0, 120, 220, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    color: @white;
    font-size: 2rem;
  }
  .darkGreenBox {
    background-color: @green-darker;
  }
  .lightGreenBox {
    background-color: @contextual-green;
  }
  .yellowBox {
    background-color: @amber-light;
  }
  .redBox {
    background-color: @red-lighter;
  }
  .highlight {
    border: 0.5rem solid @dark-grey;
  }
  textarea {
    box-sizing: border-box;
    font-size: 1.8rem;
    padding: 1rem 2.5rem;
    border-radius: @border-radius-standard;
    border: 2px solid @dark-grey;
    transition: 0.2s ease-in;
    &:focus {
      border: 2px solid @uniper-blue;
      transition: 0.2s ease-in;
      outline: none;
    }
    &.invalid {
      border: 2px solid @red-darker;
      box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
      transition: 0.1s ease-in;
    }
  }
  .redtext {
    color: red;
  }
}
</style>