<template>
    <div class="static-report-edit">
        <content-wrapper>
            <template v-slot:filters>
                <div class="flex-row flex-1">
                    <div class="flex-col flex-3">
                        <StaticReportRibbon
                            @selection-details="updateComponents"
                            :serialNumberList="updateSnoList"
                        />
                    </div>
                    <div class="flex-col flex-1 flex-end mr-3 mt-1">
                        <button
                            class="btn"
                            @click="openNewPage"
                        >
                            {{ $t('generateNewLink') }}
                        </button>
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <ReportGrid
                    v-if="isDataSelected"
                    class="border"
                    :isNewUi="isNewUi"
                    :serialNumber="serialNumber"
                    :asset="selectedAsset"
                />
            </template>
        </content-wrapper>
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import StaticReportRibbon from '@/components/final-report/static-report-edit/static-report-ribbon.vue';
import ReportGrid from '../../report-grid/report-grid.vue';
import ContentWrapper from '../../content-wrapper/content-wrapper.vue';
import { Asset } from '@/models';
import { EventBus } from '@/utils';
import { toastModuleDisplayError } from '@/store/toastModule';

@Component({
    components: {
        ContentWrapper,
        StaticReportRibbon,
        ReportGrid,
    },
})
export default class StaticReportEdit extends Vue {
    private serialNumberList: number[] = [];
    private updateSnoList: number[] = [];
    private selectedAsset = {};
    private serialNumber = 0;
    private isDataSelected = false;
    private isNewUi = true;
    private isFinalExists = true;
    private isReportEdited = false;

    private mounted(): void {
        try {
            this.getSnoList();
            EventBus.$on(EventBus.REPORT.UPDATE, (sNo: number) => this.updateReport(sNo));
            this.updateSnoList = this.serialNumberList;
        } catch (error) {
            this.$store.dispatch(toastModuleDisplayError, error);
        }
    }

    private getSnoList(): void {
        const payload = this.$route.query.payload ? this.$route.query.payload as string : '';
        const data = JSON.parse(decodeURIComponent(payload));
        this.serialNumberList = [...data.snoList];
    }

    private updateComponents(
        data: { asset: Asset, sno: number },
    ): void {
        this.isDataSelected = false;
        this.serialNumber = data.sno;
        this.selectedAsset = data.asset;
        this.isDataSelected = true;
    }

    private updateReport(sno: number): void {
        if (sno !== this.serialNumber) {
            const snoList = [...this.serialNumberList];
            const index = snoList.indexOf(this.serialNumber);
            snoList[index] = sno;
            this.updateSnoList = [...snoList];
            this.serialNumber = sno;
            this.isReportEdited = true;
        }
    }

    private openNewPage(): void {
        let editedTime = (new Date()).toString();
        editedTime += this.isReportEdited ? ' (Edited)' : '';
        const routeData = this.$router.resolve({
            name: 'static-report',
            query: { payload: encodeURIComponent(
                JSON.stringify({
                    snoList: this.updateSnoList,
                    timeStamp: editedTime,
                }),
            )},
        });
        window.open(routeData.href, '_blank');
    }

    private updateUi(uiFlag: boolean): void {
        this.isNewUi = uiFlag;
    }
}
</script>

<style lang="less" scoped>
@import '~@/variables.less';
.border {
    border: 0.1rem solid @grey-lighter;
}
.noreport {
    width: 100%;
    margin: 0px auto;
    padding-top: 10rem;
    z-index: 5;
    font-size: 2rem;
    color: @uniper-blue;
    font-family: Roboto-Regular, sans-serif;
    font-weight: bolder;
    font-stretch: extra-expanded;
    background: @white;
    overflow-x: auto;
    min-height: calc(100vh - 30rem);
    text-align: center;
}
.btn {
    width: 20rem;
    background-color: @uniper-blue;
    color: @white;
}
</style>
