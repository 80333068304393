<template>
    <div class="configValue-edit">
        <row-card>
            <template v-slot:title>{{configModel.uiText}}</template>
            <template v-slot:value>
                <div v-if="!isEditable">
                    {{configModel.value}}
                </div>
                <div v-else>
                    <input v-model="editValue" class="mb-2">
                </div>
            </template>
            <!-- <template v-slot:comment>
                <div v-if="!isEditable">
                    {{configModel.updComment}}
                </div>
                <div v-else>
                    <input class="mb-2" v-model="comment" placeholder="Comment">
                </div>
            </template> -->
            <template v-slot:buttons>
                <!-- <div v-if="isEditable" class="flex-row flex-1 buttons flex-align-center">
                    <div class="flex-col flex-1 flex-justify-center"><button @click="save()" class="save"><font-awesome-icon :icon="['fas', 'save']" title="save"/></button></div>
                    <div class="flex-col flex-1 flex-justify-center"><button @click="reset()" class="reset"><font-awesome-icon :icon="['fas', 'undo']" title="reset"/></button></div>
                    <div class="flex-col flex-1 flex-justify-center"><button @click="cancel()" class="cancel"><font-awesome-icon :icon="['fas', 'times']" title="cancel"/></button></div>
                </div> -->
                <div class="flex-row flex-1 buttons">
                    <div class="flex-col flex-3">
                        <button @click="makeEditable()" class="edit">
                            <img src="@/assets/icons/ic-plantparameters.svg" width="auto">
                        </button>
                    </div>
                </div>
            </template>
        </row-card>
    </div>
</template>

<script lang="ts">
import { ConfigValueModel } from '@/models';
import { ConfigValueSer } from '@/services';
import { Component, Prop, Vue } from 'vue-property-decorator';
import RowCard from './row-card.vue';

@Component({
    components: {
        RowCard,
    },
})
export default class ConfigValueEdit extends Vue {
   @Prop()
   private configModel!: ConfigValueModel;
   private isEditable = false;
   private editValue = '';
   private comment = '';
   private configSer: ConfigValueSer = new ConfigValueSer();

   private makeEditable(): void {
       this.$emit('on-edit', this.configModel);
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.bittons{
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

</style>
