<template>
    <div :class="{ 'backdrop' : true }">
        <div class="modal-dialog">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'report-modal',
    components: {},
})
export default class ReportModal extends Vue {}
</script>

<style lang='less'>
@import '../../variables.less';

.backdrop {
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background: rgba(0, 0, 0, 0.65);

    .modal-dialog {
        height: auto; 
        min-height: calc(100% - 25rem);
        min-width: calc(100% - 40rem);
        max-width: calc(100% - 20rem);
        margin: 0 auto;
        vertical-align: middle;
        margin-top:50vh;
        max-height:95vh;
        overflow:auto;
        transform: translateY(-50%);
    }
}
</style>