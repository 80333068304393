import { render, staticRenderFns } from "./strategy-cluster.vue?vue&type=template&id=65a1f7b5&scoped=true"
import script from "./strategy-cluster.vue?vue&type=script&lang=ts"
export * from "./strategy-cluster.vue?vue&type=script&lang=ts"
import style0 from "./strategy-cluster.vue?vue&type=style&index=0&id=65a1f7b5&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a1f7b5",
  null
  
)

export default component.exports