import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import {sdk} from '@/utils/coode-sdk';

class NotificationHub {
    client: HubConnection;
    baseUrl: string = process.env.VUE_APP_NOTIFICATIONS_BASE_URL ?? 'https://localhost:5001';

    constructor() {
        this.client = new HubConnectionBuilder()
            .withUrl(this.baseUrl + '/NotificationHub')
            .withAutomaticReconnect()
            .build();
    }

    public on(event: string, fn: (...args: any[]) => void) : void {
        sdk?.console.log('Adding event listener for ' + event);
        this.client.on(event, fn);
    }

    public start() : void {
        sdk?.console.log('Try starting SignalR client');
        if(this.client.state == HubConnectionState.Disconnected) {
            sdk?.console.log('Starting SignalR client');
            this.client.start();
            sdk?.console.log('SignalR client started');
        }
        else {
            sdk?.console.log('SignalR client already started');
        }
    }
}

export default new NotificationHub();
