<template>
    <modal-box
        v-if="showDownloadTemplate"
        :open="showDownloadTemplate"
        :onClose.prop="hideDownloadTemplate"
        :enableHeader.prop="true"
        :enableFooter.prop="true"
        :enableHeaderClose.prop="true"
        :transparentOverlay.prop="false"
        :closeOnClickOutside.prop="true"
    >
        <div slot="header" class="header">
            <div class="flex-row flex-1">
                <div class="flex-col flex-1">
                    <h3>{{ $t('downloadHydroDataTemplate') }}</h3>
                </div>
            </div>
        </div>
        <div>
            <div class="flex-col pr-1">
                <div class="alert alert-info">
                    {{ $t('hydroDataScenarioCodeInfo') }}
                </div>
                <label for="reviewScenarioCode">{{ $t('enterReviewScenarioCode') }}</label>
                <input type="text" class="form-control" id="reviewScenarioCode" v-model="reviewScenarioCode"/>
            </div>
        </div>
        <div slot="footer" class="footer">
            <div class="flex-row reverse">
                <div class="flex-col flex-align-end mt-1">
                    <button class="btn" @click="download">
                        {{ $t('continue') }}
                    </button>
                </div>
            </div>
        </div>
    </modal-box>
</template>

<script lang="ts">
import {Component, Mixins, Prop} from 'vue-property-decorator';
import DownloadFile from '@/mixins/download-file';
import {HydroDataService} from '@/services/hydro-data-service';
import {FileDownload} from '@/models/file-download';

@Component({
    name: 'hydro-data-download-template'
})
export default class HydroDataDownloadTemplate extends Mixins(DownloadFile) {
    @Prop()
    private showDownloadTemplate = false;
    private reviewScenarioCode = '';
    private hydroDataService = new HydroDataService();

    private async download() : Promise<void> {
        const file: FileDownload | undefined = await this.hydroDataService.downloadTemplate(this.reviewScenarioCode);
        if (file !== undefined) {
            this.downloadFile(file, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }
        this.hideDownloadTemplate();
    }

    private hideDownloadTemplate(): void
    {
        this.showDownloadTemplate = false;
        this.$emit('close');
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';

.header {
    line-height: var(--adam-d48);
    font-weight: bolder;
}

.btn {
    width: auto;
    height: 3rem;
    background-color: @uniper-blue;
    color: @white;
    border-radius: .5rem;
    border: 1px solid @uniper-blue;
    font-size: 1.2rem;
    text-transform: uppercase;
    white-space: nowrap;
    margin-left: 1rem;
}

.btn:disabled {
    color: @warm-grey;
    border: 1px solid @warm-grey;
    background: @pale-grey;
    cursor: auto;
}
</style>
