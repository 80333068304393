import BaseService from '@/utils/base-service';
import {FileDownload} from '@/models/file-download';
import {BulkDownloadRequest} from '@/models/bulk-download-request';
import {AssetIdentifier} from '@/models/asset-identifier';
import {ApiResponse} from '@/models';

export class BulkDownloadService extends BaseService {

    private endpoint = '/resultsets';

    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }

    public async download(model: BulkDownloadRequest): Promise<FileDownload> {
        const response = (await this.api.post(`${this.endpoint}/bulk-download`, model, {
            responseType: 'arraybuffer',
        }));
        return FileDownload.fromResponse(response, 'bulk-update-template.xlsx');
    }

    public async frozenReportAvailability(year: number): Promise<ApiResponse<AssetIdentifier[]>> {
        return (await this.api.get(`${this.endpoint}/frozenReportAvailability/${year}`)).data;
    }
}
