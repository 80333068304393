import { ApiResponse, StaticLinkEditModel } from '@/models';
import BaseService from '@/utils/base-service';

export class StaticLinkEditService extends BaseService {
    private endpoint = '/MachineNameMapping/CalculationDateFinalSno';

    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async postSerialNumberList(sNo: any): Promise<ApiResponse<StaticLinkEditModel[]>> {
        return (await this.api.post(`${this.endpoint}`, sNo)).data;
    }
}
