<template>
    <div
        class="dropdown-component"
        v-click-outside="() => (openDropdown = false)"
    >
        <div v-if="dataloaded" class="flex-container">
            <div class="flex-row" @click="toggleDropdown">
                <div class="flex-col">
                    <div class="flex-row pl-1 pr-1 option">
                        <div class="flex-2 txt">
                            <strong>[{{ selected.easYear }}]</strong> {{ selected.dateTime }}
                        </div>
                        <div class="flex-col flex-1">
                            <div v-if="selected.isFinal" class="lebel final">
                                {{ $t('final') }}
                            </div>
                        </div>
                        <div class="flex-col flex-1">
                            <div v-if="selected.isDataFreeze" class="lebel data-freeze">
                                {{ $t('freezed') }}
                            </div>
                        </div>
                        <div
                            class="flex-col flex-1 lebel"
                            :class="
                                selected.generatedBy == 'User'
                                    ? 'user'
                                    : 'system'
                            "
                        >
                            {{ selected.generatedBy }}
                        </div>
                    </div>
                </div>
                <span class="icon"
                    ><font-awesome-icon
                        :icon="[
                            'fas',
                            openDropdown ? 'angle-up' : 'angle-down',
                        ]"
                /></span>
            </div>
        </div>
        <div v-show="openDropdown" class="dropdown">
            <div
                v-for="item in itemList"
                :key="item.sNo"
                class="flex-row pl-1 pr-1 option"
                @click="triggerSelection(item)"
            >
                <div class="flex-2 txt"><strong>[{{ item.easYear }}]</strong> {{ item.dateTime }}</div>
                <div v-if="item.isFinal" class="flex-col flex-1">
                    <div class="lebel final">{{ $t('final') }}</div>
                </div>
                <div v-if="item.isDataFreeze" class="flex-col flex-1">
                    <div class="lebel data-freeze">{{ $t('freezed') }}</div>
                </div>
                <div class="flex-col flex-1">
                    <div class="lebel"
                         :class="item.generatedBy == 'User' ? 'user' : 'system'"
                    >
                        {{ item.generatedBy }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import clickOutside from '@/directives/click-outside';
import { RunId } from '@/models';

@Component({
    name: 'dropdown',
    directives: {
        clickOutside,
    },
})
export default class Dropdown extends Vue {
    /**
     * list of items to generate dropdown
     */
    @Prop({ required: true })
    private itemList!: RunId[];

    /**
     * item to select on load
     */
    @Prop({ required: true })
    private defaultRunId!: RunId;

    private selected: RunId | undefined = undefined;
    private openDropdown = false;
    private dataloaded = false;

    private toggleDropdown(): void {
        this.openDropdown = !this.openDropdown;
    }
    private triggerSelection(item: any): void {
        this.selected = { ...item };
        this.openDropdown = false;
        this.$emit('selected-runid', this.selected);
    }
    @Watch('itemList')
    private changedefaultSelection(): void {
        this.dataloaded = false;
        this.selected = {...this.defaultRunId};
        if (this.selected !== undefined) {
            this.triggerSelection(this.selected);
            this.dataloaded = true;
        }
    }
}
</script>

<style lang="less">
@import "../../variables.less";
.dropdown-component {
    font-size: 1.5rem;
    width: 100%;
    border: 2px solid #e9e9e9;
    color: #363636;
    border-radius: 0.6rem;
    font-size: 1.2rem;

    .dropdown {
        z-index: 98;
        position: absolute;
        background-color: #f1f1f1;
        border: 1px solid #0078dc;
        z-index: 1;
        max-height: 300px;
        white-space: nowrap;
        overflow-y: scroll;
        .option {
            background: #ffff;
            height: 50px;
            align-items: center;
        }
        .option:hover {
            background: #ddd;
        }
    }
}
.lebel {
    font-size: 1rem;
    margin: 2px;
    padding: 2px;
    border-radius: 3px;
    padding: 9px 19px 8px 19px;
    color: white;
    text-align: center;
}
span.icon {
    padding: 9px;
}
.final {
    background: @dark-green;
}
.user {
    background: @amber-darker;
}
.system {
    background: @dark-blue-lighter;
}
.data-freeze {
    background: @uniper-blue;
    white-space: nowrap;
}
.txt {
    margin-right: 2rem;
    white-space: nowrap;
    padding: 9px 19px 8px 19px;
}
</style>

