<template>
    <div class="change-log mt-4">
        <div v-if="!isDataLoaded" class="popup">
            <eas-loader></eas-loader>
        </div>
        <div v-else>
            <content-wrapper>
                <template v-slot:filters>
                    <div class="change-log-filter">
                        <div class="flex-row changeLog-filter">
                            <div class="flex-col">
                                <div class="flex-row">
                                    <div class="flex-col pt-1 pb-2 filter" v-on="hideDateFilter()">
                                        <a
                                            href="javascript:void(0)"
                                            @click="toggleDateFilter()"
                                            :class="{ active: filterItem.dateItem.isActive }"
                                        >
                                            {{ $t("filterByDate") }}
                                        <span v-if="filterFromDateTimezone || filterToDateTimezone">
                                            (
                                            <span v-if="filterFromDateTimezone">{{ dateLabelFunction(filterFromDateTimezone) }}</span>
                                            <span v-if="filterFromDateTimezone && filterToDateTimezone"> - </span>
                                            <span v-if="filterToDateTimezone">{{ dateLabelFunction(filterToDateTimezone) }}</span>
                                            )
                                        </span>
                                            <img
                                                src="@/assets/icons/ic-triangle-dropdown@2x.png"
                                                v-show="!filterItem.dateItem.isActive"
                                                :class="[ 'triangle ml-1',{ up: filterItem.dateItem.isExpanded } ]"
                                            />
                                            <img
                                                src="@/assets/icons/ic-triangle-dropdown-dark@2x.png"
                                                v-show="filterItem.dateItem.isActive"
                                                :class="[ 'triangle ml-1',{ up: filterItem.dateItem.isExpanded } ]"
                                            />
                                        </a>
                                        <fade-transition :fast="true">
                                            <div class="filter-expand" v-show="filterItem.dateItem.isExpanded">
                                                <span class="close-button" @click="closeDateFilter()">&times;</span>
                                                <div class="flex-row">
                                                    <div class="flex-col mr-3 mt-1">
                                                        <label style="font-size: 1.5rem;">{{ $t('dateFrom') }}:</label>
                                                        <datetime-picker
                                                            v-model="filterFromDateTimezone"
                                                            type="date"
                                                            class="changelog-filter-datepicker"
                                                            lang="en"
                                                            :inline="true"
                                                            format="YYYY-MM-DD"
                                                            :disabled-date="disabledDates"
                                                            @change="onDateChange(true)"
                                                        />
                                                    </div>
                                                    <div class="flex-col mt-1">
                                                        <label style="font-size: 1.5rem;">{{ $t('dateTo') }}:</label>
                                                        <datetime-picker
                                                            v-model="filterToDateTimezone"
                                                            type="date"
                                                            class="changelog-filter-datepicker"
                                                            lang="en"
                                                            :inline="true"
                                                            format="YYYY-MM-DD"
                                                            :disabled-date="disabledDates"
                                                            @change="onDateChange(false)"/>
                                                    </div>
                                                </div>
                                                <div class="flex-row apply-container">
                                                    <adam-button @click="clear()"
                                                    :disabled="!filterFromDateTimezone && !filterToDateTimezone">{{ $t('clear') }}</adam-button>
                                                    <adam-button @click="apply()" >{{ $t('apply') }}</adam-button>
                                                </div>
                                            </div>
                                        </fade-transition>
                                    </div>
                                    <span class="separator ml-1 mr-1"></span>
                                    <div class="flex-col pt-1 pb-2 filter" v-click-outside="hideTypeFilter">
                                        <a
                                            href="javascript:void(0)"
                                            @click="toggleTypeFilter()"
                                            :class="{ active: filterItem.plantItem.isActive }"
                                        >
                                        {{ $t("filterByPlant") }}
                                            <img
                                                src="@/assets/icons/ic-triangle-dropdown@2x.png"
                                                v-show="!filterItem.plantItem.isActive"
                                                :class="[ 'triangle ml-1',{ up: filterItem.plantItem.isExpanded } ]"
                                            />
                                            <img
                                                src="@/assets/icons/ic-triangle-dropdown-dark@2x.png"
                                                v-show="filterItem.plantItem.isActive"
                                                :class="[ 'triangle ml-1',{ up: filterItem.plantItem.isExpanded } ]"
                                            />
                                        </a>
                                        <fade-transition :fast="true">
                                            <div
                                                class="filter-expand filter-expand--type"
                                                v-if="filterItem.plantItem.isExpanded"
                                            >
                                                <div class="search-filter">
                                                    <input
                                                        type="text"
                                                        :placeholder="$t('plant/unit')"
                                                        v-model="filterItem.plantItem.search"
                                                        max-length="100"
                                                        class="search"
                                                    />
                                                    <font-awesome-icon :icon="['fas', 'search']" class="search-icon fa-2x"/>
                                                    <button
                                                        v-show="filterItem.plantItem.search !== ''"
                                                        class="clear-search"
                                                        @click="() => filterItem.plantItem.search = ''"
                                                    >
                                                        <font-awesome-icon :icon="['fas', 'times']" class="clear"/>
                                                    </button>
                                                </div>
                                                <div class="checkboxFilter">
                                                    <label v-if="!filteredPlants.length" class="empty-list">
                                                        {{ $t('noItemsMatched') }}
                                                    </label>
                                                    <label v-else-if="filterItem.plantItem.showSelectAll" class="checkbox-container">
                                                        <input
                                                            type="checkbox"
                                                            v-model="filterItem.plantItem.isCheckAll"
                                                            @click="checkAllPlants()"
                                                        />{{ $t('selectAll') }}
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <label
                                                        v-for="plant in filteredPlants"
                                                        :key="plant.id"
                                                        class="checkbox-container"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            v-model="filterItem.plantItem.checkedList"
                                                            @change='updateCheckallPlants()'
                                                            :value="plant.id"
                                                        />
                                                        {{ plant.name }}
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <button
                                                    type="button"
                                                    class="done-filter-button mt-1"
                                                    @click="filterPlantChange()"
                                                >
                                                    {{ $t("done") }}
                                                </button>
                                            </div>
                                        </fade-transition>
                                    </div>
                                    <span class="separator ml-1 mr-1"></span>
                                    <div class="flex-col pt-1 pb-2 filter" v-click-outside="hideUserFilter">
                                        <a
                                            href="javascript:void(0)"
                                            @click="toggleUserFilter()"
                                            :class="{ active: filterItem.userItem.isActive }"
                                        >
                                            {{ $t("filterByUser") }}
                                            <img
                                                src="@/assets/icons/ic-triangle-dropdown@2x.png"
                                                v-show="!filterItem.userItem.isActive"
                                                :class="[ 'triangle ml-1',{ up: filterItem.userItem.isExpanded } ]"
                                            />
                                            <img
                                                src="@/assets/icons/ic-triangle-dropdown-dark@2x.png"
                                                v-show="filterItem.userItem.isActive"
                                                :class="[ 'triangle ml-1',{ up: filterItem.userItem.isExpanded } ]"
                                            />
                                        </a>
                                        <fade-transition :fast="true">
                                            <div
                                                class="filter-expand filter-expand--type"
                                                v-if="filterItem.userItem.isExpanded"
                                            >
                                                <div class="search-filter">
                                                    <input
                                                        type="text"
                                                        :placeholder="$t('user')"
                                                        v-model="filterItem.userItem.search"
                                                        max-length="100"
                                                        class="search"
                                                    />
                                                    <font-awesome-icon :icon="['fas', 'search']" class="search-icon fa-2x"/>
                                                    <button
                                                        v-show="filterItem.userItem.search !== ''"
                                                        class="clear-search"
                                                        @click="() => filterItem.userItem.search = ''"
                                                    >
                                                        <font-awesome-icon :icon="['fas', 'times']" class="clear"/>
                                                    </button>
                                                </div>
                                                <div class="checkboxFilter">
                                                    <label v-if="!filteredUsers.length" class="empty-list">
                                                        {{ $t('noItemsMatched') }}
                                                    </label>
                                                    <label v-else-if="filterItem.userItem.showSelectAll" class="checkbox-container">
                                                        <input
                                                            type="checkbox"
                                                            v-model="filterItem.userItem.isCheckAll"
                                                            @click="checkAllUsers()"
                                                        />{{ $t('selectAll') }}
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <label
                                                        class="checkbox-container"
                                                        v-for="(user, i) in filteredUsers"
                                                        :key="i"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            v-model="filterItem.userItem.checkedList"
                                                            @change="updateCheckallUsers()"
                                                            :value="user"
                                                        />{{ user }}
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <button
                                                    type="button"
                                                    class="done-filter-button mt-1"
                                                    @click="filterUserChange()"
                                                >
                                                    {{ $t("done") }}
                                                </button>
                                            </div>
                                        </fade-transition>
                                    </div>
                                    <span class="separator ml-1 mr-1"></span>
                                    <div class="flex-col pt-1 pb-2 filter" v-click-outside="hideItemFilter">
                                        <a
                                            href="javascript:void(0)"
                                            @click="toggleChangedItemFilter()"
                                            :class="{ active: filterItem.changedItem.isActive }"
                                        >
                                            {{ $t("filterByChangedItem") }}
                                            <img
                                                src="@/assets/icons/ic-triangle-dropdown@2x.png"
                                                v-show="!filterItem.changedItem.isActive"
                                                :class="[ 'triangle ml-1',{ up: filterItem.changedItem.isExpanded } ]"
                                            />
                                            <img
                                                src="@/assets/icons/ic-triangle-dropdown-dark@2x.png"
                                                v-show="filterItem.changedItem.isActive"
                                                :class="[ 'triangle ml-1',{ up: filterItem.changedItem.isExpanded } ]"
                                            />
                                        </a>
                                        <fade-transition :fast="true">
                                            <div
                                                class="filter-expand filter-expand--type"
                                                v-if="filterItem.changedItem.isExpanded"
                                            >
                                                <div class="search-filter">
                                                    <input
                                                        type="text"
                                                        :placeholder="$t('changedItem')"
                                                        v-model="filterItem.changedItem.search"
                                                        max-length="100"
                                                        class="search"
                                                    />
                                                    <font-awesome-icon :icon="['fas', 'search']" class="search-icon fa-2x"/>
                                                    <button
                                                        v-show="filterItem.changedItem.search !== ''"
                                                        class="clear-search"
                                                        @click="() => filterItem.changedItem.search = ''"
                                                    >
                                                        <font-awesome-icon :icon="['fas', 'times']" class="clear"/>
                                                    </button>
                                                </div>
                                                <div class="checkboxFilter">
                                                    <label v-if="!filteredItems.length" class="empty-list">
                                                        {{ $t('noItemsMatched') }}
                                                    </label>
                                                    <label v-else-if="filterItem.changedItem.showSelectAll" class="checkbox-container">
                                                        <input
                                                            type="checkbox"
                                                            v-model="filterItem.changedItem.isCheckAll"
                                                            @click="checkAllItems()"
                                                        />{{ $t('selectAll') }}
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <label
                                                        class="checkbox-container"
                                                        v-for="(item, i) in filteredItems"
                                                        :key="i"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            v-model="filterItem.changedItem.checkedList"
                                                            @change="updateCheckallItems()"
                                                            :value="item"
                                                        />{{ item }}
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <button
                                                    type="button"
                                                    class="done-filter-button mt-1"
                                                    @click="filterChangedItem()"
                                                >
                                                    {{ $t("done") }}
                                                </button>
                                            </div>
                                        </fade-transition>
                                    </div>
                                    <span class="separator ml-1"></span>
                                    <div class="flex-col pt-1 pl-1">
                                        <button
                                            type="button"
                                            class="remove-button reset-filter-button"
                                            :class="{reset: filterItem.resetFilters.isActive}"
                                            @click="resetFilter()">
                                            {{ $t('resetFilters') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-col flex-1">
                                <div class="flex-row reverse" style="align-items: center">
                                    <div v-if="isDataLoaded" class="flex-col mr-4 ml-1" @click="onUiToggle()">
                                        <div :class="['flex-row', 'toggler', isTableData ? 'reverse' : '']">
                                            <div class="flex-col flex-1 toggle-text"><font-awesome-icon :icon="['fas', isTableData ? 'text-width' : 'chart-pie']" /></div>
                                            <div class="flex-col flex-1 toggle-switch"></div>
                                        </div>
                                    </div>
                                    <span class="separator ml-1"></span>
                                    <div class="flex-col" style="align-items: flex-end">
                                        <select v-model="pageSize" @change="changePageSize">
                                            <option
                                                v-for="size in pageSizeSelection"
                                                :key="size"
                                                :value="size"
                                            >{{ size }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="flex-col pagesizelebel mr-2">Page Size:</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:content>
                    <div v-if="!pagingData.totalPageCount" class="ml-4 changelog-table no-data">
                        {{ $t('noDataAvailable') }}
                    </div>
                    <paging v-else :pagingData="pagingData" @current-page="modifyContent">
                    </paging>
                </template>
            </content-wrapper>
            <adam-table v-if="pagingData.totalPageCount" class="mr-3 ml-3 changelog-table"
                :columns.prop="columns"
                :data.prop="data"
                :onRowExpanded.prop="rowExpanded"
                :hasActions.prop="true">
                <span slot="rowDetails">
                    <p><em><b>Old Text:</b></em> [[ oldValue ]]</p>
                    <p><em><b>New Text:</b></em> [[ newValue ]]</p>
                    <p><em><b>Rationale:</b></em> [[ updComment ]]</p>
                </span>
            </adam-table>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import EasLoader from '../loader/eas-loader.vue';
import { AssetService, ChangeLogService } from '@/services';
import FadeTransition from '@/components/fade-transition/fade-transition.vue';
import clickOutside from '../../directives/click-outside';
import ContentWrapper from '../content-wrapper/content-wrapper.vue';
import Paging from '@/components/paging/paging.vue';
import { DateHelper } from '@/utils';
import moment from 'moment';
import { PagedResult } from '@/models';


@Component({
    name: 'change-log',
    components: {
        EasLoader,
        fadeTransition: FadeTransition,
        ContentWrapper,
        Paging,
    },
    directives: {
        clickOutside,
    },
})
export default class ChangeLog extends Vue {

    private tableName = '';
    private isTableData = true;
    private columns: any[] = [];
    private data: any[] = [];
    private isDataLoaded = false;
    private changeLogResult: PagedResult | null = null;
    private changeLogService = new ChangeLogService();
    /* Filter By date */
    private today = new Date();
    private filterFromDate = '';
    private filterToDate = '';
    private filterFromDateTimezone: Date | null = null;
    private filterToDateTimezone: Date | null = null;
    private assetService: AssetService = new AssetService();
    private filterItem: any = {
        plantItem: {
            isExpanded: false,
            isActive: false,
            showSelectAll: true,
            isCheckAll: false,
            list: [],
            checkedList: [],
            search: '',
        },
        userItem: {
            isExpanded: false,
            isActive: false,
            showSelectAll: true,
            isCheckAll: false,
            list: [],
            checkedList: [],
            search: '',
        },
        dateItem: {
            isExpanded: false,
            isActive: false,
        },
        changedItem: {
            isExpanded: false,
            isActive: false,
            showSelectAll: true,
            isCheckAll: false,
            list: [],
            checkedList: [],
            search: '',
        },
        resetFilters: {
            isExpanded: false,
            isActive: false,
        },
    };

    /*PAGING */
    private currentPage = 1;
    private contentPerPage = 5;
    private pageSize = 5;
    private pageSizeSelection: number[] = [5, 10, 15, 25, 30];
    private title = '';
    private value = '';
    private comment = '';
    private errorExists = false;
    private pagingData: any = {
        currentPage: 1,
        hasNext: false,
        hasPrevious: false,
        totalPageCount: 37,
        prevArray: [],
        afterArray: [],
    };

    private mounted(): void {
        this.isDataLoaded = false;
        this.modifyContent(1);
        this.createUserList();
        this.createChangedItemList();
        this.changePageSize();
    }


    private async changePageSize(): Promise<void> {
        await this.modifyContent(1);
    }
    private async modifyContent(currentPage: number): Promise<void> {
        this.changeLogResult = (await this.changeLogService.
            getPages(currentPage, this.pageSize, {
                dateFrom: this.filterFromDate,
                dateTo: this.filterToDate,
                plantIds: this.filterItem.plantItem.checkedList,
                user: this.filterItem.userItem.checkedList,
                changedItem: this.filterItem.changedItem.checkedList,
                tableName: this.tableName,
            })).result;
        this.filterItem.plantItem.list = (await this.assetService.get()).result;
        this.filterItem.plantItem.list.push({id: '0', name: this.$i18n.t('genericUIText')});
        this.data = this.changeLogResult.items;
        this.columns = this.changeLogResult.meta;
        this.isDataLoaded = true;

        this.pagingData.totalPageCount = Math.ceil(this.changeLogResult.totalCount / this.pageSize);
        this.pagingData.currentPage = currentPage;
        this.pagingData.hasPrevious = (currentPage > 1) ? true : false;
        this.pagingData.hasNext = (currentPage < this.pagingData.totalPageCount) ? true : false;
        this.pagingData.prevArray = [];
        this.pagingData.afterArray = [];
        for ( let i = 1; i < 3; i++) {
            if (currentPage - i >= 1) { this.pagingData.prevArray.unshift(currentPage - i); }
            if (currentPage + i <= this.pagingData.totalPageCount) { this.pagingData.afterArray.push(currentPage + i); }
        }
    }

    private rowExpanded(row: any): void {
        console.log('expanded', row);
    }

    private checkAllUsers(): any {
        this.filterItem.userItem.isCheckAll = !this.filterItem.userItem.isCheckAll;
        if (this.filterItem.userItem.isCheckAll) {
            this.filterItem.userItem.checkedList = [...this.filterItem.userItem.list];
        } else {
            this.filterItem.userItem.checkedList = [];
        }
    }

    private updateCheckallUsers(): any {
        this.filterItem.userItem.isCheckAll =
            this.filterItem.userItem.checkedList.length === this.filterItem.userItem.list.length;
    }

    private checkAllItems(): any {
        this.filterItem.changedItem.isCheckAll = !this.filterItem.changedItem.isCheckAll;
        if (this.filterItem.changedItem.isCheckAll) {
            this.filterItem.changedItem.checkedList = [...this.filterItem.changedItem.list];
        } else {
            this.filterItem.changedItem.checkedList = [];
        }
    }

    private updateCheckallItems(): any {
        this.filterItem.changedItem.isCheckAll =
            this.filterItem.changedItem.checkedList.length === this.filterItem.changedItem.list.length;
    }

    private checkAllPlants(): any {
        this.filterItem.plantItem.isCheckAll = !this.filterItem.plantItem.isCheckAll;
        this.filterItem.plantItem.checkedList = [];
        if (this.filterItem.plantItem.isCheckAll) {
            for (const key in this.filterItem.plantItem.list) {
                this.filterItem.plantItem.checkedList.push(this.filterItem.plantItem.list[key].id);
            }
        }
    }

    private updateCheckallPlants(): any {
        this.filterItem.plantItem.isCheckAll =
            this.filterItem.plantItem.checkedList.length === this.filterItem.plantItem.list.length;
    }

    /******* Filter By Date ********/
    private toggleDateFilter(): void {
        this.filterItem.dateItem.isExpanded = !this.filterItem.dateItem.isExpanded;
        this.filterItem.plantItem.isExpanded = false;
        this.filterItem.userItem.isExpanded = false;
        this.filterItem.changedItem.isExpanded = false;
    }

    private hideDateFilter(): void {
        if (this.filterItem.plantItem.isExpanded || this.filterItem.userItem.isExpanded
            || this.filterItem.changedItem.isExpanded) {
            this.filterItem.dateItem.isExpanded = false;
        }
    }

    private closeDateFilter(): void {
        this.filterItem.dateItem.isExpanded = false;
    }

    private apply(): void {
        this.filterFromDate = moment(this.filterFromDateTimezone).format('DD MMM YYYY HH:mm');
        const nextToDate = moment(this.filterToDateTimezone).add(1, 'days');
        this.filterToDate = moment(nextToDate).format('DD MMM YYYY HH:mm');
        this.filterItem.dateItem.isExpanded = false;
        this.filterItem.dateItem.isActive = (this.filterFromDateTimezone && this.filterToDateTimezone);
        this.changePageSize();
    }

    private onDateChange(startDateChange: boolean): void {
        if ((startDateChange && this.filterFromDateTimezone && this.filterToDateTimezone)
            && (this.filterFromDateTimezone > this.filterToDateTimezone)) {
            this.filterToDateTimezone = this.filterFromDateTimezone;
        } else if ((!startDateChange && this.filterFromDateTimezone && this.filterToDateTimezone)
            && (this.filterToDateTimezone < this.filterFromDateTimezone)) {
            this.filterFromDateTimezone = this.filterToDateTimezone;
        }
    }

    private disableApply(): any {
        if (this.filterFromDateTimezone === null || this.filterToDateTimezone === null) {
            return true;
        }
    }

    private disabledDates(date: Date): Date | null {
        return this.today < date ? date : null;
    }

    private clear(): void {
        this.filterFromDateTimezone = null;
        this.filterToDateTimezone = null;
        this.filterItem.dateItem.isActive = false;
    }

    private dateLabelFunction(date: Date): string {
        return DateHelper.formatDate(date.toISOString());
    }
    /******* Filter By Plant ********/
    private toggleTypeFilter(): void {
        this.filterItem.plantItem.isExpanded = !this.filterItem.plantItem.isExpanded;
    }

    private filterPlantChange(): void {
        this.filterItem.plantItem.isExpanded = false;
        this.filterItem.plantItem.isActive = this.filterItem.plantItem.checkedList.length > 0;
        this.filterItem.plantItem.search = '';
        this.changePageSize();
    }

    private hideTypeFilter(): void {
        if (this.filterItem.plantItem.isExpanded) {
            this.filterItem.plantItem.isExpanded = false;
        }
    }

    get filteredPlants(): any {
        if (this.filterItem.plantItem.search !== '') {
            this.filterItem.plantItem.showSelectAll = false;
        } else {
            this.filterItem.plantItem.showSelectAll = true;
        }
        return this.filterItem.plantItem.list.filter((x: any) => {
            const search = this.filterItem.plantItem.search.toLowerCase()
                .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const p = x.name.toLowerCase();
            return p.match(search);
        });
    }
    private disableDonePlant(): any {
        return this.filterItem.plantItem.checkedList.filter((p: any) => p).length === 0;
    }
    /******* Filter By User *******/
    private toggleUserFilter(): void {
        this.filterItem.userItem.isExpanded = !this.filterItem.userItem.isExpanded;
    }

    private filterUserChange(): void {
        this.filterItem.userItem.isExpanded = false;
        this.filterItem.userItem.isActive = this.filterItem.userItem.checkedList.length > 0;
        this.filterItem.userItem.search = '';
        this.changePageSize();
    }

    private hideUserFilter(): void {
        if (this.filterItem.userItem.isExpanded) {
            this.filterItem.userItem.isExpanded = false;
        }
    }

    private async createUserList(): Promise<any> {
        this.filterItem.userItem.list = (await this.changeLogService.getUsers()).result;
        this.filterItem.userItem.list = this.filterItem.userItem.list.filter((x: any) => x != null);
        if (this.filteredUsers.length === 0) {
            this.filterItem.userItem.showSelectAll = true;
        }
    }

    get filteredUsers(): any {
        if (this.filterItem.userItem.search !== '') {
            this.filterItem.userItem.showSelectAll = false;
        } else {
            this.filterItem.userItem.showSelectAll = true;
        }
        return this.filterItem.userItem.list.filter((x: any) => {
            const search = this.filterItem.userItem.search.toLowerCase()
                .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const p = x.toLowerCase();
            return p.match(search);
        });
    }
    private disableDoneUser(): any {
        return this.filterItem.userItem.checkedList.filter((p: any) => p).length === 0;
    }

    /******* Filter By Changed Item *******/
    private toggleChangedItemFilter(): void {
        this.filterItem.changedItem.isExpanded = !this.filterItem.changedItem.isExpanded;
    }

    private filterChangedItem(): void {
        this.filterItem.changedItem.isExpanded = false;
        this.filterItem.changedItem.isActive = this.filterItem.changedItem.checkedList.length > 0;
        this.filterItem.changedItem.search = '';
        this.changePageSize();
    }

    private hideItemFilter(): void {
        if (this.filterItem.changedItem.isExpanded) {
            this.filterItem.changedItem.isExpanded = false;
        }
    }
    private async createChangedItemList(): Promise<any> {
        this.filterItem.changedItem.list = (await this.changeLogService.getChangedItems()).result;
        this.filterItem.changedItem.list = this.filterItem.changedItem.list.filter((x: any) => x != null);
    }

    get filteredItems(): any {
        if (this.filterItem.changedItem.search !== '') {
            this.filterItem.changedItem.showSelectAll = false;
        } else {
            this.filterItem.changedItem.showSelectAll = true;
        }
        return this.filterItem.changedItem.list.filter((x: any) => {
            const search = this.filterItem.changedItem.search.toLowerCase()
                .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const p = x.toLowerCase();
            return p.match(search);
        });
    }
    private disableDoneItem(): any {
        return this.filterItem.changedItem.checkedList.filter((p: any) => p).length === 0;
    }

    private resetFilter(): void {
        this.filterFromDateTimezone = null;
        this.filterToDateTimezone = null;
        this.filterItem.dateItem.isExpanded = false;
        this.filterItem.dateItem.isActive = false;
        this.filterItem.plantItem.isExpanded = false;
        this.filterItem.plantItem.isActive = false;
        this.filterItem.userItem.isExpanded = false;
        this.filterItem.userItem.isActive = false;
        this.filterItem.changedItem.isExpanded = false;
        this.filterItem.changedItem.isActive = false;
        this.filterItem.plantItem.isCheckAll = false;
        this.filterItem.userItem.isCheckAll = false;
        this.filterItem.changedItem.isCheckAll = false;
        this.filterFromDate = '';
        this.filterToDate = '';
        const arr: any[] = [];
        this.filterItem.plantItem.checkedList = arr;
        this.filterItem.userItem.checkedList = arr;
        this.filterItem.changedItem.checkedList = arr;
        this.filterItem.plantItem.search = '';
        this.filterItem.userItem.search = '';
        this.filterItem.changedItem.search = '';
        this.changePageSize();
    }
    private async onUiToggle(): Promise<void> {
        this.isTableData = !this.isTableData;
        if (this.isTableData) {
            this.tableName = 'freetext';
        } else {
            this.tableName = 'resultset';
        }
        await this.changePageSize();
    }
}
</script>

<style lang="less">
@import './change-log-filter.less';
adam-table {
//   --table-active-color: red;
    --table-header-bg: @uniper-blue;
    --table-bg: @light-blue-grey;
    --table-header-color: white;
}
:host {
    padding: 40px;
    display: block;
}
.no-data {
    font-size: 2rem;
    width: 100%;
    text-align: center;
    background-color: @uniper-blue;
    color: @white;
}
.changelog-table {
    margin-top: 8rem;
    justify-content: center;
    width: 95%;
}
.adam-table {
    .table__header-cell, .table__body-cell, .table__body-cell-action {
        &:nth-child(1) {
          flex: 0.2 1 5%;
        }
        &:nth-child(2) {
          flex: 0.2 0 20%;
        }
        &:nth-child(3) {
          flex: 0.3 0 20%;
        }
        &:last-child {
          flex: 0.05 0 1%;
        }
    }
}
</style>
