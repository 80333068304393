<template>
    <div class="amprocess pb-1">
        <div v-if="!isDataLoaded">{{ $t('loading') }}</div>
        <div v-else>
            <div v-if="!showAmpTable" class="flex-row">
            </div>
            <div v-else>
                <div class="flex-row flex-6 flex-justify-center">
                    <div class="flex-col flex-1">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th colspan="7" class="mainheader bl br pb-2">{{ $t('maintenanceStrategyAndRisk') }}</th>
                                    <th class="br"></th>
                                    <th></th>
                                    <th colspan="9" class="mainheader bl br pb-2">{{ $t('digitalizationComplianceAndSupport') }}</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th class="sub-category" v-for="decisionModel in decisionModelListLocal" :key="decisionModel.id">
                                        <div class="rotate">{{ decisionModel.category }}</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="category bt bb br">{{ grow }}</td>
                                    <td v-for="(decisionModel, index) in decisionModelListLocal" :key="decisionModel.id"
                                        class="tooltip"
                                        :class="{ bt: true, bb: true, br: isBorderRightRequired(index), darkGreenBox: decisionModel.process==grow }"
                                    >
                                        <div v-if="decisionModel.process === 'Grow Value'">
                                            <div class="tooltiptext">
                                                <div class="hover-box-title mb-1">
                                                    {{ decisionModel.category }}
                                                </div>
                                                <div>
                                                    {{ decisionModel.description || $t('noDecriptionAvaliable') }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="category bt bb br">{{ maintain }}</td>
                                    <td v-for="(decisionModel, index) in decisionModelListLocal" :key="decisionModel.id"
                                        class="tooltip"
                                        :class="{ bt: true, bb: true, br: isBorderRightRequired(index), lightGreenBox: decisionModel.process==maintain }"
                                    >
                                        <div v-if="decisionModel.process === 'Maintain Value'">
                                            <div class="tooltiptext">
                                                <div class="hover-box-title mb-1">
                                                    {{ decisionModel.category }}
                                                </div>
                                                <div>
                                                    {{ decisionModel.description || $t('noDecriptionAvaliable') }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="category bt bb br">{{ keepOption }}</td>
                                    <td v-for="(decisionModel, index) in decisionModelListLocal" :key="decisionModel.id"
                                        class="tooltip"
                                        :class="{ bt: true, bb: true, br: isBorderRightRequired(index), yellowBox: decisionModel.process==keepOption }"
                                    >
                                        <div v-if="decisionModel.process === 'Keep Option'">
                                            <div class="tooltiptext">
                                                <div class="hover-box-title mb-1">
                                                    {{ decisionModel.category }}
                                                </div>
                                                <div>
                                                    {{ decisionModel.description || $t('noDecriptionAvaliable') }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="category bt bb br">{{ optimizeEol }}</td>
                                    <td v-for="(decisionModel, index) in decisionModelListLocal" :key="decisionModel.id"
                                        class="tooltip"
                                        :class="{ bt: true, bb: true, br: isBorderRightRequired(index), redBox: decisionModel.process==optimizeEol }"
                                    >
                                        <div v-if="decisionModel.process === 'Optimize EOL'">
                                            <div class="tooltiptext">
                                                <div class="hover-box-title mb-1">
                                                    {{ decisionModel.category }}
                                                </div>
                                                <div>
                                                    {{ decisionModel.description || $t('noDecriptionAvaliable') }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AMPModel, DecisionModel } from '@/models';

@Component({
    name: 'amprocess',
})
export default class AmProcess extends Vue {
    /**
     * List of decision model subcategory containing decision value.
     */
    @Prop({ required: true })
    private decisionModelList!: DecisionModel[];

    /**
     * Set AM process data.
     */
    @Prop({ required: true })
    private ampModel!: AMPModel;

    private isEditable = false;
    private decisionModelListLocal: DecisionModel[] = [];
    private isDataLoaded = false;
    private showAmpTable = this.ampModel.showAmpTable;
    private grow = '';
    private maintain = '';
    private keepOption = '';
    private optimizeEol = '';

    private mounted(): void {
        this.grow = this.$i18n.t('grow') as string;
        this.maintain = this.$i18n.t('maintain') as string;
        this.keepOption = this.$i18n.t('keepOption') as string;
        this.optimizeEol = this.$i18n.t('optimizeEol') as string;
        this.decisionModelListLocal = JSON.parse(JSON.stringify(this.decisionModelList));
        this.isDataLoaded = true;
    }

    @Watch('decisionModelList')
    private updateComponent(newData: DecisionModel[], oldData: DecisionModel[]): void {
        this.isDataLoaded = false;
        this.decisionModelListLocal = JSON.parse(JSON.stringify(newData));
        if (newData && newData !== oldData) {
            this.isDataLoaded = true;
        }
    }

    private isBorderRightRequired(index: number): boolean {
        const cellRequiredBorderRight = [ 6, 7, 8, this.decisionModelListLocal.length - 1 ];
        return cellRequiredBorderRight.includes(index);
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';
.amprocess {
    white-space: pre-line;
    margin: 0 -1rem;
    width: 103%;
    font-size: 1rem;
    table {
        border-spacing: 0;
        border-collapse: collapse;
    }
    .scrollable {
        overflow: auto;
    }
    .rotate {
        margin: 0 auto;
        padding: 0.5rem;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        letter-spacing: 0.15rem;
    }
    .bl {
        border-left: 0.1rem solid @dark-grey;
    }
    .br {
        border-right: 0.1rem solid @dark-grey;
    }
    .bt {
        border-top: 0.1rem solid @dark-grey;
    }
    .bb {
        border-bottom: 0.1rem solid @dark-grey;
    }
    .sub-category {
        font-size: 1.5rem;
        font-weight: lighter;
        text-align: center;
        vertical-align: bottom;
    }
    .category {
        font-size: 1.5rem;
        text-align: left;
        border-left: none !important
    }
    td {
        border: 0.1rem solid @warm-grey;
        height: 2rem;
        width: 2rem;
        white-space: nowrap;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        line-height: 100%;
        padding: auto;
        vertical-align: middle;
    }
    th:first-child,
    th:nth-child(8),
    th:nth-child(9),
    th:nth-child(10),
    th:last-child {
        border-right: 0.1rem solid @dark-grey;
    }
    .mainheader {
        font-size: 1.5rem;
        font-stretch: extra-expanded;
    }
    .darkGreenBox::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        margin-top: 0.3rem;
        margin-bottom: 0.1rem;
        background-color: @green-darker !important;
    }
    .lightGreenBox::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        margin-top: 0.3rem;
        margin-bottom: 0.1rem;
        background-color: @contextual-green !important;
    }
    .yellowBox::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        margin-top: 0.3rem;
        margin-bottom: 0.1rem;
        background-color: @amber-light;
    }
    .redBox::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        margin-top: 0.3rem;
        margin-bottom: 0.1rem;
        background-color: @red-lighter;
    }
    .flex-justify-center {
        justify-content: center;
    }
    .tooltip {
        position: relative;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        font-size: 1.5rem;
        width: 45rem;
        height: auto;
        max-height: 20rem;
        line-height: 1.5rem;
        background-color: @uniper-blue-light;
        color: @white;
        white-space: pre-wrap;
        padding: 1.2rem;
        border-radius: @border-radius-standard;

        content: " ";
        position: absolute;
        overflow: scroll;
        overflow-x: hidden;
        z-index: 1;
        bottom: 100%;
        right: 50%;
        border-width: 0.75rem;
        border-style: solid;
        border-color: transparent transparent transparent transparent;
    }
    .hover-box-title {
        left: 0;
        font-weight: bolder;
    }
    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: @uniper-blue-light;
    }
}
</style>
