import { CommentModel, ApiResponse, PagedResult, FooterData } from '@/models';
import { CommentAttributes, CommentModelPage } from '@/models/comment-model';
import BaseService from '@/utils/base-service';
import { BulkUpdateResult } from '@/models/bulk-update-result';
import { FileDownload } from '@/models/file-download';

export class CommentService extends BaseService {
    private endpoint = '/FreeTexts';

    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async get(pageno: number, pagesize: number, filters: CommentModelPage): Promise<ApiResponse<PagedResult>> {
        return (await this.api.post(`${this.endpoint}/paged?PageNr=${pageno}&PageSize=${pagesize}`, filters)).data;
    }
    public async getAttributes(): Promise<ApiResponse<CommentAttributes[]>> {
        return (await this.api.get(`${this.endpoint}/attributes`)).data;
    }
    public async getFooter(id: number): Promise<ApiResponse<FooterData[]>> {
        return (await this.api.get(`${this.endpoint}/footer/${id}`)).data;
    }
    public async save(comment: CommentModel): Promise<ApiResponse<CommentModel>> {
        return (await this.api.post(`${this.endpoint}`, comment)).data;
    }
    public async getPopUpMessage(): Promise<ApiResponse<any>> {
        return (await this.api.get(`${this.endpoint}/pop-up-message`)).data;
    }

    public async bulkUpdate(file: File, dryRun = false): Promise<ApiResponse<BulkUpdateResult>> {
        const formData = new FormData();
        formData.append('file', file);
        return (await this.api.post(`${this.endpoint}/BulkUpdate?dryRun=${dryRun}`,
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })).data;
    }

    public async downloadTemplate(): Promise<FileDownload> {
        const response = (await this.api.get(`${this.endpoint}/DownloadTemplate`,
            {
                responseType: 'arraybuffer',
            }));

        return FileDownload.fromResponse(response, 'bulk-update-template.xlsx');
    }
}
