import { ApiResponse } from '@/models';
import BaseService from '@/utils/base-service';
import {KpiMapping} from '@/models/kpi-mapping';
import {KpiDto} from '@/models/kpi-dto';

export interface KpiMappingUpdateData {
    comment: string;
    chartType: string;
    kpis: {id:number}[];
}

export class KpiMappingsService extends BaseService {
    private kpiMappingsEndpoint = '/Configurations/AssetKpiMappings';
    private kpisEndpoint = '/Configurations/Kpis';

    constructor() {
        super(process.env.VUE_APP_API_BASE_URL);
    }
    public async getAll(): Promise<ApiResponse<KpiMapping[]>> {
        return (await this.api.get(`${this.kpiMappingsEndpoint}`)).data;
    }

    public async getAvailableKpis(): Promise<ApiResponse<KpiDto[]>> {
        return (await this.api.get(`${this.kpisEndpoint}`)).data;
    }

    public async updateKpiMapping(hierarchyId: number, kpiMapping: KpiMappingUpdateData): Promise<void> {
        await this.api.post(`${this.kpiMappingsEndpoint}/${hierarchyId}`, kpiMapping);
    }
}
