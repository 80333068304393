<template>
    <div class='csa'>
        <eas-loader v-if="!dataloaded"></eas-loader>
        <div v-else class="csabody">
            <div class="flex-row flex-5 mb-1">
                <div class="flex-col flex-4">
                    <div class="flex-row flex-1">
                        <div class="flex-col flex-1 flex-justify-center label">{{ csaLabels.commercialOutlookLabel }}: </div>
                        <div class="flex-col flex-1">
                            <div class="flex-row flex-1">
                                <div class="flex-col flex-1 flex-justify-center">{{ csaLabels.commercialOutlookSubLabel1 }}: </div>
                                <div class="flex-col flex-1 flex-justify-center">{{ csaData.commercialOutlookSubValue1 }}</div>
                            </div>
                            <div class="flex-row flex-1">
                                <div class="flex-col flex-1 flex-justify-center">{{ csaLabels.commercialOutlookSubLabel2 }}: </div>
                                <div class="flex-col flex-1 flex-justify-center">{{ csaData.commercialOutlookSubValue2 }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-row flex-2">
                        <div class="flex-col flex-1 flex-justify-center label">{{ csaLabels.currentValueDriverLabel }}: </div>
                        <div class="flex-col flex-1 flex-justify-center pre-formatted">{{ csaData.currentValueDriver }}</div>
                    </div>
                    <div class="flex-row flex-2">
                        <div class="flex-col flex-1 flex-justify-center label">{{ csaLabels.ltContractValueLabel }}: </div>
                        <div class="flex-col flex-1 flex-justify-center">{{ csaData.ltContractValue }}</div>
                    </div>
                    <div class="flex-row flex-2">
                        <div class="flex-col flex-3 flex-justify-center label">{{ csaLabels.runningRegimeTrendLabel }}: </div>
                        <div class="flex-col flex-2 flex-justify-center">{{ csaData.runningRegime }}</div>
                        <div class="flex-col flex-1 flex-justify-center" style="align-items: center">
                            <div v-if="csaData.runningTrend == 'Unchanged'" style="font-size: 2rem"><font-awesome-icon :icon="['fas', 'arrow-right']" /></div>
                            <div v-else-if="csaData.runningTrend == 'Up'" class="uparrow" style="font-size: 2rem"><font-awesome-icon :icon="['fas', 'arrow-up']" /></div>
                            <div v-else-if="csaData.runningTrend == 'Down'" class="downarrow" style="font-size: 2rem"><font-awesome-icon :icon="['fas', 'arrow-down']" /></div>
                            <div v-else-if="csaData.runningTrend == 'No running forecasted'">{{ $t('noRunningForecasted') }}</div>
                        </div>
                    </div>
                </div>
                <div class="flex-col flex-3">
                    <div class="flex-row flex-1 flex-justify-center chart-title chart-title-fcf">{{ $t('freeCashFlow') }}</div>
                    <div class="flex-row flex-6 flex-justify-end">
                        <div :id="fcfChartId" class="fcf-chart"></div>
                    </div>
                    <div class="flex-row flex-1 flex-justify-center mb-2">
                        <div class="flex-col flex-align-end text-no-wrap pl-5">
                            <span class="legend-p5-p95"><font-awesome-icon :icon="['fas', 'square']" /></span>
                        </div>
                        <div class="flex-col">P5 - P95</div>
                    </div>
                    <div v-if="csaData.isCo2GraphRequired" class="flex-row flex-1 flex-justify-center chart-title chart-title-co2">{{ $t('absoluteCo2Emissions') }}</div>
                    <div v-if="csaData.isCo2GraphRequired" class="flex-row flex-6 flex-justify-end">
                        <div :id="co2ChartId" class="co2-chart"></div>
                    </div>
                    <div v-else class="co2graphspace">
                    </div>
                </div>
            </div>

            <div class="flex-row flex-2 mb-1">
                <div class="flex-col flex-4 flex-justify-center label"><p v-html="csaLabels.co2AbatementCostsLabel.replace('CO2', 'CO<sub>2</sub>')+':' "></p> </div>
                <div class="flex-col flex-3">
                    <div class="flex-row flex-1">{{ csaData.co2AbatementCostsText1 }}</div>
                    <div class="flex-row flex-1">{{ csaData.co2AbatementCostsText2 }}</div>
                </div>
                <div class="flex-col flex-3">
                    <div class="flex-row flex-1">{{ csaData.co2AbatementCostsValue1 }}</div>
                    <div class="flex-row flex-1">{{ csaData.co2AbatementCostsValue2 }}</div>
                </div>
                <div class="flex-col flex-3">
                    <div class="flex-row flex-1">{{ csaLabels.specificEmissionsLabel }}: </div>
                    <div class="flex-row flex-1">{{ csaData.specificEmissionsText }}</div>
                </div>
                <div class="flex-col flex-3">
                    <div v-if="csaData.specificEmissionsValue>0" class="flex-row flex-2 flex-center">{{ csaData.specificEmissionsValue }} {{ csaLabels.specificEmissionsMeasureUnitLabel }}</div>
                </div>
            </div>
            <div class="flex-row flex-1 mb-1">
                <div class="flex-col flex-1 flex-justify-center label">{{ csaLabels.commentSustainabilityLabel }}: </div>
                <div class="flex-col flex-3 flex-wrap">{{ co2subscript(csaData.commentSustainabilityValue) }}</div>
            </div>
            <div class="flex-row flex-1 mb-1">
                <div class="flex-col flex-1 flex-justify-center label">{{ csaLabels.externalRisksLabel }}: </div>
                <div class="flex-col flex-3">{{ csaData.externalRisksValue }}</div>
            </div>
            <div class="flex-row flex-1 mb-1">
                <div class="flex-col flex-1 flex-justify-center label">{{ csaLabels.pmaViewLabel }}: </div>
                <div class="flex-col flex-3">{{ csaData.pmaViewValue }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CommercialSustainability } from '@/models/commercial-sustainability';
import EasLoader from '../loader/eas-loader.vue';
import { CsaLabel } from '@/models/csa-label';
import { GoogleCharts } from 'google-charts';
import CsaUtil from './csa-util';

@Component({
    name: 'csa',
    components: {
        EasLoader,
    },
})
export default class CommercialAndSustainability extends Vue {
    /**
     * Set the Commercial and Sustainability data
     */
    @Prop({ required: true })
    private csaData!: CommercialSustainability;

    /**
     * Set all the label of Commercial and Sustainability layout
     */
    @Prop()
    private csaLabels!: CsaLabel;

    private csaUtil = new CsaUtil();
    private dataloaded = false;
    private trendArrowMap = { Up: 'uparrow', Unchanged: 'rightarrow', Down: 'downarrow' };

    @Watch('csaData')
    private updateComponent(
        newCsaData: CommercialSustainability,
        oldCsaData: CommercialSustainability,
    ): void {
        if (newCsaData && newCsaData !== oldCsaData) {
            this.dataloaded = true;
            this.drawFcfChart();
            this.drawCO2Chart();
        }
    }

    private co2subscript(val: string): string {
        return val ? val.replace(/CO2/g, 'CO₂') : val;
    }

    private mounted(): void {
        GoogleCharts.load(this.drawFcfChart);
        GoogleCharts.load(this.drawCO2Chart);
        this.dataloaded = true;
    }

    private drawFcfChart(): void {
        const options = {
            fontSize: 12,
            vAxis: {
                title: 'm\u20ac',
                titleTextStyle: {
                    italic: false,
                    fontSize: 15,
                },
                gridlines: { count: 4 },
            },
            seriesType: 'scatter',
            colors: ['#FFB503'],
            pointShape: 'diamond',
            series: {
                0: { type: 'line', color: '#0078DC', pointShape: 'circle', pointSize: 3 },
            },
            chartArea: { width: '75%', right: 0, top: 10 },
            pointSize: 5,
        };
        const dataTable = this.csaUtil.getFcfChartDataTable(this.csaData);
        this.$nextTick(() => {
            const el = document.getElementById(this.getFcfChartId());
            if (el) {
                setTimeout(() => {
                    const chart = new GoogleCharts.api.visualization.ComboChart(el);
                    chart.draw(dataTable, options);
                }, 50);
            }
        });
    }

    private drawCO2Chart(): void {
        const options = {
            fontSize: 12,
            vAxis: {
                title: 'ktCO₂',
                titleTextStyle: {
                    italic: false,
                    fontSize: 15,
                },
                gridlines: { count: 4 },
            },
            title: 'Absolute CO2 Emissions',
            titlePosition: 'none',
            chartArea: { width: '75%', right: 0, top: 10 },
            pointSize: 3,
            series: { 0: { color: '#9E9E9E'} },
        };
        const dataTable = this.csaUtil.getCO2ChartDataTable(this.csaData);
        this.$nextTick(() => {
            const el = document.getElementById(this.getCo2ChartId());
            if (el) {
                setTimeout(() => {
                    const chart = new GoogleCharts.api.visualization.LineChart(el);
                    if (this.csaData.isCo2GraphRequired) {
                        chart.draw(dataTable, options);
                    }
                }, 50);
            }
        });
    }

    private getFcfChartId(): string {
        return 'fcf-chart-' + this.csaData.sNo.toString();
    }

    private getCo2ChartId(): string {
        return 'co2-chart-' + this.csaData.sNo.toString();
    }

    get fcfChartId(): string {
        return this.getFcfChartId();
    }

    get co2ChartId(): string {
        return this.getCo2ChartId();
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';
.csa{
    white-space: pre-wrap;
    .boxborder{
        border: 1px solid @uniper-blue-light;
    }
    .fcf-chart{
        height: 12rem;
        width: 100%;
    }
    .co2graphspace{
        min-height: 12rem;
    }
    .co2-chart{
        height: 12rem;
        width: 100%;
    }
    .pre-formatted{
        white-space: pre-wrap;
    }
    .component-header{
        font-weight: bold;
        font-size: 1rem;
    }
    .flex-justify-center{
        justify-content: center;
    }
    .flex-justify-end{
        justify-content: flex-end;
    }
    .uparrow, .rotate-45-degree {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .downarrow {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    .legend-p5-p95 {
        font-size: 1rem;
        color: @contextual-amber;
        margin-right: 0.5rem;
        transform: rotate(45deg);
    }
    .flex-align-end {
        align-items: flex-end;
    }
    .text-no-wrap {
        white-space: nowrap;
    }
}
</style>
