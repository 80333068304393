import Vue from 'vue';
import Vuex from 'vuex';
import { toastModule } from '@/store/toastModule';

Vue.use(Vuex);

const messageList: string[] = [];

export default new Vuex.Store({
    modules: {
        toastModule,
    },
    state: {
        messages: messageList,
        isLoading: false,
        unSavedChanges: {},
    },
    mutations: {
        loading(state): void {
            state.isLoading = !state.isLoading;
        },
        setUnsavedChanges(state, payload): void {
            state.unSavedChanges = payload;
        },
        pushMessage(state, message: string): void {
            state.messages.push(message);
        },
        removeMessage(state): void {
            if (state.messages.length > 0) {
                state.messages.shift();
            }
        },
    },
    actions: {
        resetUnsavedChanges({ commit }): void {
            commit('setUnsavedChanges', {});
        },
        updateUnsavedChanges({ state, commit }, payload): void {
            commit('setUnsavedChanges', { ...state.unSavedChanges, ...payload });
        },
    },
    getters: {
        isLoading(state): boolean {
            return state.isLoading;
        },
        unSavedChanges(state): Record<string, boolean> {
            return state.unSavedChanges;
        },
        getMessage(state): any {
            if (state.messages.length > 0) {
                return state.messages[0];
            } else {
                return null;
            }
        },
    },
});
